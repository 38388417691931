import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";

export default R14.connect(
  class BlockDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.blockDomain = this.props.app.dm.block;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.handleCopyPress = this.handleCopyPress.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.projectType)
        filter.projectType = { eq: this.props.projectType };
      let res = await this.props.app.dm.block.find(
        `
        uid
        projectType
        name
        description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async updateTodoActive(uid, active, dataTable) {
      let res = await this.props.app.dm.todo.update({
        uid: uid,
        active: active,
      });
      dataTable.refresh();
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("blocks");
    }
    get title() {
      return this.props.type
        ? `${this.props.app.dm.block.getTypeLabel(this.props.type)} Blocks`
        : "Blocks";
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      // if (window.confirm("Are you sure?")) {
      //   await this.blockDomain.delete(uid);
      //   dataTable.refresh({ indicator: true });
      // }
    }
    async handleCopyPress(uid, dataTable) {
      // //await this.blockDomain.copy(uid);
      // dataTable.refresh({ indicator: true });
    }
    handleRowPress(row) {
      // this.props.app.nav.to("block", {
      //   uid: row.uid,
      //   key: this.blockDomain.getKeyByType(row.type)
      // });
    }
    render() {
      return (
        <DataTable
          name='blocks'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          //onRowPress={this.handleRowPress}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || this.title}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Create Block'
                value='test1'
                to='blockCreate'
                key='add'
              />
            </PopUpMenu>,
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to(this.props.editAction || "blockEdit", {
                    uid: row.uid,
                    key: this.props.app.dm.project.getKeyByType(
                      this.props.projectType
                    ),
                  })
                }
              />,
              <PopUpMenu
                controlIcon='dotsHorizontal'
                controlIconSize='small'
                key='blockMenu'
                direction='downLeft'
              >
                <PopUpMenuItem
                  icon='documents'
                  label='Create Copy'
                  key='copy'
                  onPress={() =>
                    this.props.app.nav.to("blockCopy", {
                      uid: row.uid,
                      key: this.props.app.dm.project.getKeyByType(
                        this.props.projectType
                      ),
                    })
                  }
                />
                <PopUpMenuItem
                  icon='close'
                  label='Remove'
                  key='remove'
                  onPress={() => this.handleRemovePress(row.uid, dataTable)}
                />
              </PopUpMenu>,
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
});
