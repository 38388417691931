import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import ActivityLogDataTable from '../components/ActivityLogDataTable';
export default R14.connect(
  class ActivityLogScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          scrollEnabled
        >
          <ActivityLogDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});