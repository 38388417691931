import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  ColorInputField,
  SectionHeader,
  StyleSheet,
  View,
  SwitchField,
  FileInputField,
  FadeView,
} from "../core";
import EditForm from "../components/EditForm";

export default R14.connect(
  class ProjectPipelineBlockEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.blockItemLoader = this.blockItemLoader.bind(this);
      this.datasetItemLoader = this.datasetItemLoader.bind(this);
      this.handleBlockValueChange = this.handleBlockValueChange.bind(this);
      this.handleUseAgentValueChange = this.handleUseAgentValueChange.bind(
        this
      );
      this.handleAfterSave = this.handleAfterSave.bind(this);
      this.handleCloudAccessKeyValueChange = this.handleCloudAccessKeyValueChange.bind(
        this
      );
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.state = {
        connectionType: this.props.formData.values.connectionType,
        optionFields: this.props.formData.optionFields || null,
        appModule:
          this.props.formData.block && this.props.formData.block.appModule
            ? this.props.formData.block.appModule
            : null,
        useAgent:
          this.formData.values && this.formData.values.agentCloudAccessKeyUid
            ? true
            : false,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async blockItemLoader(filters = {}) {
      filters.projectType = this.props.formData.values.projectType;
      return await this.props.app.dm.pipelineBlock.fetchBlockSelections(
        filters
      );
    }
    async datasetItemLoader(filters = {}) {
      return await this.props.app.dm.pipelineBlock.fetchDatasetSelections(
        this.props.projectUid,
        filters
      );
    }
    async handleBlockValueChange(val) {
      this.props.app.ui.progressIndicator.show();
      let block = await this.props.app.dm.block.fetchPipelineBlockEditFormData(
        val.value
      );
      let blockOptions = block.options;
      this.setState({
        optionFields: blockOptions && blockOptions.length ? blockOptions : null,
        appModule: block.appModule || null,
      });
      this.props.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    async handleUseAgentValueChange(value, elmt) {
      this.setState({
        useAgent: value ? true : false,
      });
    }
    async handleAfterSave(isSuccess, form, options = {}) {
      if (
        isSuccess &&
        this.props.app.ui.pipeline.exists(this.props.pipelineUid)
      ) {
        let pipeline = await this.props.app.ui.pipeline.instance(
          this.props.pipelineUid
        );
        await pipeline.refresh();
      }
    }
    async handleCloudAccessKeyValueChange(value) {
      let cloudAccessKey = null;
      for (let i in this.formData.cloudAccessKeySelections) {
        if (this.formData.cloudAccessKeySelections[i].value === value) {
          cloudAccessKey = this.formData.cloudAccessKeySelections[i];
          break;
        }
      }
      this.setState({ cloudAccessKey });
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='pipelineBlock'
          redirect={this.props.redirect || "projectPipeline"}
          refreshDataTable={this.props.refreshDataTable || "projectPipeline"}
          onAfterSave={this.handleAfterSave}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='pipelineUid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
              helper='Used as remote folder name'
            />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
          />
          {/* <SelectMenuField
            useValuesOnly
            name='type'
            label='Type'
            items={this.formData.typeSelections}
            required='Please select a type.'
          /> */}
          <SelectMenuField
            name='block'
            label='Block'
            searchable
            onValueChange={this.handleBlockValueChange}
            itemLoader={this.blockItemLoader}
            items={this.formData.blockSelections}
            required='Please select a block.'
          />
          {this.formData.datasetSelections.length ? (
            <SelectMenuField
              name='datasets'
              label='Datasets'
              multiple
              searchable
              itemLoader={this.datasetItemLoader}
              items={this.formData.datasetSelections}
            />
          ) : null}
          {false && this.props.formData.cloudAccessKeySelections.length ? (
            <SelectMenuField
              useValuesOnly
              name='cloudAccessKeyUid'
              label='Cloud Access Key'
              items={this.props.formData.cloudAccessKeySelections}
              helper='Optional'
              onValueChange={this.handleCloudAccessKeyValueChange}
            />
          ) : null}
          <SwitchField
            key='autoRun'
            name='autoRun'
            label='Auto Run'
            helper='Run when input(s) have queued items'
          />
          <SwitchField
            key='useAgentSwitch'
            name='useAgent'
            label='Use R14 Os Agent'
            onValueChange={this.handleUseAgentValueChange}
            value={this.state.useAgent}
          />
          {this.props.formData.agentCloudAccessKeySelections.length ? (
            <FadeView visible={this.state.useAgent}>
              <SelectMenuField
                useValuesOnly
                name='agentCloudAccessKeyUid'
                label='Agent Cloud Access Key'
                items={this.formData.agentCloudAccessKeySelections}
              />
            </FadeView>
          ) : null}

          <FadeView
            visible={
              this.state.appModule &&
              [
                this.props.app.dm.appModule.TYPE_PYTHON_APP,
                this.props.app.dm.appModule.TYPE_NODE_APP,
                this.props.app.dm.appModule.TYPE_TASK,
              ].includes(this.state.appModule.type)
            }
          >
            <SectionHeader level={4} style={styles.sectionHeader}>
              Instance
            </SectionHeader>
            {this.state.appModule && this.state.appModule.gpuAccelerated !== true && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='cpu'
                  label='CPU'
                  items={this.formData.cpuSelections}
                />
                <SelectMenuField
                  useValuesOnly
                  name='memory'
                  label='Memory'
                  items={this.formData.memorySelections}
                />
              </FieldGroup>
            )}
            <FieldGroup>
              <TextInputField
                name='maxInstances'
                label='Max Instances'
                helper='Optional'
                validator={"positiveInt"}
              />
              <TextInputField
                name='inputItemsPerInstance'
                label='Input Items per Instance'
                helper='Optional'
                validator={"positiveInt"}
              />
            </FieldGroup>
          </FadeView>

          <SectionHeader level={4} style={styles.sectionHeader}>
            Appearance
          </SectionHeader>
          <FieldGroup>
            <TextInputField
              name='height'
              label='Height'
              helper='Optional'
              validator={"positiveInt"}
            />
            <TextInputField
              name='width'
              label='Width'
              helper='Optional'
              validator={"positiveInt"}
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='backgroundColor'
              label='Background Color'
              // required='Please enter a background color.'
              validator='hexColor'
              helper='Optional, Dark color suggested.'
            />
            <SelectMenuField
              name='icon'
              label='Icon'
              items={this.formData.iconSelections}
              helper='Optional'
            />
          </FieldGroup>
          {/* <SectionHeader level={4} style={styles.sectionHeader}>
            Statistics
          </SectionHeader>
          <FieldGroup>
            <FileInputField
              name='statConfusionMatrixImageFile'
              label='Confusion Matrix'
              helper='Optional'
            />
            <TextInputField
              name='statPercentageMatrix'
              label='Percentage Matrix'
              helper='Optional'
              validator={"number"}
            />
          </FieldGroup> */}
          {this.state.optionFields && (
            <React.Fragment>
              <SectionHeader level={4} style={styles.sectionHeader}>
                Options
              </SectionHeader>
              <View>
                {this.state.optionFields.map((field) => {
                  let ret = null;
                  let fieldProps = {
                    name: `options_${field.key}`,
                    key: `options_${field.key}`,
                    label: field.label,
                    required: field.required,
                    helper: field.required ? field.helperText : `Optional`,
                  };
                  switch (field.type) {
                    case this.props.app.dm.block.OPTION_TYPE_TEXT_MULTI:
                      ret = (
                        <TextInputField {...fieldProps} multiline autoSize />
                      );
                      break;
                    case this.props.app.dm.block.OPTION_TYPE_TEXT_SECURE:
                      ret = <TextInputField {...fieldProps} secure />;
                      break;
                    case this.props.app.dm.block.OPTION_TYPE_SWITCH:
                      ret = <SwitchField {...fieldProps} />;
                      break;
                    default:
                      ret = <TextInputField {...fieldProps} />;
                  }
                  return ret;
                })}
              </View>
            </React.Fragment>
          )}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    paddingBottom: 16,
  },
});
