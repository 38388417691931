import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  SelectMenuField
} from "../core";
export default R14.connect(
  class ProjectResourceAddScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(projectResourceAddForm) {
      let isSuccess = await this.props.app.dm.project.addResource(
        projectResourceAddForm.values
      );
      if (isSuccess) {
        let dataTable = this.props.refreshDataTable || "projectResources";
        this.props.app.ui.dataTable.exists(dataTable) &&
          this.props.app.ui.dataTable(dataTable).refresh();

        let redirect = {
          route: "projectResources",
          params: { uid: this.props.formData.values.projectUid }
        };
        if (this.props.formData.values.projectType)
          redirect.params.key = this.props.app.dm.project.getKeyByType(
            this.props.formData.values.projectType
          );
        return (
          <Redirect
            to={this.props.redirect || redirect}
          />
        );
      }
    }
    render() {
      return (
        <Form
          name='projectResourceAddForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Add' key='submit' />
          ]}
        >
          <HiddenInputField name='projectUid' />
          <SelectMenuField
            useValuesOnly
            name='resourceUid'
            label='Select Resource'
            items={this.props.formData.resourceSelections}
          />
        </Form>
      );
    }
  }
);
