import R14 from "../core";

export default class ContactGroupDomain extends R14.Domain {
  constructor(key) {
    super();
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr) throw new Error("ContactGroup Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindContactGroups($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ContactGroupFilter) {
        contactGroups(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.contactGroups;
  }

  async create(values, { resourceUid = null }) {
    // Check for resourceUid. If exists, create resourceContactGroup

    console.log("CREATE",values,resourceUid);
    if (resourceUid) {
      values.resourceContactGroups = {
        create: [
          { resourceUid: resourceUid, clientUid: this.dm.userSession.clientUid }
        ]
      };
    }
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateContactGroup($input: CreateContactGroupInput!) {
        createContactGroup(input: $input){
          contactGroup {
            uid
            name
            description
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateContactGroup($input: UpdateContactGroupInput!) {
        updateContactGroup(input: $input){
          contactGroup {
            uid
            name
            description
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async get(uid) {
    console.log("get with uid", uid);
    let res = await this.api.qry(
      `
      query GetContactGroup($uid: ID!) {
       contactGroup(uid: $uid){
          uid
          name
          description
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.contactGroup;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteContactGroup($uid: ID!) {
        deleteContactGroup(uid: $uid){
          contactGroup {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let qry = "";
    let qryVals = {
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } }
    };
    if (uid) {
      qry = `
      query ContactGroupCreateFormData($uid: ID!, $userFilter: UserFilter) {
        contactGroup(uid: $uid){
          uid
          name
          description
          userUids
          emails
        },
        users(filter:$userFilter) {
          nodes {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query ContactGroupEditFormData($userFilter: UserFilter) {
          users(filter:$userFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = res.data.contactGroup || {};
    let formData = {
      values: formVals,
      userSelections: res.data.users.nodes.map(val => ({
        label: val.name,
        value: val.uid
      }))
    };
    return formData;
  }
  async send(contactGroup, message) {
    // console.log(
    //   "ALERT ALERT ALERT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
    //   event.userUids,
    //   resource.uid,
    //   resource.name
    // );

    if (contactGroup.userUids.length > 0) {
      let qry = this.db.qry();
      let qryExp = this.db.qryExp();
      let users = await qry
        .select(["uid", "email"])
        .from("user")
        .where(qryExp.in("uid", contactGroup.userUids))
        .cacheTime(1)
        .run();

      let emails = [];
      users.forEach(
        user =>
          user.email && !emails.includes(user.email) && emails.push(user.email)
      );

      // let subject = `ContactGroup: ${resource.name} - ${event.name}`;
      // let body = `An contactGroup (${event.name}) has been triggered by resource ${resource.name}.`;

      let subject = "Test test test";
      let body = "Test body";
      if (emails.length) {
        return await this.dm.app.sendEmail({
          to: emails,
          from: this.dm.app.EMAIL_ADDRESS_ALERT,
          subject: subject,
          body: body
        });
      }
      //   else eventTask.log = "Error running contactGroup. No emails found.";
      // } else eventTask.log = "Error running contactGroup. No users found.";

      // eventTask.state = this.STATE_ERROR;
      return false;
    }
  }
}
