import React from "react";
import R14, { View, Text } from "../core";
import LogViewer from "../components/LogViewer";
export default R14.connect(
  class ResourceLogScreen extends React.Component {
    constructor(props) {
      super(props);
      this.textLoader = this.textLoader.bind(this);
    }
    async textLoader() {
      let log = await this.props.app.dm.resource.fetchLog(this.props.resourceUid, this.props.logUid);
      return log.text;
    }
    render() {
      return (
        <View>
          <LogViewer 
            textLoader={this.textLoader} 
            autoRefresh={30}
          />
        </View>
      );
    }
  }
);
