import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-twilight";

import R14, {
  StyleSheet,
  ScreenLayout,
  View,
  Surface,
  SectionHeader,
  Text,
  PopUpMenu,
  PopUpMenuItem,
  Colors
} from "../core";

import FileExplorer from "../components/FileExplorer";

export default R14.connect(
  class ProjectCodeEditorScreen extends React.Component {
    constructor(props) {
      super(props);
      this.directoryLoader = this.directoryLoader.bind(this);
      this.handleFileSelect = this.handleFileSelect.bind(this);
      this.handleFileChange = this.handleFileChange.bind(this);
      this.handleEditorLayout = this.handleEditorLayout.bind(this);
      this.editorRef = React.createRef();
      this.state = {
        changed: false,
        filePath: null
      };
      this.fileContent = "";
    }
    componentDidMount() {
      // bind key commands
      console.log("refs", this.refs);
      // this.editorRef.current.commands.addCommand({
      //   name: "saveFile",
      //   bindKey: {
      //     win: "Ctrl-S",
      //     mac: "Command-S",
      //     sender: "editor|cli"
      //   },
      //   exec: (env, args, request) => {
      //     alert("save");
      //   }
      // });
    }
    async directoryLoader(path) {
      let res = await this.props.app.dm.project.fetchCodeListDir(
        this.props.project.uid,
        path
      );
      console.log(res);
      return res;
    }
    async handleFileSelect(path) {
      this.props.app.ui.progressIndicator.show();
      let res = await this.props.app.dm.project.fetchCodeFile(
        this.props.project.uid,
        path
      );
      if (res && res.content) {
        this.fileContent = res.content;
        this.setState({
          changed: false,
          filePath: res.path
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      console.log(res);
    }
    async handleFileChange(newValue) {
      console.log("update handle fileChange");
      this.fileContent = newValue;
      this.setState({
        changed: true,
      });
    }
    async handleFileSave() {
      if (this.state.changed) {
        this.props.app.ui.progressIndicator.show();
        let res = await this.props.app.dm.project.updateCodeFile(
          this.props.project.uid,
          this.state.filePath,
          this.fileContent
        );
        console.log(res);
        this.setState({
          changed: false
        });
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      }
    }
    get editorMode(){
      if(! this.state.filePath) return null;
      let filePath = this.state.filePath.toLowerCase();
      let mode = 'javascript';
      if(filePath.endsWith('.js') || filePath.endsWith('.jsx')) mode = 'javascript';
      if(filePath.endsWith('.py')) mode = 'python';
      return mode;
    }
    handleEditorLayout(event) {
      console.log('handleEditorLayout',);
      this.setState({ editorHeight: `${event.nativeEvent.layout.height}px` });
    }
    render() {
      let project = this.props.project;
      console.log(this.state.editorHeight);
      return (
        <ScreenLayout
          headerTitle={project.name}
          style={styles.screenLayout}
          headerStyle={styles.screenLayoutHeader}
          // headerControlsLeft={[
          //   <StateIndicatorIcon
          //     color={this.props.app.dm.resource.getStateIndicatorColor(
          //       resource.state
          //     )}
          //     style={styles.headerStateIndicatorIcon}
          //     key='resourceStateIndicator'
          //   />
          // ]}
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                label='Edit Project'
                icon='edit'
                key='sshKey'
                onPress={() =>
                  this.props.app.nav.to("projectEdit", {
                    uid: project.uid
                  })
                }
              />
            </PopUpMenu>
          ]}
          // scrollEnabled
        >
          <View style={styles.ide} >
            <Surface style={styles.sidebar}>
              <FileExplorer
                name='code'
                rootPath={`/${this.props.app.dm.project.getKeyByType(project.type)}/${project.key}`}
                activeFilePath={this.state.filePath}
                directoryLoader={this.directoryLoader}
                onFileSelect={this.handleFileSelect}
                style={styles.fileExplorer}
              />
            </Surface>
            <View onLayout={this.handleEditorLayout} style={styles.codeEditor}>
              {this.state.filePath && (
                <AceEditor
                  commands={[
                    {
                      name: "saveFile",
                      bindKey: {
                        win: "Ctrl-S",
                        mac: "Command-S",
                        sender: "editor|cli"
                      },
                      exec: (env, args, request) => {
                        console.log("save save saver save");
                        this.handleFileSave();
                      }
                    }
                  ]}
                  onChange={this.handleFileChange}
                  mode={this.editorMode}
                  theme='twilight'
                  fontSize={15}
                  // onChange={onChange}
                  name='UNIQUE_ID_OF_DIV'
                  width='auto'
                  height={this.state.editorHeight}
                  value={this.fileContent || ""}
                  editorProps={{ $blockScrolling: true }}
                />
              )}
            </View>
          </View>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
    flex: 1,
    flexDirection: "column"
  },
  screenLayoutHeader: {
    marginBottom: 0
  },
  ide: {
    flex: 1,
    flexDirection: "row"
  },
  codeEditor: {
    flex: 1,
    flexDirection: "column"
  },
  sidebar: {
    flex: 0,
    width: 256,
    flexDirection: "column",
    ...StyleSheet.padding(16,0,0,0),
    backgroundColor: StyleSheet.color(Colors.surface).rgba(0.5),
  },
  fileExplorer: {
    flex: 1,
    flexDirection: "column"
  }
});
