import React from 'react';
import StyleSheet from '../StyleSheet';
import View from '../View';

export default class LinkBase extends React.Component {
  constructor(props){
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.ref = React.createRef();
  }
  handlePress(e){
    e.preventDefault();
    this.props.onPress(e);
  }
  measureInWindow(callback){
    this.ref.current.measureInWindow(callback);
  }
  measure(callback){
    this.ref.current.measure(callback);
  }
  render() {
    let linkStyles = [styles.link,this.props.style];
    return (
      <View
        href={this.props.routeProps.route} 
        style={[styles.link,this.props.style]} 
        onClick={this.handlePress}
        htmlTag='a'
        ref={this.ref}
        onLayout={this.props.onLayout}
      >
        {this.props.children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  link: {
    textDecoration: "none"
  }
});