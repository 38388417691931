import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Theme,
  View
} from "../core";

export default R14.connect(
  class ThemeDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.themeDomain = this.props.app.dm.theme;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };

      if ("active" in this.props) filter.active = this.props.active;
      let res = await this.props.app.dm.theme.find(
        `
        uid
        name
        description
        colors {
          key
          hex
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async updateTodoActive(uid, active, dataTable) {
      let res = await this.props.app.dm.todo.update({
        uid: uid,
        active: active
      });
      dataTable.refresh();
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("themes");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          let colors = {};
          row.colors.forEach(color => {
            colors[color.key] = color.hex;
          });
          let colorStyles = StyleSheet.create({
            primary: {
              backgroundColor: colors.primary
            },
            primaryLight: {
              backgroundColor: colors.primaryLight
            },
            primaryDark: {
              backgroundColor: colors.primaryDark
            },
            secondary: {
              backgroundColor: colors.secondary
            },
            secondaryLight: {
              backgroundColor: colors.secondaryLight
            },
            secondaryDark: {
              backgroundColor: colors.secondaryDark
            },
            background: {
              backgroundColor: colors.background
            },
            onBackground: {
              backgroundColor: colors.onBackground
            },
            surface: {
              backgroundColor: colors.surface
            },
            onSurface: {
              backgroundColor: colors.onSurface
            }
          });
          ret = (
            <View style={styles.nameCell}>
              <View style={[styles.swatch]}>
                <View style={[styles.swatchTopLeft, colorStyles.primary]} />
                <View style={[styles.swatchBottomLeft, colorStyles.primary]} />
                <View
                  style={[styles.swatchTopRight, colorStyles.primaryLight]}
                />
                <View
                  style={[styles.swatchBottomRight, colorStyles.primaryDark]}
                />
              </View>
              <View style={[styles.swatch]}>
                <View style={[styles.swatchTopLeft, colorStyles.secondary]} />
                <View
                  style={[styles.swatchBottomLeft, colorStyles.secondary]}
                />
                <View
                  style={[styles.swatchTopRight, colorStyles.secondaryLight]}
                />
                <View
                  style={[styles.swatchBottomRight, colorStyles.secondaryDark]}
                />
              </View>
              <View style={[styles.swatch]}>
                <View style={[styles.swatchTopLeft, colorStyles.background]} />
                <View style={[styles.swatchBottomLeft, colorStyles.surface]} />
                <View
                  style={[styles.swatchTopRight, colorStyles.onBackground]}
                />
                <View
                  style={[styles.swatchBottomRight, colorStyles.onSurface]}
                />
              </View>
              <View>
                <Text
                  style={[
                    styles.cellText,
                    styles.nameCellNameText,
                    row.uid === Theme.key && styles.cellSelectedText
                  ]}
                >
                  {row.name}
                </Text>
                {row.description && (
                  <Text
                    style={[styles.cellText, styles.nameCellDescriptionText]}
                  >
                    {row.description}
                  </Text>
                )}
              </View>
            </View>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.theme.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleRowPress(row) {
      await this.props.app.dm.theme.setCustomTheme(row.uid);
    }
    render() {
      return (
        <DataTable
          name='themes'
          columns={{
            name: {
              label: "Name",
              sortable: true
            }
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          onRowPress={this.handleRowPress}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Themes"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='state'
          initialSortDirection='desc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Create Theme'
                value='test1'
                to='themeCreate'
                key='add'
              />
            </PopUpMenu>
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to("themeEdit", {
                    uid: row.uid
                  })
                }
              />,
              <PopUpMenu
                controlIcon='dotsHorizontal'
                controlIconSize='small'
                key='themeMenu'
                direction='downLeft'
              >
                <PopUpMenuItem
                  icon='close'
                  label='Remove'
                  key='remove'
                  onPress={() => this.handleRemovePress(row.uid, dataTable)}
                />
              </PopUpMenu>
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  nameCell: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 0
  },
  cellText: {
    color: Colors.onSurface
  },
  cellSelectedText: {
    fontWeight: 800,
    color: Colors.secondary
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  },
  swatch: {
    position: "relative",
    flexShrink: 0,
    height: 32,
    width: 32,
    marginRight: 8,
    borderRadius: 16,
    overflow: "hidden",
    ...StyleSheet.border(
      1,
      "solid",
      StyleSheet.color(Colors.onBackground).rgba(0.1)
    )
  },
  swatchTopRight: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 16,
    width: 16
  },
  swatchBottomRight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 16,
    width: 16
  },
  swatchTopLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 16,
    width: 16
  },
  swatchBottomLeft: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 16,
    width: 16
  }
});
