import React from "react";
import R14, {
  Text,
  Card,
  IconButton,
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  FadeView,
  Icon,
  View,
  DraggableView
} from "../core";

export default class PipelineBlockIoWidget extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragCancel = this.handleDragCancel.bind(this);
    this.handleDragCollision = this.handleDragCollision.bind(this);
    this.handleMovePress = this.handleMovePress.bind(this);
    this.handleDeleteDataPress = this.handleDeleteDataPress.bind(this);
  }
  async handleDragStart(event) {
    this.props.onDragStart && this.props.onDragStart(event);
  }
  async handleDrag(event) {
    this.props.onDrag && this.props.onDrag(event);
  }
  async handleDragEnd(event) {
    this.props.onDragEnd && this.props.onDragEnd(event);
  }
  async handleDragCancel(event) {
    this.props.onDragCancel && this.props.onDragCancel(event);
  }
  async handleDragCollision(blocks) {
    this.props.handleDragCollision && this.props.handleDragCollision(blocks);
  }
  handleMovePress() {
    if (this.props.onMoveEnable) this.props.onMoveEnable(this.props.blockIoKey);
  }
  handleDeleteDataPress() {
    if (!window.confirm("Are you sure?")) return;
  }

  get cellSize() {
    return this.props.cellSize || 24;
  }
  get perms() {
    return this.props.permissions;
  }
  createDynamicStyles() {
    // let size = this.props.size || 28;
    // let cellSize = this.cellSize;
    // let block = this.props.block;
    // let ioWidgetOffset = Math.round(this.props.size / 2);

    // Figure out the icon rotation

    let ioPos = null;
    if (this.props.x === 0) {
      ioPos = "left";
    } else if (this.props.x === this.props.block.width) {
      ioPos = "right";
    } else if (this.props.y === 0) {
      ioPos = "top";
    } else if (this.props.y === this.props.block.height) {
      ioPos = "bottom";
    }
    let rotate = 0;
    // let queueItemCountTransform = [];
    // let queueItemMargin = {};
    switch (ioPos) {
      case "left":
        rotate = this.props.type === "input" ? 90 : -90;
        // queueItemCountTransform = [{ translateX: "100%" }, { translateY: -23 }];
        // queueItemMargin = { marginRight: 4 };
        break;
      case "left":
        rotate = this.props.type === "input" ? 90 : -90;
        // queueItemCountTransform = [{ translateX: "100%" }, { translateY: -23 }];
        // queueItemMargin = { marginRight: 4 };
        break;
      case "right":
        rotate = this.props.type === "input" ? -90 : 90;
        // queueItemCountTransform = [
        //   { translateX: "-100%" },
        //   { translateY: -23 },
        // ];
        // queueItemMargin = { marginLeft: 4 };
        break;
      case "top":
        rotate = this.props.type === "input" ? 180 : 0;
        break;
      case "bottom":
        rotate = this.props.type === "input" ? 0 : 180;
        // queueItemCountTransform = [{ translateY: -46 }];
        break;
    }
    let borderColor =
      this.props.ioPathCollision || this.props.pathError
        ? StyleSheet.color(Colors.error).rgba(0.8)
        : this.props.queueItemCount
        ? "#60ad5e"
        : Colors.onBackground;

    return StyleSheet.create({
      icon: {
        transform: [{ rotate: `${rotate}deg` }],
        height: this.props.height,
        width: this.props.width,
        backgroundColor: this.props.queueItemCount
          ? this.props.activeBackgroundColor || "#2e7d32"
          : this.props.backgroundColor || Colors.primaryDark,
        borderColor: borderColor,
      },
      queueItemCount: {
        // transform: queueItemCountTransform,
        // ...queueItemMargin,
      },
      ioWidget: {
        zIndex: this.props.moveEnabled ? 3 : 2,
      },
    });
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    let ioWidgetOffset = Math.round(this.props.size / 2);
    let tooltip = this.props.name
      ? ` ${R14.utils.str.capitalize(this.props.type)}: ${this.props.name}`
      : "N/A";
    if (this.props.queueItemCount)
      tooltip = `${tooltip} (${this.props.queueItemCount})`;
    return (
      <DraggableView
        name={this.props.blockIoKey}
        key={this.props.blockIoKey}
        // style={}
        onDrag={this.handleDrag}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        onDragCancel={this.handleDragCancel}
        onDragCollision={this.handleDragCollision}
        offsetTop={
          (this.props.y + this.props.block.y) * this.props.cellSize -
          ioWidgetOffset
        }
        offsetLeft={
          (this.props.x + this.props.block.x) * this.props.cellSize -
          ioWidgetOffset
        }
        height={this.props.size}
        width={this.props.size}
        disabled={!this.props.moveEnabled}
        style={[styles.ioWidget, dynamicStyles.ioWidget]}
      >
        <View tooltip={tooltip}>
          {!this.perms.pipelineBlock.edit && !this.perms.pipelineBlock.delete && (
            <Icon
              name='chevronUp'
              size="small"
              color={Colors.onPrimaryDark}
              style={[styles.icon, dynamicStyles.icon]}
            />
          )}
          {(this.perms.pipelineBlock.edit || this.perms.pipelineBlock.delete) && (
            <PopUpMenu
              controlIcon='chevronUp'
              controlIconSize='small'
              controlIconStyle={[styles.icon, dynamicStyles.icon]}
              controlIconColor={Colors.onPrimaryDark}
              // Control={
              //   <IconButton
              //     style={[
              //       styles.icon,
              //       dynamicStyles.icon,
              //     ]}
              //     size='small'
              //     icon='chevronUp'
              //   />
              // }
              key='menu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              {this.perms.pipelineBlock.edit && (
                <PopUpMenuItem
                  label='Move'
                  icon='move'
                  key='move'
                  onPress={this.handleMovePress}
                />
              )}
              {this.perms.pipelineBlock.edit && (
                <PopUpMenuItem
                  label='Edit'
                  icon='edit'
                  key='edit'
                  onPress={() => alert("todo")}
                />
              )}
              {this.perms.pipelineBlock.edit && (
                <PopUpMenuItem
                  label='Remove'
                  icon='close'
                  key='remove'
                  onPress={() => alert("todo")}
                />
              )}
            </PopUpMenu>
          )}
          <FadeView
            unmountOnExit
            style={styles.moveOverlay}
            visible={this.props.moveEnabled}
          >
            <Icon
              name='move'
              size='small'
              color={StyleSheet.color(Colors.onPrimaryDark).rgba(0.8)}
            />
          </FadeView>
          <FadeView
            unmountOnExit
            style={[styles.queueItemCount, dynamicStyles.queueItemCount]}
            visible={
              !this.props.moveEnabled && this.props.queueItemCount
                ? true
                : false
            }
          >
            <Text pointerEvents='none' style={styles.queueItemCountText}>
              {this.props.queueItemCount}
            </Text>
          </FadeView>
        </View>
      </DraggableView>
    );
  }
}

let styles = StyleSheet.create({
  ioWidget: {
    position: "absolute",
    // zIndex: 2,
    flex: 0,
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    elevation: 2,
    borderStyle: "solid",
    borderRadius: 14,
    borderWidth: 2,
    borderColor: Colors.onBackground,
    backgroundColor: Colors.primaryDark,
    ...StyleSheet.padding(1),
    ...StyleSheet.margin(0),
  },
  queueItemCount: {
    position: "absolute",
    flex: 0,
    bottom: 22,
    left: 22,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.4),
    ...StyleSheet.padding(0, 4, 0, 4),
    borderRadius: 4,
    height: 16,
    minWidth: 16,
  },
  queueItemCountText: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: "center",
    color: Colors.onBackground,
  },
  // block: {
  //   position: "absolute",
  //   ...StyleSheet.padding(4, 4, 8, 8),
  //   borderRadius: 4,
  //   textAlign: "center",
  //   elevation: 8,
  // },
  // title: {
  //   fontSize: 16,
  // },
  // blockCollision: {
  //   opacity: 0.5,
  // },
  // blockData: {
  //   borderRadius: 24,
  // },
  // stateIndicator: {
  //   ...StyleSheet.margin(8),
  // },
  moveOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    borderRadius: 14,
    backgroundColor: StyleSheet.color(Colors.primaryDark).rgba(0.5),
  },
});
