import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField,
} from "../core";
export default R14.connect(
  class AppModulePackagesManageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCommandValueChange = this.handleCommandValueChange.bind(this);

      this.formData = this.props.formData || {};
      this.appModuleDomain = this.props.app.dm.appModule;
      this.state = {
        command: null,
      };
    }
    async handleCommandValueChange(command) {
      this.setState({
        command: command || null,
      });
    }
    async handleSubmit(form) {
      let ret = null;
      let vals = form.values;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.appModuleDomain.runServerRepoCommand(
          vals.uid,
          vals.repoType,
          vals.command,
          vals.comment,
          vals.name
        );
        if (isSuccess) {
          ret = <Redirect to={this.props.redirect || `projectAppModuleLog`} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='appModulePackagesForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Continue' key='continue' />,
          ]}
        >
          <HiddenInputField key='uid' name='uid' />
          <SelectMenuField
            useValuesOnly
            name='repoType'
            label='Repository'
            items={this.props.formData.repoSelections}
            required='Please select a repository.'
          />
          <SelectMenuField
            useValuesOnly
            name='command'
            label='Command'
            items={this.props.formData.commandSelections}
            onValueChange={this.handleCommandValueChange}
            required='Please select a command.'
          />
          {(this.state.command ===
            this.appModuleDomain.SERVER_REPO_COMMAND_COMMIT ||
            this.state.command ===
              this.appModuleDomain.SERVER_REPO_COMMAND_ADD_COMMIT_PUSH) && (
            <TextInputField
              name='comment'
              label='Comment'
              required='Please enter a comment.'
              multiline
              autoSize
            />
          )}
          {(this.state.command ===
            this.appModuleDomain.SERVER_REPO_COMMAND_BRANCH ||
            this.state.command ===
            this.appModuleDomain.SERVER_REPO_COMMAND_CHECKOUT ||
            this.state.command ===
              this.appModuleDomain.SERVER_REPO_COMMAND_MERGE) && (
            <TextInputField
              name='name'
              label='Branch Name'
              required='Please enter a branch name.'
            />
          )}
        </Form>
      );
    }
  }
);
