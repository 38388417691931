import React from "react";
import R14, {
  Chart,
  StyleSheet,
  View,
  Form,
  SelectMenuField,
  ActivityIndicator,
  SectionHeader,
} from "../core";

// Import fusioncharts.js files from fusioncharts module
// import FusionCharts from 'fusioncharts';
// // Import the timeseries file from fusioncharts module
// import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
// // Import ReactFusioncharts from react-fusioncharts module
// // import ReactFC from 'react-fusioncharts';
// import ReactFC from 'react-fusioncharts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.candy';

// // Add core FusionCharts module and TimeSeries module as dependecies in react-fusioncharts
// ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

// const jsonify = res => res.json();
// // This is the remote url to fetch the data.
// const dataFetch = fetch(
//   'https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/plotting-multiple-series-on-time-axis-data.json'
// ).then(jsonify);
// // This is the remote url to fetch the schema.
// const schemaFetch = fetch(
//   'https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/plotting-multiple-series-on-time-axis-schema.json'
// ).then(jsonify);

// class MultiSeriesTimeAxis extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       // Here timeseriesDs is the configuration object which we will pass as a prop to our ReactFC component.
//       timeseriesDs: {
//         type: 'timeseries',
//         renderAt: 'container',
//         // width: '800',
//         // height: '600',
//         theme: 'candy',
//         dataSource: {
//           // caption: {
//           //   text: 'Sales Analysis'
//           // },
//           // subcaption: {
//           //   text: 'Grocery & Footwear'
//           // },
//           series: 'Type',
//           yAxis: [
//             {
//               plot: 'Sales Value',
//               title: 'Sale Value',
//               format: {
//                 prefix: '%'
//               }
//             }
//           ],
//           // Initially data is set as null
//           data: null
//         }
//       }
//     };

//     // In this method we will create our DataStore and using that we will create a custom DataTable which takes two
//     // parameters, one is data another is schema. Check the method definition to get more info.
//     this.createDataTable = this.createDataTable.bind(this);
//   }

//   createDataTable() {
//     Promise.all([dataFetch, schemaFetch]).then(res => {
//       const data = res[0];
//       const schema = res[1];

//       console.log(data);
//       console.log(schema);

//       // First we are creating a DataStore
//       const fusionDataStore = new FusionCharts.DataStore();
//       // After that we are creating a DataTable by passing our data and schema as arguments
//       const fusionTable = fusionDataStore.createDataTable(data, schema);
//       // Afet that we simply mutated our timeseries datasource by attaching the above
//       // DataTable into its data property.
//       const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
//       timeseriesDs.dataSource.data = fusionTable;
//       this.setState({
//         timeseriesDs
//       });
//     });
//   }

//   // We are creating the DataTable immidietly after the component is mounted
//   componentDidMount() {
//     this.createDataTable();
//   }

//   render() {
//     return (
//       <div className="App">
//         <ReactFC {...this.state.timeseriesDs} />
//       </div>
//     );
//   }
// }

// export default MultiSeriesTimeAxis;

export default R14.connect(
  class ResourceProcessMetricsChart extends React.Component {
    constructor(props) {
      super(props);
      this.handleMetricValueChange = this.handleMetricValueChange.bind(this);
      this.handlePeriodValueChange = this.handlePeriodValueChange.bind(this);
      this.state = {
        metric: this.formData.values.metric,
        period: this.formData.values.period,
        data: null,
        isLoaded: false
      };
      // Check Permissions
      if(! this.props.app.dm.user.checkPermissions(
        "resource",
        "monitor",
        this.props.app.dm.user.PERMISSION_ACCESS_READ
      )) throw new Error("Permission Denied.");
    }
    componentDidMount() {
      this.initMetrics();
    }
    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.metric !== this.state.metric ||
        prevState.period !== this.state.period
      )
        this.initMetrics();
    }
    handleMetricValueChange(metric) {
      this.setState({
        metric: metric,
        isLoaded: false
      });
    }
    handlePeriodValueChange(period) {
      this.setState({
        period: period,
        isLoaded: false
      });
    }
    get formData() {
      return {
        metricSelections: this.props.app.dm.resource.getProcessMetricSelections(),
        periodSelections: this.props.app.dm.resource.getPeriodSelections(),
        values: {
          metric: this.props.app.dm.resource.METRIC_CPU_UTILIZATION,
          period: this.props.app.dm.resource.PERIOD_1_HOUR
        }
      };
    }
    async initMetrics() {
      let periodInfo = this.props.app.dm.resource.getPeriodFilterInfo(
        this.state.period
      );
      let opts = {
        period: periodInfo.period,
        startTime: periodInfo.startTime,
      };
      if (this.props.resourceDockerTaskUid)
        opts.resourceDockerTaskUid = this.props.resourceDockerTaskUid;
      let metrics = await this.props.app.dm.resource.fetchProcessMetricData(
        this.props.resourceUid,
        this.state.metric,
        opts
      );

      // let data = [];
      // metrics.processes.forEach(node=>{
      //   data = [...data, ...node.data.map(({x, y})=>{
      //     let date = new Date(x);
      //     let shortDate = R14.utils.date.toShortDate(date);
      //     let time = R14.utils.date.toTime(date);
      //     // let monthLabel = R14.utils.date.getMonthLabel(date, R14.utils.date.LABEL_TYPE_SHORT);
      //     let dateStr = `${shortDate} ${time}`;
      //     return [dateStr, node.name, y]
      //   })]
      // })

      this.setState({
        data: metrics,
        isLoaded: true
      });
    }
    render() {
      let periodInfo = this.props.app.dm.resource.getPeriodFilterInfo(
        this.state.period
      );
      return (
        <View>
          <Form
            name='processMetricChartForm'
            onSubmit={this.handleSubmit}
            validateBeforeSubmit
            initialValues={this.formData.values}
            style={styles.metricForm}
          >
            <SectionHeader level={2} style={styles.title}>
              Processes
            </SectionHeader>
            <SelectMenuField
              useValuesOnly
              name='metric'
              items={this.formData.metricSelections}
              onValueChange={this.handleMetricValueChange}
              style={styles.headerSelectMenu}
              containerStyle={styles.headerSelectMenuContainer}
              // valueLabelTextStyle={styles.headerSelectMenuValueLabel}
            />
            <SelectMenuField
              useValuesOnly
              name='period'
              items={this.formData.periodSelections}
              onValueChange={this.handlePeriodValueChange}
              style={styles.headerSelectMenu}
              containerStyle={styles.headerSelectMenuContainer}
              // valueLabelTextStyle={styles.headerSelectMenuValueLabel}
            />
          </Form>
          {!this.state.isLoaded && (
            <ActivityIndicator
              size='large'
              containerStyle={styles.activityIndicator}
            />
          )}
          {this.state.isLoaded && (
            <Chart
              name='processMetricChart'
              data={this.state.data}
              style={styles.metricsChart}
              height={300}
              type='timeSeries'
              dateAxis={{
                dateRangeStart: this.props.app.utils.date.roundUpToMinuteInterval(
                  periodInfo.startTime,
                  5
                ),
                dateRangeEnd: new Date(),
                intervalUnit: "second",
                intervalValue: periodInfo.period,
                labelFormats: {
                  minute: "h:mm a",
                  hour: "h:mm a",
                  day: "MMMM d, yyyy"
                }
              }}
            />
          )}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  metricForm: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    ...StyleSheet.padding(0, 12, 0, 16)
  },
  metricsChart: {
    ...StyleSheet.padding(16, 0, 16, 0)
  },
  headerSelectMenu: {
    flex: 0,
    // width: 192,
    minWidth: 80,
    marginBottom: 0
    // ...StyleSheet.margin(-12, 0, 0, -12)
  },
  headerSelectMenuContainer: {
    borderColor: "transparent"
    // borderBottomColor: '#FFF'
  },
  headerSelectMenuValueLabel: {
    fontWeight: "500",
    fontSize: 18
  },
  activityIndicator: {
    height: 300
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    ...StyleSheet.padding(0),
    marginRight: "auto"
  }
});
