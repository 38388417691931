import React from 'react';
import StyleSheet from '../StyleSheet';
import ResizeObserver from 'resize-observer-polyfill';

export default class TextBase extends React.Component {
  constructor(props){
    super(props);
    this.htmlRef = this.props.htmlRef || React.createRef();
  }
  render() {
    let htmlTag = this.props.htmlTag || "span";
    let styles = this.styles();
    let TextComponent = React.createElement(htmlTag,{
      ref: this.htmlRef,
      className:StyleSheet.className([this.props.style, styles.wrap, this.props.pointerEvents && styles.pointerEvents])
    }, this.props.children);
    if(this.props.onLayout){
      this.resizeObserver = new ResizeObserver(entries => {
        this.triggerOnLayout();
      });
    }
    return TextComponent;
  }
  triggerOnLayout(){
    if(! this.props.onLayout) return false;
    let ret = {
      nativeEvent: {
        layout: {
          x: this.htmlRef.current.offsetLeft,
          y: this.htmlRef.current.offsetTop,
          width: this.htmlRef.current.offsetWidth,
          height: this.htmlRef.current.offsetHeight
        }
      }
    };
    this.props.onLayout(ret);
  }
  componentDidMount() {
    /** @todo should the view check for layout changes before calling this? Use   observer? */
    if (this.props.onLayout) {
      //setTimeout(()=>{
       this.triggerOnLayout();
      //},5);
      this.resizeObserver.observe(this.htmlRef.current)
    }
  }
  styles(){
    return StyleSheet.create({
      wrap: {
        whiteSpace: this.props.noWrap ? 'nowrap' : 'pre-wrap'
      },
      pointerEvents: {
        pointerEvents: this.props.pointerEvents
      }
    });
  }
}

