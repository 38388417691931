import { StyleSheet as StyleSheetWeb, css } from 'aphrodite/no-important';
import R14Utils from '../../R14Utils';
import R14 from '../../R14';
import Dimensions from '../Dimensions';
import {ThemeColorValue} from '../Theme';

export default class StyleSheetBase extends R14.Domain{
  static absoluteFill = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
  constructor(styles){
    super();
    let {height, width} = Dimensions.get('window');
    this.state = {
      height: height,
      width: width
    };
    this._styles = styles;
    this._parsedStyles = null;
    this._hasScreenListener = false;
    return new Proxy(this, {
			get : (obj, prop) => {
        /** @todo MAKRE SURE THAT IT IS NOT CONSTANTLY RERENDERING STYLE SHEETS??? */
        if(this._styles[prop] && this._styles[prop].screen && this.state.height && this.state.width){
          // Do nothing, this should trigger reaction
        }
        let styles = this._parse(this._styles);
        if(styles['::placeholder']) throw new Error("SLDKJF");
        if(! this._parsedStyles) this._parsedStyles = StyleSheetWeb.create(styles);
        return obj._parsedStyles[prop];
			},
		});
  }
  static create(styles) {
    return new StyleSheetBase(styles);
  }
  static className(styles) {
    return css(styles);
  }
  static utils = {
    renderCssTransformStr: (transform) => {
      if (typeof transform === 'string') return transform;
      let transformArr = [];
      transform.forEach(trans => {
        for (let t in trans) {
          let val = trans[t];
          if (typeof val !== 'string') {
            switch (t) {
              case "scale":
                // Do nothing 
                break;
              default:
                val = `${val}px`;
                break;
            }
          }
          transformArr.push(`${t}(${val})`);
        }
      });
      return (transformArr.length) ? transformArr.join(' ') : null;
    }
  }
  /** Parses styles from r14 to aphrodite */
  _parse(styles) {
    let nStyles = {};
    for (let name in styles) {
      if(! styles[name]) continue;
      let nStyle = {}
      let style = this._parseStyle(styles[name]);
      if (style !== null && typeof style !== 'undefined') nStyles[name] = style
    }
    return nStyles;
  }
  _parseStyle(style) {
    if(! style) return null;

    let nStyle = {};
   
    // Parse Colors
    for(let p in style){
      if(style[p] instanceof ThemeColorValue) nStyle[p] = style[p].toString();
      else nStyle[p] = style[p];
    }

    // Parse Elevation
    nStyle = this._parseElevation(nStyle);
    // Parse Flex
    if ("flex" in nStyle) {
      nStyle.display = "flex";
      nStyle.flexGrow = nStyle.flex;
      delete nStyle.flex;
      if (!("flexDirection" in nStyle)) nStyle.flexDirection = "column";
    }
    if ("lineHeight" in nStyle && typeof nStyle.lineHeight === 'number') {
      nStyle.lineHeight = `${nStyle.lineHeight}px`;
    }
    if (nStyle.screen) {
      let screenStyle = this._parseStyle(this._parseScreen(nStyle.screen));
      nStyle = {...nStyle, ...screenStyle}
      /** @todo Add screen to domain state so that it responds to connected components */
      // let utils = new R14Utils();
      // let { height, width } = utils.dom.getViewportDimensions();
      delete nStyle.screen;
    }
    if (nStyle.placeholder) {
      nStyle = { ...nStyle, ":placeholder":this._parseStyle(nStyle.placeholder)};
      delete nStyle.placeholder;
    }

    if (nStyle.animationName) {
      nStyle.animationName = this._parse(nStyle.animationName);
    }
    if (nStyle.hover) {
      nStyle = { ...nStyle, ":hover":this._parseStyle(nStyle.hover)};
      delete nStyle.hover;
    }
    if (nStyle.transform) {
      nStyle.transform = StyleSheetBase.utils.renderCssTransformStr(nStyle.transform);
    }

    return nStyle;
  }
  _parseScreen(handler){
    if(! this._hasScreenListener){
      this._hasScreenListener = true;
      Dimensions.addEventListener('change',({window})=>{
        //console.log('Update dimensions ASD', window.height, window.width);
        this.setState({
          height: window.height,
          width: window.width
        });
        this._parsedStyles = StyleSheetWeb.create(this._parse(this._styles));
      });
    }
    let ret = handler({height: this.state.height, width: this.state.width});
      // console.log(ret);
      // console.log("returning", handler, ret, this.state.height, this.state.width);
      return ret;
  }
  _parseElevation(style) {
    if ("elevation" in style) {
      /** @todo make sure these all line up with native, add elevation greater than 12*/
      let elevationMap = [
        "0px 0px 0px 0px",//0
        "0px 1px 2px 1px",//1
        "0px 1px 3px 1px",//2
        "0px 1px 4px 1px",//3
        "0px 1px 6px 1px",//4
        "0px 1px 8px 1px",//5
        "0px 1px 10px 1px",//6
        "0px 2px 11px 1px",//7
        "0px 2px 12px 1px",//8
        "0px 2px 14px 1px",//9
        "0px 2px 15px 1px",//10
        "0px 2px 16px 1px",//11
        "0px 2px 17px 2px",//12
      ];
      if (elevationMap[style.elevation]) {
        let alpha = ".24";
        switch (style.elevation) {
          case 1:
            alpha = ".15";
            break;
          case 2:
          case 3:
          case 4:
            alpha = ".2";
            break;
        }

        style.boxShadow = `${elevationMap[style.elevation]} rgba(0, 0, 0, ${alpha})`;
      }
      delete style.elevation;
    }
    return style;
  }
}