import React from "react";
import R14, {
  StyleSheet,
  Colors,
  FadeView,
  HiddenInputField,
  TextInputField,
  SelectMenuField,
  TimePickerField,
  DatePickerField,
  FieldGroup,
} from "../core";
import EventWidget from "./EventWidget";
export default R14.connect(
  class EventEditScheduleWidget extends React.Component {
    constructor(props) {
      super(props);
      this.handleOccursChange = this.handleOccursChange.bind(this);
      this.handleItemAddPress = this.handleItemAddPress.bind(this);
      this.handleItemRemovePress = this.handleItemRemovePress.bind(this);
      this.formData = this.props.formData || { values: null };
      this.formName = this.props.formName || "events";

      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
      this.eventDomain = this.props.app.dm.event;
      this.dateUtils = this.props.app.utils.date;
      this.state = {
        occurs: (this.formData.values && this.formData.values.occurs) || null,
        items: this.formData.values.schedule,
        showForm: this.formData.values.schedule.length ? false : true,
      };
    }
    async handleOccursChange(value) {
      this.setState({
        occurs: value,
      });
    }
    async handleItemRemovePress(i) {
      let form = this.props.app.ui.form(this.formName);
      let items = this.state.items;
      items.splice(i, 1);
      form.elmts.schedule.value = items;
      this.setState({
        items: items,
        showForm: this.state.showForm || items.length === 0,
      });
    }
    async handleItemAddPress() {
      // Get the form from the context
      let form = this.props.app.ui.form(this.formName);
      let items = this.state.items;

      if (!this.state.showForm) {
        this.setState({
          showForm: true,
        });
        return true;
      }

      if (await form.validate()) {
        items.push(this.eventDomain.createScheduleNode(form.values));
        form.elmts.schedule.value = items;
        this.setState({
          items: items,
          showForm: false,
          occurs: null,
        });
      }
    }
    renderItemLabel(item, idx) {
      let displayText = "";
      switch (item.occurs) {
        case this.eventDomain.OCCURS_FREQUENCY:
          switch (item.frequency.type) {
            case this.eventDomain.FREQUENCY_DAY:
              let days =
                item.frequency.values ||
                (item.frequency.value && [item.frequency.value]);
              displayText = `${this.dateUtils.toTime(
                new Date(item.frequency.at)
              )}: ${days
                .map((val) => this.dateUtils.getDayLabel(val))
                .join(", ")}`;
              break;
            case this.eventDomain.FREQUENCY_ONCE:
              displayText = `${this.dateUtils.toTime(
                new Date(item.frequency.at)
              )} ${this.dateUtils.toShortDate(new Date(item.frequency.at))} `;
              break;
            case this.eventDomain.FREQUENCY_DAILY:
              displayText = `${this.dateUtils.toTime(
                new Date(item.frequency.at)
              )} Daily`;
              break;
          }
          break;
        case this.eventDomain.OCCURS_METRIC:
          displayText = `${this.props.app.dm.resource.getMetricTypeLabel(
            item.metric.type
          )} ${item.metric.operator} ${item.metric.value} for ${
            item.metric.period
          }m`;
          break;
        case this.eventDomain.OCCURS_PROCESS_METRIC:
          displayText = `Process (${
            item.processMetric.name
          }) ${this.props.app.dm.resource.getMetricTypeLabel(
            item.processMetric.type
          )} ${item.processMetric.operator} ${item.processMetric.value} for ${
            item.processMetric.period
          }m`;
          break;
        case this.eventDomain.OCCURS_STATUS_CHECK:
          let statusCheckLabel = "Status Check";
          for (let statusCheck of this.formData.statusCheckSelections) {
            if ((statusCheck.value = item.statusCheck.uid)) {
              statusCheckLabel = statusCheck.label;
              break;
            }
          }
          displayText = `Status Check (${statusCheckLabel}) ${this.props.app.dm.statusCheck.getStateLabel(
            item.statusCheck.state
          )}`;
          break;
        case this.eventDomain.OCCURS_PROJECT_HEALTH_CHECK:
          let healthCheckLabel = "Health Check";
          for (let healthCheck of this.formData.projectHealthCheckSelections) {
            if ((healthCheck.value = item.projectHealthCheck.uid)) {
              healthCheckLabel = healthCheck.label;
              break;
            }
          }
          displayText = `Health Check (${healthCheckLabel}) ${this.props.app.dm.healthCheck.getStateLabel(
            item.projectHealthCheck.state
          )}`;
          break;
        case this.eventDomain.OCCURS_EVENT:
          displayText = `${this.eventDomain.getEventLabel(item.event.type)}`;
          break;
        default:
          return null;
      }
      return displayText;
    }
    renderTimePickerField(name) {
      return (
        <TimePickerField
          name={name}
          label='Time'
          required='Please select a time.'
        />
      );
    }
    render() {
      // let isAddMoreButton = !this.state.items.length || !this.state.showForm;
      return (
        <EventWidget
          title='Schedule'
          onItemAddPress={this.handleItemAddPress}
          addMoreButton={!this.state.items.length || !this.state.showForm}
          onItemRemovePress={this.handleItemRemovePress}
          items={
            this.state.items.length &&
            this.state.items.map((val) => this.renderItemLabel(val))
          }
        >
          <HiddenInputField name='schedule' />
          <FadeView visible={this.state.showForm} style={styles.formFields}>
            <SelectMenuField
              useValuesOnly
              name='occurs'
              label='When should this occur?'
              items={this.formData.occursSelections}
              required='Please select a occurs.'
              onValueChange={this.handleOccursChange}
            />
            {/* {this.renderDateTimeFields()} */}
            {this.state.occurs === this.eventDomain.FREQUENCY_DAY && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='days'
                  label='Day'
                  multiple
                  items={this.formData.daySelections}
                  required='Please select a day.'
                />
                {this.renderTimePickerField("timeDay")}
              </FieldGroup>
            )}
            {this.state.occurs === this.eventDomain.FREQUENCY_ONCE && (
              <FieldGroup>
                <DatePickerField
                  name='date'
                  label='Date'
                  mode='modal'
                  required='Please select a date.'
                  onValueChange={(val)=>console.log(val)}
                />
                {this.renderTimePickerField("timeOnce")}
              </FieldGroup>
            )}
            {this.state.occurs === this.eventDomain.FREQUENCY_DAILY &&
              this.renderTimePickerField("timeDaily")}
            {/* {this.state.occurs &&
              (this.state.occurs === this.eventDomain.FREQUENCY_DAY ||
                this.state.occurs === this.eventDomain.FREQUENCY_ONCE ||
                this.state.occurs === this.eventDomain.FREQUENCY_DAILY) && (
                <TimePickerField
                  name='time'
                  label='Time'
                  required='Please select a time.'
                />
              )} */}
            {this.state.occurs === this.eventDomain.OCCURS_PROCESS_METRIC && (
              <TextInputField
                name='name'
                label='Process Name'
                required='Please enter a value.'
                helper='Use % for wildcard'
              />
            )}
            {(this.state.occurs === this.eventDomain.OCCURS_METRIC ||
              this.state.occurs === this.eventDomain.OCCURS_PROCESS_METRIC) && (
              <React.Fragment>
                <FieldGroup>
                  <SelectMenuField
                    useValuesOnly
                    name='metric'
                    label='Metric'
                    items={
                      this.state.occurs === this.eventDomain.OCCURS_METRIC
                        ? this.formData.metricSelections
                        : this.formData.processMetricSelections
                    }
                    required='Please select a metric.'
                  />
                  <SelectMenuField
                    useValuesOnly
                    name='operator'
                    label='When Value is'
                    items={this.formData.operatorSelections}
                    required='Please select a boolean comparison operator.'
                  />
                </FieldGroup>
                <FieldGroup>
                  <TextInputField
                    name='value'
                    label='Value'
                    required='Please enter a value.'
                  />
                  <SelectMenuField
                    useValuesOnly
                    name='period'
                    label='For at least (minutes)'
                    validator='positiveInt'
                    required='Please enter a time period in minutes.'
                    items={this.formData.periodSelections}
                  />
                </FieldGroup>
              </React.Fragment>
            )}
            {this.state.occurs === this.eventDomain.OCCURS_STATUS_CHECK && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='statusCheck'
                  label='Status Check'
                  items={this.formData.statusCheckSelections}
                  required='Please select a status check.'
                />
                <SelectMenuField
                  useValuesOnly
                  name='state'
                  label='When State is'
                  items={this.formData.statusCheckStateSelections}
                  required='Please select a status check state.'
                />
              </FieldGroup>
            )}
            {this.state.occurs ===
              this.eventDomain.OCCURS_PROJECT_HEALTH_CHECK && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='projectHealthCheck'
                  label='Health Check'
                  items={this.formData.projectHealthCheckSelections}
                  required='Please select a health check.'
                />
                <SelectMenuField
                  useValuesOnly
                  name='state'
                  label='When State is'
                  items={this.formData.projectHealthCheckStateSelections}
                  required='Please select a health check state.'
                />
              </FieldGroup>
            )}
            {this.state.occurs === this.eventDomain.OCCURS_EVENT && (
              <SelectMenuField
                useValuesOnly
                name='event'
                label='Event'
                items={this.formData.eventSelections}
                required='Please select an resource event.'
              />
            )}
          </FadeView>
        </EventWidget>
      );
    }
  }
);
const styles = StyleSheet.create({
  // eventEditScheduleWidget: {
  //   borderRadius: 4,
  //   ...StyleSheet.margin(-8, 0, 16, 0),
  //   ...StyleSheet.padding(12, 12, 12, 12),
  //   borderColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
  //   borderWidth: 1,
  //   borderStyle: "solid",
  // },
  formFields: {
    marginTop: 20,
  },
  sectionHeader: {
    paddingBottom: 16,
  },
  itemAddButton: {
    marginTop: -8,
    marginLeft: "auto",
  },
  scheduleItems: {
    marginBottom: 24,
  },
  scheduleItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    borderRadius: 4,
    ...StyleSheet.padding(4, 4, 4, 8),
    marginBottom: 4,
  },
  scheduleItemDark: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
  },
});
