import React from "react";
import R14 from "../core";
import RelatedAddForm from "../components/RelatedAddForm";

export default R14.connect(
  class EventTaskAddScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <RelatedAddForm
          name='eventTaskAddForm'
          formData={this.props.formData}
          domainName='eventTask'
          refreshDataTable={this.props.refreshDataTable || "eventTasks"}
          redirect={this.props.redirect || {
              route: 'eventTasks',
              params: { uid: this.props.resourceUid }
            }
          }
          relatedFieldName='resourceUid'
          fields={['type']}
          createButtonTitle='Create New Event'
          createAction={this.props.createAction || "eventTaskCreate"}
          selectMenuFieldName='eventUid'
          selectMenuFieldLabel='Select Event'
          selectMenuFieldSelections={this.props.formData.eventSelections}
        />
      );
    }
  }
);
