import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import InputField from "./InputField";
import TextInput from "./TextInput";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

const TextInputField = R14.connectForm(
  /**
   * A text input field component
   */
  class TextInputField extends React.Component {
    static propTypes = {
      /** Label for the text input. */
      label: PropTypes.string,
      /** Value of the text input, array if multiple. */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      /** Value of the text input for multiple. */
      textValue: PropTypes.string,
      /** The text input varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** If true, disable the text input */
      disabled: PropTypes.bool,
      /** If true, displays a secure text input for things like passwords */
      secure: PropTypes.bool,
      /** Handler to be called when the text inputs value is channed  */
      onChangeText: PropTypes.func,
      /** Handler to be called when the user taps the text input  */
      onPress: PropTypes.func,
      /** Handler to be called when the text input gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the text input loses focus  */
      onBlur: PropTypes.func,
      /** Handler to be called for a multiple input when value added  */
      onValueAdd: PropTypes.func,
      /** Handler to be called for a multiple input when value removed  */
      onValueRemove: PropTypes.func,
      /** The name of the icon to be displayed to the left of the input field  */
      icon: PropTypes.string,
      /** The function to be called when the icon is pressed  */
      onIconPress: PropTypes.func,
      /** The name of the icon to be displayed to the right of the input field  */
      iconRight: PropTypes.string,
      /** The function to be called when the iconRight is pressed  */
      onIconRightPress: PropTypes.func,
      /** Helper text displayed below the input. */
      helper: PropTypes.string,
      /** If true, text content can be multiple lines */
      multiline: PropTypes.bool,
      /** If true, the input will accept an array of values.  */
      multiple: PropTypes.bool,
      /** If true, any values added to multiple inputs must be unique.  */
      unique: PropTypes.bool,
      /** If true, multiline text input will grow or shrink with content */
      autoSize: PropTypes.bool,
      /** If false, auto complete is disabled */
      autoComplete: PropTypes.bool,
      /** Style to be applied to the input field. */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Helper text style to be applied to the input field helper. */
      helperTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Label text style to be applied to the input field helper label. */
      labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** The function to be called when getting all form values (for example, on submit), or if using elmt.parseValue()  */
      valueParser: PropTypes.func,
    };
    static defaultProps = {
      variant: "outlined",
      autoSize: false,
      multiple: false,
    };
    constructor(props) {
      super(props);
      let opts = {};
      // Form multiple, use text value instead of value
      if (this.props.multiple) opts.useTextValue = true;
      this.elmt = this.props.form.addElement(props, opts);
      this.handleChangeText = this.handleChangeText.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleAutoFill = this.handleAutoFill.bind(this);
      this.handleValueAdd = this.handleValueAdd.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
      this.handleValueRemove = this.handleValueRemove.bind(this);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    handleChangeText(text) {
      this.props.multiple
        ? this.elmt.setTextValue(text)
        : this.elmt.setValue(text);
      if (this.props.onChangeText)
        this.props.onChangeText(text, this.elmt, this.elmt.form);
      this.elmt.form.triggerValueChange(text, this.elmt);
    }
    handleKeyPress(event){
      if(this.props.handleKeyPress) this.props.handleKeyPress(event);
      if(this.props.multiple){
        if(event.nativeEvent.key === 'Enter'){
          event.preventDefault();
          this.handleValueAdd();
        }
      }
    }
    async handleValueAdd() {
      let text = this.elmt.textValue;
      if (!text) return false;
      if (!this.props.multiple)
        throw new Error("Text Input Field Error: handleAdd for multiple only");

      let valid = await this.elmt.validate({
        useTextValue: true,
        unique: this.props.unique,
      });

      if (valid !== true) return false;

      if (
        this.props.unique &&
        this.elmt.value &&
        this.elmt.value.includes(text)
      ) {
        this.elmt.error = "Value must be unique";
        return false;
      }

      if (this.props.onValueAdd)
        this.props.onValueAdd(text, this.elmt.value, this.elmt, this.elmt.form);

      let val = this.elmt.value;
      if (!val) val = [];
      if (val.push(text));
      this.elmt.setValue(val);
      this.elmt.setTextValue("");
    }
    handleValueRemove(val) {
      if (!this.props.multiple)
        throw new Error(
          "Text Input Field Error: handleRemove for multiple only"
        );
      if (this.props.onValueRemove)
        this.props.onValueRemove(
          val,
          this.elmt.value,
          this.elmt,
          this.elmt.form
        );

      let vals = this.elmt.value;
      if (!vals) return;
      vals = vals.filter((n) => n !== val);
      if (vals !== this.elmt.value) this.elmt.setValue(vals);
    }
    handleAutoFill(action) {
      switch (action) {
        case "start":
          this.elmt.setAutoFill(true);
          break;
        default:
          this.elmt.setAutoFill(false);
      }
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    renderValue() {
      let val = this.elmt.value;
      if (this.props.multiple) {
        val = val ? val : val;
        if (val && !Array.isArray(val))
          throw new Error(
            "Text Input Error: Given value must be array for multiple text input"
          );
      } else val = val ? `${val}` : "";
      return val;
    }
    render() {
      let styles = this.styles();
      let variantStyles = this.variantStyles();
      let type = "text";
      if (this.props.secure) type = "password";
      return (
        <InputField
          {...this.props}
          element={this.elmt}
          InputComponent={
            <TextInput
              type={type}
              name={this.elmt.name}
              ref={this.elmt.ref}
              placeholder={
                this.elmt.isActive && this.props.placeholder
                  ? this.props.placeholder
                  : null
              }
              value={this.renderValue()}
              textValue={this.elmt.textValue ? `${this.elmt.textValue}` : ""}
              onChangeText={this.handleChangeText}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onValueAdd={this.handleValueAdd}
              onValueRemove={this.handleValueRemove}
              onKeyPress={
                this.props.onKeyPress || this.props.multiple
                  ? this.handleKeyPress
                  : null
              }
              autoFocus={this.props.autoFocus || null}
              autoSelect={this.props.autoSelect || null}
              readOnly={this.props.readOnly || null}
              // valueParser={(val) => {
              //   console.log("value parser", val);
              //   return val;
              // }}
              style={[
                styles.textInput,
                variantStyles.textInput,
                this.props.style,
              ]}
              multipleStyle={[
                styles.textInputMultiple,
                variantStyles.textInputMultiple,
                this.props.multipleStyle,
              ]}
              secureTextEntry={this.props.secure || false}
              autoComplete={this.props.autoComplete}
              multiline={this.props.multiline || false}
              multiple={this.props.multiple || false}
              autoSize={this.props.autoSize || false}
              onAutoFill={this.handleAutoFill}
            />
          }
        />
      );
    }
    variantStyles() {
      let hasVal = this.elmt.value ? true : false;
      if (this.props.multiple)
        hasVal = this.elmt.value && this.elmt.value.length ? true : false;

      switch (this.props.variant) {
        case "outlined":
          return StyleSheet.create({
            textInput: {
              paddingTop: 16,
              paddingBottom: 16,
              // height: this.elmt.isFocused ? 52 : 54,
              paddingLeft: this.props.icon ? 43 : 11,
              paddingRight: this.props.iconRight ? 43 : 11,
              lineHeight: 22,
              height: 52,
              // minHeight: 54
            },
            textInputMultiple: {
              // ...StyleSheet.margin(
              //   this.elmt.isFocused ? -1 : 0,
              //   this.elmt.isFocused && hasVal ? -1 : 0,
              //   this.elmt.isFocused ? -1 : 0,
              //   this.elmt.isFocused && hasVal ? -1 : 0
              // ),
            },
          });
          break;
        default:
          return StyleSheet.create({
            textInput: {
              paddingTop: 20,
              paddingBottom: 5,
              minHeight: 55,
              lineHeight: 30,
            },
          });
      }
    }
    styles() {
      return StyleSheet.create({
        textInput: {
          margin: 0,
          paddingLeft: this.props.icon ? 44 : 12,
          paddingRight: this.props.iconRight ? 44 : 12,
          width: "100%",
          backgroundColor: "transparent",
          color: Colors.onBackground,
          fontSize: 16,
        },
      });
    }
  }
);
export default TextInputField;
