import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import StatusCheckDataTable from "../components/StatusCheckDataTable";
export default R14.connect(
  class ScriptsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "statusCheckCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <StatusCheckDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
