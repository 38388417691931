import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectHealthCheckDataTable from "../components/ProjectHealthCheckDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectsHealthChecksScreen extends React.Component {
    render() {
      let project = this.props.project;
      return (
        <ProjectScreenLayout
          project={this.props.project}
          headerTitle="Dashboard"
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.app.dm.project.getKeyByType(project.type),
              "healthCheck",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("projectHealthCheckCreate", {
                  uid: project.uid,
                  key: this.props.app.dm.project.getKeyByType(project.type)
                }),
              icon: "add"
            }
          }
        >
          <ProjectHealthCheckDataTable
            projectUid={project.uid}
            projectType={project.type}
            widget={false}
          />
        </ProjectScreenLayout>
      );
    }
  }
);
