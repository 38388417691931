import React from "react";
import R14, { View, Text } from "../core";
import LogViewer from "../components/LogViewer";
import PipelineBlockLogViewer from "../components/PipelineBlockLogViewer";
export default R14.connect(
  class ProjectPipelineBlockLogScreen extends React.Component {
    render() {
      return (
        <PipelineBlockLogViewer uid={this.props.uid}/>
      );
    }
  }
);