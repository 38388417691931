import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectPipelineLogDataTable from "../components/ProjectPipelineLogDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectsPipelineLogScreen extends React.Component {
    render() {
      return (
        <ProjectScreenLayout
          project={this.props.project}
          breadCrumbs={[
            {
              label: this.props.pipeline.name,
              to: {
                route: "projectPipeline",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                  pipelineUid: this.props.pipeline.uid,
                },
              },
            },
          ]}
          scrollEnabled
          DataTableComponent={<ProjectPipelineLogDataTable {...this.props} />}
        />
      );
    }
  }
);
