import React from "react";
import PropTypes from "prop-types";
import R14, { StyleSheet, Colors, View, Text } from "../core";
import StateIndicatorIcon from "./StateIndicatorIcon";

export default class StateIndicatorText extends React.PureComponent {
  static propTypes = {
    /** The label for the indicator */
    label: PropTypes.string,
    /** The color of the indicator */
    color: PropTypes.oneOf(["red", "green", "yellow"]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the label */
    labelStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };
  static defaultProps = {
    color: "green"
  };
  render() {
    let indicatorStyle = [styles.indicator, styles[this.props.color]];
    return (
      <View style={[styles.indicatorText, this.props.style]}>
        <StateIndicatorIcon
          color={this.props.color}
          style={[styles.stateIndicatorIcon, this.props.iconStyle]}
        />
        <Text style={[styles.text, this.props.labelStyle]}>{this.props.label}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  indicatorText: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  stateIndicatorIcon: {
    marginRight: 8
  }
});
