import React from "react";
import R14, {
  StyleSheet,
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  FieldGroup
} from "../core";
export default R14.connect(
  class StatusCheckEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.statusCheckDomain = this.props.app.dm.statusCheck;
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let opts = {};
      let ret = null;
      if (this.props.resourceUid) opts.resourceUid = this.props.resourceUid;
      try {
        this.props.app.ui.progressIndicator.show();
        let isSuccess = await this.props.app.dm.statusCheck[editMethod](
          form.values,
          opts
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "statusChecks";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "statusChecks"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='statusCheckForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          style={styles.statusCheckForm}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              autoFocus
            />
            <TextInputField name='description' label='description' />
          </FieldGroup>

          <TextInputField
            name='content'
            label='Commands'
            required='Please enter a command.'
            multiline
            autoSize
            style={styles.contentField}
            helper='echo "r14.status:PASS"'
          />
        </Form>
      );
    }
  }
);
let styles = StyleSheet.create({
  statusCheckForm: {
    // maxWidth: 900
  },
  contentField: {
    maxHeight: 160,
    width: "100%"
  }
});
