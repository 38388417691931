import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectDataTable from "../components/ProjectDataTable";
export default R14.connect(
  class ProjectsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.type);
    }
    get perms() {
      return this.props.app.dm.user.getPermissions(this.projectKey);
    }
    render() {
      let editTo =
        this.props.type === this.props.app.dm.project.TYPE_ROOT
          ? "rootProjectCreate"
          : {
              route: "projectCreate",
              params: {
                key: this.projectKey,
              },
            };
      return (
        <ScreenLayout
          floatingActionButton={
            this.perms.create
              ? {
                  to: editTo,
                  icon: "add",
                }
              : null
          }
          scrollEnabled
        >
          <ProjectDataTable {...this.props} />
        </ScreenLayout>
      );
    }
  }
);
