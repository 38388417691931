import React from "react";
import R14, { Card, StyleSheet } from "../core";
import DashboardWidget from "./DashboardWidget";

export default class DashboardCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <DashboardWidget
        size={this.props.size}
        surface={false}
      >
        <Card
          titleStyle={styles.titleStyle}
          {...this.props}
          style={styles.dashboardCard}
          contentStyle={styles.cardContent}
          surfaceStyle={styles.cardSurface}
        >
          {this.props.children}
        </Card>
      </DashboardWidget>
    );
  }
}
const styles = StyleSheet.create({
  dashboardCard: {
    flex: 1,
    flexBasis: 0,
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: "flex-start",
    ...StyleSheet.margin(0),
    borderRadius: 0,
    // width: "100%",
    // screen: ({ width }) => {
    //   if (width <= 800)
    //     return {
    //       width: "100%"
    //     };
    // }
  },
  titleStyle: {
    fontSize: 18,
    fontWeight: "500",
  },
  cardSurface: {
    borderRadius: 0,
    ...StyleSheet.margin(0),
  },
  cardContent: {
    flex: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});
