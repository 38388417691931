import R14 from "../core";
// module.exports = class ProjectDomain extends R14.DomainInstances {
export default class ProjectDomain extends R14.DomainInstances {
  constructor(key) {
    super();
    this.TYPE_ROOT = "ROOT"
    this.TYPE_CLOUD = "CLOUD";
    this.TYPE_DEV = "DEV";
    this.TYPE_AI = "AI";
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!this.dm.user.hasAdminRole)
      options.filter.userUids = { eq: this.dm.userSession.uid };

    let result = await this.api.qry(
      `
    query FindProjects($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ProjectFilter) {
      projects(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
        totalCount @include(if: $totalCount)
        nodes {
          ${fieldsStr}
        }
      }
    }`,
      options
    );
    return result.data.projects;
  }
  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let project = new ProjectInstanceDomain(uid, options);
    await project.init();
    this.addInstance(uid, project);
    return project;
  }

  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
  async getProjectHealthCheck(uid) {
    let res = await this.api.qry(
      `
      query GetProjectHealthCheck($uid: ID!, $projectHealthCheckFilter: ProjectHealthCheckFilter) {
        project(uid: $uid){
          uid
          name
          projectHealthChecks(filter:$projectHealthCheckFilter){
            nodes {
              uid
              name
            }
          }
        }
      }
      `,
      {
        uid: uid,
        projectHealthCheckFilter: {
          clientUid: { eq: this.dm.userSession.clientUid },
        },
      }
    );
    let ret = null;
    ret =
      res.data &&
      res.data.project &&
      res.data.project.projectHealthChecks &&
      res.data.project.projectHealthChecks.nodes &&
      res.data.project.projectHealthChecks.nodes.length
        ? res.data.project.projectHealthChecks.nodes[0]
        : null;
    return ret;
  }
  async findResources(uid, fieldsStr, options = {}) {
    options.uid = uid;
    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    let res = await this.api.qry(
      `
      query FindProjectResources($uid: ID!, $page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceFilter) {
        project(uid: $uid) {
          resources(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
            totalCount @include(if: $totalCount)
            nodes {
              ${fieldsStr}
            }
          }
          uid
          name
        }
      }`,
      options
    );
    return res.data.project.resources;
  }
  async get(uid, options = {}) {
    let appModuleQry = "";
    let appModuleFilter = "";
    let datasetQry = "";
    let qryVals = {
      uid: uid,
    };
    if (options.appModules) {
      qryVals.appModuleFilter = {
        clientUid: { eq: this.dm.userSession.clientUid },
      };
      appModuleFilter = ", $appModuleFilter: AppModuleFilter";
      appModuleQry = `
        appModules(filter:$appModuleFilter){
          nodes {
            uid
            key
            name
            state
            type
            serverState {
              type
              state
            }
            resource {
              name
              type
              state
              uid
              publicIpAddress
            }
          }
        }
      `;
    }
    if(options.datasets){
      datasetQry = `
        datasets {
          nodes {
            uid
            name
          }
        }
      `;
    }
    let res = await this.api.qry(
      `
      query GetProject($uid: ID!${appModuleFilter}) {
       project(uid: $uid){
          uid
          key
          name
          description
          type
          ${appModuleQry}
          ${datasetQry}
        }
      }`,
      qryVals
    );

    return res.data.project;
  }

  async create(values) {
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input){
          project {
            uid
            name
            description
          }
        }
      }`,
      {
        input: this.parseSubmitValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateProject($input: UpdateProjectInput!) {
        updateProject(input: $input){
          project {
            uid
            name
            description
          }
        }
      }`,
      {
        input: this.parseSubmitValues(values),
      }
    );
    return true;
  }
  parseSubmitValues(values) {
    let newValues = { ...values };
    newValues.clientUid = this.dm.userSession.clientUid;
    newValues.resourceUids = values.resources
      ? values.resources.map((val) => val.value)
      : null;
    delete newValues.resources;
    newValues.userUids = values.users
      ? values.users.map((val) => val.value)
      : null;
    delete newValues.users;
    newValues.datasetUids = values.datasets
      ? values.datasets.map((val) => val.value)
      : null;
    delete newValues.datasets;
    return newValues;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteProject($uid: ID!) {
        deleteProject(uid: $uid){
          project {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }

  async initDashboard(uid, type) {
    let res = null;
    let projectData = null;
    let ret = {};
    switch (type) {
      case this.TYPE_AI:
      case this.TYPE_DEV:
        let appModuleFilter = {
          clientUid: { eq: this.dm.userSession.clientUid },
        };
        if (this.dm.userSession.role !== this.dm.user.ROLE_SUPER_ADMIN)
          appModuleFilter.userUids = { eq: this.dm.userSession.uid };

        res = await this.api.qry(
          `
        query GetProjectDashboard($projectUid: ID!, $appModuleFilter: AppModuleFilter!) {
          project(uid: $projectUid){
            uid
            key
            name
            description
            type
            appModules(filter:$appModuleFilter){
              nodes {
                uid
                projectUid
                key
                name
                state
                type
                serverResourceDockerTaskUids
                serverState {
                  type
                  state
                  port
                  publicIpAddress
                }
                resource {
                  name
                  type
                  state
                  uid
                  publicIpAddress
                }
              }
            }
          }
        }`,
          {
            projectUid: uid,
            appModuleFilter: appModuleFilter,
          }
        );

        projectData = res.data;

        // Get the ports and subscribe
        let appModuleUids = projectData.project.appModules.nodes.map(
          (appModule) => appModule.uid
        );

        let { data: projectDetailsData } = await this.api.qry(
          `
        query GetProjectDashboardDetails($appModulePortFilter: AppModulePortFilter!, $appModuleRepoFilter: AppModuleRepoFilter!, $resourceDockerTaskFilter: ResourceDockerTaskFilter!) {
          appModulePorts(filter: $appModulePortFilter){
            nodes {
              uid
              appModuleUid
              port
              type
            }
          }
          appModuleRepos(filter: $appModuleRepoFilter){
            nodes {
              uid
              appModuleUid
              type
            }
          }
          resourceDockerTasks(filter:$resourceDockerTaskFilter){
            nodes {
              uid
              type
              subtype
              appModuleUid
              publicIpAddress
              state
              containers {
                ports {
                  hostPort
                  containerPort
                }
              }
            }
          }
        }`,
          {
            projectUid: uid,
            appModulePortFilter: {
              appModuleUid: { in: appModuleUids },
              clientUid: { eq: this.dm.userSession.clientUid },
            },
            appModuleRepoFilter: {
              appModuleUid: { in: appModuleUids },
              clientUid: { eq: this.dm.userSession.clientUid },
            },
            resourceDockerTaskFilter: {
              appModuleUid: { in: appModuleUids },
              clientUid: { eq: this.dm.userSession.clientUid },
              state: { eq: this.dm.resourceDockerTask.STATE_RUNNING },
            },
          }
        );
        let appModulePorts = {};
        let ports = {};
        let ipAddresses = {};

        projectDetailsData.appModulePorts.nodes.forEach((appModulePort) => {
          if (!ports[appModulePort.appModuleUid])
            ports[appModulePort.appModuleUid] = {};
          ports[appModulePort.appModuleUid][appModulePort.type] = {
            uid: appModulePort.uid,
            port: appModulePort.port,
            type: appModulePort.type,
          };
        });

        let resourceDockerTasks = {};

        // Get resource docker task
        projectDetailsData.resourceDockerTasks.nodes.forEach(
          (resourceDockerTask) => {
            if (
              resourceDockerTask.containers &&
              resourceDockerTask.containers.length
            ) {
              if (
                resourceDockerTask.containers[0].ports &&
                resourceDockerTask.containers[0].ports.length
              ) {
                let dockerPorts = resourceDockerTask.containers[0].ports[0];

                if (dockerPorts && dockerPorts.hostPort) {
                  if (!ports[resourceDockerTask.appModuleUid])
                    ports[resourceDockerTask.appModuleUid] = {};
                  ports[resourceDockerTask.appModuleUid][
                    resourceDockerTask.subtype
                  ] = {
                    uid: null,
                    port: dockerPorts.hostPort,
                    type: resourceDockerTask.subtype,
                  };
                }
              }
            }
            if (resourceDockerTask.publicIpAddress) {
              if (!ipAddresses[resourceDockerTask.appModuleUid])
                ipAddresses[resourceDockerTask.appModuleUid] = {};
              ipAddresses[resourceDockerTask.appModuleUid][
                resourceDockerTask.subtype
              ] = resourceDockerTask.publicIpAddress;
            }
          }
        );
        // Get ipAddress / ports and ip from docker tasks
        // let appModules = projectData.project.appModules.nodes.map(
        //   (appModule) => {

        //   }
        // );

        // Fill in default ports
        projectData.project.appModules.nodes.forEach((appModule) => {
          let defaultPorts = this.dm.appModule.getDefaultPortsByType(
            appModule.type
          );
          if (!ports[appModule.uid]) ports[appModule.uid] = {};
          for (let portType in defaultPorts) {
            if (!ports[appModule.uid][portType]) {
              ports[appModule.uid][portType] = {
                uid: null,
                port: defaultPorts[portType],
                type: portType,
              };
            }
          }
        });

        // Fill app module ports
        for (let appModuleUid in ports) {
          if (!appModulePorts[appModuleUid]) appModulePorts[appModuleUid] = [];
          for (let portType in ports[appModuleUid]) {
            appModulePorts[appModuleUid].push(ports[appModuleUid][portType]);
          }
        }

        let appModuleRepos = {};
        projectDetailsData.appModuleRepos.nodes.forEach((appModuleRepo) => {
          if (!appModuleRepos[appModuleRepo.appModuleUid])
            appModuleRepos[appModuleRepo.appModuleUid] = [];

          appModuleRepos[appModuleRepo.appModuleUid].push({
            uid: appModuleRepo.uid,
            type: appModuleRepo.type,
          });
        });

        // Get the public IP

        let appModules = projectData.project.appModules.nodes.map(
          (appModule) => {
            return {
              ...appModule,
              appModulePorts: {
                nodes: appModulePorts[appModule.uid] || [],
              },
              appModuleRepos: {
                nodes: appModuleRepos[appModule.uid] || [],
              },
            };
          }
        );

        ret = {
          ...projectData.project,
          appModules: appModules,
        };

        break;
      case this.TYPE_CLOUD:
        let projectHealthCheckFilter = {
          clientUid: { eq: this.dm.userSession.clientUid },
        };
        res = { data: res } = await this.api.qry(
          `
          query GetProjectDashboard($projectUid: ID!, $projectHealthCheckFilter: ProjectHealthCheckFilter!) {
            project(uid: $projectUid){
              uid
              name
              description
              type
              projectHealthChecks(filter:$projectHealthCheckFilter){
                nodes {
                  uid
                  name
                }
              }
            }
          }`,
          {
            projectHealthCheckFilter: projectHealthCheckFilter,
            projectUid: uid,
          }
        );
        projectData = res.data.project;
        ret = {
          uid: projectData.uid,
          name: projectData.name,
          description: projectData.description,
          type: projectData.type,
          projectHealthCheck:
            projectData.projectHealthChecks &&
            projectData.projectHealthChecks.nodes &&
            projectData.projectHealthChecks.nodes.length
              ? projectData.projectHealthChecks.nodes[0]
              : null,
        };
        break;
      default:
        throw new Error(
          `Project Dashboard Error: Project type '${type}' not found`
        );
    }

    return ret;
  }
  async fetchResourceSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    let res = await this.dm.resource.find(
      `
      uid
      name
      resourceId
      type
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: `${
              val.name || val.resourceId
            } (${this.dm.resource.getTypeLabel(val.type)})`,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchUserSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    let res = await this.dm.user.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchDatasetSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if(filters.type){
      filter.projectType = { eq: filters.type };
    }
    console.log(filters);
    let res = await this.dm.dataset.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    let qryVals = {
      resourcesPage: 1,
      resourcesPerPage: 50,
      resourceFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      usersPage: 1,
      usersPerPage: 50,
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      datasetsPage: 1,
      datasetsPerPage: 50,
      datasetFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    if (options.type) qryVals.datasetFilter.projectType = { eq: options.type };
    // let initialResourceValueLabels = {};
    // let initialUserValueLabels = {};
    let formVals = {};
    if (uid) {
      qry = `
        query ProjectEditFormData($uid: ID!, $usersPage: Int, $usersPerPage: Int, $userFilter: UserFilter, $resourcesPage: Int, $resourcesPerPage: Int, $resourceFilter: ResourceFilter, $datasetsPage: Int, $datasetsPerPage: Int, $datasetFilter: DatasetFilter) {
          project(uid: $uid){
            uid
            key
            name
            description
            resources(filter:$resourceFilter) {
              nodes {
                uid
                name
                resourceId
                type
              }
            }
            users(filter:$userFilter) {
              nodes {
                uid
                name
              }
            }
            datasets(filter:$datasetFilter) {
              nodes {
                uid
                name
              }
            }
          }
          resources(filter:$resourceFilter, page: $resourcesPage, resultsPerPage: $resourcesPerPage) {
            nodes {
              uid
              name
              resourceId
              type
            }
          }
          users(filter:$userFilter, page: $usersPage, resultsPerPage: $usersPerPage) {
            nodes {
              uid
              name
            }
          }
          datasets(filter:$datasetFilter, page: $datasetsPage, resultsPerPage: $datasetsPerPage) {
            nodes {
              uid
              name
            }
          }
        }
      `;
      qryVals.uid = uid;
    } else {
      qry = `
        query ProjectCreateFormData($usersPage: Int, $usersPerPage: Int,$userFilter: UserFilter, $resourcesPage: Int, $resourcesPerPage: Int, $resourceFilter: ResourceFilter, $datasetsPage: Int, $datasetsPerPage: Int, $datasetFilter: DatasetFilter) {
          resources(filter:$resourceFilter, page: $resourcesPage, resultsPerPage: $resourcesPerPage){
            nodes {
              uid
              name
              type
            }
          }
          users(filter:$userFilter, page: $usersPage, resultsPerPage: $usersPerPage) {
            nodes {
              uid
              name
            }
          }
          datasets(filter:$datasetFilter, page: $datasetsPage, resultsPerPage: $datasetsPerPage) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }
    let res = await this.api.qry(qry, qryVals);

    if (uid) {
      formVals = res.data.project || {};
      formVals.resources =
        formVals.resources && formVals.resources.nodes.length
          ? formVals.resources.nodes.map((val) => ({
              label: `${
                val.name || val.resourceId
              } (${this.dm.resource.getTypeLabel(val.type)})`,
              value: val.uid,
            }))
          : null;
      formVals.users =
        formVals.users && formVals.users.nodes.length
          ? formVals.users.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : null;
      formVals.datasets =
        formVals.datasets && formVals.datasets.nodes.length
          ? formVals.datasets.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : null;
    }

    if (options.type) formVals.type = options.type;
    let formData = {
      values: formVals,
      resourceSelections: res.data.resources.nodes.map((val) => ({
        label: `${val.name || val.resourceId} (${this.dm.resource.getTypeLabel(
          val.type
        )})`,
        value: val.uid,
      })),
      userSelections: res.data.users.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      datasetSelections: res.data.datasets.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      typeSelections: [
        { label: "Cloud", value: this.TYPE_CLOUD },
        { label: "Dev", value: this.TYPE_DEV },
        { label: "AI", value: this.TYPE_AI },
      ],
    };

    return formData;
  }
  getTypeByKey(key) {
    let ret = this.TYPE_ROOT;
    switch (key) {
      case "dev":
        ret = this.TYPE_DEV;
        break;
      case "ai":
        ret = this.TYPE_AI;
        break;
      case "cloud":
        ret = this.TYPE_CLOUD;
        break;
      default:
      // Do Nothing
    }
    return ret;
  }
  getKeyByType(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_DEV:
        ret = "dev";
        break;
      case this.TYPE_AI:
        ret = "ai";
        break;
      case this.TYPE_CLOUD:
        ret = "cloud";
        break;
      default:
      // Do Nothing
    }
    return ret;
  }
  getTypeLabelByKey(key) {
    return this.getTypeLabel(this.getTypeByKey(key));
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_ROOT:
        ret = null;
        break;
      case this.TYPE_AI:
        ret = "nQube";
        break;
      case this.TYPE_CLOUD:
        ret = "Cloud Ops";
        break;
      case this.TYPE_DEV:
        ret = "Dev Studio";
        break;
    }
    return ret;
  }
  async addResource(values) {
    let res = await this.api.mutate(
      `
      mutation AddProjectResource($input: UpdateProjectInput!) {
        updateProject(input: $input){
          project {
            uid
            name
          }
          success
        }
      }`,
      {
        input: {
          resources: {
            add: [values.resourceUid],
          },
          uid: values.projectUid,
        },
      }
    );
    return true;
  }
  async removeResource(values) {
    let res = await this.api.mutate(
      `
      mutation RemoveProjectResource($input: UpdateProjectInput!) {
        updateProject(input: $input){
          project {
            uid
            name
          }
          success
        }
      }`,
      {
        input: {
          resources: {
            remove: [values.resourceUid],
          },
          uid: values.projectUid,
        },
      }
    );
    return true;
  }
  async fetchResourceAddFormData(uid = null, options = {}) {
    let qry = "";
    if (!uid) return {};
    let qryVals = {
      uid: uid,
      resourceFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        // project: {
        //   uid: { eq: uid }
        // }
      },
    };
    // , $projectFilter: ProjectFilter
    // project (filter: $projectFilter) {
    //   uid
    // }
    // projectFilter: {
    //   uid: { eq: uid }
    // }
    qry = `
      query ProjectResourceAddFormData($resourceFilter: ResourceFilter){
        resources(filter: $resourceFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    let formData = {
      values: {
        projectUid: uid,
      },
      resourceSelections: res.data.resources.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    if (options.type) formData.values.projectType = options.type;
    return formData;
  }
  async fetchCodeListDir(uid, path) {
    let res = await this.api.qry(
      `
      query FetchProjectCodeListDir($uid: ID!, $path: String! ) {
        projectCodeListDir(uid: $uid, path: $path){
          path
          nodes {
            name
            type
          }
        }
      }`,
      {
        uid,
        path,
      }
    );
    return res.data.projectCodeListDir || null;
  }
  async fetchCodeFile(uid, path) {
    let res = await this.api.qry(
      `
      query FetchProjectCodeFile($uid: ID!, $path: String! ) {
        projectCodeFile(uid: $uid, path: $path){
          name
          path
          content
        }
      }`,
      {
        uid,
        path,
      }
    );
    return res.data.projectCodeFile || {};
  }
  async updateCodeFile(uid, path, content) {
    let res = await this.api.qry(
      `
      mutation UpdateProjectCodeFile($uid: ID!, $path: String!, $content: String! ) {
        updateProjectCodeFile(uid: $uid, path: $path, content: $content){
          path
        }
      }`,
      {
        uid,
        path,
        content,
      }
    );
    return res.data.updateProjectCodeFile || {};
  }
}

class ProjectInstanceDomain extends R14.Domain {
  constructor(uid, options = {}) {
    super();
    this._options = options;
    this.state = {
      uid: uid,
    };
  }
  async init() {
    await this.refresh();
  }
  remove() {
    this.dm.resource.removeInstance(this.state.uid);
  }
  async refresh() {
    let project = await this.dm.project.get(this.state.uid, this._options);
    this.setState(project);
  }
  get name() {
    return this.state.name || null;
  }
  get uid() {
    return this.state.uid || null;
  }
  get type() {
    return this.state.type || null;
  }
}
