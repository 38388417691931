import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  Text,
  View,
  Form,
  BackButton,
  TextInputField,
  SubmitButton,
  Surface,
  Touchable
} from "../core";
export default R14.connect(
  class ComingSoonScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleCreateConversationPress = this.handleCreateConversationPress.bind(
        this
      );
    }
    componentDidMount() {}
    async handleCreateConversationPress(userUid) {
      this.props.app.ui.progressIndicator.show();
      let conversation = await this.props.app.dm.message.initConversation(
        userUid
      );
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      console.log(conversation);
      this.props.app.nav.to('messagesConversation',{uid: conversation.uid})
    }
    render() {
      return (
        <ScreenLayout style={styles.screenLayout}>
          {this.props.navigationInfo.users.map(user => {
            return (
              <Touchable key={user.uid} onPress={()=>this.handleCreateConversationPress(user.uid)}>
                <Text>{user.name}</Text>
              </Touchable>
            );
          })}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
    flex: 1,
    flexDirection: "column"
  }
  // messages: {
  //   flexGrow: 1
  // },
  // messageFormSurface: {
  //   alignSelf: 'flex-end',
  //   width: '100%',
  //   flexGrow: 0,
  //   ...StyleSheet.padding(0)
  // }
});
