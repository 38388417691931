
import R14 from '../core';

export default class DashboardDomain extends R14.Domain {
  constructor() {
    super();
    this.state = {
      servers: {
        web: {
          loading: false,
          running: false
        },
        native: {
          loading: false,
          running: false
        }, 
        watch: {
          loading: false,
          running: false
        },
        server: {
          loading: false,
          running: false
        }
      }
    };
    this._interval = null;
    //this.poll();
  }
  poll(){
    if(this._interval) clearInterval(this._interval);
    this._interval = setInterval(()=>{
      this.refresh();
    },5000);
  }
  async refresh(command, type) {
    console.log(command, type);
    let res = await this.api.mut(`
      query RefreshDashboard {
        dashboard {
          servers {
            web {
              running
              loading
            }
            native {
              running
              loading
            }
            watch {
              running
              loading
            }
            server {
              running
              loading
            }
          }
        }
      }`, {
        input: {
          [type]: {command: 'RUN'}
        }
      }
    );
    console.log('res',res);
    if(res && res.dashboard && res.dashboard.servers) this.setState({
      servers: res.dashboard.servers
    });
  }
  async run(type) {
    return this.command('RUN', type);
  }
  async command(command, server) {
    console.log(command, server);
    let res = await this.api.mut(`
      mutation UpdateServers($input: ServerCommandsInput!) {
        updateServers(input: $input){
            web {
              running
              loading
            }
            native {
              running
              loading
            }
            watch {
              running
              loading
            }
            server {
              running
              loading
            }
        }
      }`, {
        input: {
          [server]: {command: 'RUN'}
        }
      }
    );
    return {
      
    };
  }
}