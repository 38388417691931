import React from "react";
import R14, {
} from "../core";
import RelatedAddForm from "../components/RelatedAddForm";
export default R14.connect(
  class ResourceStatusCheckAddScreen extends React.Component {
    render() {
      return (
        <RelatedAddForm
          name='resourceStatusCheckAddForm'
          formData={this.props.formData}
          domainName='resourceStatusCheck'
          refreshDataTable={this.props.refreshDataTable || 'resourceStatusChecks'}
          redirect={this.props.redirect || {
            route: "resource",
            params: { uid: this.props.resourceUid }
          }}
          relatedFieldName='resourceUid'
          createButtonTitle='Create Status Check'
          createAction={this.props.createAction || 'resourceStatusCheckCreate'}
          selectMenuFieldName='statusCheckUid'
          selectMenuFieldLabel='Select Status Check'
          selectMenuFieldSelections={this.props.formData.statusCheckSelections}
        />
      );
    }
  }
);
