import React from "react";
import R14, { IconButton, Colors, StyleSheet } from "../core";
import AppModuleDataTable from "../components/AppModuleDataTable";
import ProjectAppModuleDashboard from "../components/ProjectAppModuleDashboard";
import ProjectScreenLayout from "../components/ProjectScreenLayout";

export default R14.connect(
  class ProjectsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleViewToggle = this.handleViewToggle.bind(this);
      this.state = {
        view: "dataTable",
      };
      console.log(this.props.project);
    }
    handleViewToggle() {
      this.setState({
        view: this.state.view === "dashboard" ? "dataTable" : "dashboard",
      });
    }
    renderViewToggleButton(view) {
      return (
        <IconButton
          key='view'
          icon={view === "dashboard" ? "formatListCheckbox" : "viewDashboard"}
          variant='circle'
          size='small'
          backgroundColor={StyleSheet.color(Colors.onSurface).rgba(0.1)}
          onPress={this.handleViewToggle}
        />
      );
    }
    render() {
      return (
        <ProjectScreenLayout
          project={this.props.project}
          floatingActionButton={{
            onPress: () =>
              this.props.app.nav.to(`projectAppModuleCreate`, {
                uid: this.props.project.uid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.project.type
                ),
              }),
            icon: "add",
          }}
          scrollEnabled
          headerTitle='App Modules'
          DataTableComponent={
            this.state.view === "dataTable" && (
              <AppModuleDataTable
                projectUid={this.props.project.uid}
                projectType={this.props.project.type}
                headerControlsRight={[
                  this.renderViewToggleButton("dataTable"),
                ]}
              />
            )
          }
          headerControlsRight={[
            this.renderViewToggleButton("dashboard"),
          ]}
        >
          {this.state.view === "dashboard" && (
            <ProjectAppModuleDashboard project={this.props.project} />
          )}
        </ProjectScreenLayout>
      );
    }
  }
);
