import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import UserPermissionGroupDataTable from '../components/UserPermissionGroupDataTable';
export default R14.connect(
  class UsersScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "userPermissionGroupCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <UserPermissionGroupDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});