import React from "react";
import R14, {
  StyleSheet,
  View,
  IconButton,
} from "../core";
export default R14.connect(
  class ResourceControls extends React.Component {
    constructor(props) {
      super(props);
      this.resourceDomain = this.props.app.dm.resource;
    }
    async updateResourceState(command) {
      let confirmMessage = null;

      switch (command) {
        case this.resourceDomain.COMMAND_START:
          confirmMessage = "Are you sure you want to start this resource?";
          break;
        case this.resourceDomain.COMMAND_STOP:
          confirmMessage = "Are you sure you want to stop this resource?";
          break;
        case this.resourceDomain.COMMAND_REBOOT:
          confirmMessage = "Are you sure you want to reboot this resource?";
          break;
      }

      if (confirmMessage && !window.confirm(confirmMessage)) return;

      this.props.app.ui.progressIndicator.show();
      if(this.resourceDomain.exists(this.props.resourceUid)){
        let instance = await this.resourceDomain.instance(this.props.resourceUid);
        await instance.updateState(command);
      }
      else{
        await this.resourceDomain.updateState(this.props.resourceUid, command);
      }
      if(this.props.dataTable) await this.props.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide();
    }
    get shouldRender(){
      return [this.resourceDomain.TYPE_EC2_INSTANCE,this.resourceDomain.TYPE_AZURE_VM].contains(this.props.resourceType);
    }
    render() {
      
      if(this.props.resourceType === this.resourceDomain.TYPE_EFS_FILESYSTEM) return null;

      let resource = {
        state: this.props.resourceState,
        uid: this.props.resourceUid
      };
      return (
        <View style={styles.resourceControls}>
          {(resource.state === "stopped" || resource.state === "deallocated") && (
            <IconButton
              icon='play'
              size='small'
              key='run'
              tooltip='Run'
              onPress={() =>
                this.updateResourceState(this.resourceDomain.COMMAND_START)
              }
            />
          )}
          {(resource.state !== "stopped" && resource.state !== "deallocated" && resource.state !== "terminated") && (
            <IconButton
              icon='stop'
              size='small'
              key='stop'
              tooltip='Stop'
              onPress={() =>
                this.updateResourceState(this.resourceDomain.COMMAND_STOP)
              }
            />
          )}
          {resource.state === "running" && (
            <IconButton
              icon='refresh'
              size='small'
              key='reboot'
              tooltip='Reboot'
              onPress={() =>
                this.updateResourceState(this.resourceDomain.COMMAND_REBOOT)
              }
            />
          )}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  resourceControls: {
    flex: 0,
    flexDirection: 'row',
  }
});
