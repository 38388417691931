import R14 from "../core";

export default class ScriptDomain extends R14.Domain {
  constructor(key) {
    super();
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindScripts($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ScriptFilter) {
        scripts(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.scripts;
  }

  async create(values, { resourceUid = null }) {
    // Check for resourceUid. If exists, add resourceScript
    if (resourceUid) {
      values.resourceScripts = {
        create: [
          { resourceUid: resourceUid, clientUid: this.dm.userSession.clientUid }
        ]
      };
    }
    values.clientUid = this.dm.userSession.clientUid;

    let res = await this.api.mutate(
      `
      mutation CreateScript($input: CreateScriptInput!) {
        createScript(input: $input){
          script {
            uid
            name
            description
            content
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateScript($input: UpdateScriptInput!) {
        updateScript(input: $input){
          script {
            uid
            name
            description
            content
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async get(uid) {
    console.log("get with uid", uid);
    let res = await this.api.qry(
      `
      query GetScript($uid: ID!) {
       script(uid: $uid){
          uid
          name
          description
          content
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.script;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteScript($uid: ID!) {
        deleteScript(uid: $uid){
          script {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let qry = "";
    let qryVals = {};
    if (!uid) return {};
    qry = `
      query ScriptEditFormData($uid: ID!) {
        script(uid: $uid){
          uid
          name
          description
          content
        },
      }
    `;
    qryVals.uid = uid;
    let res = await this.api.qry(qry, qryVals);
    let formData = {
      values: res.data.script || {}
    };
    return formData;
  }
}
