import React from "react";
import R14, { ScreenLayout, View, Text, Colors, StyleSheet } from "../core";

export default class ProjectPipelineGrid extends React.PureComponent {
  createDynamicStyles() {
    return StyleSheet.create({
      gridRow: {
        width: this.props.cellSize * this.props.totalRows,
        height: this.props.cellSize,
        borderStyle: "solid",
        borderWidth: 1,
        // borderTopWidth: 1,
        borderColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
        flexShrink: 0,
      },
      gridCol: {
        width: this.props.cellSize,
        height: this.props.cellSize * this.props.totalCols,
        borderStyle: "solid",
        borderWidth: 1,
        // borderTopWidth: 1,
        borderColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
        flexShrink: 0,
      },
      gridCols: {
        flex: 1,
        flexDirection: "row",
        position: "absolute",
        ...StyleSheet.absoluteFill,
      },
      gridRows: {
        flex: 1,
        flexDirection: "column",
        position: "absolute",
        ...StyleSheet.absoluteFill,
      },
      grid: {
        position: "relative",
        width: this.props.cellSize * this.props.totalRows,
        height: this.props.cellSize * this.props.totalCols,
      }
    });
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    let rows = [];
    let cols = [];
    for (let y = 0; y < this.props.totalRows; y++) {
      rows.push(<View key={`grid-row-${y}`} style={dynamicStyles.gridRow}></View>);
    }
    for (let x = 0; x < this.props.totalCols; x++) {
      cols.push(
        <View key={`grid-cell-${x}`} style={[dynamicStyles.gridCol]}></View>
      );
    }
    return (
      <View style={dynamicStyles.grid}>
        <View style={dynamicStyles.gridRows}>{rows}</View>
        <View style={dynamicStyles.gridCols}>{cols}</View>
      </View>
    );
  }
}