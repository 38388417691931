import R14, { Theme } from "../core";

export default class ThemeDomain extends R14.Domain {
  constructor(key) {
    super();
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr) throw new Error("Theme Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindThemes($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ThemeFilter) {
        themes(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.themes;
  }
  parseFormValues(values) {
    let ret = {
      name: values.name,
      description: values.description,
      clientUid: this.dm.userSession.clientUid,
      userUid: this.dm.userSession.uid,
      components: [],
      colors: []
    };
    let components = {};
    if (values.uid) ret.uid = values.uid;
    for (let key in values) {
      if (!values[key]) continue;
      switch (key) {
        case "description":
        case "name":
        case "uid":
          // Do nothing
          break;
        default:
          if (key.startsWith("component_")) {
            let arr = key.split("_");
            console.log(arr);
            if (!components[arr[1]]) components[arr[1]] = { key: arr[1] };
            console.log(components[arr[1]]);
            switch (arr[2]) {
              case "color":
                if (!components[arr[1]].colors) components[arr[1]].colors = [];
                components[arr[1]].colors.push({
                  key: arr[3],
                  hex: values[key]
                });
                break;
              case "option":
                if (!components[arr[1]].options)
                  components[arr[1]].options = [];
                components[arr[1]].options.push({
                  key: arr[3],
                  value: values[key]
                });
                break;
            }
          } else {
            ret.colors.push({ key: key, hex: values[key] });
          }
      }
    }

    if (Object.keys(components).length) {
      for (let k in components) {
        ret.components.push(components[k]);
      }
    }
    return ret;
  }
  async create(values) {
    let submitValues = this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation CreateTheme($input: CreateThemeInput!) {
        createTheme(input: $input){
          theme {
            uid
            name
            colors {
              key
              hex
            }
          }
        }
      }`,
      {
        input: submitValues
      }
    );
    return true;
  }
  async update(values) {
    let submitValues = this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation UpdateTheme($input: UpdateThemeInput!) {
        updateTheme(input: $input){
          theme {
            uid
            name
            description
          }
        }
      }`,
      {
        input: submitValues
      }
    );
    return true;
  }
  async get(uid) {
    console.log("get with uid", uid);
    let res = await this.api.qry(
      `
      query GetTheme($uid: ID!) {
       theme(uid: $uid){
          uid
          name
          colors {
            key
            hex
          }
          components {
            key
            colors {
              key
              hex
            }
            options {
              key
              value
            }
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return res.theme;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteTheme($uid: ID!) {
        deleteTheme(uid: $uid){
          theme {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let formData = {};
    let qry = "";

    if (uid) {
      let qry = `
      query ThemeCreateFormData($uid: ID!) {
        theme(uid: $uid){
          uid
          name
          description
          colors {
            key
            hex
          }
          components {
            key
            colors {
              key
              hex
            }
            options {
              key
              value
            }
          }
        }
      }
    `;
      let qryVals = {
        uid: uid
      };
      let res = await this.api.qry(qry, qryVals);
      if (res && res.theme) {
        formData = {
          values: {
            uid: res.theme.uid,
            name: res.theme.name,
            description: res.theme.description
          }
        };
        if(res.theme.colors){
          for (let color of res.theme.colors) {
            formData.values[color.key] = color.hex;
          }
        }
        if(res.theme.components){
          for (let component of res.theme.components) {
            if(component.colors){
              for(let color of component.colors){
                formData.values[`component_${component.key}_color_${color.key}`] = color.hex;
              }
            }
            if(component.options){
              for(let option of component.options){
                formData.values[`component_${component.key}_option_${option.key}`] = option.value;
              }
            }
          }
        }
       
      }
    }
    formData.logoCompanySelections = [
      {label: 'Rule 14', value: 'rule14'},
      {label: 'Exela', value: 'exela'}
    ];
    formData.logoColorSchemeSelections = [
      {label: 'On Light', value: 'onLight'},
      {label: 'On Dark', value: 'onDark'}
    ];
    return formData;
  }
  async setCustomTheme(uid) {
    let themeInfo = await this.get(uid);
    let theme = { name: themeInfo.name, colors: {}, components: {} };
    themeInfo.colors.forEach(color => {
      theme.colors[color.key] = color.hex;
    });
    if(themeInfo.components){
      themeInfo.components.forEach(component => {
        theme.components[component.key] = {};
        if(component.colors){
          if(! theme.components[component.key].colors) theme.components[component.key].colors = {};
          component.colors.forEach(color=>{
            theme.components[component.key].colors[color.key] = color.hex;
          });
        }
        if(component.options){
          if(! theme.components[component.key].options) theme.components[component.key].options = {};
          component.options.forEach(option=>{
            theme.components[component.key].options[option.key] = option.value;
          });
        }
      });
    }
    this.setTheme(themeInfo.uid, theme);
  }
  async setTheme(key, theme=null) {
    Theme.setTheme(key, theme);
  }
}
