import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  View,
  Text,
  StyleSheet,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryUserEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handlePermissionsRenderer = this.handlePermissionsRenderer.bind(
        this
      );
      this.handlePermissionsDialogClose = this.handlePermissionsDialogClose.bind(
        this
      );
      this.handlePipelineValueChange = this.handlePipelineValueChange.bind(
        this
      );
      this.handlePermissionsDialogSubmit = this.handlePermissionsDialogSubmit.bind(
        this
      );
      this.handlePermissionsItemEditPress = this.handlePermissionsItemEditPress.bind(
        this
      );
      this.state = { pipelineBlockSelections: [] };
      this.pipelineMap = this.props.formData.pipelineMap;

    }
    async handlePipelineValueChange(value) {
      this.props.app.ui.progressIndicator.show();

      if (!value) {
        this.setState({ pipelineBlockSelections: [] });
        return false;
      }
      let pipelineBlockSelections = await this.props.app.dm.pipeline.fetchBlockSelections(
        { pipelineUid: value }
      );
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.setState({ pipelineBlockSelections });
    }
    handlePermissionsItemEditPress(values){
      this.handlePipelineValueChange(values.parentUid)
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryUserForm");
    }

    handlePermissionsRenderer(values) {
      if (
        !this.pipelineMap[values.parentUid] ||
        !this.pipelineMap[values.parentUid].pipelineBlocks[values.uid]
      )
        throw new Error("Pipeline map render error");
      return (
        <View>
          <View style={styles.permissionItemRow}>
            <LabelView
              style={styles.permissionItemLabelView}
              key='pipeline'
              label='Pipeline'
            >
              <Text>{this.pipelineMap[values.parentUid].name}</Text>
            </LabelView>
            <LabelView
              style={styles.permissionItemLabelView}
              key='pipelineBlock'
              label='Pipeline Block'
            >
              <Text>
                {
                  this.pipelineMap[values.parentUid].pipelineBlocks[values.uid]
                    .name
                }
              </Text>
            </LabelView>
          </View>
        </View>
      );
    }

    handlePermissionsDialogSubmit(form) {
      let perm = form.values;
      // for (let perm of this.form.values["permissions"]) {
      // Check for block in selections
      if (
        !perm.type ===
        this.props.app.dm.manualEntryUser.PERMISSION_TYPE_PIPELINE_BLOCK
      )
        return false;
      if (!this.pipelineMap[perm.parentUid])
        throw new Error("Pipeline map error.");
      if (!this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid]) {
        for (let pipelineBlock of this.state.pipelineBlockSelections) {
          if (pipelineBlock.value === perm.uid) {
            this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid] = {
              uid: pipelineBlock.value,
              name: pipelineBlock.label,
            };
            break;
          }
        }
        if (!this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid])
          throw new Error("Pipeline block map error.");
      }
      // this.setState({ pipelineBlockSelections: [] });
      return true;
    }
    handlePermissionsDialogClose() {
      this.setState({ pipelineBlockSelections: [] });
    }

    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryUser[editMethod](form.values);
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "manualEntryUsers";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "manualEntryUsers"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='manualEntryUserForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <FieldGroup>
            <TextInputField
              name='username'
              label='Username'
              required='Enter Username'
              validator='username'
            />
            <TextInputField name='name' label='Name' required='Enter Name' />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='password'
              label={this.isEditMode ? "Change Password" : "Password"}
              secure
              required={!this.isEditMode && "Enter Password"}
            />
            <TextInputField name='email' label='Email' validator='email' />
          </FieldGroup>
          <DialogFormField
            name='permissions'
            dialogTitle='Add Permission'
            label='Add Permission'
            formName='manualEntryUserForm'
            itemRenderer={this.handlePermissionsRenderer}
            onSubmit={this.handlePermissionsDialogSubmit}
            onDialogClose={this.handlePermissionsDialogClose}
            onItemEditPress={this.handlePermissionsItemEditPress}
          >
            <HiddenInputField
              name='type'
              value={
                this.props.app.dm.manualEntryUser.PERMISSION_TYPE_PIPELINE_BLOCK
              }
            />
            <SelectMenuField
              useValuesOnly
              name='parentUid'
              label='Pipeline'
              items={this.formData.pipelineSelections}
              required='Please select a pipeline.'
              onValueChange={this.handlePipelineValueChange}
            />
            <FadeView
              unmountOnExit
              visible={this.state.pipelineBlockSelections.length ? true : false}
            >
              {this.state.pipelineBlockSelections.length ? (
                <SelectMenuField
                  useValuesOnly
                  name='uid'
                  label='Pipeline Block'
                  items={this.state.pipelineBlockSelections}
                  required='Please select a pipeline block.'
                />
              ) : null}
            </FadeView>
          </DialogFormField>
          <SwitchField name='active' label='Is Active' value={true} />
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  permissionItemRow: {
    flex: 1,
    flexDirection: "row",
  },
  permissionItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  permissionItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
