import React from 'react';
import R14, { StyleSheet, ScreenLayout, Text, View} from '../core';
export default R14.connect(
  class SupportScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          // floatingActionButton={{
          //   to: "contact",
          //   icon: "contact"
          // }}
          scrollEnabled
        >
          <View style={styles.comingSoon}><Text>Coming soon!</Text></View>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
 comingSoon: {
   margin: 16
 }
});