import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  FieldGroup,
  StyleSheet
} from "../core";
export default R14.connect(
  class SshKeyEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.formData = this.props.formData || {};
      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let opts = {};
      let ret = null;

      this.props.app.ui.progressIndicator.show();
      if (this.props.resourceUid) opts.resourceUid = this.props.resourceUid;
      try {
        let isSuccess = await this.props.app.dm.sshKey[editMethod](
          form.values,
          opts
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "sshKeys";
          if (this.props.app.ui.dataTable.exists(dataTable)) {
            this.props.app.ui.dataTable(dataTable).refresh();
          }
          if (this.props.app.dm.resource.exists(this.props.resourceUid)) {
            let resource = await this.props.app.dm.resource.instance(
              this.props.resourceUid
            );
            await resource.refresh();
          }
          ret = <Redirect to={this.props.redirect || "sshKeys"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='sshKeyForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
            />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='username'
              label='Username'
              required='Please enter a ssh username.'
            />
            <TextInputField
              name='port'
              label='Port'
              required='Please enter a ssh port.'
              validator='positiveInt'
            />
          </FieldGroup>
          <TextInputField
            name='privateKey'
            label='SSH Private Key'
            required='Please enter an ssh private key.'
            style={styles.sshPrivateKeyField}
            multiline
            autoSize
          />
        </Form>
      );
    }
  }
);
let styles = StyleSheet.create({
  sshPrivateKeyField: {
    maxHeight: 160
    // flex: 1
  }
});
