import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  TextInputField,
  FadeView,
  SubmitButton,
  View,
  Text,
  StyleSheet
} from "../core";
import AddRemoveWidgetField from "../components/AddRemoveWidgetField";

export default R14.connect(
  class UserPermissionGroupEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleAccessValueChange = this.handleAccessValueChange.bind(this);
      this.handleKeyValueChange = this.handleKeyValueChange.bind(this);
      this.handlePermissionRenderer = this.handlePermissionRenderer.bind(this);
      this.handlePermissionValueFormat = this.handlePermissionValueFormat.bind(
        this
      );
      this.state = {
        hasSubtypeSelections:
          (this.formData.values.type &&
            this.formData.subtypeSelections[this.formData.values.type]) ||
          null
            ? true
            : false,
        type: this.formData.values.type || null
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    getSubtypeSelections(type) {
      if(! type) return null;
      return  this.formData.subtypeSelections[type] || null
    }
    handleAccessValueChange(value, elmt) {
      if (value === this.props.app.dm.userPermission.PERMISSION_FULL) {
        if (elmt.value.length > 1)
          elmt.value = [this.props.app.dm.userPermission.PERMISSION_FULL];
      } else if (
        elmt.value.includes(this.props.app.dm.userPermission.PERMISSION_FULL)
      ) {
        let nValues = [...elmt.value];
        nValues.splice(
          nValues.indexOf(this.props.app.dm.userPermission.PERMISSION_FULL),
          1
        );
        elmt.value = nValues;
      }
    }
    handleKeyValueChange(value, label, elmt, form) {
      let subtypeSelections = this.getSubtypeSelections(value);
      if (form.elmts.subtype) {
        form.elmts.subtype.setItems(subtypeSelections);
        form.elmts.subtype.setValue(null);
      }
      this.setState({
        hasSubtypeSelections: subtypeSelections ? true : false,
        type: value
      });
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "updateGroup" : "createGroup";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.userPermission[editMethod](
          form.values
        );

        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "userPermissionGroups";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "userPermissionGroups"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    handlePermissionValueFormat(values) {
      return {
        type: values.type,
        subtype: values.subtype,
        access: values.access
      };
    }
    handlePermissionRenderer(values) {
      let accessLabels = values.access.map(val =>
        this.props.app.dm.userPermission.getAccessLabel(val)
      );
      return (
        <View>
          <View>
            <Text>
              {this.props.app.dm.userPermission.getTypeLabel(values.type)}
            </Text>
            {values.subtype && (
              <Text>
                {" "}
                /{" "}
                {this.props.app.dm.userPermission.getSubtypeLabel(
                  values.type,
                  values.subtype
                )}
              </Text>
            )}
          </View>
          <View style={styles.permissionAccess}><Text>{accessLabels.join(", ")}</Text></View>
        </View>
      );
    }
    render() {
      return (
        <Form
          name='userPermissionGroupForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a value.'
          />
          {/* <TextInputField
            name='key'
            label='Key'
            required='Please enter a value.'
          /> */}
          <AddRemoveWidgetField
            name='permissions'
            label='Permissions'
            formName='userPermissionGroupForm'
            itemValueFormatter={this.handlePermissionValueFormat}
            itemRenderer={this.handlePermissionRenderer}
          >
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Type'
              required='Select permission'
              onValueChange={this.handleKeyValueChange}
              items={this.formData.typeSelections}
            />
            <FadeView visible={this.state.hasSubtypeSelections}>
              <SelectMenuField
                useValuesOnly
                name='subtype'
                label='Sub Type'
                items={this.getSubtypeSelections(this.state.type)}
                helper='Optional'
              />
            </FadeView>
            <SelectMenuField
              useValuesOnly
              name='access'
              label='Access'
              required='Select access levels'
              onValueChange={this.handleAccessValueChange}
              multiple
              items={this.props.formData.accessSelections}
            />
          </AddRemoveWidgetField>
        </Form>
      );
    }
  }
);

const styles = StyleSheet.create({
  permissionAccess: {
    fontSize: 14,
    fontWeight: 300
  }
});
