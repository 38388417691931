import R14 from "../core";

export default class StatusCheckDomain extends R14.Domain {
  constructor(key) {
    super();
    this.STATE_PASS = "PASS";
    this.STATE_FAIL = "FAIL";
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
    query FindStatusChecks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: StatusCheckFilter) {
      statusChecks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
        totalCount @include(if: $totalCount)
        nodes {
          ${fieldsStr}
        }
      }
    }`,
      options
    );
    return res.data.statusChecks;
  }

  async create(values, { resourceUid = null }) {
    // Check for resourceUid. If exists, create resourceStatusCheck
    if (resourceUid) {
      values.resourceStatusChecks = {
        create: [
          { resourceUid: resourceUid, clientUid: this.dm.userSession.clientUid }
        ]
      };
    }
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateStatusCheck($input: CreateStatusCheckInput!) {
        createStatusCheck(input: $input){
          statusCheck {
            uid
            name
            description
            content
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateStatusCheck($input: UpdateStatusCheckInput!) {
        updateStatusCheck(input: $input){
          statusCheck {
            uid
            name
            description
            content
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async get(uid) {
    let res = await this.api.qry(
      `
      query GetStatusCheck($uid: ID!) {
       statusCheck(uid: $uid){
          uid
          name
          description
          content
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.statusCheck;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteStatusCheck($uid: ID!) {
        deleteStatusCheck(uid: $uid){
          statusCheck {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let qry = "";
    let qryVals = {};
    let res = {};
    let formVals = {};
    if (uid) {
      qry = `
      query StatusCheckEditFormData($uid: ID!) {
        statusCheck(uid: $uid){
          uid
          name
          description
          content
        },
      }
    `;
      qryVals.uid = uid;
      res = await this.api.qry(qry, qryVals);
      formVals = res.data.statusCheck || {};
    }
    let formData = {
      values: formVals
    };
    return formData;
  }

  getStateLabel(state) {
    let ret = "";
    switch (state) {
      case this.STATE_FAIL:
        ret = "Fail";
        break;
      case this.STATE_PASS:
        ret = "Pass";
        break;
    }
    return ret;
  }

  getStateSelections() {
    return [
      {
        label: this.getStateLabel(this.STATE_FAIL),
        value: this.STATE_FAIL
      },
      {
        label: this.getStateLabel(this.STATE_PASS),
        value: this.STATE_PASS
      }
    ];
  }
}
