import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  ColorInputField,
  SelectMenuField,
  FieldGroup,
  Theme,
  View
} from "../core";

export default R14.connect(
  class ThemeEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.formData = this.props.formData || {};
      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;

      //if (!this.isEditMode) this.formData.values = Theme.colors;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.theme[editMethod](form.values);
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "themes";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());

          if (this.isEditMode && this.formData.values.uid === Theme.key)
            setTimeout(()=>{this.props.app.dm.theme.setCustomTheme(Theme.key)}, 250);

          ret = <Redirect reload to={this.props.redirect || "themes"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }

    render() {
      return (
        <Form
          name='themeForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
            />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='primary'
              label='Primary Color'
              required='Please enter a primary color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onPrimary'
              label='On-Primary Color'
              required='Please enter an on-primary color.'
              validator='hexColor'
            />
          </FieldGroup>

          <FieldGroup>
            <ColorInputField
              name='primaryDark'
              label='Primary Dark Color'
              required='Please enter a primary dark color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onPrimaryDark'
              label='On-Primary Dark Color'
              required='Please enter an on-primary dark color.'
              validator='hexColor'
            />
          </FieldGroup>

          <FieldGroup>
            <ColorInputField
              name='primaryLight'
              label='Primary Light Color'
              required='Please enter a primary light color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onPrimaryLight'
              label='On-Primary Light Color'
              required='Please enter an on-primary light color.'
              validator='hexColor'
            />
          </FieldGroup>

          <FieldGroup>
            <ColorInputField
              name='secondary'
              label='Secondary Color'
              required='Please enter a secondary color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onSecondary'
              label='On-Secondary Color'
              required='Please enter an on-secondary color.'
              validator='hexColor'
            />
          </FieldGroup>

          <FieldGroup>
            <ColorInputField
              name='secondaryDark'
              label='Secondary Dark Color'
              required='Please enter a secondary dark color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onSecondaryDark'
              label='On-Secondary Dark Color'
              required='Please enter an on-secondary dark color.'
              validator='hexColor'
            />
          </FieldGroup>

          <FieldGroup>
            <ColorInputField
              name='secondaryLight'
              label='Secondary Light Color'
              required='Please enter a secondary light color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onSecondaryLight'
              label='On-Secondary Light Color'
              required='Please enter an on-secondary light color.'
              validator='hexColor'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='background'
              label='Background Color'
              required='Please enter a background color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onBackground'
              label='On-Background Color'
              required='Please enter an on-background color.'
              validator='hexColor'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='surface'
              label='Surface Color'
              required='Please enter a surface color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onSurface'
              label='On-Surface Color'
              required='Please enter an on-background color.'
              validator='hexColor'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='error'
              label='Error Color'
              required='Please enter an error color.'
              validator='hexColor'
            />
            <ColorInputField
              name='onError'
              label='On-error Color'
              required='Please enter an on-error color.'
              validator='hexColor'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='component_header_color_background'
              label='Header Color'
              validator='hexColor'
              helper='Default to Primary'
            />
            <ColorInputField
              name='component_header_color_onBackground'
              label='On-Header Color'
              validator='hexColor'
              helper='Default to On-Primary'
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='component_sidebar_color_background'
              label='Sidebar Color'
              validator='hexColor'
              helper='Default to Background'
            />
            <ColorInputField
              name='component_sidebar_color_onBackground'
              label='On-Sidebar Color'
              validator='hexColor'
              helper='Default to On-Background'
            />
          </FieldGroup>
            <SelectMenuField
              useValuesOnly
              name='component_logo_option_company'
              label='Logo Company'
              helper='Default to Rule14'
              items={this.formData.logoCompanySelections}
            />
            

          <FieldGroup>
          <SelectMenuField
              useValuesOnly
              name='component_logo_option_colorScheme'
              label='Logo Color Scheme'
              helper='Default to On Dark'
              items={this.formData.logoColorSchemeSelections}
            />
            <SelectMenuField
              useValuesOnly
              name='component_drawerLogo_option_colorScheme'
              label='Drawer Logo Color Scheme'
              helper='Default to logo'
              items={this.formData.logoColorSchemeSelections}
            />
          </FieldGroup>
        </Form>
      );
    }
  }
);
