import R14 from "../R14";
// import ThemeConfig from "../../config/theme.config";
import { ThemeColorValue } from "../components/Theme";
import AsyncStorage from "../components/AsyncStorage";

export default class ThemeUiDomain extends R14.Domain {
  constructor() {
    super();
    // Set the first key
    // console.error("THEME CONFIG GET FROM R14", this.r14.app);
    // let ThemeConfig = {};
    // if (!ThemeConfig) throw new Error("Theme Error: No theme config found.");
    // this.config = { ...ThemeConfig };
    // let key = this.config ? Object.keys(this.config)[0] : null;
    // let theme = ThemeConfig[key];

    // // @todo this is NOT going to work with native.
    // if (localStorage) {
    //   let themeJson = localStorage.getItem("r14AppTheme");
    //   if (themeJson) {
    //     let themeInfo = JSON.parse(themeJson);
    //     key = themeInfo.key;
    //     theme = themeInfo.theme;
    //   }
    // }

    // this._key = key;
    // this._theme = theme;
    this.state = {
      key: null,
      theme: {}
    };
    this._config = {};
    this._key = null;
    this._theme = {};
  }
  async initialize(themeConfig) {
    if (!themeConfig) throw new Error("Theme Error: No theme config found.");
    let key = Object.keys(themeConfig)[0] || null;
    this._config = {...themeConfig};

    let theme = this._config[key] || null;
    // let themeJson = localStorage.getItem('r14AppTheme');
    let themeJson = await AsyncStorage.getItem("r14AppTheme");
    let storThemeInfo = null;

    if(themeJson){
      storThemeInfo = JSON.parse(themeJson);
      key = storThemeInfo.key;
      if(this._config[key]) theme = this._config[key];
      else if (storThemeInfo.theme) theme = storThemeInfo.theme;
    }
    this._key = key;
    this._theme = theme;
  }
  get colors() {
    return new Proxy(this, {
      get: function(obj, prop) {
        return new ThemeColorValue(obj, "color", prop);
      }
    });
  }
  get components() {
    return this._theme.components;
    //return this.state.theme.colors;
  }
  componentColors(componentKey, options = {}) {
    return new Proxy(this, {
      get: (obj, prop) => {
        return new ThemeColorValue(obj, "color", prop, componentKey, options);
      }
    });
    // if (
    //   !this._theme.components ||
    //   !this._theme.components[componentKey] ||
    //   !this._theme.components[componentKey].colors
    // )
    //   return {};
    // else return this._theme.components[componentKey].colors;
  }
  componentOptions(componentKey) {
    if (
      !this._theme.components ||
      !this._theme.components[componentKey] ||
      !this._theme.components[componentKey].options
    )
      return {};
    else return this._theme.components[componentKey].options;
  }
  setTheme(key, theme = null, options = {reload: true}) {
    // if (theme) this.config[key] = theme;
    // else theme = this.config[key];
    if(! theme) theme = this.config[key] || null;
    if (!theme) throw new Error(`Theme Error: No theme found with key ${key}`);
    AsyncStorage.setItem(
      "r14AppTheme",
      JSON.stringify({
        key: key,
        theme: ! this._config[key] ? theme : null
      })
    );
    this._key = key;
    this._theme = theme;
    
     /** @todo this is not going to work with native */
    if(options && options.reload && window) window.location.reload();
  }
  get config() {
    return this._config || null;
  }
  get theme() {
    return this._theme;
  }
  get key() {
    return this._key;
    //return this.state.key;
  }
  // get components() {
  //   return this.config.components[this.state.key];
  // }
}
