import React from 'react';
import R14, {
  StyleSheet,
  ControlsView,
  Card,
  Colors,
  ScreenLayout,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Button,
  Surface,
  SectionHeader,
  AnimatedView,
  View,
  ScrollView,
  Text,
  Link
} from '../core';
import LogViewer from './LogViewer';
export default R14.connect(
  class DashboardServerWidget extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = React.createRef();
      this.state = {
        log: false
      }
    }
    componentDidUpdate() {
      if (this.scrollRef.current && this.state.log){
        this.scrollRef.current.scrollToEnd({ animated: false });
      }
      console.log('update update update',this.state.log, this.scrollRef.current);
    }
    handleCommandPress(command) {
      this.props.project.command(command, this.props.server);
    }
    handleConsolePress() {
      this.setState({
        log: !this.state.log
      });
    }
    render() {
      if (!this.props.project.state.servers[this.props.server]) {
        console.warn(`Dashboard module type '${this.props.server} not found.'`);
        return null;
      }
      let serverDisplayName = this.props.app.utils.str.capitalize(this.props.server);
      let serverState = this.props.project.state.servers[this.props.server];
      console.log('SERVER STATE CURR', serverState);
      console.log('LOADING:', serverState.loading);

      let iconButtons = [];
      if (serverState.running) {
        iconButtons.push(
          <IconButton
            icon='stop'
            key='stop'
            disabled={serverState.loading || false}
            style={styles.button}
            onPress={() => this.handleCommandPress('STOP')}
          />,
          <IconButton
            icon='refresh'
            key='refresh'
            disabled={serverState.loading || false}
            style={styles.button}
            onPress={() => this.handleCommandPress('RESTART')}
          />
        );
      }
      else {
        iconButtons.push(
          <IconButton
            icon='play'
            key='play'
            disabled={serverState.loading || false}
            style={styles.button}
            onPress={() => this.handleCommandPress('RUN')}
          />
        );
      }
      iconButtons.push(
        <IconButton
          icon='console'
          key='console'
          disabled={serverState.loading || false}
          style={styles.button}
          color={this.state.log && Colors.primary}
          onPress={() => this.handleConsolePress()}
        />
      );
      return (
        <View
          style={styles.dashboardServerWidget}
        >
          <View style={styles.controls}>
            <Text>{serverDisplayName}</Text>
            <ControlsView
              align='left'
            >
              {iconButtons}
            </ControlsView>
          </View>
          {this.state.log &&
            <AnimatedView
              in={this.state.log}
              enter={{
                opacity: 1
              }}
              exit={{
                opacity: 0
              }}
              duration={200}
              onEnter
              animateOnMount
            >
              <LogViewer initialText={serverState.log} />
            </AnimatedView>
          }
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboardServerWidget: {
    borderRadius: 4,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(.05),
    // ...StyleSheet.padding(0, 0, 16, 0),
    ...StyleSheet.margin(0, 0, 8, 0),
  },
  controls: {
    flex: 1,
    ...StyleSheet.padding(8, 4, 8, 16),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  controlButton: {
    ...StyleSheet.margin(0)
  },
  log: {
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(.05),
    paddingBottom: 16
  },
  logScroll: {
    ...StyleSheet.margin(0, 16, 0, 16),
    ...StyleSheet.padding(8),
    backgroundColor: Colors.background
  },
  logText: {
    fontSize: 13,
    fontFamily: 'monospace'
  }
});