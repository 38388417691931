import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";

export default R14.connect(
  class ResourceAlertDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
      this.resourceAlertDomain = this.props.app.dm.resourceAlert;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("resource", "alert");
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        resourceUid: { eq: this.props.resourceUid }
      };
      if (search) {
        filter.alert = {
          name: { like: `%${search}%` }
        };
      }
      let res = await this.props.app.dm.resourceAlert.find(
        `
        uid
        alert {
          uid
          name
          description
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          state: row.state,
          name: row.alert.name,
          description: row.alert.description,
          alertUid: row.alert.uid
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("resourceAlerts");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.resourceAlert.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}
    async handleRunPress(uid) {
      this.props.app.ui.progressIndicator.show();
      await this.resourceAlertDomain.run(uid);
      await this.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide();
    }
    async handleShowLogPress(resourceAlertUid) {
      this.props.app.nav.to("resourceAlertLog", {
        uid: this.props.resourceUid,
        resourceAlertUid: resourceAlertUid
      });
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <DataTable
          name='resourceAlerts'
          columns={{
            name: {
              label: "Alert",
              sortable: true
            }
          }}
          searchable
          maxHeight={300}
          // autoRefresh={30}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Alerts"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={
            this.userDomain.checkPermissions(
              "resource",
              "alert",
              this.userDomain.PERMISSION_ACCESS_CREATE
            ) &&
            this.props.widget !== false && [
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={() =>
                  this.props.app.nav.to("resourceAlertAdd", {
                    uid: this.props.resourceUid
                  })
                }
              />
            ]
          }
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='console'
                size='small'
                key='console'
                onPress={() => this.handleShowLogPress(row.uid)}
              />,
              (this.perms.edit || this.perms.delete) && (
                <PopUpMenu
                  controlIcon='dotsHorizontal'
                  controlIconSize='small'
                  key='resourceMenu'
                  direction='downLeft'
                >
                  {this.perms.edit && (
                    <PopUpMenuItem
                      label='Edit'
                      icon='edit'
                      key='edit'
                      onPress={() =>
                        this.props.app.nav.to(
                          this.props.editAction || "resourceAlertEdit",
                          {
                            uid: this.props.resourceUid,
                            alertUid: row.alertUid
                          }
                        )
                      }
                    />
                  )}
                  {this.perms.delete && (
                    <PopUpMenuItem
                      label='Remove'
                      icon='close'
                      key='remove'
                      onPress={() => this.handleRemovePress(row.uid, dataTable)}
                    />
                  )}
                </PopUpMenu>
              )
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
