import R14 from "../core";

export default class DatasetDomain extends R14.Domain {
  constructor() {
    super();
    this.TYPE_AWS_S3 = "AWS_S3";
    this.TYPE_R14 = "R14";
    this.STATE_INIT = "INIT";
    this.STATE_INITIALIZING = "INITIALIZING";
    this.STATE_SYNC = "SYNC";
    this.STATE_SYNCING = "SYNCING";
    this.STATE_READY = "READY";
    this.STATE_ERROR = "ERROR";
    this.STATE_DELETE = "DELETE";
    this.STATE_DELETING = "DELETING";
    this.STATE_CLEAR = "CLEAR";
    this.STATE_CLEARING = "CLEARING";
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Dataset Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    if (!options.orderBy)
      options.orderBy = {
        name: "ASC",
      };
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindDatasets($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: DatasetFilter) {
        datasets(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.datasets;
  }

  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateDataset($input: CreateDatasetInput!) {
        createDataset(input: $input){
          dataset {
            uid
            name
            description
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values, options = {}) {
    console.log("UPDATE DS", values, options);
    let res = await this.api.mutate(
      `
      mutation UpdateDataset($input: UpdateDatasetInput!) {
        updateDataset(input: $input){
          dataset {
            uid
            name
            description
          }
        }
      }`,
      {
        input: options.parse === false ? values : this.parseFormValues(values),
      }
    );
    return true;
  }
  parseFormValues(values) {
    let ret = {
      ...values,
      clientUid: this.dm.userSession.clientUid,
    };
    return ret;
  }
  async get(uid) {
    let res = await this.api.qry(
      `
      query GetDataset($uid: ID!) {
       Dataset(uid: $uid){
          uid
          name
          description
        }
      }`,
      {
        uid: uid,
      }
    );
    return res.data.dataset;
  }

  async delete(uid) {
    return await this.updateState(uid, this.STATE_DELETE);
  }
  async clearData(uid) {
    return await this.updateState(uid, this.STATE_CLEAR);
  }
  async stopSync(uid) {
    return await this.updateState(uid, this.STATE_READY);
  }
  async runSync(uid) {
    return await this.updateState(uid, this.STATE_SYNC);
  }
  async updateState(uid, state) {
    let res = await this.update(
      {
        uid: uid,
        state: state,
      },
      { parse: false }
    );
    return true;
  }

  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    let qryVals = {
      //userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      cloudAccessKeyFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        type: { eq: this.dm.cloudAccessKey.TYPE_AWS },
      },
    };
    if (uid) {
      qry = `
      query DatasetCreateFormData($uid: ID!, $cloudAccessKeyFilter: CloudAccessKeyFilter) {
        dataset(uid: $uid){
          uid
          key
          type
          name
          description
          bucketName
          cloudAccessKeyUid
        },
        cloudAccessKeys(filter:$cloudAccessKeyFilter) {
          nodes {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query DatasetEditFormData($cloudAccessKeyFilter: CloudAccessKeyFilter) {
          cloudAccessKeys(filter:$cloudAccessKeyFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = res.data.dataset || {};
    if (!formVals.projectType && options.projectType)
      formVals.projectType = options.projectType;
    let formData = {
      values: formVals,
      typeSelections: this.getTypeSelections(),
      // userSelections: res.data.users.nodes.map((val) => ({
      //   label: val.name,
      //   value: val.uid,
      // })),
      cloudAccessKeySelections: res.data.cloudAccessKeys.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }
  getTypeSelections(options = {}) {
    return [
      {
        label: this.getTypeLabel(this.TYPE_AWS_S3),
        value: this.TYPE_AWS_S3,
      },
      {
        label: this.getTypeLabel(this.TYPE_R14),
        value: this.TYPE_R14,
      },
    ];
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_AWS_S3:
        ret = "AWS S3";
        break;
      case this.TYPE_R14:
        ret = "R14 Storage";
        break;
    }
    return ret;
  }
  getStateIndicatorLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_CLEAR:
      case this.STATE_CLEARING:
        label = "Clearing";
        break;
      case this.STATE_DELETE:
      case this.STATE_DELETING:
        label = "Deleting";
        break;
      case this.STATE_INIT:
      case this.STATE_INITIALIZING:
        label = "Initializing";
        break;
      case this.STATE_SYNC:
      case this.STATE_SYNCING:
        label = "Syncing";
        break;
      case this.STATE_READY:
        label = "Ready";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_CLEAR:
      case this.STATE_CLEARING:
      case this.STATE_INIT:
      case this.STATE_INITIALIZING:
      case this.STATE_SYNC:
      case this.STATE_SYNCING:
        color = "yellow";
        break;
      case this.STATE_READY:
        color = "green";
        break;
    }
    return color;
  }
}
