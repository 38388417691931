import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import EventDataTable from '../components/EventDataTable';
export default R14.connect(
  class EventsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "eventCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <EventDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});