import React from "react";
import R14, { Surface, StyleSheet, View } from "../core";

export default class DashboardWidget extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    let Content = this.props.surface === false ? View : Surface;
    return (
      <View style={styles.dashboardWidget}>
        <Content
          style={[
            styles.dashboardWidgetContent,
            dynamicStyles.dashboardWidgetContent,
          ]}
        >
          {this.props.children}
        </Content>
      </View>
    );
  }
  createDynamicStyles() {
    let marginRight = 16;
    let borderRadius = 4;
    switch (this.props.size) {
      case "small":
        marginRight = 0;
        borderRadius = 0;
        break;
    }
    return new StyleSheet({
      dashboardWidgetContent: {
        ...StyleSheet.margin(0, marginRight, 16, 0),
        ...StyleSheet.padding(0),
        borderRadius: borderRadius,
      },
    });
  }
}
const styles = StyleSheet.create({
  dashboardWidgetSurface: {
    flex: 1,
    // ...StyleSheet.margin(0, 16, 16, 0),
    ...StyleSheet.padding(0),
    ...StyleSheet.margin(0, 16, 16, 0),
    borderRadius: 4,
    // screen: ({ width }) => {
    //   console.log(width);
    //   if (width <= 800)
    //     return {
    //       ...StyleSheet.margin(0, 0, 16, 0),
    //     };
    // },
    component: ({ width }) => console.log("width"),
  },
  dashboardWidget: {
    flex: 1,
    // width: "100%",
    flexBasis: 0,
    flexGrow: 0,
    flexShrink: 0,
  },
});
