import R14 from "../core";

export default class ResourceScriptDomain extends R14.Domain {
  constructor(key) {
    super();
    this.STATE_RUNNING = "RUNNING";
    this.STATE_READY = "READY";
    this.STATE_ERROR = "ERROR";
    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindResourceScripts($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceScriptFilter) {
        resourceScripts(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.resourceScripts;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_READY:
        label = "Ready";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
      case this.STATE_RUNNING:
        label = "Running";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_RUNNING:
        color = "yellow";
        break;
      case this.STATE_ERROR:
        color = "red";
        break;
      case this.STATE_READY:
        color = "green";
        break;
    }
    return color;
  }
  async run(uid) {
    let res = await this.api.mut(
      `
      mutation runResourceScript($uid: ID!) {
        runResourceScript(uid: $uid){
          uid
          state
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.runResourceScript;
  }
  async add(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation AddResourceScript($input: CreateResourceScriptInput!) {
        createResourceScript(input: $input){
          resourceScript {
            uid
            script {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateResourceScript($input: UpdateResourceScriptInput!) {
        updateResourceScript(input: $input){
          resourceScript {
            uid
          }
        }
      }`,
      {
        input: values
      }
    );
    return res.data.updateResourceScript.resourceScript || null;
  }
  async resetState(uid){
    let ret = await this.update({uid: uid, state: this.STATE_READY});
    return ret ? true : false;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteResourceScript($uid: ID!) {
        deleteResourceScript(uid: $uid){
          resourceScript {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async fetchLog(uid) {
    let res = await this.api.qry(
      `
      query FetchResourceScriptLog($uid: ID!) {
       resourceScript(uid: $uid){
          log
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data && res.data.resourceScript && res.data.resourceScript.log
      ? res.data.resourceScript.log
      : "";
  }
  async onUpdateLog(uid, callback) {
    return await this.api.subscribe(
      `
      subscription OnUpdateResourceScript($uid: ID!) {
        onUpdateResourceScript(uid: $uid){
           log
         }
       }`,
      {
        uid: uid,
      },
      (res)=>{
        callback(res.onUpdateResourceScript);
      }
    );
  }
  async fetchScriptAddFormData(uid = null) {
    let qry = "";
    if (!uid) return {};
    let qryVals = {
      uid: uid,
      scriptFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        resourceScript: {
          resourceUid: {neq: uid}
        }
      },
      resourceScriptFilter: {
        resourceUid: { eq: uid}
      }
    };
    
    qry = `
      query ResourceScriptAddFormData($scriptFilter: ScriptFilter, $resourceScriptFilter: ResourceScriptFilter){
        scripts(filter: $scriptFilter){
          nodes {
            uid
            name
            resourceScript(filter: $resourceScriptFilter) {
              uid
            }
          }
        }
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    let formData = {
      values: {
        resourceUid: uid
      },
      scriptSelections: res.data.scripts.nodes.map(val => ({
        label: val.name,
        value: val.uid
      }))
    };
    return formData;
  }
}