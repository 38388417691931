import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  TextInputField,
  BackButton,
  Form,
  SubmitButton,
  StyleSheet,
  SwitchField,
  FadeView,
  Redirect
} from "../core";

export default R14.connect(
  class BlockEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleCopyAppModuleChange = this.handleCopyAppModuleChange.bind(
        this
      );
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        copyAppModule: true,
      };
    }
    get formData() {
      return this.props.formData;
    }
    handleCopyAppModuleChange(value) {
      this.setState({
        copyAppModule: value,
      });
    }
    async handleSubmit(form) {
      console.log("form values", form.values);
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let res = await this.props.app.dm.block.copy(form.values);
        if (res.success) {
          let dataTable = this.props.refreshDataTable || "blocks";
          let redirect = this.hasType
            ? {
                route: "blocks",
                params: {
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.projectType
                  ),
                },
              }
            : "blocks";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || redirect} />;
        } else if (res.errors) {
          res.errors.forEach((err) => form.addError(err.message));
        } else form.addError("Unknown Error, please try again.");
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
      // if (
      //   isSuccess &&
      //   this.isEditMode &&
      //   this.props.app.ui.pipeline.exists(this.props.formData.values.uid)
      // ) {
      //   let pipeline = await this.props.app.ui.pipeline.instance(
      //     this.props.formData.values.uid
      //   );
      //   await pipeline.refresh();
      // }
    }
    render() {
      return (
        <Form
          name='copyBlockForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Copy' key='submit' />,
          ]}
        >
          <HiddenInputField name='uid' />
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              helper='Required'
              required={true}
            />
            <SwitchField
              key='copyAppModule'
              name='copyAppModule'
              label='Copy App Module'
              onValueChange={this.handleCopyAppModuleChange}
              value={this.state.copyAppModule}
            />
          </FieldGroup>
          <FadeView visible={this.state.copyAppModule}>
            <FieldGroup>
              <TextInputField
                name='appModuleName'
                label='App Module Name'
                required={true}
              />
              <TextInputField
                name='appModuleKey'
                label='App Module Key'
                required={true}
                validator='key'
              />
            </FieldGroup>
          </FadeView>
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({});
