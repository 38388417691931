import React from "react";
import PropTypes from "prop-types";

import R14 from "../R14";
import View from "./View";
// import PopUpMenu from "./PopUpMenu";
// import PopUpMenuItem from "./PopUpMenuItem";
// import Touchable from "./Touchable";
import IconButton from "./IconButton";
import InputField from "./InputField";
import Form from "./Form";
import HiddenInputField from "./HiddenInputField";
import Dialog from "./Dialog";
import Card from "./Card";
import ControlsView from "./ControlsView";
import StyleSheet from "./StyleSheet";
import PopUpMenu from "./PopUpMenu";
import PopUpMenuItem from "./PopUpMenuItem";
import { Colors } from "./Theme";
// import R14, {
//   StyleSheet,
//   Colors,
//   HiddenInputField,
//   IconButton,
//   ControlsView,
//   View,
//   Form,
//   Card,
//   Dialog,
// } from "../core";

export default R14.connectForm(
  class DialogFormField extends React.Component {
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleItemAddPress = this.handleItemAddPress.bind(this);
      this.handleItemEditPress = this.handleItemEditPress.bind(this);
      this.handleItemRemovePress = this.handleItemRemovePress.bind(this);
      this.handleDialogClosePress = this.handleDialogClosePress.bind(this);
      this.handleDialogOkPress = this.handleDialogOkPress.bind(this);
      this.handleDialogFormSubmit = this.handleDialogFormSubmit.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.state = {
        modalVisible: false,
        editIdx: null,
      };
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    componentDidUpdate(prevProps, prevState){

    }
    get isEditMode() {
      return this.form.values && this.form.values.uid ? true : false;
    }
    get dialogFormName() {
      return this.props.dialogFormName || "dialogFormFieldForm";
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    handleValueChange(val) {
      this.props.onValueChange &&
        this.props.onValueChange(val, this.elmt, this.elmt.form);
      this.elmt.form.triggerValueChange(val, this.elmt);
    }
    async handleItemAddPress() {
      if(this.props.onItemAddPress) this.props.onItemAddPress();
      this.setState({
        modalVisible: true,
        editIdx: null,
      });
    }
    async handleItemEditPress(i) {
      if(this.props.onItemEditPress) this.props.onItemEditPress(this.elmt.value[i]);
      this.setState({
        modalVisible: true,
        editIdx: i,
      });
    }
    async handleItemRemovePress(i) {
      if (!window.confirm("Are you sure?")) return;
      let items = [...this.elmt.value];
      let item = items[i] || null;
      items.splice(i, 1);
      this.elmt.value = items.length ? items : null;
      this.props.onRemoveItem &&
        this.props.onRemoveItem(item, this.elmt, this.props.form);
      this.handleValueChange(this.elmt.value);
    }

    async handleDialogClosePress() {
      if (this.props.onDialogClose) this.props.onDialogClose("cancel");
      this.setState({
        modalVisible: false,
        editIdx: null,
      });
    }
    async handleDialogOkPress() {
      let dialogForm = this.props.app.ui.form[this.dialogFormName];
      dialogForm.submit();
      // this.setState({
      //   modalVisible: false,
      // });
    }
    async handleDialogFormSubmit(dialogForm) {
      if(this.props.onSubmit){
        if(! this.props.onSubmit(dialogForm)) return;
      }
      let items = this.elmt.value || [];
      let newVals = this.props.itemValueFormatter
        ? this.props.itemValueFormatter(dialogForm.values)
        : dialogForm.values;
      if (await dialogForm.validate()) {
        this.isDialogEditMode
          ? (items[this.state.editIdx] = newVals)
          : items.push(newVals);
        this.elmt.value = items;
        this.handleValueChange(this.elmt.value);
        if (this.props.onDialogClose) this.props.onDialogClose("ok");
        this.setState({
          modalVisible: false,
          editIdx: null,
        });
      }
    }
    get isDialogEditMode() {
      return this.state.editIdx !== null;
    }
    // async handleItemAddPress() {
    //   // Get the form from the context
    //   let form = this.props.app.ui.form(this.props.formName);
    //   let items = form.elmts[this.props.name].value || [];
    //   if (!this.state.showForm) {
    //     this.setState({
    //       showForm: true,
    //     });
    //     return true;
    //   }
    //   if (await form.validate()) {
    //     items.push(this.props.itemValueFormatter(form.values));
    //     form.elmts[this.props.name].value = items;
    //     this.setState({
    //       // items: items,
    //       showForm: false,
    //       occurs: null,
    //     });
    //   }
    // }
    render() {
      let elementVals = this.props.form.values[this.props.name];
      let dialogFormVals = this.isDialogEditMode
        ? this.elmt.value[this.state.editIdx]
        : null;

      if (this.props.formValueFormatter)
        dialogFormVals = this.props.formValueFormatter(dialogFormVals);

      let dialogFormKey = this.isDialogEditMode
        ? `r14DialogForm${this.state.editIdx}`
        : "r14DialogForm";

      return (
        <InputField
          {...this.props}
          label={null}
          element={this.elmt}
          InputComponent={
            <View style={styles.addRemoveFormWidget}>
              <Card
                surfaceStyle={styles.surface}
                title={this.props.label}
                titleStyle={[
                  styles.title,
                  this.elmt.value && styles.titleValue,
                ]}
                titleControlsRight={[
                  <IconButton
                    icon='add'
                    key='add'
                    variant='circle'
                    onPress={this.handleItemAddPress}
                    style={styles.addMoreButton}
                  />,
                ]}
              >
                {elementVals && elementVals.length > 0 && (
                  <View style={styles.items}>
                    {elementVals.map((val, i) => {
                      return (
                        <View
                          key={`${val[this.props.name]}${i}`}
                          style={styles.item}
                        >
                          <View style={styles.itemLeft}>
                            {this.props.itemRenderer(val)}
                          </View>
                          <ControlsView
                            style={styles.itemControlsRight}
                            align='right'
                          >
                            <PopUpMenu
                              controlIcon='dotsVertical'
                              key='menu'
                              direction='downLeft'
                              onSelect={(value, label) => {}}
                            >
                              <PopUpMenuItem
                                label='Edit'
                                icon='edit'
                                key='edit'
                                onPress={() => this.handleItemEditPress(i)}
                              />
                              <PopUpMenuItem
                                label='Remove'
                                icon='close'
                                key='remove'
                                onPress={() => this.handleItemRemovePress(i)}
                              />
                            </PopUpMenu>
                          </ControlsView>
                        </View>
                      );
                    })}
                  </View>
                )}
                <Dialog
                  name='addRemoveWidgetDialog'
                  title={this.props.dialogTitle || `Add ${this.props.label}`}
                  visible={this.state.modalVisible}
                  titleControlVariant='close'
                  onClosePress={this.handleDialogClosePress}
                  onCancelPress={this.handleDialogClosePress}
                  onBackdropPress={this.handleDialogClosePress}
                  onOkPress={this.handleDialogOkPress}
                  okButtonTitle={this.isDialogEditMode ? "Update" : "Add"}
                >
                  <Form
                    key={dialogFormKey}
                    name={this.dialogFormName}
                    style={styles.dialogForm}
                    onSubmit={this.handleDialogFormSubmit}
                    validateBeforeSubmit
                    validators={this.props.form.validators || null}
                    initialValues={dialogFormVals}
                  >
                    {this.props.dialogFormRenderer
                      ? this.props.dialogFormRenderer(dialogFormVals)
                      : this.props.children}
                  </Form>
                </Dialog>
              </Card>
            </View>
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  addRemoveFormWidget: {},
  surface: {
    ...StyleSheet.margin(0, 0, 0, 0),
    ...StyleSheet.padding(8, 8, 8, 12),
    // minHeight: 56,
    elevation: 0,
    backgroundColor: "transparent",
    // borderRadius: 4,
    // borderColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    // borderWidth: 1,
    // borderStyle: "solid",
  },
  title: {
    fontSize: 16,
    // fontWeight: "500",
    color: StyleSheet.color(Colors.onBackground).rgba(0.5),
    ...StyleSheet.padding(0),
  },
  titleValue: {
    fontSize: 16,
    fontWeight: "500",
    color: Colors.onBackground,
  },
  addMoreButton: {
    marginRight: 4,
  },
  dialogForm: {
    ...StyleSheet.padding(0),
  },
  items: {
    ...StyleSheet.margin(8, -4, -8, -8),
  },
  itemLeft: {
    width: "100%",
  },
  item: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    borderRadius: 4,
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(0, 0, 0, 0),
    marginBottom: 4,
  },
  itemDark: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
  },
  itemControlsRight: {
    ...StyleSheet.margin(-8, -12, 0, 0),
  },
});
