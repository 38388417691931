import R14 from "../core";

export default class ManualEntryUserDomain extends R14.Domain {
  constructor(key) {
    super();
    this.PERMISSION_TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";
    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindManualEntryUsers($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryUserFilter) {
        manualEntryUsers(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.manualEntryUsers;
  }
  async fetchEditFormData(uid = null) {
    let qryVals = {
      pipelineFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      pipelineSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
    };
    if (!this.dm.user.hasAdminRole)
      qryVals.pipelineFilter["userUids"] = { eq: this.dm.userSession.uid };

    if (uid) {
      qryVals.uid = uid;
      qryVals.pipelineBlockSort = {
        field: "name",
        order: "ASC",
      };
      qryVals.pipelineBlockFilter = {};
      // if (!this.dm.user.hasAdminRole)
      //   qryVals.pipelineBlockFilter = {
      //     userUids: { eq: this.dm.userSession.uid },
      //   };
    }
    let res = uid
      ? await this.api.qry(
          `
          query ManualEntryUserEditFormData($uid: ID!, $pipelineBlockFilter: PipelineBlockFilter, $pipelineBlockSort: [SortOption!]!, $pipelineFilter: PipelineFilter, $pipelineSort: [SortOption!]!) {
            manualEntryUser(uid: $uid){
              uid
              name
              email
              username
              active
              pipelineBlocks (filter: $pipelineBlockFilter, sort: $pipelineBlockSort) {
                nodes {
                  uid
                  name
                  pipelineUid
                }
              }
            }
            pipelines(filter: $pipelineFilter, sort: $pipelineSort){
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryUserCreateFormData($pipelineFilter: PipelineFilter, $pipelineSort: [SortOption!]!) {
            pipelines(filter: $pipelineFilter, sort: $pipelineSort){
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        );
    let formData = {
      values: {},
      pipelineMap: {},
      pipelineSelections:
        res.data.pipelines && res.data.pipelines.nodes
          ? res.data.pipelines.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
    };

    let formVals = {
      permissions: [],
    };
    if (res.data) {
      if (res.data.pipelines && res.data.pipelines.nodes) {
        res.data.pipelines.nodes.forEach((pipeline) => {
          formData.pipelineMap[pipeline.uid] = {
            uid: pipeline.uid,
            name: pipeline.name,
            pipelineBlocks: {},
          };
        });
      }
      if (res.data.manualEntryUser) {
        let manualEntryUser = res.data.manualEntryUser;
        formVals = {
          username: manualEntryUser.username,
          email: manualEntryUser.email,
          uid: manualEntryUser.uid,
          name: manualEntryUser.name,
          active: manualEntryUser.active,
          permissions: [],
        };
        formData.values = formVals;
        if (
          manualEntryUser.pipelineBlocks &&
          manualEntryUser.pipelineBlocks.nodes
        ) {
          manualEntryUser.pipelineBlocks.nodes.forEach((pipelineBlock) => {
            if (!formData.pipelineMap[pipelineBlock.pipelineUid]) return;
            formData.pipelineMap[pipelineBlock.pipelineUid].pipelineBlocks[
              pipelineBlock.uid
            ] = { uid: pipelineBlock.uid, name: pipelineBlock.name };
            formVals.permissions.push({
              type: this.PERMISSION_TYPE_PIPELINE_BLOCK,
              uid: pipelineBlock.uid,
              parentUid: pipelineBlock.pipelineUid,
            });
          });
        }
      }
    }
    return formData;
  }
  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateManualEntryUser($input: CreateManualEntryUserInput!) {
        createManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateManualEntryUser($input: UpdateManualEntryUserInput!) {
        updateManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  parseFormValues(values) {
    let updateValues = {
      username: values.username,
      name: values.name,
      email: values.email,
      active: values.active,
      clientUid: this.dm.userSession.clientUid,
      pipelineBlockUids: [],
    };
    if (values.uid) updateValues.uid = values.uid;
    if (values.password) updateValues.password = values.password;
    values.permissions.map((perm) => {
      switch (perm.type) {
        case this.PERMISSION_TYPE_PIPELINE_BLOCK:
          if (!updateValues.pipelineBlockUids.includes(perm.uid))
            updateValues.pipelineBlockUids.push(perm.uid);
          break;
      }
    });

    return updateValues;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteManualEntryUser($uid: ID!) {
        deleteManualEntryUser(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
}
