import R14 from "../core";

export default class MessageDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
    this.GROUP_TYPE_CONVERSATION = "CONVERSATION";
    this.GROUP_TYPE_ALERT = "ALERT";
    this.GROUP_TYPE_CHANNEL = "CHANNEL";
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindMessages($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: MessageFilter) {
        messages(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.messages;
  }

  async initClientMessaging(clientUid) {}

  async initUser() {
    let userFindOptions = {
      // page: null,
      // resultsPerPage: null,
      filter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        uid: { neq: this.dm.userSession.uid }
      },
      sort: [
        {
          field: "name",
          order: "ASC"
        }
      ],
      totalCount: false
    };
    let userRes = await this.dm.user.find(
      `
        uid
        name
      `,
      userFindOptions
    );
    console.log(userRes);
    return {
      users: userRes.nodes || []
    };
  }

  async onCreateMessageGroupMessage(messageGroupUid, callback) {
    console.log('onCreateMessageGroupMessage', messageGroupUid)
    return await this.api.subscribe(
      `
        subscription OnCreateMessageGroupMessage($messageGroupUid: ID!) {
          onCreateMessageGroupMessage(messageGroupUid: $messageGroupUid){
             uid
             text
             createdAt
             user {
               uid
               name
             }
           }
         }`,
      {
        messageGroupUid: messageGroupUid
      },
      res => {
        callback(res.onCreateMessageGroupMessage);
      }
    );
  }

  async create(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;
    values.userUid = this.dm.userSession.uid;

    let res = await this.api.mutate(
      `
      mutation CreateMessage($input: CreateMessageInput!) {
        createMessage(input: $input){
          message {
            uid
            text
            user {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values
      }
    );
    return res.createMessage;
  }

  async delete(uid) {
    await this.api.mutate(
      `
      mutation DeleteMessage($uid: ID!) {
        deleteMessage(uid: $uid){
          message {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async createGroup(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;
    return await this.api.mutate(
      `
      mutation CreateMessageGroup($input: CreateMessageGroupInput!) {
        createMessageGroup(input: $input){
          messageGroup {
            uid,
            messageGroupUsers {
              nodes {
                uid
                userUid
              }
            }
          }
        }
      }`,
      {
        input: values
      }
    );
  }
  async findGroups(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!options.totalCount) options.totalCount = false;

    let result = await this.api.qry(
      `
      query FindMessageGroups($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!], $filter: MessageGroupFilter) {
        messageGroups(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.messageGroups;
  }
  createConversationKey(userUids) {
    console.log("len len len", userUids.length, userUids);
    if (userUids.length !== 2)
      throw new Error(
        "Messages Error: Unable to create conversation key. Requires 2 users."
      );
    return `conversation-${
      this.dm.userSession.clientUid
    }-${userUids.sort().join("-")}`;
  }
  async initConversation(withUserUid) {
    let clientUid = this.dm.userSession.clientUid;
    let userUid = this.dm.userSession.uid;
    let userUids = [userUid, withUserUid].sort();
    console.log(userUid, withUserUid, userUids);
    let key = this.createConversationKey(userUids);

    // Find conversation...
    let findRes = await this.findGroups("uid", {
      resultsPerPage: 1,
      filter: {
        key: { eq: key },
        type: { eq: this.GROUP_TYPE_CONVERSATION }
      }
    });

    if (findRes && findRes.nodes && findRes.nodes.length)
      return findRes.nodes[0];

    // Create conversation
    let createRes = await this.createGroup({
      name: null,
      type: this.GROUP_TYPE_CONVERSATION,
      clientUid: clientUid,
      key: key,
      userUids: userUids,
      messageGroupUsers: {
        create: userUids.map(userUid => ({
          userUid: userUid,
          clientUid: clientUid
        }))
      }
    });

    return createRes;
  }
  // async initUser(uid) {
  //   // Check for message group user
  //   // If none exist, create default message groups

  //   let res = await this.api.qry(
  //     `
  //     query InitMessageUser($uid: ID!) {
  //       messages(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
  //         nodes {
  //           ${fieldsStr}
  //         }
  //       }
  //     }`,
  //     {
  //       uid: uid
  //     }
  //   );
  //   return res.message && res.message.log
  //     ? res.message.log
  //     : "";
  // }
}
