import React from "react";
import R14 from "../R14";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import View from "./View";
import Text from "./Text";
import Theme from "./Theme";
import ScrollView from "./ScrollView";
import Modal from "./Modal";
import AnimatedView from "./AnimatedView";
import DrawerItems from "./DrawerItems";

export default R14.connect(
  class DrawerNavigator extends React.Component {
    constructor(props) {
      super(props);
      this.handleItemPress = this.handleItemPress.bind(this);
      this.drawerNavRef = React.createRef();
      this.drawerNavCntrRef = React.createRef();

      // this.navigator = this.props.app.ui.navigator.create(this.props);

      // this.props.navigator.setVisible(true);

      // AsyncStorage.getItem(`r14App-${this._name}-visible`).then(val=>{
      //   let visible = props.visible || false;
      //   if(val) visible = val === 'true';
      //   if(val && val === 'true') this.setState({
      //     visible: visible
      //   });
      // });
      // if(options.saveState){
      //   alert(options.saveState);
      //   AsyncStorage.setItem(
      //     `r14App-${this._name}-visible`,
      //     options.saveState ? "true" : "false"
      //   );
      // }
    }
    componentDidMount() {
      if (this.props.scrollEnabled) {
        // this.drawerNavCntrRef.current.measureInWindow((px, py, width, height) => {
        //   console.log('measureInWindow',px, py, width, height);
        //   // this.setState({
        //   //   controlLayout: {
        //   //     top: py,
        //   //     left: px,
        //   //     width: width,
        //   //     height: height
        //   //   }
        //   // });
        //   // this.open();
        // });
      }
      this.updateLayoutStyles();
      // this.updateLayout();
      // Dimensions.addEventListener('change',({window})=>{
      //   this.updateLayout();
      // });
    }
    handleItemPress() {
      if (this.props.type === "modal") this.props.navigator.hide();
    }
    updateLayout() {
      // let windowDimensions = Dimensions.get("window");
      // if(windowDimensions.width <= 640){
      //   this.props.navigator.hide();
      // }
      // else this.props.navigator.show();
    }
    updateLayoutStyles() {
      if (this.props.onLayoutStylesUpdate) {
        this.props.onLayoutStylesUpdate(this.layoutStyles);
      }
    }
    componentDidUpdate(prevProps) {
      if (
        prevProps.visible !== this.props.visible ||
        prevProps.type !== this.props.type
      )
        this.updateLayoutStyles();
    }
    componentWillUnmount() {
      this.props.onWillBlur &&
        this.props.onWillBlur(this.props, this.props.app);
    }
    renderItems(drawerType = null) {
      // && type !== "minimized"
      if (this.props.contentComponent) {
        let ContentComponent = this.props.contentComponent;
        return (
          <ContentComponent
            {...this.props}
            activeItemKey={this.props.app.r14Instance.navigation.activeRoute}
          />
        );
      }
      if (this.props.routes) {
        return (
          <DrawerItems
            {...this.props}
            type={drawerType || this.props.type}
          />
        );
      } else
        return this.props.children.map((element) => {
          let navProps = this.props.app.r14Instance.navigation.parseNavigationProps(
            element.props
          );
          throw new Error("DrawerNavigator Error: Child items in dev.");
          return React.cloneElement(element, {
            position: this.props.position,
            scrollEnabled: this.props.scrollEnabled,
            active: this.props.app.r14Instance.navigation.isActiveRoute(
              navProps.route
            ),
          });
        });
    }
    render() {
      let drawerNavCntrStyles = [styles.drawerNavCntr];
      let drawerNavMinimizedCntrStyles = [styles.drawerNavMinimizedCntr];
      let drawerNavStyles = [styles.drawerNav];
      let drawerCntrStyles = [styles.drawerCntr];
      let drawerModalStyles = [styles.drawerModal];
      let drawerMinimizedCntrStyles = [styles.drawerMinimizedCntr];
      let position = this.props.position || "top";
      switch (position) {
        // case "top":
        //   drawerNavCntrStyles.push(styles.drawerNavCntrTop);
        //   drawerNavStyles.push(styles.drawerNavTop);
        //   break;
        // case "bottom":
        //   drawerNavCntrStyles.push(styles.drawerNavCntrBottom);
        //   drawerNavStyles.push(styles.drawerNavBottom);
        //   break;
        case "left":
          drawerNavCntrStyles.push(styles.drawerNavCntrLeft);
          drawerNavStyles.push(styles.drawerNavLeft);
          drawerCntrStyles.push(styles.drawerCntrLeft);
          let drawerCntrColor = Theme.componentColors("sidebar").background;
          if (drawerCntrColor) {
            let sidebarStyles = StyleSheet.create({
              sidebar: {
                backgroundColor: drawerCntrColor,
              },
            });
            drawerCntrStyles.push(sidebarStyles.sidebar);
            drawerModalStyles.push(sidebarStyles.sidebar);
          }
          drawerMinimizedCntrStyles.push(styles.drawerMinimizedCntrLeft);
          drawerNavMinimizedCntrStyles.push(styles.drawerNavMinimizedCntrLeft);
          break;
        // case "responsive-left-bottom":
        //   drawerNavCntrStyles.push(styles.drawerNavCntrResponsiveLeftBottom);
        //   drawerNavStyles.push(styles.drawerNavResponsiveLeftBottom);
        //   break;
      }
      let DrawerNavCntr = View;
      let DrawerNavMinimizedCntr = View;
      let drawerNavCntrProps = {};
      let animatedViewStyle = null;

      if (this.props.scrollEnabled) {
        DrawerNavCntr = ScrollView;
        DrawerNavMinimizedCntr = ScrollView;
      }
      drawerNavCntrProps.style = drawerNavCntrStyles;
      // let renderDrawer = true;
      // let renderMinimizedDrawer = ()
      let ModalHeader = this.props.modalHeader || null;
      return (
        <React.Fragment>
          <AnimatedView
            in={this.props.type !== "modal" && this.props.visible}
            enter={{
              transform: [{ translateX: 0 }, { scale: 1 }],
              opacity: 1,
            }}
            exit={{
              transform: [{ translateX: -256 }, { scale: 1 }],
              opacity: 0,
            }}
            duration={150}
            unmountOnExit
            style={drawerCntrStyles}
          >
            <DrawerNavCntr {...drawerNavCntrProps} ref={this.drawerNavCntrRef}>
              <View ref={this.drawerNavRef} style={drawerNavStyles}>
                {this.renderItems()}
              </View>
            </DrawerNavCntr>
          </AnimatedView>
          <AnimatedView
            in={this.props.type !== "modal" && !this.props.visible}
            enter={{
              transform: [{ translateX: 0 }],
              opacity: 1,
            }}
            exit={{
              transform: [{ translateX: -64 }],
              opacity: 0,
              screen: ({ width }) => {
                if (width <= 640)
                  return {
                    transform: [{ translateX: -56 }],
                  };
              },
            }}
            duration={150}
            // delay={150}
            unmountOnExit
            style={drawerMinimizedCntrStyles}
          >
            <DrawerNavMinimizedCntr style={drawerNavMinimizedCntrStyles}>
              {this.renderItems("minimized")}
            </DrawerNavMinimizedCntr>
          </AnimatedView>

          <Modal
            visible={this.props.type === "modal" && this.props.visible}
            transparent
            animationType='fade'
            // style={styles.drawerModal}
            backdrop
            backdropStyle={styles.drawerModalBackdrop}
            contentStyle={styles.drawerModalContent}
            onBackdropPress={() => this.props.navigator.hide()}
            scrollEnabled
          >
            <AnimatedView
              in={this.props.visible}
              animateOnMount
              enter={{
                transform: [{ translateX: 0 }, { scale: 1 }],
                opacity: 1,
              }}
              exit={{
                transform: [{ translateX: -256 }, { scale: 1 }],
                opacity: 0,
              }}
              duration={150}
              animateOnMount
              unmountOnExit
              style={drawerModalStyles}
            >
              {ModalHeader && (
                <View style={styles.drawerModalHeader}>
                  <ModalHeader />
                </View>
              )}
              {this.renderItems()}
            </AnimatedView>
          </Modal>
        </React.Fragment>
      );
    }
    get layoutStyles() {
      let position = this.props.position || "top";
      let layoutStyles = null;
      switch (position) {
        case "left":
          layoutStyles = StyleSheet.create({
            offset: {
              marginLeft: this.props.visible ? 256 : 64,
              //marginLeft: 256,
              screen: ({ width }) => {
                if (width <= 640)
                  return {
                    marginLeft: 0,
                  };
              },
            },
          });
          break;
      }
      return layoutStyles;
    }
  }
);

const styles = StyleSheet.create({
  drawerNavCntr: {
    position: "absolute",
    backgroundColor: "transparent",
  },
  drawerCntr: {
    position: "absolute",
    backgroundColor: "transparent",
    // elevation: 4,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          backgroundColor: Colors.surface,
          elevation: 4,
        };
    },
    //
  },
  drawerMinimizedCntr: {
    position: "absolute",
    backgroundColor: "transparent",
  },
  drawerNavMinimizedCntr: {
    position: "absolute",
    backgroundColor: Colors.primaryDark,
  },
  drawerModal: {
    // position: "absolute",
    // top: 56,
    // bottom: 0,
    backgroundColor: Colors.surface,
    width: 256,
    elevation: 12,
    flex: 1,
    zIndex: 2,
    marginTop: 0,
    marginRight: "auto",
    paddingTop: 8,
  },
  drawerModalHeader: {
    height: 56,
    ...StyleSheet.margin(-4, 0, 4, 0),
  },
  drawerModalContent: {
    flex: 1,
    flexDirection: "column",
    // width: "100%",
    ...StyleSheet.padding(0, 0, 0, 0),
    justifyContent: "flex-end",
  },
  drawerModalBackdrop: {
    ...StyleSheet.absoluteFill,
    zIndex: 1,
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.8),
  },
  drawerNavCntrTop: {
    height: 56,
    top: 0,
    right: 0,
    left: 0,
    bottom: null,
    backgroundColor: Colors.surface,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          elevation: 4,
        };
    },
  },
  drawerNavCntrLeft: {
    top: 0,
    right: null,
    paddingTop: 8,
    elevation: 3,
    left: 0,
    bottom: 0,
    width: 256,
    // borderStyle: "solid",
    // borderRightWidth: 1,
    // borderColor: StyleSheet.color(Colors.onBackground).rgba(0.1)
  },
  drawerNavMinimizedCntrLeft: {
    top: 0,
    right: null,
    paddingTop: 8,
    elevation: 1,
    left: 0,
    bottom: 0,
    width: 64,
    // screen: ({ width }) => {
    //   if (width <= 640)
    //     return {
    //       width: 56,
    //     };
    // },
  },
  drawerCntrLeft: {
    top: 0,
    right: null,
    left: 0,
    bottom: 0,
    width: 256,
  },
  drawerMinimizedCntrLeft: {
    top: 0,
    right: null,
    left: 0,
    bottom: 0,
    width: 64,
  },
  // drawerNavCntrResponsiveLeftBottom: {
  //   top: 0,
  //   right: null,
  //   left: 0,
  //   bottom: 0,
  //   width: 56,
  //   screen: ({ width }) => {
  //     if (width <= 640)
  //       return {
  //         height: 56,
  //         width: "auto",
  //         elevation: 12,
  //         top: null,
  //         right: 0,
  //         left: 0,
  //         bottom: 0
  //       };
  //   }
  // },
  // drawerNavCntrBottom: {
  //   top: null,
  //   right: 0,
  //   left: 0,
  //   bottom: 0
  // },
  drawerNav: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  // drawerNavResponsiveLeftBottom: {
  //   screen: ({ width }) => {
  //     if (width <= 640)
  //       return {
  //         flex: 1,
  //         flexDirection: "row",
  //         justifyContent: "space-evenly",
  //         alignItems: "center"
  //       };
  //   }
  // },
  drawerNavTop: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          // elevation: 4,
        };
    },
  },
  // drawerNavScrollX: {
  //   height: 72,
  //   overflowX: 'auto',
  //   overflowY: 'hidden',
  //   '::-webkit-scrollbar': {
  //     width: 0
  //   }
  // }
});
