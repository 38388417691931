import React from "react";
import R14, { Colors, View, StyleSheet, Text, DataTable } from "../core";

export default R14.connect(
  class ActivityLogDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.activityLog = this.props.app.dm.activityLog;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if(this.props.resourceUid) filter.resourceUid = { eq: this.props.resourceUid };
      
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };

      let res = await this.props.app.dm.activityLog.find(
        `
        uid
        description
        createdAt
        user {
          uid
          name
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          description: row.description,
          createdAt: row.createdAt,
          user: row.user || null
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("resourceActivityLogs");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "createdAt":
          let date = new Date(cell);
          let shortDate = this.props.app.utils.date.toShortDate(date);
          let time = this.props.app.utils.date.toTime(date);
          ret = (
            <View>
              <Text style={[styles.cellText]}>
                {shortDate} {time}
              </Text>
            </View>
          );
          break;
        case "description":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText]}>{row.description}</Text>
              {row.user && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.user.name}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    // async handleRemovePress(uid, dataTable) {
    //   if (window.confirm("Are you sure?")) {
    //     await this.props.app.dm.eventTask.delete(uid);
    //     dataTable.refresh({ indicator: true });
    //   }
    // }
    // async handleSearchPress() {}
    // async handleRunPress(uid) {
    //   this.props.app.ui.progressIndicator.show();
    //   await this.eventTaskDomain.run(uid);
    //   await this.dataTable.refresh();
    //   this.props.app.ui.progressIndicator.hide();
    // }
    // async handleShowLogPress(uid) {
    //   this.props.app.nav.to("eventTaskLog", { uid: uid });
    // }
    render() {
      return (
        <DataTable
          name='activityLogs'
          columns={{
            createdAt: {
              label: "Date",
              sortable: true,
              width: 192
            },
            description: {
              label: "Description",
              sortable: true
            }
          }}
          searchable
          maxHeight={300}
          autoRefresh={30}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Activity Log"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='createdAt'
          initialSortDirection='desc'
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
