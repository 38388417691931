import R14 from "../core";

export default class ProjectHealthCheckDomain extends R14.Domain {
  constructor(key) {
    super();
    this.CONNECTION_TYPE_SFTP = "SFTP";
    this.CONNECTION_TYPE_RESOURCE = "RESOURCE";
    this.TYPE_SELENIUM = "SELENIUM";
    this.STATE_ERROR = "ERROR";
    this.STATE_READY = "READY";
    this.STATE_RUNNING = "RUNNING";
    this.MODE_UPDATE = "UPDATE";
    this.MODE_APPEND = "APPEND";
    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("ProjectHealthCheck Domain Find Error: No fields found");
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    let result = await this.api.qry(
      `
      query FindProjectHealthChecks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ProjectHealthCheckFilter) {
        projectHealthChecks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.projectHealthChecks;
  }
  async get(uid) {
    let res = await this.api.qry(
      `
      query GetProjectHealthCheck($uid: ID!) {
       projectHealthCheck(uid: $uid){
          uid
          name
          state
          error
          healthCheckState {
            state
            total
            totalFail
          }
          project {
            uid
            type
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return res.data.projectHealthCheck;
  }
  async fetchResourceSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
      filter.type = {
        in: [
          this.dm.resource.TYPE_EC2_INSTANCE,
          this.dm.resource.TYPE_AZURE_VM,
        ],
      };
    }
    let res = await this.dm.resource.find(
      `
      uid
      name
      resourceId
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name || val.resourceId,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let qry = null;
    let initialResourceValueLabels = {};
    let initialContactGroupValueLabels = {};
    let qryVals = {
      resourcesPage: 1,
      resourcesPerPage: 25,
      resourceFilter: {
        type: {
          in: [
            this.dm.resource.TYPE_EC2_INSTANCE,
            this.dm.resource.TYPE_AZURE_VM,
          ],
        },
        state: { neq: "terminated" },
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      contactGroupFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
    };
    if (uid) {
      qryVals.uid = uid;
      qry = `
        query ProjectHealthCheckEditFormData($uid: ID!, $resourcesPage: Int, $resourcesPerPage: Int, $resourceFilter: ResourceFilter, $contactGroupFilter: ContactGroupFilter) {
          projectHealthCheck(uid: $uid){
            uid
            name
            description
            filePath
            syncFrequency
            host
            mode
            username
            connectionType
            resourceUid
            contactGroupUid
            contactGroup {
              uid
              name
            }
            resource {
              uid
              name
              resourceId
            }
          }
          resources(filter:$resourceFilter, page: $resourcesPage, resultsPerPage: $resourcesPerPage) {
            nodes {
              uid
              name
              resourceId
            }
          }
          contactGroups(filter:$contactGroupFilter) {
            nodes {
              uid
              name
            }
          }
        }`;
    } else {
      qry = `
        query ProjectHealthCheckCreateFormData($resourcesPage: Int, $resourcesPerPage: Int, $resourceFilter: ResourceFilter, $contactGroupFilter: ContactGroupFilter) {
          resources(filter:$resourceFilter, page: $resourcesPage, resultsPerPage: $resourcesPerPage) {
            nodes {
              uid
              name
              resourceId
            }
          }
          contactGroups(filter:$contactGroupFilter) {
            nodes {
              uid
              name
            }
          }
        }`;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = {
      projectUid: options.projectUid || null,
      connectionType: this.CONNECTION_TYPE_RESOURCE,
      type: this.TYPE_SELENIUM,
    };
    if (uid) {
      formVals = { ...formVals, ...res.data.projectHealthCheck };
      if (formVals.resource) {
        initialResourceValueLabels[formVals.resource.uid] =
          formVals.resource.name || formVals.resource.resourceUid;
        delete formVals.resource;
        // console.log(formVals);
        // formVals.contactGroup = formVals.contactGroup
        //   ? formVals.contactGroup.nodes.map((val) => ({
        //       label: val.name,
        //       value: val.uid,
        //     }))
        //   : [];
      }
    }

    if (options.type) formVals.type = options.type;
    return {
      values: formVals,
      initialResourceValueLabels: initialResourceValueLabels,
      resourceSelections: res.data.resources.nodes.map((val) => ({
        label: val.name || val.resourceId,
        value: val.uid,
      })),
      contactGroupSelections: res.data.contactGroups.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      modeSelections: [
        { label: "Default (Append)", value: this.MODE_APPEND },
        {
          label: "Update",
          value: this.MODE_UPDATE,
        },
      ],
      connectionTypeSelections: [
        { label: "Resource", value: this.CONNECTION_TYPE_RESOURCE },
        {
          label: "Sftp",
          value: this.CONNECTION_TYPE_SFTP,
        },
      ],
    };

    // if (res.data && res.data.projectHealthCheck)
    //   values = { ...values, ...res.data.projectHealthCheck };
    // return {
    //   values: values,
    // };
  }
  async create(values) {
    values = this.parseSubmitValues(values);
    let res = await this.api.mutate(
      `
      mutation CreateProjectHealthCheck($input: CreateProjectHealthCheckInput!) {
        createProjectHealthCheck(input: $input){
          projectHealthCheck {
            uid
            name
          }
        }
      }`,
      {
        input: values,
      }
    );
    return true;
  }
  async update(values) {
    values = this.parseSubmitValues(values);
    let res = await this.api.mutate(
      `
      mutation UpdateProjectHealthCheck($input: UpdateProjectHealthCheckInput!) {
        updateProjectHealthCheck(input: $input){
          projectHealthCheck {
            uid
            name
          }
        }
      }`,
      {
        input: values,
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteProjectHealthCheck($uid: ID!) {
        deleteProjectHealthCheck(uid: $uid){
          projectHealthCheck {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  parseSubmitValues(values) {
    values.clientUid = this.dm.userSession.clientUid;
    values.syncFrequency = parseInt(values.syncFrequency);
    if (!values.password && values.uid) delete values.password;
    switch (values.connectionType) {
      case this.CONNECTION_TYPE_RESOURCE:
        values.username = null;
        values.password = null;
        values.host = null;
        break;
      case this.CONNECTION_TYPE_SFTP:
        values.resourceUid = null;
        break;
    }
    return values;
  }
}
