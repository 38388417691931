import React from "react";
import PropTypes from "prop-types";
import R14, {
  StyleSheet,
  Colors,
  FadeView,
  HiddenInputField,
  IconButton,
  ControlsView,
  SectionHeader,
  View,
  Button,
  Form,
  Card,
  Dialog,
} from "../core";

export default R14.connect(
  class AddRemoveWidgetField extends React.Component {
    constructor(props) {
      super(props);
      this.handleItemAddPress = this.handleItemAddPress.bind(this);
      this.handleItemRemovePress = this.handleItemRemovePress.bind(this);
      this.handleDialogClosePress = this.handleDialogClosePress.bind(this);
      this.handleDialogOkPress = this.handleDialogOkPress.bind(this);
      this.handleDialogFormSubmit = this.handleDialogFormSubmit.bind(this);
      this.form = this.props.app.ui.form(this.props.formName);
      this.state = {
        modalVisible: false,
      };
    }
    get isEditMode() {
      return this.form.values && this.form.values.uid ? true : false;
    }
    async handleItemRemovePress(i) {
      let form = this.props.app.ui.form(this.props.formName);
      let items = form.elmts[this.props.name].value;
      items.splice(i, 1);
      form.elmts[this.props.name].value = items;
    }
    async handleItemAddPress() {
      this.setState({
        modalVisible: true,
      });
    }
    async handleDialogClosePress() {
      this.setState({
        modalVisible: false,
      });
    }
    async handleDialogOkPress() {
      let dialogForm = this.props.app.ui.form.addRemoveWidgetForm;
      dialogForm.submit();
      // this.setState({
      //   modalVisible: false,
      // });
    }
    async handleDialogFormSubmit(dialogForm) {
      let form = this.props.app.ui.form(this.props.formName);
      let items = form.elmts[this.props.name].value || [];
      if (await dialogForm.validate()) {
        items.push(this.props.itemValueFormatter(dialogForm.values));
        form.elmts[this.props.name].value = items;
        this.setState({
          modalVisible: false,
        });
      }
    }
    // async handleItemAddPress() {
    //   // Get the form from the context
    //   let form = this.props.app.ui.form(this.props.formName);
    //   let items = form.elmts[this.props.name].value || [];
    //   if (!this.state.showForm) {
    //     this.setState({
    //       showForm: true,
    //     });
    //     return true;
    //   }
    //   if (await form.validate()) {
    //     items.push(this.props.itemValueFormatter(form.values));
    //     form.elmts[this.props.name].value = items;
    //     this.setState({
    //       // items: items,
    //       showForm: false,
    //       occurs: null,
    //     });
    //   }
    // }
    render() {
      let elementVals = this.form.values[this.props.name];
      let isAddMoreButton =
        !elementVals || !elementVals.length || !this.state.showForm;
      return (
        <View style={styles.addRemoveFormWidget}>
          <Card
            surfaceStyle={styles.surface}
            title={this.props.label}
            titleStyle={styles.title}
            titleControlsRight={[
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={this.handleItemAddPress}
                style={styles.addMoreButton}
              />,
            ]}
          >
            <HiddenInputField
              required={this.props.required}
              name={this.props.name}
            />

            {elementVals && elementVals.length > 0 && (
              <View style={styles.items}>
                {elementVals.map((val, i) => {
                  return (
                    <View
                      key={`${val[this.props.name]}${i}`}
                      style={styles.item}
                    >
                      <View style={styles.itemLeft}>
                        {this.props.itemRenderer(val)}
                      </View>
                      <ControlsView
                        style={styles.itemControlsRight}
                        align='right'
                      >
                        <IconButton
                          icon='close'
                          onPress={() => this.handleItemRemovePress(i)}
                        />
                      </ControlsView>
                    </View>
                  );
                })}
              </View>
            )}
            <Dialog
              name='addRemoveWidgetDialog'
              title={this.props.dialogTitle || `Add ${this.props.label}`}
              visible={this.state.modalVisible}
              titleControlVariant='close'
              onClosePress={this.handleDialogClosePress}
              onCancelPress={this.handleDialogClosePress}
              onBackdropPress={this.handleDialogClosePress}
              onOkPress={this.handleDialogOkPress}
              okButtonTitle='Add'
            >
              <Form
                name='addRemoveWidgetForm'
                style={styles.dialogForm}
                onSubmit={this.handleDialogFormSubmit}
                validateBeforeSubmit
              >
                {this.props.children}
              </Form>
            </Dialog>
          </Card>
          <View>{this.form.elmts[this.props.name] && this.form.elmts[this.props.name].error}</View>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  addRemoveFormWidget: {
   
  },
  surface: {
    ...StyleSheet.margin(0, 0, 32, 0),
    ...StyleSheet.padding(10, 8, 8, 12),
    // minHeight: 56,
    // elevation: 1,

    borderRadius: 4,
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    borderWidth: 1,
    borderStyle: "solid",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    ...StyleSheet.padding(0),
  },
  addMoreButton: {
    marginRight: 8,
  },
  dialogForm: {
    ...StyleSheet.padding(0),
  },
  items: {
    ...StyleSheet.margin(4, 0, 0, 0),
  },
  itemLeft: {
    width: "100%",
  },
  item: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    borderRadius: 4,
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(12, 0, 4, 0),
    marginBottom: 4,
  },
  itemDark: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
  },
  itemControlsRight: {
    ...StyleSheet.margin(-8, -12, 0, 0),
  },
});
