import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  SelectMenuField,
  TextInputField,
  Chip,
  ChipGroup,
  ShowHideText,
  View,
} from "../core";
import StateIndicatorIcon from "./StateIndicatorIcon";

export default R14.connect(
  class ProjectPipelineLogDataTable extends React.Component {
    constructor(props) {
      super(props);
      console.log(this.props);
      this.pipelineLogDomain = this.props.app.dm.pipelineLog;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      //this.handleRowPress = this.handleRowPress.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.state = {};
      //this.getBillingData();
    }

    get perms() {
      return this.props.app.dm.user.getPermissions(this.projectKey, "pipeline");
    }
    // async getBillingData() {
    //   let res = await this.props.app.dm.pipeline.findBillingItems(
    //     `
    //     stringValue1
    //     intValue1
    //     intValue2
    //     intValue3
    //     `,
    //     {
    //       page: 1,
    //       resultsPerPage: 1000,
    //       filter: {},
    //       sort: [
    //         {
    //           field: "intValue2",
    //           order: "DESC",
    //         },
    //       ],
    //       totalCount: true,
    //     }
    //   );
    //   this.setState({ billingItems: res.nodes });
    //   return {
    //     pageData: res.nodes,
    //     totalRows: res.totalCount || null,
    //   };
    // }
    async getPageData(
      {
        page,
        rowsPerPage,
        sortColumnName,
        sortDirection,
        search = null,
        filters = null,
      },
      options = {}
    ) {
      let filter = {};
      if (this.props.pipeline)
        filter.pipelineUid = { eq: this.props.pipeline.uid };
      if (search) filter.search = { like: `%${search}%` };
      if (filters) {
        if (filters.types) filter.type = { in: filters.types };
        if (filters.pipelineBlockUids)
          filter.pipelineBlockUid = { in: filters.pipelineBlockUids };
        if (filters.key) filter.key = { like: `%${filters.key}%` };
        if (filters.subkey) filter.subkey = { like: `%${filters.subkey}%` };
      }


      let res = await this.pipelineLogDomain.find(
        `
        uid
        loggedAt
        key
        type
        subkey
        instanceId
        message
        pipelineBlock {
          uid
          name
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("pipelines");
    }
    get title() {
      if (this.props.pipeline) return `${this.props.pipeline.name} Log`;
      else return "Pipeline Log";
      // return this.props.type
      //   ? `${this.props.app.dm.project.getTypeLabel(this.props.type)} Pipelines`
      //   : "Pipelines";
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "loggedAt":
          let date = new Date(cell);
          let shortDate = this.props.app.utils.date.toShortDate(date);
          let time = this.props.app.utils.date.toTime(date);
          ret = (
            <Text style={style}>
              {shortDate} {time}
            </Text>
          );
          break;
        case "message":
          ret = <ShowHideText text={row.message} maxCharacters={160} />;
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    rowBottomRenderer({ row, columns }) {
      return (
        <ChipGroup style={styles.cellChipGroup}>
          <Chip
            iconLeft={
              <StateIndicatorIcon
                color={this.pipelineLogDomain.getTypeIndicatorColor(row.type)}
              />
            }
            label={this.pipelineLogDomain.getTypeLabel(row.type)}
          />
          <Chip
            label={row.pipelineBlock.name}
            onPress={() =>
              this.props.app.nav.to("projectPipelineBlock", {
                uid: this.props.project.uid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.project.type
                ),
                pipelineUid: this.props.pipeline.uid,
                pipelineBlockUid: row.pipelineBlock.uid,
              })
            }
          />
          {row.instanceId && <Chip label={row.instanceId} />}
          {row.key && <Chip label={row.key} />}
          {row.subkey && <Chip label={row.subkey} />}
        </ChipGroup>
      );
    }
    // handleRowPress(row) {
    //   this.props.app.nav.to("projectPipeline", {
    //     uid: this.props.projectUid,
    //     key: this.props.app.dm.project.getKeyByType(this.props.projectType),
    //     pipelineUid: row.uid,
    //   });
    // }
    render() {
      // return (
      //   <View>
      //     <View><Text>Batch, Images, Processed, 50%</Text></View>
      //     <View>
      //       {this.state.billingItems &&
      //         this.state.billingItems.map((item) => (
      //           <View>
      //             <Text>{`${item.stringValue1}, ${item.intValue1}, ${item.intValue2}, ${item.intValue3}`}</Text>
      //           </View>
      //         ))}
      //     </View>
      //   </View>
      // );
      // return null;
      return (
        <DataTable
          name='projectPipelines'
          columns={{
            loggedAt: {
              label: "Logged At",
              sortable: true,
              width: 204,
            },
            // type: {
            //   label: "Type",
            //   sortable: true,
            // },
            // pipelineBlock: {
            //   label: "Block",
            // },
            // key: {
            //   label: "Key",
            //   sortable: true,
            // },
            // subkey: {
            //   label: "Subkey",
            //   sortable: true,
            // },
            message: {
              label: "Message",
              sortable: false,
            },
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || this.title}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='loggedAt'
          initialSortDirection='desc'
          rowBottom={this.rowBottomRenderer}
          headerControlsRight={
            this.perms.create
              ? [
                  <PopUpMenu
                    controlIcon='dotsVertical'
                    key='adminMenu'
                    direction='downLeft'
                    onSelect={(value, label) => {}}
                  >
                    <PopUpMenuItem
                      icon='add'
                      iconSize='small'
                      label='Create Pipeline'
                      value='test1'
                      to='projectPipelineCreate'
                      key='add'
                    />
                  </PopUpMenu>,
                ]
              : null
          }
          filterFields={[
            <SelectMenuField
              useValuesOnly
              multiple
              key='types'
              name='types'
              label='Types'
              items={this.props.dataGridFiltersFormData.typeSelections}
            />,
            <SelectMenuField
              useValuesOnly
              multiple
              key='pipelineBlockUids'
              name='pipelineBlockUids'
              label='Blocks'
              searchable
              items={this.props.dataGridFiltersFormData.pipelineBlockSelections}
            />,
            <TextInputField name='key' key='key' label='Key' />,
            <TextInputField name='subkey' key='subkey' label='Subkey' />,
          ]}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
  cellChipGroup: {
    marginTop: 8,
  },
});
