import React from 'react';
import PropTypes from 'prop-types'
import R14 from '../R14';
import Switch from './Switch';
import View from './View';
import Text from './Text';
import InputField from './InputField';
import StyleSheet from './StyleSheet';
import { Colors } from './Theme';

export default R14.connectForm(
  /**
  * A hidden form field field component
  */
  class SwitchField extends React.Component {
    static propTypes = {
      /** Label for the switch. */
      label: PropTypes.string,
      /** Name for the switch field. */
      name: PropTypes.string.isRequired,
      /** Value of the switch. */
      value: PropTypes.bool,
      /**
       * Callback for when the value of the switch is change
       *
       * @param {Boolean} value The value of the switch
       */
      handleValueChange: PropTypes.func,
      /** If true, disable the switch */
      disabled: PropTypes.bool
    }
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
    }
    handleValueChange(value) {
      this.elmt.setValue(value);
      if (this.props.onValueChange) this.props.onValueChange(value, this.elmt);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    componentWillUnmount(){
      this.elmt.remove();
    }
  //   renderSwitch(name, label, value) {
  //     ,
  // switchGroupField: {
  //   borderColor: StyleSheet.color(Colors.onBackground).rgba(.4),
  //   borderStyle: "solid",
  //   borderRadius: 4,
  //   borderWidth: 1,
  //   ...StyleSheet.padding(8, 0, 8, 0),
  //   marginBottom: 24
  // }
  //     return (
  //       <View style={styles.switchFieldWrapper}>
  //         <Text style={styles.labelText}>{label}</Text>
  //         <Switch name={name} value={value} />
  //       </View>
  //     );
  //   }
    render() {
      return (
        <InputField
          // {...this.props}
          style={this.props.style}
          element={this.elmt}
          InputComponent={
            <View style={styles.switchFieldWrapper}>
              {this.props.label &&
                <Text style={styles.labelText}>
                  {this.props.label}
                </Text>
              }
              <Switch
                name={this.elmt.name}
                ref={this.elmt.ref}
                value={this.elmt.value ? true : false}
                onValueChange={this.handleValueChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                autoFocus={this.props.autoFocus || null}
                style={styles.switch}
              />
            </View>
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  switchFieldWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...StyleSheet.padding(21, 12, 21, 12)
  },
  labelText: {
    lineHeight: 14,
    // pointerEvents: "none",
    fontWeight: "500",
    paddingRight: 12,
    color: StyleSheet.color(Colors.onBackground).rgba(.8)
  },
});