import R14 from "../R14";

export default class DataTableUiDomain extends R14.DomainInstances {
  create(props) {
    let dataTable = new DataTableInstanceUiDomain(props);
    this.addInstance(props.name, dataTable);
    return dataTable;
  }
}

export class DataTableInstanceUiDomain extends R14.Domain {
  constructor(props, component) {
    super();
    if (!props.name) throw "DataTable Error: DataTable must have a name.";
    if (!props.columns)
      throw "DataTable Error: DataTable must have columns prop.";
    this.props = props;
    /** @todo DataTable should this use historyState on a refresh? */
    let historyState = {};
    if (
      this.nav.state["_r14DataTable"] &&
      this.nav.state["_r14DataTable"][this.props.name]
    ) {
      historyState = this.nav.state["_r14DataTable"][this.props.name];
    }

    // Init the initial column states
    this._columns = this.props.columns;
    let hiddenColumns = [];
    for(let colName in this._columns){
      this._columns[colName].collapsed && hiddenColumns.push(colName);
    }
    
    // Init the state
    this.state = {
      hasLoaded: false,
      showActivityIndicator: true,
      page: historyState.page || this.props.initialPage,
      rowsPerPage: historyState.rowsPerPage || this.props.initialRowsPerPage,
      sortColumnName:
        historyState.sortColumnName || this.initialSortColumnName(),
      sortDirection:
        historyState.sortDirection || this.props.initialSortDirection,
      totalRows: historyState.totalRows || this.props.initialTotalRows,
      initialized: false,
      selectedRows: this.props.initialSelectedRows,
      showFilters: false,
      filters: null,
      searchTextInputValue: "",
      showSearch: false,
      hiddenColumns: hiddenColumns,
    };
    this._data = {};
    this._component = component;
    this._autoRefreshInterval = null;
    if (props.autoRefresh) this.autoRefresh(props.autoRefresh);
    this._autoSize = this.props.autoSize || false;
    this._searchTextChangeTimeout = null;
  }
  autoRefresh(seconds = 5) {
    if (seconds) {
      if (this._autoRefreshInterval) this.autoRefresh(false);
      this._autoRefreshInterval = setInterval(() => {
        //this.refresh({indicator: false});
        this.refresh({ init: false, reset: true, indicator: false });
      }, seconds * 1000);
    } else if (this._autoRefreshInterval) {
      clearInterval(this._autoRefreshInterval);
      this._autoRefreshInterval = null;
    }
  }
  remove() {
    this.autoRefresh(false);
    this.ui.dataTable.removeInstance(this.props.name);
  }
  init() {}
  // Refreshes current page (or page in options)
  // If init not given in options, it defaults to true
  async refresh(options = {}) {
    // Set to automatcally reset
    if (!("init" in options)) options.init = true;
    return await this.load(options);
  }
  async resetFilters() {
    if (this.hasFilterValues) {
      this.ui.form[`dataTable${this.props.name}FilterForm`] &&
        this.ui.form[`dataTable${this.props.name}FilterForm`].reset();
      // this.setState({filters: null});
      this.load(1, { filters: null, init: true, indicator: true });
    }
  }
  async submitFilters(formName, options = {}) {
    let filters = {};
    let formVals = this.ui.form[formName].values;
    for (let f in formVals) {
      if (formVals[f] !== null) filters[f] = formVals[f];
    }

    this.load(1, {
      filters: filters && Object.keys(filters).length ? filters : null,
      init: true,
      indicator: true,
      ...options,
    });
  }
  async load(page = null, options = {}) {
    // If the grid has not loaded yet, or the filters change, call initializer
    /** @todo DataGrid shouldInit check filters */

    if (page && typeof page === "object") {
      options = page;
      page = options.page || null;
    }

    let shouldInit = options.init || !this.isInitialized;

    // Check for state changes in options, if they exist reset the data
    let shouldReset =
      options.reset === true ||
      (options.sortColumnName &&
        options.sortColumnName !== this.sortColumnName) ||
      (options.sortDirection && options.sortDirection !== this.sortDirection) ||
      (options.rowsPerPage && options.rowsPerPage !== this.rowsPerPage) ||
      (options.search && options.search !== this.state.searchTextInputValue) ||
      false;

    let showIndicator =
      shouldInit || (this._data[page] && !shouldReset) ? false : true;
    if ("indicator" in options) {
      // override default incidicator value
      showIndicator = options.indicator === true;
    }

    let shouldScroll = this.state.hasLoaded;
    let sortColumnName = options.sortColumnName || this.sortColumnName;
    let sortDirection = options.sortDirection || this.sortDirection;
    let rowsPerPage = options.rowsPerPage || this.rowsPerPage;
    let totalRows = this.totalRows;
    let filters = "filters" in options ? options.filters : this.state.filters;
    let search = options.search || this.state.searchTextInputValue;
    page = page || this.state.page;
    let currState = {
      page: page,
      rowsPerPage: rowsPerPage,
      sortColumnName: sortColumnName,
      sortDirection: sortDirection,
      totalRows: totalRows,
      filters: filters,
      search: search,
    };

    // If page hasn't been loaded, show the progress indicator
    if (showIndicator) this.ui.progressIndicator.show();

    // Reset to loading state
    /** @todo is there a better way to make this mount less? Does it matter. */
    if (this.state.hasLoaded)
      this.setState({ hasLoaded: false, showActivityIndicator: !shouldReset });

    if (shouldInit) {
      let newState = await this.props.initializer(currState);
      // Update the local variables, which will set into the component state
      /** @todo DataTable, refactor this is kind of verbose */
      if ("page" in newState) page = newState.page;
      if ("rowsPerPage" in newState) rowsPerPage = newState.rowsPerPage;
      if ("totalRows" in newState) totalRows = newState.totalRows;
      if ("sortDirection" in newState) sortDirection = newState.sortDirection;
      if ("filters" in newState) filters = newState.filters;
      // Reset pageData and set with data if exists
      this._data = {
        [page]: newState.pageData || [],
      };
    } else if (shouldReset) {
      let newData = await this.props.pageLoader(currState);
      // Reset the current data
      this._data = {
        [page]: newData,
      };
    } else if (!this._data[page])
      this._data[page] = await this.props.pageLoader(currState);

    let selectedRows = this.state.selectedRows;
    if (this.props.isSelectedRow) {
      this._data[page].forEach((row) => {
        let key = this.keyExtractor(row);
        if (this.props.isSelectedRow(row)) {
          if (selectedRows.indexOf(key) === -1) selectedRows.push(key);
        } else if (selectedRows.indexOf(key) !== -1)
          selectedRows.splice(selectedRows.indexOf(key), 1);
      });
    }

    if (showIndicator) this.ui.progressIndicator.hide({ timeout: 750 });

    // Update the navigation replace only this datatable
    let navState = this.nav.state;
    if (!navState["_r14DataTable"]) navState["_r14DataTable"] = {};
    navState["_r14DataTable"][this.props.name] = {
      page: page,
      rowsPerPage: rowsPerPage,
      sortColumnName: sortColumnName,
      sortDirection: sortDirection,
    };
    this.nav.updateState(navState);

    // Update the state
    this.setState({
      hasLoaded: true,
      page: page,
      rowsPerPage: rowsPerPage,
      sortColumnName: sortColumnName,
      sortDirection: sortDirection,
      totalRows: totalRows,
      initialized: true,
      selectedRows: selectedRows,
      filters: filters && Object.keys(filters).length ? filters : null,
      //searchTextInputValue: search
    });
  }
  /**
   * Returns true if a row is selected. Can either supply a key or a row
   * @param {*} key
   */
  isSelectedRow(row) {
    return this.state.selectedRows.indexOf(this.keyExtractor(row)) !== -1;
  }

  hasSelectedAllRows() {
    let hasSelectedAll = true;
    if (!this.pageData.length) return false;
    this.pageData.forEach((row) => {
      if (!this.isSelectedRow(row)) hasSelectedAll = false;
    });
    return this.state.selectedRows.length === this.pageData.length;
  }

  getRow(key) {
    for (let row of this.pageData) {
      if (this.keyExtractor(row) === key) return row;
    }
    return false;
  }

  showColumn(columnKey) {
    this.addHiddenColumn(columnKey);
  }
  hideColumn(columnKey) {
    this.removeHiddenColumn(columnKey);
  }
  toggleColumn(columnKey) {
    this.isColumnVisible(columnKey)
      ? this.addHiddenColumn(columnKey)
      : this.removeHiddenColumn(columnKey);
  }
  addHiddenColumn(columnKey) {
    if (this.isColumnVisible(columnKey)) {
      let hiddenColumns = this.state.hiddenColumns;
      hiddenColumns.push(columnKey);
      this.setState({ hiddenColumns });
    }
  }
  removeHiddenColumn(columnKey) {
    if (!this.isColumnVisible(columnKey)) {
      let hiddenColumns = this.state.hiddenColumns;
      let idx = hiddenColumns.indexOf(columnKey);
      idx !== -1 && hiddenColumns.splice(idx, 1);
      this.setState({ hiddenColumns });
    }
  }
  isColumnVisible(columnKey) {
    return !this.state.hiddenColumns.includes(columnKey);
  }
  keyExtractor(row, index) {
    return this.props.rowKeyExtractor(row);
  }

  async handleRowCheckboxValueChange(key, value) {
    let row = this.getRow(key);
    if (this.props.onRowSelect) {
      let ret = await this.props.onRowSelect(row, value, this);
      if (ret === false) return;
      else if (typeof ret === "object") {
        this.updateRow(row);
        return;
      }
    }
  }

  async handleSelectAllCheckboxValueChange(value) {
    let rows = [];
    this.pageData.forEach((row) => {
      if (this.isSelectedRow(row) !== value) {
        rows.push(row);
      }
    });
    if (!rows.length) return;
    let ret = await this.props.onRowsSelect(rows, value, this);
    if (ret === false) return;
    else if (Array.isArray(ret)) {
      this.updateRows(ret);
      return;
    }
  }

  showSearch() {
    this.setState({
      showSearch: true,
    });
  }
  hideSearch() {
    this.setState({
      showSearch: false,
    });
  }
  searchTextExists() {
    return this.state.searchTextInputValue &&
      this.state.searchTextInputValue !== ""
      ? true
      : false;
  }
  async handleSearchChangeText(val) {
    this.setState({
      searchTextInputValue: val,
    });
    /** @todo make throttling more complex */
    if (this._searchTextChangeTimeout)
      clearTimeout(this._searchTextChangeTimeout);
    this._searchTextChangeTimeout = setTimeout(() => {
      this.load(1, {
        search: val,
        init: true,
      });
    }, 250);
  }
  initialSortColumnName() {
    // if initial sort is not set, get the first sortable column
    if (this.props.initialSortColumnName)
      return this.props.initialSortColumnName;
    let initialSortColumnName = null;
    for (let i in this.props.columns) {
      if (this.props.columns[i].sortable) return i;
    }
    return null;
  }
  get filtersVisible() {
    return this.state.showFilters;
  }
  toggleFilters() {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  }
  showFilters() {
    this.setState({
      showFilters: true,
    });
  }
  hideFilters() {
    this.setState({
      showFilters: false,
    });
  }
  get name() {
    return this._name;
  }
  get totalPages() {
    if (!this.totalRows) return 0;
    return Math.ceil(this.totalRows / this.rowsPerPage);
  }
  get totalRows() {
    return this.state.totalRows;
  }
  get pageData() {
    if (!this.state.page || !this._data[this.state.page]) return [];
    return this._data[this.state.page];
  }
  get page() {
    return this.state.page;
  }
  get sortColumnName() {
    return this.state.sortColumnName;
  }
  get sortDirection() {
    return this.sortColumnName ? this.state.sortDirection : null;
  }
  get startIndex() {
    return (this.page - 1) * this.rowsPerPage;
  }
  get endIndex() {
    return this.startIndex + (this.rowsPerPage - 1);
  }
  get rowsPerPage() {
    return this.state.rowsPerPage;
  }
  get isInitialized() {
    return this.state.initialized;
  }
  get autoSize() {
    return this._autoSize;
  }
  get columns() {
    return this._columns;
  }
  get hasFilterValues() {
    return this.state.filters && Object.keys(this.state.filters).length
      ? true
      : false;
  }
  get rowsPerPageSelectMenuItems() {
    return [
      {
        label: "5",
        value: 5,
      },
      {
        label: "10",
        value: 10,
      },
      {
        label: "25",
        value: 25,
      },
      {
        label: "50",
        value: 50,
      },
      {
        label: "100",
        value: 100,
      },
    ];
  }

  updateRow(row) {
    this.updateRows([row]);
  }
  updateRows(rows) {
    let selectedRows = this.state.selectedRows;
    let hasStateUpdate = false;
    let rowVals = {};
    rows.forEach((row) => {
      rowVals[this.keyExtractor(row)] = row;
    });
    for (let p in this._data) {
      for (let i in this._data[p]) {
        let key = this.keyExtractor(this._data[p][i]);
        if (rowVals[key]) {
          let row = rowVals[key];
          this._data[p][i] = row;
          if (this.props.isSelectedRow) {
            let isSelected = this.props.isSelectedRow(row);
            if (isSelected && selectedRows.indexOf(key) === -1) {
              hasStateUpdate = true;
              selectedRows.push(key);
            } else if (!isSelected && selectedRows.indexOf(key) !== -1) {
              hasStateUpdate = true;
              selectedRows.splice(selectedRows.indexOf(key), 1);
            }
          }
        }
      }
    }
    if (hasStateUpdate) this.setState({ selectedRows: selectedRows });
  }

  async loadNextPage() {
    if (this.page >= this.totalPages) return false;
    return await this.load(this.page + 1);
  }
  async loadPreviousPage() {
    if (this.page <= 1) return false;
    return await this.load(this.page - 1);
  }
  setSort(colName, sortDirection = null) {
    if (!sortDirection) {
      sortDirection =
        colName === this.sortColumnName && this.sortDirection === "asc"
          ? "desc"
          : "asc";
    }
    this.load(1, {
      sortColumnName: colName,
      sortDirection: sortDirection,
      reset: true,
    });
  }
}
