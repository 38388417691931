import R14 from "../core";

export default class DevDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
  }
  async run() {
    let res = await this.api.qry(`
      query DevRun {
        devRun{
          success
        }
      }`);
    return true;
  }
}
