import R14 from "../core";
export default class TodoDomain extends R14.Domain {
  constructor() {
    super();
    this.STATUS_TODO = "TODO";
    this.STATUS_ACTIVE = "ACTIVE";
    this.STATUS_REVIEW = "REVIEW";
    this.STATUS_COMPLETE = "COMPLETE";
    this.STATUS_ARCHIVED = "ARCHIVED";

    this.PRIORITY_LOW = "LOW";
    this.PRIORITY_NORMAL = "NORMAL";
    this.PRIORITY_HIGH = "HIGH";
    this.PRIORITY_CRITICAL = "CRITICAL";

    this.TYPE_TASK = "TASK";
    this.TYPE_BUG = "BUG";

  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindTodos($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: TodoFilter) {
        todos(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.todos;
  }

  async create(values) {
    values.clientUid = this.dm.userSession.clientUid;

    if(! values.assignedToUserUid) values.assignedToUserUid = this.dm.userSession.uid;
    values.createdByUserUid = this.dm.userSession.uid;

    let res = await this.api.mutate(
      `
      mutation CreateTodo($input: CreateTodoInput!) {
        createTodo(input: $input){
          todo {
            uid
            clientUid
            type
            title
            description
            assignedToUserUid
            createdByUserUid
            status
            priority
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    if(! values.assignedToUserUid) values.assignedToUserUid = this.dm.userSession.uid;

    let res = await this.api.mutate(
      `
      mutation UpdateTodo($input: UpdateTodoInput!) {
        updateTodo(input: $input){
          todo {
            uid
            clientUid
            type
            title
            description
            assignedToUserUid
            status
            priority
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async get(uid) {
    console.log("get with uid", uid);
    let res = await this.api.qry(
      `
      query GetTodo($uid: ID!) {
       todo(uid: $uid){
          uid
          name
          description
          content
        }
      }`,
      {
        uid: uid
      }
    );
    return res.todo;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteTodo($uid: ID!) {
        deleteTodo(uid: $uid){
          todo {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let res = uid
      ? await this.api.qry(
          `
          query TodoEditFormData($uid: ID!) {
            todo(uid: $uid){
              uid
              clientUid
              type
              title
              description
              assignedToUserUid
              status
              priority
            },
          }`,
          {
            uid: uid
          }
        )
      : {};
    console.log(uid);
    let formData = {
      values: res.todo || {},
      statusSelections: this.getStatusSelections(),
      prioritySelections: this.getPrioritySelections(),
      typeSelections: this.getTypeSelections()
    };
    return formData;
  }
  getStatusLabel(status) {
    let ret = null;
    switch (status) {
      case this.STATUS_TODO:
        ret = "To Do";
        break;
      case this.STATUS_ACTIVE:
        ret = "In Progress";
        break;
      case this.STATUS_REVIEW:
        ret = "In Review";
        break;
      case this.STATUS_COMPLETE:
        ret = "Complete";
        break;
      case this.STATUS_ARCHIVED:
        ret = "Archived";
        break;
    }
    return ret;
  }
  getStatusSelections() {
    return [
      {
        label: this.getStatusLabel(this.STATUS_TODO),
        value: this.STATUS_TODO
      },
      {
        label: this.getStatusLabel(this.STATUS_ACTIVE),
        value: this.STATUS_ACTIVE
      },
      {
        label: this.getStatusLabel(this.STATUS_REVIEW),
        value: this.STATUS_REVIEW
      },
      {
        label: this.getStatusLabel(this.STATUS_COMPLETE),
        value: this.STATUS_COMPLETE
      },
      {
        label: this.getStatusLabel(this.STATUS_ARCHIVED),
        value: this.STATUS_ARCHIVED
      }
    ];
  }
  getPriorityLabel(priority) {
    let ret = null;
    switch (priority) {
      case this.PRIORITY_LOW:
        ret = "Low";
        break;
      case this.PRIORITY_NORMAL:
        ret = "Normal";
        break;
      case this.PRIORITY_HIGH:
        ret = "High";
        break;
      case this.PRIORITY_CRITICAL:
        ret = "Critical";
        break;
    }
    return ret;
  }
  getPrioritySelections() {
    return [
      {
        label: this.getPriorityLabel(this.PRIORITY_LOW),
        value: this.PRIORITY_LOW
      },
      {
        label: this.getPriorityLabel(this.PRIORITY_NORMAL),
        value: this.PRIORITY_NORMAL
      },
      {
        label: this.getPriorityLabel(this.PRIORITY_HIGH),
        value: this.PRIORITY_HIGH
      },
      {
        label: this.getPriorityLabel(this.PRIORITY_CRITICAL),
        value: this.PRIORITY_CRITICAL
      }
    ];
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_TASK:
        ret = "Task";
        break;
      case this.TYPE_BUG:
        ret = "Bug";
        break;
    }
    return ret;
  }
  getTypeSelections() {
    return [
      {
        label: this.getTypeLabel(this.TYPE_TASK),
        value: this.TYPE_TASK
      },
      {
        label: this.getTypeLabel(this.TYPE_BUG),
        value: this.TYPE_BUG
      }
    ];
  }
  // async find(fields, options = null) {
  //   let fieldsStr = this.utils.gql.fieldsToString(fields);
  //   if (!fieldsStr) throw new Error("Todo Domain Find Error: No fields found");
  //   return await this.api.qry(`
  //     query FindTodos($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: TodoFilter) {
  //       todos(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
  //         totalCount @include(if: $totalCount)
  //         nodes {
  //           ${fieldsStr}
  //         }
  //       }
  //     }`, options
  //   );
  // }

  // async create(values) {
  //    /** @todo make sure to check return for errors */
  //    console.log(values);
  //   let res = await this.api.mutate(`
  //     mutation CreateTodo($input: CreateTodoInput!) {
  //       createTodo(input: $input){
  //         todo {
  //           uid
  //           description
  //           active
  //         }
  //       }
  //     }`, {
  //       input: values
  //     }
  //   );
  //   return true;
  // }
  // async update(values) {
  //    /** @todo make sure to check return for errors */
  //   let res = await this.api.mutate(`
  //     mutation UpdateTodo($input: UpdateTodoInput!) {
  //       updateTodo(input: $input){
  //         todo {
  //           uid
  //           description
  //           active
  //         }
  //       }
  //     }`, {
  //       input: values
  //     }
  //   );
  //   return true;
  // }
  // async updateMany(values) {
  //    /** @todo make sure to check return for errors */
  //   let res = await this.api.mutate(`
  //     mutation UpdateTodos($input: [UpdateTodoInput!]!) {
  //       updateTodos(input: $input){
  //         todos {
  //           todo {
  //             uid
  //             description
  //             active
  //           }
  //         }
  //       }
  //     }`, {
  //       input: values
  //     }
  //   );
  //   return true;
  // }
  // async delete(uid) {
  //   /** @todo make sure to check return for errors */
  //   let res = await this.api.mutate(`
  //     mutation DeleteTodo($uid: ID!) {
  //       deleteTodo(uid: $uid){
  //         todo {
  //           uid
  //         }
  //       }
  //     }`, {
  //       uid: uid
  //     }
  //   );
  //   return true;
  // }
  // async fetchFormData(uid = null) {
  //   let res = {};
  //   if (uid) {
  //     res = await this.api.qry(`
  //     query TodoEditFormData($uid: ID!) {
  //       todo(uid: $uid){
  //         uid
  //         description
  //         active
  //       }
  //     }`, {
  //         uid: uid
  //       }
  //     );
  //   }
  //   let vals = res.todo || {};
  //   vals.userUid = this.dm.userSession.uid;
  //   return {
  //     values: vals,
  //   };
  // }
}
