export default {
  default: {
    colors: {
      primary: "#0097a7",
      onPrimary: "#FFFFFF",
      primaryLight: "#56c8d8",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006978",
      onPrimaryDark: "#FFFFFF",
      secondary: "#c62828",
      onSecondary: "#FFFFFF",
      secondaryLight: "#ff5f52",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#8e0000",
      onSecondaryDark: "#FFFFFF",
      background: "#1b1b1b",
      onBackground: "#FFFFFF",
      surface: "#212121",
      onSurface: "#FFFFFF",
      error: "#e53935",
      onError: "#FFFFFF"
    },
    components: {
      header: {
        colors: {
          // background: "#00FFFF",
          // onBackground: "#00FFFF"
        }
      },
      sidebar: {
        colors: {
          // background: "#00FFFF",
          // onBackground: "#00FFFF"
        }
      }
    }
  },
  light: {
    colors: {
      primary: "#039be5",
      onPrimary: "#FFFFFF",
      primaryLight: "#63ccff",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006db3",
      onPrimaryDark: "#FFFFFF",
      secondary: "#f4511e",
      onSecondary: "#FFFFFF",
      SecondaryLight: "#ff844c",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#b91400",
      onSecondaryDark: "#FFFFFF",
      background: "#F6F6F6",
      onBackground: "#101010",
      surface: "#FFFFFF",
      onSurface: "#101010",
      error: "#B00020",
      onError: "#FFFFFF"
    }
  }
  // themeExela: {
  //   primary: "#397ea0",
  //   onPrimary: "#FFFFFF",
  //   primaryLight: "#4dc5f2",
  //   onPrimaryLight: "#FFFFFF",
  //   primaryDark: "#397ea0",
  //   onPrimaryDark: "#FFFFFF",
  //   secondary: "#397ea0",
  //   onSecondary: "#FFFFFF",
  //   SecondaryLight: "#ff844c",
  //   onSecondaryLight: "#FFFFFF",
  //   secondaryDark: "#b91400",
  //   onSecondaryDark: "#FFFFFF",
  //   background: "#f4f4f4",
  //   onBackground: "#151b26",
  //   surface: "#FFFFFF",
  //   onSurface: "#151b26",
  //   error: "#B00020",
  //   onError: "#FFFFFF",
  //   header:  "#FFFFFF",
  //   onHeader: "#1c1c1c",
  // }
  // ecelaDark: {
  //   primary: "#00acc1",
  //   onPrimary: "#FFFFFF",
  //   primaryLight: "#5ddef4",
  //   onPrimaryLight: "#FFFFFF",
  //   primaryDark: "#005662",
  //   onPrimaryDark: "#FFFFFF",
  //   secondary: "#bc1683",
  //   onSecondary: "#FFFFFF",
  //   secondaryLight: "#f256b3",
  //   onSecondaryLight: "#FFFFFF",
  //   secondaryDark: "#870056",
  //   onSecondaryDark: "#FFFFFF",
  //   background: "#202020",
  //   onBackground: "#FFFFFF",
  //   surface: "#262626",
  //   onSurface: "#FFFFFF",
  //   error: "#B00020",
  //   onError: "#FFFFFF",
  //   header: '#313131',
  //   onHeader: '#FFFFFF'
  // },
};
