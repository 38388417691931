import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
// import Icon from './Icon';
import StyleSheet from "./StyleSheet";
import Icon from "./Icon";
import ButtonBase from "./base/ButtonBase";
import View from "./View";
import Text from "./Text";

import { Colors } from "./Theme";

/**
 * A basic button Component
 */
export default R14.connect(
  class Button extends React.Component {
    static propTypes = {
      /** Title text inside the button. */
      title: PropTypes.string,
      /** The background color of the button, or text if using text / outlined variant  */
      color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** The background color of the button */
      backgroundColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      /** The color of the title text  */
      titleColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** The button varient to use */
      variant: PropTypes.oneOf(["outlined", "text"]),
      /** If true, disable the button */
      disabled: PropTypes.bool,
      /** A StyleSheet object, or array of StyleSheet objects to apply to the button */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** A StyleSheet object, or array of StyleSheet objects to apply to the button title text */
      titleTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Handler to be called when the user taps the button  */
      onPress: PropTypes.func,
      /** The name of the icon to be displayed in the button  */
      icon: PropTypes.string,
      /** The html type of the button  */
      type: PropTypes.string,
    };
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.state = {
        isFocused: false,
      };
    }
    handlePress() {
      if (this.props.onPress) this.props.onPress();
      else if (this.props.to) this.props.app.nav.to(this.props.to);
    }
    handleFocus() {
      this.setState({ isFocused: true });
    }
    handleBlur() {
      this.setState({ isFocused: false });
    }
    render() {
      let styles = this.styles();
      let iconColor = Colors.onPrimary;
      let titleStyles = [styles.title];
      let buttonStyles = [styles.button];
      if (this.props.disabled) buttonStyles.push(styles.disabled);
      if (this.props.style) buttonStyles.push(this.props.style);
      if (this.props.variant) {
        buttonStyles.push(styles[`${this.props.variant}VariantButton`]);
        titleStyles.push(styles[`${this.props.variant}VariantTitle`]);
        switch (this.props.variant) {
          case "outlined":
          case "text":
            iconColor = this.props.color || Colors.primary;
            break;
        }
      }
      if (this.props.titleTextStyle)
        titleStyles.push(this.props.titleTextStyle);

      if (this.state.isFocused) buttonStyles.push(styles.focused);

      let title = this.props.title ? this.props.title.toUpperCase() : null;
      let icon = null;
      if (this.props.icon) {
        icon = (
          <View style={styles.icon}>
            <Icon name={this.props.icon} color={iconColor} size='small' />
          </View>
        );
      }
      return (
        <ButtonBase
          type={this.props.type || "button"}
          onPress={this.handlePress}
          disabled={this.props.disabled}
          style={buttonStyles}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          <View tooltip={this.props.tooltip || null} style={styles.content}>
            {icon}
            <Text style={titleStyles}>{title}</Text>
          </View>
        </ButtonBase>
      );
    }
    styles() {
      return StyleSheet.create({
        button: {
          flex: 0,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          borderRadius: 4,
          backgroundColor:
            this.props.color || this.props.backgroundColor || Colors.primary,
          color: Colors.onPrimary,
          paddingRight: 16,
          paddingLeft: 16,
          elevation: 3,
          minWidth: 64,
          alignContent: "center",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor:
            this.props.color || this.props.backgroundColor || Colors.primary,
          // screen: ({ width }) => {
          //   if (width <= 640)
          //     return {
          //       width: "100%",
          //     };
          // },
        },

        disabled: {
          opacity: 0.4,
          // ":hover": {
          //   opacity: .4
          // }
        },
        content: {
          flex: 0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          height: 34,
          marginRight: "auto",
          marginLeft: "auto",
        },
        title: {
          fontSize: 14,
          fontWeight: "500",
          textTransform: "uppercase",
          color: this.props.titleColor || Colors.onPrimary,
        },
        icon: {
          marginTop: 0,
          marginRight: 4,
          marginBottom: 0,
          marginLeft: -4,
        },
        errorColor: {
          backgroundColor: Colors.error,
        },
        textVariantButton: {
          elevation: 0,
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        textVariantTitle: {
          color: this.props.titleColor || this.props.color || Colors.primary,
          backgroundColor: this.props.backgroundColor || null,
        },
        outlinedVariantButton: {
          elevation: 0,
          backgroundColor: "transparent",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
        },
        outlinedVariantTitle: {
          color: this.props.titleColor || this.props.color || Colors.primary,
          backgroundColor: this.props.backgroundColor || null,
        },
        focused: {
          borderColor: StyleSheet.color(Colors.onBackground).rgba(0.3),
        },
      });
    }
  }
);
