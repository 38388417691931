import React from "react";
import R14, { ScreenLayout } from "../core";
import DatasetDataTable from "../components/DatasetDataTable";
export default R14.connect(
  class DatasetsScreen extends React.Component {
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.projectType,
              "dataset",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("datasetCreate", {
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.projectType
                  ),
                }),
              icon: "add",
            }
          }
          scrollEnabled
        >
          <DatasetDataTable projectType={this.props.projectType} />
        </ScreenLayout>
      );
    }
  }
);
