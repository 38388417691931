import React from 'react';
import R14, { Colors, Calendar, View, Button, DatePickerModal, StyleSheet, ScreenLayout, PopUpMenu, PopUpMenuItem, IconButton, Text, DataTable } from '../core';

export default R14.connect(
  class TodosScreen extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRowSelect = this.handleRowSelect.bind(this);
      this.handleRowsSelect = this.handleRowsSelect.bind(this);
      this.state = {
        date: null,
        datePickerVisible: false
      };
      console.log('figure out clean way to forward back to grid and update, maybe use object for selectedrows, check error on select all.');
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.todo.find(
        `
          uid
          description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async updateTodoActive(uid, active, dataTable) {
      let res = await this.props.app.dm.todo.update({ uid: uid, active: active });
      dataTable.refresh();
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let style = [styles.cellText];
      switch (columnName) {
        case 'createdAt':
          let tDate = new Date();
          tDate.setTime(cell);
          content = this.props.app.utils.date.toShortDate(tDate)
          break;
        case 'description':
          if(! row.active) style.push(styles.descCompleteText);
          break;
        default:
      }
      return <Text style={style}>{content}</Text>
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    isSelectedRow(row) {
      return (row.active === false) ? true : false;
    }
    handleRowSelect(row, isSelected, dataTable) {
      row.active = !row.active;
      let update = {
        uid: row.uid,
        active: !isSelected
      };
      this.props.app.dm.todo.update(update).then((ret) => {
        // Switch back the value
        row.active = !isSelected;
        /** @todo Update row and show an error if false */
        if (ret !== true) dataTable.updateRow(row);
        else {
          // if active is in the props, refresh the table after selections...
          // For all todos, this is not needed because nothing changed other that then selection
          if ('active' in this.props) dataTable.refresh();
        }
      });
      return row;
    }
    handleRowsSelect(rows, isSelected, dataTable) {
      let updates = [];
      rows = rows.map((row) => {
        updates.push({
          uid: row.uid,
          active: !isSelected
        });
        row.active = !isSelected;
        return row;
      });
      this.props.app.dm.todo.updateMany(updates).then((ret) => {
        /** @todo Update row and show an error if false */
        if (ret !== true) {
          /** @todo Update rows and show an error if false */
          throw new Error('Error updating selected rows.');
        }
        else if ('active' in this.props) {
          // if active is in the props, refresh the table after selections...
          // For all todos, this is not needed because nothing changed other that then selection
          if ('active' in this.props) dataTable.refresh();
        }
      });
      return rows;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.todo.delete(uid);
        dataTable.refresh({indicator: true});
      }
    }
    async handleSearchPress(){

    }
    render() {
      let t = new Date();
      let startDate = new Date();
      let endDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setMonth(endDate.getMonth() + 1);

      // return (
      //   <Button 
      //     title="Test Query"
      //     onPress={()=>this.props.app.dm.dev.test()}
      //   />
      // );
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "todoCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <DataTable
            name={this.props.name}
            columns={{
              description: {
                label: 'Description',
                sortable: true
              },
              createdAt: {
                label: 'Created',
                sortable: true,
                filter: {
                  type: 'dateRange'
                },
                collapse: ({width}) => {
                  return (width <= 640);
                }
              }
            }}
            initializer={this.initializer}
            rowKeyExtractor={this.rowKeyExtractor}
            pageLoader={this.pageLoader}
            cellRenderer={this.cellRenderer}
            initialRowsPerPage={10}
            headerTitle={this.props.title || 'All Todos'}
            checkboxes
            isSelectedRow={this.isSelectedRow}
            onRowSelect={this.handleRowSelect}
            onRowsSelect={this.handleRowsSelect}
            paddingBottom={72} // Make space for the FAB
            initialSortColumnName='createdAt'
            initialSortDirection='asc'
            headerControlsRight={[
              <IconButton
                icon="search"
                key="search"
                onPress={() => { alert("search") }}
              />,
              <IconButton
                icon="filter"
                key="filter"
                onPress={() => { this.props.app.ui.dataTable(this.props.name).showFilters() }}
              />,
              <PopUpMenu
                controlIcon="dotsVertical"
                key="adminMenu"
                direction="downLeft"
                onSelect={(value, label) => { }}
              >
                <PopUpMenuItem
                  icon="add"
                  iconSize="small"
                  label='Create Todo'
                  value="test1"
                  to='todoCreate'
                  key="add"
                />
              </PopUpMenu>
            ]}
            rowControlsRight={(row, dataTable) => {
              return ([
                <IconButton
                  icon="edit"
                  size="small"
                  key="userEdit"
                  onPress={() => this.props.app.nav.to(`todoEdit`, {
                    uid: row.uid,
                  })}
                />,
                <PopUpMenu
                  controlIcon="dotsHorizontal"
                  controlIconSize="small"
                  key="userMenu"
                  direction="downLeft"
                >
                  <PopUpMenuItem
                    label={row.active ? 'Mark Complete' : 'Set Active'}
                    key='todoToggleActive'
                    onPress={() => { this.updateTodoActive(row.uid, !row.active, dataTable) }}
                  />
                  <PopUpMenuItem
                    label="Remove"
                    key="remove"
                    onPress={() => this.handleRemovePress(row.uid, dataTable)}
                  />
                </PopUpMenu>
              ]
              );
            }}
          />
          <DatePickerModal
            visible={this.state.datePickerVisible}
            date={this.state.date}
            minDate={startDate}
            maxDate={endDate}
            onCancelPress={date => {
              this.setState({ datePickerVisible: false })
            }}
            onConfirmPress={date => {
              this.setState({ date: date, datePickerVisible: false })
            }}
          />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  descCompleteText: {
    textDecorationLine: 'line-through'
  }
});