import React from "react";
import R14, {
  StyleSheet,
  Colors,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  View,
  Text,
  Link,
  Icon,
} from "../core";
import ResourceControls from "../components/ResourceControls";
export default R14.connect(
  class ProjectScreenLayout extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.project.type);
    }
    renderHeaderControls() {
      let controls = [];
      if (this.props.headerControlsRight)
        controls = [...this.props.headerControlsRight];
      // this.userDomain.checkPermissions(
      //   "project",
      //   this.userDomain.PERMISSION_ACCESS_EDIT
      // ) &&
      //   controls.push([
      //     <PopUpMenu
      //       controlIcon='dotsVertical'
      //       key='adminMenu'
      //       direction='downLeft'
      //       onSelect={(value, label) => {}}
      //     >
      //       <PopUpMenuItem
      //         label='Edit Project'
      //         icon='edit'
      //         key='edit'
      //         onPress={() =>
      //           this.props.app.nav.to("projectEdit", {
      //             uid: this.props.project.uid,
      //             key: this.projectKey,
      //           })
      //         }
      //       />
      //     </PopUpMenu>,
      //   ]);
      return controls;
    }
    render() {
      return (
        <ScreenLayout
          HeaderRowTopComponent={
            <View style={styles.headerBreadCrumbs}>
              <Link
                to={{
                  route: "projects",
                  params: { key: this.projectKey },
                }}
              >
                <Text style={styles.headerBreakCrumbText}>
                  {this.props.project.name}
                </Text>
              </Link>
              {this.props.breadCrumbs &&
                this.props.breadCrumbs.map((breadCrumb, i) => {
                  return [
                    <Icon key={`arrow${i}`} size="small" name='keyboardArrowRight' />,
                    <Link key={`link${i}`} to={breadCrumb.to}>
                      <Text style={styles.headerBreakCrumbText}>
                        {breadCrumb.label}
                      </Text>
                    </Link>,
                  ];
                })}
            </View>
          }
          HeaderTitleComponent={
            !this.props.DataTableComponent &&
            (this.props.HeaderTitleComponent || this.props.headerTitle) && (
              <View>
                {this.props.HeaderTitleComponent
                  ? this.props.HeaderTitleComponent
                  : null}
                {!this.props.HeaderTitleComponent && (
                  <View style={styles.headerTitle}>
                    <Text style={styles.headerTitleText}>
                      {this.props.headerTitle}
                    </Text>
                  </View>
                )}
              </View>
            )
          }
          // headerTitle={this.props.headerTitle}
          headerControlsRight={
            !this.props.DataTableComponent && this.renderHeaderControls()
          }
          scrollEnabled
          floatingActionButton={this.props.floatingActionButton || null}
        >
          {this.props.DataTableComponent && (
            <View style={styles.dataTable}>
              {this.props.DataTableComponent}
            </View>
          )}
          {this.props.children}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  headerTitle: {
    ...StyleSheet.padding(4, 0, 4, 0),
    ...StyleSheet.margin(0, 0, 0, 0),
  },
  headerTitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  headerBreakCrumbText: {
    fontSize: 14,
  },
  headerBreadCrumbs: {
    flex: 1,
    flexDirection: 'row',
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
    ...StyleSheet.padding(8, 16, 8, 16),
    // ...StyleSheet.margin(-16, -16, 16, -16),
  },
  dataTable: {
    ...StyleSheet.margin(-16, 0, 0, 0),
  },
});
