import React from "react";
import R14, {
  StyleSheet,
  View,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
} from "../core";
export default R14.connect(
  class AppModuleControls extends React.Component {
    constructor(props) {
      super(props);
      this.handleRepoPress = this.handleRepoPress.bind(this);
      this.handlePackagesPress = this.handlePackagesPress.bind(this);
      this.handleUpdateCorePress = this.handleUpdateCorePress.bind(this);
      this.handleShowLogPress = this.handleShowLogPress.bind(this);
      this.appModuleDm = this.props.app.dm.appModule;
      this.pipelineBlockDm = this.props.app.dm.pipelineBlock;
    }
    toAppModuleRoute(routeName) {
      let params = {
        uid: this.props.projectUid,
        key: this.props.app.dm.project.getKeyByType(this.props.projectType),
        appModuleUid: this.props.appModule.uid,
      };
      if (this.props.pipelineBlock) {
        params.pipelineUid = this.props.pipelineBlock.pipelineUid;
        params.pipelineBlockUid = this.props.pipelineBlock.uid;
      }
      this.props.app.nav.to(routeName, params);
    }
    handleRepoPress() {
      this.toAppModuleRoute(
        this.props.pipelineBlock
          ? "projectPipelineBlockIdeManageRepos"
          : "projectAppModuleIdeManageRepos"
      );
    }
    handlePackagesPress() {
      this.toAppModuleRoute(
        this.props.pipelineBlock
          ? "projectPipelineBlockIdeManagePackages"
          : "projectAppModuleIdeManagePackages"
      );
    }
    handleShowLogPress() {
      this.toAppModuleRoute(
        this.props.pipelineBlock
          ? "projectPipelineBlockIdeLog"
          : "projectAppModuleIdeLog"
      );
    }
    async handleUpdateCorePress() {
      this.props.app.ui.progressIndicator.show();
      await this.appModuleDm.updateCore(this.props.appModule.uid);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    render() {
      let appModule = this.props.appModule;
      let pipelineBlock = this.props.pipelineBlock;
      let perms = this.props.permissions;
      let server = this.props.server;
      let ideServer = this.props.ideServer;

      let controls = [];
      let menuItems = [];
      if (this.props.state === this.appModuleDm.STATE_READY) {
        if (pipelineBlock) {
          this.props.pipelineBlockState !==
            this.pipelineBlockDm.STATE_RUNNING &&
            this.props.pipelineBlockState !==
              this.pipelineBlockDm.STATE_PENDING &&
            controls.push(
              <IconButton
                icon='play'
                key='run'
                tooltip={`Run Pipeline Block`}
                size='small'
                onPress={() =>
                  this.props.onRunCommandPress(
                    server.type,
                    this.pipelineBlockDm.COMMAND_RUN
                  )
                }
              />
            );
          this.props.pipelineBlockState !==
            this.pipelineBlockDm.STATE_STOPPED &&
            controls.push(
              <IconButton
                icon='stop'
                key='run'
                tooltip={`Stop Pipeline Block`}
                size='small'
                onPress={() =>
                  this.props.onRunCommandPress(
                    server.type,
                    this.pipelineBlockDm.COMMAND_STOP
                  )
                }
              />
            );
          this.props.pipelineBlockState !==
            this.pipelineBlockDm.STATE_STOPPED &&
            controls.push(
              <IconButton
                icon='refresh'
                key='reload'
                tooltip={`Reload Pipeline Block`}
                size='small'
                onPress={() =>
                  this.props.onRunCommandPress(
                    server.type,
                    this.pipelineBlockDm.COMMAND_RELOAD
                  )
                }
              />
            );
        } else {
          if (perms.execute) {
            server.state !== this.appModuleDm.SERVER_STATE_RUNNING &&
              server.state !== this.appModuleDm.SERVER_STATE_STARTING &&
              controls.push(
                <IconButton
                  icon='play'
                  key='run'
                  tooltip={`Run ${server.name}`}
                  size='small'
                  onPress={() =>
                    this.props.onRunCommandPress(
                      server.type,
                      this.appModuleDm.COMMAND_RUN
                    )
                  }
                />
              );
            server.state !== this.appModuleDm.SERVER_STATE_STOPPED &&
              server.state !== this.appModuleDm.SERVER_STATE_STOPPING &&
              controls.push(
                <IconButton
                  icon='stop'
                  key='stop'
                  tooltip={`Stop ${server.name}`}
                  size='small'
                  onPress={() =>
                    this.props.onRunCommandPress(
                      server.type,
                      this.appModuleDm.COMMAND_STOP
                    )
                  }
                />
              );
            (server.state === this.appModuleDm.SERVER_STATE_RUNNING ||
              server.state === this.appModuleDm.SERVER_STATE_STARTING) &&
              controls.push(
                <IconButton
                  icon='refresh'
                  key='reload'
                  tooltip={`Reload ${server.name}`}
                  size='small'
                  onPress={() =>
                    this.props.onRunCommandPress(
                      server.type,
                      this.appModuleDm.COMMAND_RELOAD
                    )
                  }
                />
              );
          }
        }
      }
      ideServer &&
        (ideServer.state === this.appModuleDm.SERVER_STATE_RUNNING ||
          ideServer.state === this.appModuleDm.SERVER_STATE_STARTING) &&
        menuItems.push(
          <PopUpMenuItem
            icon='stop'
            label='Stop IDE'
            size='small'
            key='stopIde'
            onPress={() =>
              this.props.onRunCommandPress(
                this.appModuleDm.SERVER_TYPE_IDE,
                this.appModuleDm.COMMAND_STOP
              )
            }
          />
        );
      menuItems.push(
        <PopUpMenuItem
          icon='console'
          label='App Module Log'
          size='small'
          key='log'
          onPress={this.handleShowLogPress}
        />
      );
      if (this.props.state === this.appModuleDm.STATE_READY) {
        appModule.appModuleRepos.nodes.length &&
          menuItems.push(
            <PopUpMenuItem
              icon='git'
              label='Git Repositories'
              size='small'
              key='gitRepos'
              onPress={this.handleRepoPress}
            />
          );
        [
          this.appModuleDm.TYPE_REACT_APP,
          this.appModuleDm.TYPE_SERVER,
          this.appModuleDm.TYPE_TASK,
          this.appModuleDm.TYPE_NODE_APP,
        ].includes(appModule.type) &&
          menuItems.push(
            <PopUpMenuItem
              icon='npm'
              label='NPM Packages'
              size='small'
              key='manageNpm'
              onPress={this.handlePackagesPress}
            />
          );
        appModule.type === this.appModuleDm.TYPE_PYTHON_APP &&
          menuItems.push(
            <PopUpMenuItem
              icon='languagePython'
              label='PIP Packages'
              size='small'
              key='manageNpm'
              onPress={this.handlePackagesPress}
            />
          );

        [
          this.appModuleDm.TYPE_REACT_APP,
          this.appModuleDm.TYPE_SERVER,
          this.appModuleDm.TYPE_PYTHON_APP,
          this.appModuleDm.TYPE_TASK,
          this.appModuleDm.TYPE_NODE_APP,
        ].includes(appModule.type) &&
          menuItems.push(
            <PopUpMenuItem
              icon='refresh'
              label='Update Core'
              size='small'
              key='updateCore'
              onPress={this.handleUpdateCorePress}
            />
          );
      }
      if (menuItems.length) {
        controls.push(
          <PopUpMenu controlIcon='dotsVertical' key='menu' direction='downLeft'>
            {menuItems}
          </PopUpMenu>
        );
      }
      return controls.length ? controls : null;
    }
  }
);
const styles = StyleSheet.create({
  appModuleControls: {
    flex: 0,
    flexDirection: "row",
  },
});
