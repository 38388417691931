import React from 'react';
import StyleSheet from './StyleSheet';
import Text from './Text';
import { Colors } from './Theme';

export default class SectionHeader extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let level = this.props.level || 1;
    let tagName = `h${level}`;
    let sectionHeaderStyle = [styles.h, styles[tagName], styles.props];
    if (this.props.style) sectionHeaderStyle.push(this.props.style);
    return (
      <Text
        style={sectionHeaderStyle}
        htmlTag={tagName}
        noWrap={this.props.noWrap || false}
      >
        {this.props.children}
      </Text>
    );
  }
}
const styles = StyleSheet.create({
  h: {
    // fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: Colors.onBackground
  },
  h1: {
    // fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: 32,
    paddingBottom: 20,
    fontWeight: "700"
  },
  h2: {
    // fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: 24,
    paddingBottom: 16,
    fontWeight: "400"
  },
  h3: {
    fontSize: 18,
    paddingBottom: 12,
    fontWeight: "300",
    // textTransform: "uppercase"
  },
  h4: {
    fontSize: 18,
    paddingBottom: 12,
    fontWeight: "600"
  },
  h5: {
    fontSize: 16,
    paddingBottom: 8,
  },
  h6: {
    fontSize: 13,
    paddingBottom: 20,
    fontWeight: "500",
    // textTransform: "uppercase",
    color: Colors.secondary
  }
});