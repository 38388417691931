import React from "react";
import R14 from "../core";
import RelatedAddForm from "../components/RelatedAddForm";

export default R14.connect(
  class ProjectPipelineBlockAddScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      console.log("FORM VALS!", this.props.formData);
      return (
        <RelatedAddForm
          name='projectPipelineBlockAddForm'
          formData={this.props.formData}
          domainName='pipelineBlock'
          refreshDataTable={this.props.refreshDataTable || "pipelineBlocks"}
          redirect={this.props.redirect || {
              route: 'projectPipeline',
              params: { uid: this.props.pipelineUid }
            }
          }
          relatedFieldName='pipelineUid'
          fields={['type']}
          createButtonTitle='Create New Pipeline Block'
          createAction={this.props.createAction || "projectPipelineBlockCreate"}
          selectMenuFieldName='pipelineBlockUid'
          selectMenuFieldLabel='Select Block'
          selectMenuFieldSelections={this.props.formData.blockSelections}
        />
      );
    }
  }
);