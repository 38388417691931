import R14 from "../R14";

export default class NavigatorUiDomain extends R14.DomainInstances {
  create(props) {
    let dialog = new NavigatorInstanceUiDomain(this, props);
    this.addInstance(props.name, dialog);
    return dialog;
  }
  remove(name) {
    this.removeInstance(name);
  }
}

export class NavigatorInstanceUiDomain extends R14.Domain {
  constructor(navigatorDomain, props) {
    super();
    if (!props.name) throw "Navigator Error: Navigator must have a name.";
    this._name = props.name;
    this._navigatorDomain = navigatorDomain;
    this._onToggledCallback = null;
    let options = {};
    switch (props.navigator.type) {
      // case "header":
      //   options = props.navigator.header || {};
      //   break;
      // case "tab":
      //   options = props.navigator.header || {};
      //   break;
      case "drawer":
        options = props.navigator.drawerNavigator || {};
        break;
    }
    this._navigatorType = props.navigator.type;
    this._onToggledCallback = props.onToggled || null;
    this.state = {
      visible: props.visible || false,
      layoutStyles: null,
      options: options,
      toggle: false,
    };
  }
  setLayoutStyles(layoutStyles) {
    this.setState({ layoutStyles: layoutStyles });
    return this;
  }
  get isVisible() {
    return this.state.visible;
  }
  set visible(visible) {
    return this.setVisible(visible);
  }
  setVisible(visible, options = {}) {
    this.setState({
      visible: visible,
      toggled: options.toggled ? true : false,
    });
    return this;
  }
  show() {
    this.setVisible(true);
  }
  hide() {
    this.setVisible(false);
  }
  setOption(name, value) {
    let options = this.state.options;
    if (options[name] === value) return;
    options[name] = value;
    this.setState({ options: options });
  }
  get options() {
    return this.state.options;
  }
  hide() {
    this.setVisible(false);
  }
  toggle() {
    if(this._onToggledCallback) this._onToggledCallback(!this.isVisible);
    this.setVisible(!this.isVisible, { toggled: true });
  }
  onToggled(callback){
    this._onToggledCallback = callback;
  }
  get isToggled() {
    return this.state.toggled;
  }
  get toggled() {
    return this.isToggled;
  }
  remove() {
    this._navigatorDomain.remove(this._name);
  }
}
