import React from "react";
import R14, {
  Text,
  View,
  Card,
  IconButton,
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  FadeView,
  Icon,
  DraggableView,
} from "../core";
import StateIndicatorIcon from "../components/StateIndicatorIcon";

export default R14.connect(
  class PipelineBlockWidget extends React.Component {
    constructor(props) {
      super(props);
      this.handleDrag = this.handleDrag.bind(this);
      this.handleDragEnd = this.handleDragEnd.bind(this);
      this.handleDragStart = this.handleDragStart.bind(this);
      this.handleDragCancel = this.handleDragCancel.bind(this);
      this.handleDragCollision = this.handleDragCollision.bind(this);
      this.handleMovePress = this.handleMovePress.bind(this);
      this.handleCommandPress = this.handleCommandPress.bind(this);
      this.handleDeleteDataPress = this.handleDeleteDataPress.bind(this);
      this.pipelineBlockSubscription = null;
      this.pipelineBlockDm = this.props.app.dm.pipelineBlock;
      this.appModuleDm = this.props.app.dm.appModule;
    }
    get perms() {
      return this.props.perms;
    }
    componentDidMount() {
      this.init();
    }
    componentWillUnmount() {
      this.pipelineBlockSubscription &&
        this.pipelineBlockSubscription.unsubscribe();
    }
    async init() {
      if (this.props.onUpdate)
        this.pipelineBlockSubscription = await this.pipelineBlockDm.onUpdate(
          this.props.uid,
          (res) => {
            this.props.onUpdate(this.props.uid, res);
          }
        );
    }
    async handleDragStart(event) {
      this.props.onDragStart && this.props.onDragStart(event);
    }
    async handleDrag(event) {
      this.props.onDrag && this.props.onDrag(event);
    }
    async handleDragEnd(event) {
      this.props.onDragEnd && this.props.onDragEnd(event);
    }
    async handleDragCancel(event) {
      this.props.onDragCancel && this.props.onDragCancel(event);
    }
    async handleDragCollision(blocks) {
      this.props.handleDragCollision && this.props.handleDragCollision(blocks);
    }
    handleMovePress() {
      if (this.props.onMoveEnable) this.props.onMoveEnable(this.props.uid);
    }
    handleCommandPress(command) {
      if (this.props.onCommandPress)
        this.props.onCommandPress(this.props.uid, command);
    }
    async handleDeleteDataPress() {
      if (!window.confirm("Are you sure?")) return;
      this.props.app.ui.progressIndicator.show();
      let res = await this.pipelineBlockDm.deleteData(this.props.uid);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }

    get cellSize() {
      return this.props.cellSize || 24;
    }
    createDynamicStyles() {
      let width = this.props.width || 1;
      let height = this.props.height || 1;
      let cellSize = this.cellSize;
      let color = this.props.backgroundColor || null;
      if (!color) {
        if (this.props.appModule && this.props.appModule.type) {
          switch (this.props.appModule.type) {
            case this.appModuleDm.TYPE_AWS_S3_BUCKET:
            case this.appModuleDm.TYPE_REDIS_SERVER:
              color = Colors.secondary;
              break;
          }
        }
      }

      return StyleSheet.create({
        block: {
          backgroundColor: StyleSheet.color(color || Colors.primary).rgba(
            this.props.pipelineActive ? 1 : 0.6
          ),
          width: width * cellSize,
          height: height * cellSize,
        },
      });
    }
    // componentDidUpdate(prevProps){
    //   console.log('MOUNT MOUNT MOUNT', prevProps,);
    // }
    // componentDidUpdate(prevProps){
    //   console.log('UPDATE UPDATE UPDATE', prevProps,);
    // }

    renderMenu() {
      let menuItems = [];
      if (this.perms.pipelineBlock.execute && this.props.pipelineActive) {
        this.props.state !== this.pipelineBlockDm.STATE_RUNNING &&
          this.props.state !== this.pipelineBlockDm.STATE_PENDING &&
          menuItems.push(
            <PopUpMenuItem
              label='Run'
              icon='play'
              key='run'
              onPress={() =>
                this.handleCommandPress(this.pipelineBlockDm.COMMAND_RUN)
              }
            />
          );
        this.props.state !== this.pipelineBlockDm.STATE_STOPPED &&
          menuItems.push(
            <PopUpMenuItem
              label='Reload'
              icon='refresh'
              key='reload'
              onPress={() =>
                this.handleCommandPress(this.pipelineBlockDm.COMMAND_RELOAD)
              }
            />
          );
        (this.props.state === this.pipelineBlockDm.STATE_RUNNING ||
          this.props.state === this.pipelineBlockDm.STATE_PENDING) &&
          menuItems.push(
            <PopUpMenuItem
              label='Stop'
              icon='stop'
              key='stop'
              onPress={() =>
                this.handleCommandPress(this.pipelineBlockDm.COMMAND_STOP)
              }
            />
          );
      }

      if (
        this.props.instanceState &&
        this.props.state === this.pipelineBlockDm.STATE_RUNNING
      ) {
        // There should only be once instance
        let instanceState = this.props.instanceState.length
          ? this.props.instanceState[0]
          : null;

        this.props.agentCloudAccessKeyUid &&
          menuItems.push(
            <PopUpMenuItem
              icon='chartLine'
              key='monitor'
              label='Monitor'
              tooltip={`Monitor ${this.props.name}`}
              size='small'
              onPress={() =>
                this.props.app.nav.to(`projectPipelineBlockMonitor`, {
                  uid: this.props.projectUid,
                  key: this.props.projectKey,
                  pipelineUid: this.props.pipelineUid,
                  pipelineBlockUid: this.props.uid,
                })
              }
            />
          );
        if (
          instanceState &&
          instanceState.appModuleType === this.appModuleDm.TYPE_REACT_APP &&
          instanceState.port &&
          instanceState.publicIpAddress
        ) {
          let webUrl =
            process.env.NODE_ENV === "development"
              ? `http://${instanceState.publicIpAddress}:${instanceState.port}`
              : this.pipelineBlockDm.createWebUrl(
                  this.props.uid,
                  this.appModuleDm.SERVER_TYPE_WEB
                );
          menuItems.push(
            <PopUpMenuItem
              label='Web App'
              icon='openInNew'
              key='webapp'
              onPress={
                () => this.props.app.nav.openExternalUrl(webUrl)
                // this.props.app.nav.open(`projectPipelineBlockIde`, {
                //   uid: this.props.projectUid,
                //   key: this.props.app.dm.project.getKeyByType(
                //     this.props.projectType
                //   ),
                //   pipelineUid: this.props.pipelineUid,
                //   pipelineBlockUid: this.props.uid,
                // })
              }
            />
          );
        }
      }

      // if (
      //   serverWeb &&
      //   serverWeb.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
      //   serverWeb.port &&
      //   serverWeb.publicIpAddress
      // ) {
      // infoSections.push(
      //   <LabelView style={styles.labelView} key='web' label='Web'>
      //     <ExternalLink
      //       url={` http://${serverWeb.publicIpAddress}:${serverWeb.port}`}
      //       style={styles.externalLink}
      //     />
      //   </LabelView>
      // );
      // infoSections.push(
      //   <LabelView
      //     style={styles.labelView}
      //     key='webBeta'
      //     label='Web (Beta)'
      //   >
      //     <ExternalLink
      //       url={this.appModuleDomain.createUrl(
      //         appModule.uid,
      //         this.appModuleDomain.SERVER_TYPE_WEB
      //       )}
      //       style={styles.externalLink}
      //     />
      //   </LabelView>
      // );
      // }
      menuItems.push(
        <PopUpMenuItem
          label='View Log'
          icon='console'
          key='log'
          onPress={() =>
            this.props.app.nav.to(`projectPipelineBlockLog`, {
              uid: this.props.projectUid,
              key: this.props.app.dm.project.getKeyByType(
                this.props.projectType
              ),
              pipelineUid: this.props.pipelineUid,
              pipelineBlockUid: this.props.uid,
            })
          }
        />
      );
      menuItems.push(
        <PopUpMenuItem
          label='Dashboard'
          icon='monitorDashboard'
          key='dashboard'
          onPress={() =>
            this.props.app.nav.to(`projectPipelineBlock`, {
              uid: this.props.projectUid,
              key: this.props.app.dm.project.getKeyByType(
                this.props.projectType
              ),
              pipelineUid: this.props.pipelineUid,
              pipelineBlockUid: this.props.uid,
            })
          }
        />
      );
      this.perms.pipelineBlock.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Move'
            icon='move'
            key='move'
            onPress={this.handleMovePress}
          />
        );
      this.perms.appModule.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Ide'
            icon='codeBraces'
            key='ide'
            onPress={() =>
              this.props.app.nav.open(`projectPipelineBlockIde`, {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: this.props.pipelineUid,
                pipelineBlockUid: this.props.uid,
              })
            }
          />
        );
      menuItems.push(
        <PopUpMenuItem
          label='Metrics'
          icon='chartBubble'
          key='metrics'
          onPress={() =>
            this.props.app.nav.to("projectPipelineBlockMetrics", {
              uid: this.props.projectUid,
              key: this.props.app.dm.project.getKeyByType(
                this.props.projectType
              ),
              pipelineUid: this.props.pipelineUid,
              pipelineBlockUid: this.props.uid,
            })
          }
        />
      );
      this.perms.pipelineBlock.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Edit'
            icon='edit'
            key='edit'
            onPress={() =>
              this.props.app.nav.to("projectPipelineBlockEdit", {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: this.props.pipelineUid,
                pipelineBlockUid: this.props.uid,
              })
            }
          />
        );
      this.perms.pipelineBlock.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Inputs / Outputs'
            icon='import'
            key='inputsOutputs'
            onPress={() =>
              this.props.app.nav.to("projectPipelineBlockIoEdit", {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: this.props.pipelineUid,
                pipelineBlockUid: this.props.uid,
              })
            }
          />
        );
      this.perms.pipelineBlock.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Run Triggers'
            icon='calendarClock'
            key='event'
            onPress={() =>
              this.props.app.nav.to("projectPipelineBlockEventEdit", {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: this.props.pipelineUid,
                pipelineBlockUid: this.props.uid,
              })
            }
          />
        );
      this.perms.pipelineBlock.delete &&
        menuItems.push(
          <PopUpMenuItem
            label='Remove'
            icon='close'
            key='remove'
            onPress={this.handleRemovePress}
          />
        );
      this.perms.pipelineBlock.edit &&
        menuItems.push(
          <PopUpMenuItem
            label='Delete All Data'
            icon='delete'
            key='delete'
            onPress={this.handleDeleteDataPress}
          />
        );
      return (
        <PopUpMenu
          controlIcon='dotsVertical'
          controlIconColor={Colors.onPrimary}
          key='menu'
          direction='downLeft'
          onSelect={(value, label) => {}}
        >
          {menuItems}
        </PopUpMenu>
      );
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      let blockStyles = [styles.block];
      switch (this.props.type) {
        case "DATA":
          blockStyles.push(styles.blockData);
          break;
        default:
        // do nothing
      }
      if (this.props.collision) blockStyles.push(styles.blockCollision);
      blockStyles.push(dynamicStyles.block);
      let tooltip = this.pipelineBlockDm.getStateLabel(this.props.state);
      //console.log("ACTIVE INST COUNT", this.props.name, this.props.activeInstanceCount);
      if (this.props.activeInstanceCount)
        tooltip = `${tooltip} (${this.props.activeInstanceCount})`;
      let titleControlsLeft = [
        <StateIndicatorIcon
          key='stateIndicator'
          tooltip={tooltip}
          style={styles.stateIndicator}
          color={this.pipelineBlockDm.getStateIndicatorColor(this.props.state)}
        />,
      ];
      this.props.activeInstanceCount &&
        this.props.activeInstanceCount > 1 &&
        titleControlsLeft.push(
          <View key='activeInstanceCount' style={styles.activeInstanceCount}>
            <Text style={styles.activeInstanceCountText}>{this.props.activeInstanceCount}</Text>
          </View>
        );

      return (
        <DraggableView
          name={this.props.uid}
          key={this.props.uid}
          // style={}
          onDrag={this.handleDrag}
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
          onDragCancel={this.handleDragCancel}
          // onDragCollision={this.handleDragCollision}
          offsetTop={this.props.y * this.props.cellSize}
          offsetLeft={this.props.x * this.props.cellSize}
          height={this.props.height * this.props.cellSize}
          width={this.props.width * this.props.cellSize}
          disabled={!this.props.moveEnabled}
        >
          <Card
            surfaceStyle={blockStyles}
            contentStyle={styles.content}
            title={this.props.name || "No Title"}
            titleNoWrap={true}
            titleStyle={styles.title}
            titleControlsLeft={titleControlsLeft}
            titleControlsRight={[this.renderMenu()]}
          >
            <FadeView
              unmountOnExit
              style={styles.moveOverlay}
              visible={this.props.moveEnabled}
            >
              <Icon
                name='move'
                size={36}
                color={StyleSheet.color("#FFFFFF").rgba(0.8)}
              />
            </FadeView>
            {this.props.icon && (
              <FadeView
                unmountOnExit
                style={styles.iconWrapper}
                visible={!this.props.moveEnabled}
                pointerEvents='none'
              >
                <Icon
                  name={this.pipelineBlockDm.getIconByTypeKey(this.props.icon)}
                  size={48}
                  color={StyleSheet.color("#FFFFFF").rgba(0.5)}
                />
              </FadeView>
            )}
          </Card>
        </DraggableView>
      );
    }
  }
);
let styles = StyleSheet.create({
  block: {
    position: "absolute",
    ...StyleSheet.padding(4, 4, 8, 8),
    borderRadius: 4,
    textAlign: "center",
    elevation: 8,
  },
  title: {
    textAlign: "left",
    fontSize: 16,
    color: Colors.onPrimary,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockCollision: {
    opacity: 0.5,
  },
  blockData: {
    borderRadius: 24,
  },
  stateIndicator: {
    ...StyleSheet.margin(8),
    ...StyleSheet.border(
      1,
      "solid",
      StyleSheet.color(Colors.onPrimary).rgba(0.6)
    ),
  },
  moveOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    backgroundColor: StyleSheet.color(Colors.primaryDark).rgba(0.6),
  },
  iconWrapper: {
    flex: 1,
    // flexShrink: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    ...StyleSheet.absoluteFill,
    zIndex: 0,
  },
  content: {
    flex: 1,
    // flexShrink: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // position: "absolute",
    // ...StyleSheet.absoluteFill,
    // backgroundColor: StyleSheet.color(Colors.primaryDark).rgba(0.5),
  },
  activeInstanceCount: {
    flex:0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.4),
    ...StyleSheet.padding(0, 4, 0, 4),
    ...StyleSheet.margin(0, 8, 0, -4),
    borderRadius: 8,
    height: 16,
    minWidth: 16,
  },
  activeInstanceCountText: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: "center",
    color: Colors.onBackground,
  },
});
