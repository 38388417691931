import { Platform } from "../core/";

const getBaseUrl = () => {
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
let baseUrl = getBaseUrl();

const Config = {
  name: "r14-dev-app",
  api: {
    url:
      process.env.NODE_ENV === "development"
        ? Platform.OS === "web"
          ? //"http://127.0.0.1:3001" //
            "http://3.12.119.142:3001"
          : "http://10.0.2.2:3001"
        : baseUrl,
  },
  io: {
    url:
      process.env.NODE_ENV === "development"
        ? Platform.OS === "web"
          ? //"http://127.0.0.1:3001" //
            "http://3.12.119.142:3001"
          : "http://10.0.2.2:3001"
        : baseUrl,
  },
  storage: {
    url: {
      file:
        process.env.NODE_ENV === "development"
          ? "http://3.12.119.142:3001/files"
          : `${baseUrl}/files`,
      image:
        process.env.NODE_ENV === "development"
          ? "http://3.12.119.142:3001/images"
          : `${baseUrl}/images`,
    },
  },
};
export default Config;
