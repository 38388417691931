import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField
} from "../core";
export default R14.connect(
  class AppModulePackagesManageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTypeValueChange = this.handleTypeValueChange.bind(this);
      this.formData = this.props.formData || {};
      this.appModuleDomain = this.props.app.dm.appModule;
    }
    async handleTypeValueChange(type) {
      this.setState({
        type: type || null
      });
    }
    async handleSubmit(form) {
      let ret = null;
      let vals = form.values;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.appModuleDomain.runServerPackageCommand(
          vals.uid,
          vals.serverType,
          vals.command,
          vals.packageName,
          vals.version
        );
        console.log(form.values);
        if (isSuccess) {
          ret = <Redirect to={this.props.redirect || "projects"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      console.log(this.formData);
      return (
        <Form
          name='appModulePackagesForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Continue' key='continue' />
          ]}
        >
          <HiddenInputField key='uid' name='uid' />
          {!this.props.formData.serverSelections &&
            this.props.formData.values.serverType && (
              <HiddenInputField key='serverType' name='serverType' />
            )}
          {this.props.formData.serverSelections && (
            <SelectMenuField
              useValuesOnly
              name='serverType'
              label='Server Type'
              items={this.props.formData.serverSelections}
              required='Please select a server type.'
            />
          )}
          <SelectMenuField
            useValuesOnly
            name='command'
            label='Command'
            items={this.props.formData.commandSelections}
            required='Please select a command.'
          />
          <TextInputField
            name='packageName'
            label={this.props.formData.packageNameLabel}
            required='Please enter a library.'
          />
          <TextInputField
            name='version'
            label={this.props.formData.libraryVersionLabel}
            helper='Optional'
          />
        </Form>
      );
    }
  }
);
