import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";

export default R14.connect(
  class CloudAccessKeyDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.cloudAccessKeyDomain = this.props.app.dm.cloudAccessKey;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.cloudAccessKey.find(
        `
          uid
          name
          description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("cloudAccessKeys");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    // handleRowPress(row) {
    //   this.props.app.nav.to("cloudAccessKey", { uid: row.uid });
    // }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.cloudAccessKey.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    render() {
      return (
        <DataTable
          name='cloudAccessKeys'
          columns={{
            name: {
              label: "Name",
              sortable: true
            }
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Cloud Access Keys"}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Add Cloud Access Key'
                value='test1'
                to='cloudAccessKeyAdd'
                key='add'
              />
            </PopUpMenu>
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to("cloudAccessKeyEdit", {
                    uid: row.uid
                  })
                }
              />,
              <PopUpMenu
                controlIcon='dotsHorizontal'
                controlIconSize='small'
                key='statusCheckMenu'
                direction='downLeft'
              >
                <PopUpMenuItem
                  icon='import'
                  label='Import Resources'
                  key='import'
                  onPress={() =>
                    this.props.app.nav.to("cloudAccessKeyResourcesImport", {
                      uid: row.uid
                    })
                  }                
                />
              </PopUpMenu>
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
