import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import ResourceScreenLayout from "../components/ResourceScreenLayout";
import ResourceScriptDataTable from "../components/ResourceScriptDataTable";
export default R14.connect(
  class ResourcesScriptssScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ResourceScreenLayout
          floatingActionButton={{
            to: {
              route: "resourceScriptsAdd",
              params: { uid: this.props.resource.uid }
            },
            params: { uid: this.props.resource.uid },
            icon: "add"
          }}
          resource={this.props.resource}
        >
          <ResourceScriptDataTable
            widget={false}
            editAction='resourceScriptsEdit'
            resourceUid={this.props.resource.uid}
          />
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
