import R14 from "../core";
export default class ActivityLogDomain extends R14.Domain {
  constructor() {
    super();

  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Activity Log Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindActivityLogs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ActivityLogFilter) {
        activityLogs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.activityLogs;
  }

  async create(values) {
    values.clientUid = this.dm.userSession.clientUid;

    if(! values.assignedToUserUid) values.assignedToUserUid = this.dm.userSession.uid;
    values.createdByUserUid = this.dm.userSession.uid;

    let res = await this.api.mutate(
      `
      mutation CreateActivityLog($input: CreateActivityLogInput!) {
        createActivityLog(input: $input){
          activityLog {
            uid
            clientUid
            type
            title
            description
            assignedToUserUid
            createdByUserUid
            status
            priority
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    if(! values.assignedToUserUid) values.assignedToUserUid = this.dm.userSession.uid;

    let res = await this.api.mutate(
      `
      mutation UpdateActivityLog($input: UpdateActivityLogInput!) {
        updateActivityLog(input: $input){
          activityLog {
            uid
            clientUid
            type
            title
            description
            assignedToUserUid
            status
            priority
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async get(uid) {
    console.log("get with uid", uid);
    let res = await this.api.qry(
      `
      query GetActivityLog($uid: ID!) {
       activityLog(uid: $uid){
          uid
          name
          description
          content
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.activityLog;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteActivityLog($uid: ID!) {
        deleteActivityLog(uid: $uid){
          activityLog {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
}