import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class ResourceDockerTaskDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.logDomain = this.props.app.dm.log;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleStopPress = this.handleStopPress.bind(this);
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (this.props.pipelineBlockUid)
        filter.pipelineBlockUid = { eq: this.props.pipelineBlockUid };
      //if (search) filter.search = { like: `%${search}%` };

      if ("active" in this.props) filter.active = this.props.active;
      let res = await this.props.app.dm.resourceDockerTask.find(
        `
        uid
        instanceId
        startedAt
        state
        appModuleUid
        pipelineBlockUid
        type
        subtype
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async updateTodoActive(uid, active, dataTable) {
      let res = await this.props.app.dm.todo.update({
        uid: uid,
        active: active,
      });
      dataTable.refresh();
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("resourceDockerTasks");
    }
    renderStartAt(startAt) {
      let startAtDate = this.props.app.utils.date.toShortDate(
        new Date(startAt)
      );
      let startAtTime = this.props.app.utils.date.toTime(new Date(startAt));
      return `${startAtDate} ${startAtTime}`;
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "startedAt":
          ret = <Text style={style}>{this.renderStartAt(row.startedAt)}</Text>;
          break;
        case "state":
          if (row.state)
            ret = (
              <StateIndicatorText
                label={this.props.app.dm.resourceDockerTask.getTypeLabel(
                  row.type,
                  row.subtype
                )}
                color={this.props.app.dm.resourceDockerTask.getStateIndicatorColor(
                  row.state
                )}
              />
            );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleStopPress(resourceDockerTask, dataTable) {
      if (!window.confirm("Are you sure?")) return false;
      // await this.props.app.dm.log.delete(uid);
      this.props.app.ui.progressIndicator.show();
      let error = null;
      if (resourceDockerTask.pipelineBlockUid) {
        let res = await this.props.app.dm.pipelineBlock.runCommand(
          resourceDockerTask.pipelineBlockUid,
          this.props.app.dm.pipelineBlock.COMMAND_TYPE_BLOCK,
          this.props.app.dm.pipelineBlock.COMMAND_STOP,
          { instanceResourceDockerTaskUids: [resourceDockerTask.uid] }
        );
      } else if (resourceDockerTask.appModuleUid) {
        error = "App Module task commands in dev.";
      } else error = "Unable to run stop command. Missing data.";
      if (error)
        this.props.app.ui.snackBar.show({
          message: "Unable to run stop command. Missing data.",
          variant: "error",
        });
      else dataTable.refresh({ indicator: true });
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    render() {
      return (
        <DataTable
          name='resourceDockerTasks'
          columns={{
            state: {
              label: "State",
              sortable: true,
              // width: 96,
            },
            // subtype: {
            //   label: "Type",
            //   sortable: false,
            //   width: 96,
            // },
            startedAt: {
              label: "Started",
              sortable: true,
              width: 192,
            },
            // ended: {
            //   label: "Started",
            //   sortable: true
            // }
          }}
          //searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={this.props.initialRowsPerPage || 10}
          headerTitle={this.props.title || "Resource Docker Tasks"}
          onRowPress={this.handleRowPress}
          // paddingBottom={72} // Make space for the FAB
          initialSortColumnName='startedAt'
          initialSortDirection='desc'
          autoRefresh={30}
          // headerControlsRight={[
          //   <PopUpMenu
          //     controlIcon='dotsVertical'
          //     key='adminMenu'
          //     direction='downLeft'
          //     onSelect={(value, label) => {}}
          //   >
          //     <PopUpMenuItem
          //       icon='add'
          //       iconSize='small'
          //       label='Create Log'
          //       value='test1'
          //       to='logCreate'
          //       key='add'
          //     />
          //   </PopUpMenu>
          // ]}

          rowControlsRight={(row, dataTable) => {
            return (!this.props.permissions ||
              this.props.permissions.execute) &&
              row.state !== this.props.app.dm.resourceDockerTask.STATE_STOPPED
              ? [
                  <IconButton
                    icon='stop'
                    size='small'
                    key='stop'
                    onPress={() => this.handleStopPress(row, dataTable)}
                  />,
                  // <PopUpMenu
                  //   controlIcon='dotsHorizontal'
                  //   controlIconSize='small'
                  //   key='logMenu'
                  //   direction='downLeft'
                  // >
                  //   <PopUpMenuItem
                  //     icon='close'
                  //     label='Remove'
                  //     key='remove'
                  //     onPress={() => this.handleRemovePress(row.uid, dataTable)}
                  //   />
                  // </PopUpMenu>,
                ]
              : null;
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
});
