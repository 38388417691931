import R14 from "../core";

export default class EventTaskDomain extends R14.Domain {
  constructor(key) {
    super();
    this.TYPE_RESOURCE = "RESOURCE";
    this.TYPE_PROJECT = "PROJECT";
    this.TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";
    this.STATE_INIT = "INIT";
    this.STATE_PAUSED = "PAUSED";
    this.STATE_QUEUED = "QUEUED";
    this.STATE_COMPLETE = "COMPLETE";
    this.STATE_ERROR = "ERROR";
    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindEventTasks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: EventTaskFilter) {
        eventTasks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.eventTasks;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_READY:
        label = "Ready";
        break;
      case this.STATE_ERROR:
        label = "ERROR";
        break;
      case this.STATE_RUNNING:
        label = "Running";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_RUNNING:
        color = "yellow";
        break;
      case this.STATE_ERROR:
        color = "red";
        break;
      case this.STATE_READY:
        color = "green";
        break;
    }
    return color;
  }
  async run(uid) {
    let res = await this.api.mut(
      `
      mutation runEventTask($uid: ID!) {
        runEventTask(uid: $uid){
          uid
          state
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.runEventTask;
  }
  async add(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation AddEventTask($input: CreateEventTaskInput!) {
        createEventTask(input: $input){
          eventTask {
            uid
            event {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteEventTask($uid: ID!) {
        deleteEventTask(uid: $uid){
          eventTask {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async fetchLog(uid) {
    let res = await this.api.qry(
      `
      query FetchEventTaskLog($uid: ID!) {
       eventTask(uid: $uid){
          log
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data && res.data.eventTask && res.data.eventTask.log
      ? res.data.eventTask.log
      : "";
  }
  async fetchEventTaskAddFormData(type, uid = null) {
    let qry = "";
    if (!uid) return {};
    let qryVals = {
      uid: uid,
      eventFilter: {
        clientUid: { eq: this.dm.userSession.clientUid }
      }
    };
    qry = `
      query EventTaskAddFormData($eventFilter: EventFilter){
        events(filter: $eventFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    console.log(res);

    let formData = {
      values: {
        type: type,
        resourceUid: uid
      },
      eventSelections: res.events.nodes.map(val => ({
        label: val.name,
        value: val.uid
      }))
    };
    return formData;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_INIT:
      case this.STATE_PAUSED:
        color = "yellow";
        break;
      case this.STATE_QUEUED:
        color = "green";
        break;
    }
    return color;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_INIT:
        label = "Initializing";
        break;
      case this.STATE_QUEUED:
        label = "Queued";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
      case this.STATE_COMPLETE:
        label = "Complete";
        break;
      case this.STATE_PAUSED:
        label = "Paused";
        break;
    }
    return label;
  }
}
