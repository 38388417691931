import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  SelectMenuField,
  TextInputField,
  PopUpMenu,
  PopUpMenuItem,
  Chip,
  ChipGroup,
  Text,
  DataTable,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";
import StateIndicatorIcon from "./StateIndicatorIcon";
import ResourceControls from "./ResourceControls";
export default R14.connect(
  class ResourceDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.resourceDomain = this.props.app.dm.resource;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRowSelect = this.handleRowSelect.bind(this);
      this.handleRowsSelect = this.handleRowsSelect.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.state = {
        date: null,
        datePickerVisible: false,
      };
      // console.log('figure out clean way to forward back to grid and update, maybe use object for selectedrows, check error on select all.');
    }
    async getPageData(
      {
        page,
        rowsPerPage,
        sortColumnName,
        sortDirection,
        search = null,
        filters = null,
      },
      options = {}
    ) {
      let filter = {};
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };
      if (filters) {
        if (filters.type) filter.type = { eq: filters.type };
        if (filters.cloudAccessKeyUid)
          filter.cloudAccessKeyUid = { eq: filters.cloudAccessKeyUid };
      }
      let res = await this.props.app.dm.resource.find(
        ["uid", "resourceId", "name", "state", "type", "sshKeyUid"],
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("resources");
    }
    rowBottomRenderer({ row, columns }) {
      return   (columns.state.collapsed || columns.type.collapsed) && (
        <ChipGroup style={styles.cellChipGroup}>
          {columns.state.collapsed && (
            <Chip
              iconLeft={
                <StateIndicatorIcon
                  color={this.resourceDomain.getStateIndicatorColor(
                    row.state
                  )}
                />
              }
              label={this.resourceDomain.getStateLabel(row.state)}
            />
          )}
          {columns.type.collapsed && (
            <Chip
              label={this.props.app.dm.resource.getTypeLabel(row.type)}
            />
          )}
        </ChipGroup>
      );
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {[
                this.resourceDomain.TYPE_EC2_INSTANCE,
                this.resourceDomain.TYPE_EFS_FILESYSTEM,
                this.resourceDomain.TYPE_AWS_FSX_LUSTRE_FILESYSTEM,
              ].includes(row.type) &&
                row.resourceId && (
                  <Text
                    style={[styles.cellText, styles.nameCellDescriptionText]}
                  >
                    {row.resourceId}
                  </Text>
                )}
            </React.Fragment>
          );
          break;
        case "state":
          ret = (
            <StateIndicatorText
              label={this.resourceDomain.getStateLabel(cell)}
              color={this.resourceDomain.getStateIndicatorColor(cell)}
            />
          );
          break;
        case "type":
          ret = (
            <Text style={style}>
              {this.props.app.dm.resource.getTypeLabel(cell)}
            </Text>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    isSelectedRow(row) {
      return row.active === false ? true : false;
    }
    handleRowSelect(row, isSelected, dataTable) {
      // row.active = !row.active;
      // let update = {
      //   uid: row.uid,
      //   active: !isSelected
      // };
      // this.props.app.dm.todo.update(update).then((ret) => {
      //   // Switch back the value
      //   row.active = !isSelected;
      //   /** @todo Update row and show an error if false */
      //   if (ret !== true) dataTable.updateRow(row);
      //   else {
      //     // if active is in the props, refresh the table after selections...
      //     // For all todos, this is not needed because nothing changed other that then selection
      //     if ('active' in this.props) dataTable.refresh();
      //   }
      // });
      // return row;
    }
    handleRowsSelect(rows, isSelected, dataTable) {
      // let updates = [];
      // rows = rows.map((row) => {
      //   updates.push({
      //     uid: row.uid,
      //     active: !isSelected
      //   });
      //   row.active = !isSelected;
      //   return row;
      // });
      // this.props.app.dm.todo.updateMany(updates).then((ret) => {
      //   /** @todo Update row and show an error if false */
      //   if (ret !== true) {
      //     /** @todo Update rows and show an error if false */
      //     throw new Error('Error updating selected rows.');
      //   }
      //   else if ('active' in this.props) {
      //     // if active is in the props, refresh the table after selections...
      //     // For all todos, this is not needed because nothing changed other that then selection
      //     if ('active' in this.props) dataTable.refresh();
      //   }
      // });
      // return rows;
    }
    handleRowPress(row) {
      this.props.app.nav.to("resource", { uid: row.uid });
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.todo.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}
    render() {
      let t = new Date();
      let startDate = new Date();
      let endDate = new Date();

      let hasEditPerm = this.props.app.dm.user.checkPermissions(
        "resource",
        this.props.app.dm.user.PERMISSION_ACCESS_EDIT
      );
      let hasExecPerm = this.props.app.dm.user.checkPermissions(
        "resource",
        this.props.app.dm.user.PERMISSION_ACCESS_EXECUTE
      );

      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setMonth(endDate.getMonth() + 1);
      return (
        <DataTable
          name='resources'
          collapsable
          columns={{
            name: {
              label: "Name",
              sortable: true,
              // width: '40%',
              // FilterField: (
              //   <TextInputField key='nameFilter' name='name' label='Name' />
              // ),
            },
            type: {
              label: "Type",
              sortable: true,
              collapse: ({ width }) => {
                return width <= 640;
              },
              width: 160,
              filter: true,
              // FilterField: (
              //   <SelectMenuField
              //     useValuesOnly
              //     key='typeFilter'
              //     name='type'
              //     label='Type'
              //     items={this.props.dataGridFiltersFormData.typeSelections}
              //   />
              // ),
              // cellStyle: styles.typeCell
            },
            // resourceId: {
            //   label: "Resource Id",
            //   sortable: true
            //   // filter: {
            //   //   type: 'dateRange'
            //   // },
            //   // collapse: ({width}) => {
            //   //   return (width <= 640);
            //   // }
            // },
            state: {
              label: "Status",
              sortable: true,
              width: 128,
              filter: true,
              // align: 'right',
              filterFieldType: "select",
              // cellStyle: styles.stateCell,
              collapse: ({ width }) => {
                return width <= 480;
              },
            },
          }}
          maxHeight={300}
          autoRefresh={30}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          rowBottom={this.rowBottomRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Resources"}
          // checkboxes
          isSelectedRow={this.isSelectedRow}
          onRowSelect={this.handleRowSelect}
          onRowsSelect={this.handleRowsSelect}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          headerFilterFields={
            this.props.dataGridFiltersFormData && [
              <SelectMenuField
                useValuesOnly
                key='typeFilter'
                name='type'
                label='Type'
                items={this.props.dataGridFiltersFormData.typeSelections}
              />,
              <SelectMenuField
                useValuesOnly
                key='cloudAccessKeyFilter'
                name='cloudAccessKeyUid'
                label='Cloud Access Key'
                items={
                  this.props.dataGridFiltersFormData.cloudAccessKeySelections
                }
              />,
            ]
          }
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Add Resource'
                value='add'
                to='resourceAdd'
                key='add'
              />
            </PopUpMenu>,
          ]}
          rowControlsRightWidth={156}
          rowControlsRight={(row, dataTable) => {
            return [
              hasExecPerm && (
                <ResourceControls
                  key='resourceControls'
                  resourceType={row.type}
                  resourceUid={row.uid}
                  resourceState={row.state}
                  dataTable={this.dataTable}
                />
              ),
              hasEditPerm && (
                <PopUpMenu
                  controlIcon='dotsHorizontal'
                  controlIconSize='small'
                  key='resourceMenu'
                  direction='downLeft'
                >
                  <PopUpMenuItem
                    label='Manage SSH Key'
                    icon='consoleNetwork'
                    key='sshKey'
                    onPress={() =>
                      this.props.app.nav.to("resourceSshKeyManage", {
                        uid: row.uid,
                        sshKeyUid: row.sshKeyUid,
                      })
                    }
                  />
                </PopUpMenu>
              ),
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
  nameCell: {
    marginRight: "auto",
  },
  typeCell: {
    flexBasis: 100,
    flexGrow: 0,
    flexShrink: 0,
  },
  stateCell: {
    flexBasis: 100,
    flexGrow: 0,
    flexShrink: 0,
  },
});
