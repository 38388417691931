import R14DomUtilsBase from "./base/R14DomUtilsBase";

export default class R14Utils {
  constructor() {
    this.dom = new R14DomUtils(this);
    this.core = new R14CoreUtils(this);
    this.date = new R14DateUtils(this);
    this.array = new R14ArrayUtils(this);
    this.string = this.str = new R14StringUtils(this);
    this.graphQl = this.gql = new R14GraphQlUtils(this);
    this.nav = new R14NavUtils(this);
    this.form = this.fm = new R14FormUtils(this);
  }
}
class R14UtilsChild {
  constructor(utils) {
    this._utils = utils;
  }
}
export class R14DateUtils extends R14UtilsChild {
  LABEL_TYPE_SHORT = "short";
  LABEL_TYPE_ABBREVIATED = "abbrv";
  LABEL_TYPE_SINGLE_LETTER = "letter";
  _monthLabels = [
    "january",
    "febuary",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
  ];
  _monthLabelsShort = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"
  ];
  _dayLabels = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  _dayLabelsShort = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  _dayLabelsAbbrv = ["su", "mo", "tu", "we", "th", "fr", "sa"];
  _dayLabelsSingleLetter = ["s", "m", "t", "w", "t", "f", "s"];

  toShortDate(date) {
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
  isShortDate(value){
    let t = value.split('/');
    let d = new Date(t[2] + '/' + t[0] + '/' + t[1]);
    return (d && (d.getMonth() + 1) == t[0] && d.getDate() == Number(t[1]) && d.getFullYear() == Number(t[2])) ?
      true : false;
  }
  toTime(val) {
    let hours = null;
    let minutes = null;
    let seconds = null;
    let isPM = false;
    if(val instanceof Date){
      let date = val;
      isPM = date.getHours() >= 12;
      minutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      if (hours === 0) hours = 12;
    }
    else if(typeof val === 'object' && val.hour && val.minute && val.period){
      hours = val.hour;
      minutes = val.minute;
      isPM = val.period === 'PM';
    }
    else throw new Error("Date Utils Error: Time must be Date or an object returned from parseTime.");
    return `${hours}:${minutes} ${(isPM && "PM") || "AM"}`;
  }
  
  parseTime(time) {
    let ret = {
      hour: "12",
      minute: "00",
      second: "00",
      period: "PM"
    };
    let timeParts = time
      .trim()
      .replace(" ", ":")
      .split(":");
    if (timeParts.length < 3) return ret;
    ret.hour = timeParts[0];
    ret.minute = timeParts[1];
    if (timeParts.length === 3) ret.period = timeParts[2];
    else if (timeParts.length === 4) {
      ret.minute = timeParts[2];
      ret.period = timeParts[3];
    }
    return ret;
  }
  roundUpToMinuteInterval(date, minuteInterval = 5) {
    let coeff = 1000 * 60 * minuteInterval;
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
  }
  fromUnixTimestamp(timestamp) {
    return new Date(timestamp * 1000);
  }
  getDayLabel(day, type) {
    let ret = "";
    if (day instanceof Date) day = day.getDay();
    else if (day < 0 || day > 6) return null;
    switch (type) {
      case this.LABEL_TYPE_SHORT:
        ret = this._dayLabelsShort[day];
        break;
      case this.LABEL_TYPE_ABBREVIATED:
        ret = this._dayLabelsAbbrv[day];
        break;
      case this.LABEL_TYPE_SINGLE_LETTER:
        ret = this._dayLabelsSingleLetter[day];
        break;
      default:
        ret = this._dayLabels[day];
    }
    return this._utils.str.capitalize(ret);
  }
  getMonthLabel(month, type) {
    let ret = "";
    if (month instanceof Date) month = month.getMonth();
    else if (month < 0 || month > 11) return null;
    switch (type) {
      case this.LABEL_TYPE_SHORT:
        ret = this._monthLabelsShort[month];
        break;
      default:
        ret = this._monthLabels[month];
    }
    return this._utils.str.capitalize(ret);
  }
  getDayOrdinal(date) {
    let number = date.getDate();
    switch (number) {
      case 1:
      case 21:
        return "st";
        break;
      case 2:
      case 22:
        return "nd";
        break;
      case 3:
      case 23:
        return "rd";
        break;
      default:
        return "th";
    }
  }
  getDayRange(date) {
    let startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 0);
    return {
      start: startDate,
      end: endDate
    };
  }
  getWeekRange(date, isEndDate = false) {
    let startDate = null;
    let endDate = null;
    if (isEndDate) {
      endDate = new Date(date);
      endDate.setHours(23, 59, 59, 0);
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);
    } else {
      startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 7);
      endDate.setHours(23, 59, 59, 0);
    }
    return {
      start: startDate,
      end: endDate
    };
  }
  toLocalTime(date) {
    date = new Date(date);
    let localOffset = date.getTimezoneOffset() * 60000;
    let localTime = date.getTime();
    date = localTime - localOffset;
    return new Date(date);
  }
  areDatesSameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
  areDatesSameMonth(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
    );
  }
  isDayLessThan(d1, d2) {
    let d1Year = d1.getFullYear();
    let d1Month = d1.getMonth();
    let d1Day = d1.getDate();
    let d2Year = d2.getFullYear();
    let d2Month = d2.getMonth();
    let d2Day = d2.getDate();
    if (d1Year < d2Year) return true;
    else if (d1Year > d2Year) return false;
    else if (d1Month < d2Month) return true;
    else if (d1Day < d2Day) return true;
    else return false;
  }
  isMonthLessThan(d1, d2) {
    let d1Year = d1.getFullYear();
    let d1Month = d1.getMonth();
    let d2Year = d2.getFullYear();
    let d2Month = d2.getMonth();
    if (d1Year < d2Year) return true;
    else if (d1Year > d2Year) return false;
    else if (d1Month < d2Month) return true;
    else return false;
  }
}
export class R14CoreUtils {
  // Utils
  arePropsEqual(prop1, prop2, maxDepth, _depth = 0) {
    // Check for non objects
    if (prop1 === prop2) return true;
    //else if(typeof prop1 !== 'object' && typeof prop2 !== 'object') return false;

    if (
      typeof prop1 !== "object" ||
      prop1 === null ||
      typeof prop2 !== "object" ||
      prop2 === null
    )
      return false;

    var keys1 = Object.keys(prop1);
    var keys2 = Object.keys(prop2);

    if (keys1.length !== keys2.length) return false;

    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(prop2);
    for (var i = 0; i < keys1.length; i++) {
      //! bHasOwnProperty(keys1[i]) ||
      if (
        (!maxDepth || _depth < maxDepth) &&
        typeof prop1[keys1[i]] === "object" &&
        typeof prop2[keys1[i]] === "object"
      ) {
        if (
          !this.arePropsEqual(
            prop1[keys1[i]],
            prop2[keys1[i]],
            maxDepth,
            _depth + 1
          )
        )
          return false;
      } else if (prop1[keys1[i]] !== prop2[keys1[i]]) return false;
    }
    return true;
  }
  getFunctionArgumentNames(func) {
    let STRIP_COMMENTS = /(\/\/.*)|(\/\*[\s\S]*?\*\/)|(\s*=[^,\)]*(('(?:\\'|[^'\r\n])*')|("(?:\\"|[^"\r\n])*"))|(\s*=[^,\)]*))/gm;
    let ARGUMENT_NAMES = /([^\s,]+)/g;
    let fnStr = func.toString().replace(STRIP_COMMENTS, "");
    let result = fnStr
      .slice(fnStr.indexOf("(") + 1, fnStr.indexOf(")"))
      .match(ARGUMENT_NAMES);
    return result || [];
  }
  getMethodArgumentNames(classObj, methodName) {
    return this.getFunctionArgumentNames(classObj[methodName]);
  }
  sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
}
export class R14NavUtils {
  getRouteByState(state) {
    while (state.index !== undefined) state = state.routes[state.index];
    return state;
  }
}
export class R14ArrayUtils {
  unique(arr) {
    return [...new Set(arr)];
  }
  shuffle(array) {
    var i = 0,
      j = 0,
      temp = null;

    for (i = array.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }
}
export class R14DomUtils extends R14DomUtilsBase {
  hyphenateCssProp(property) {
    return property.replace(/([a-z])([A-Z])/, function(a, b, c) {
      return b + "-" + c.toLowerCase();
    });
  }
  getComputedStyle(node, property) {
    if (window.getComputedStyle) {
      property = this.hyphenateCssProp(property);
      return window.getComputedStyle(node, null).getPropertyValue(property);
    } else if (node.currentStyle) {
      return node.currentStyle[property];
    }
    return node.style[property];
  }
  getTransformInfo(node) {
    var matrix = this.parseMatrix(getComputedStyle(node, null).transform),
      rotateY = Math.asin(-matrix.m13),
      rotateX,
      rotateZ;

    if (Math.cos(rotateY) !== 0) {
      rotateX = Math.atan2(matrix.m23, matrix.m33);
      rotateZ = Math.atan2(matrix.m12, matrix.m11);
    } else {
      rotateX = Math.atan2(-matrix.m31, matrix.m22);
      rotateZ = 0;
    }
    return {
      rotate: { x: rotateX, y: rotateY, z: rotateZ },
      translate: { x: matrix.m41, y: matrix.m42, z: matrix.m43 }
    };
  }
  parseMatrix(matrixString) {
    var c = matrixString.split(/\s*[(),]\s*/).slice(1, -1),
      matrix;

    if (c.length === 6) {
      // 'matrix()' (3x2)
      matrix = {
        m11: +c[0],
        m21: +c[2],
        m31: 0,
        m41: +c[4],
        m12: +c[1],
        m22: +c[3],
        m32: 0,
        m42: +c[5],
        m13: 0,
        m23: 0,
        m33: 1,
        m43: 0,
        m14: 0,
        m24: 0,
        m34: 0,
        m44: 1
      };
    } else if (c.length === 16) {
      // matrix3d() (4x4)
      matrix = {
        m11: +c[0],
        m21: +c[4],
        m31: +c[8],
        m41: +c[12],
        m12: +c[1],
        m22: +c[5],
        m32: +c[9],
        m42: +c[13],
        m13: +c[2],
        m23: +c[6],
        m33: +c[10],
        m43: +c[14],
        m14: +c[3],
        m24: +c[7],
        m34: +c[11],
        m44: +c[15]
      };
    } else {
      // handle 'none' or invalid values.
      matrix = {
        m11: 1,
        m21: 0,
        m31: 0,
        m41: 0,
        m12: 0,
        m22: 1,
        m32: 0,
        m42: 0,
        m13: 0,
        m23: 0,
        m33: 1,
        m43: 0,
        m14: 0,
        m24: 0,
        m34: 0,
        m44: 1
      };
    }
    return matrix;
  }
  getPropertyUnitMap() {
    return {
      pixel: "px",
      percent: "%",
      inch: "in",
      cm: "cm",
      mm: "mm",
      point: "pt",
      pica: "pc",
      em: "em",
      ex: "ex"
    };
  }
  getPropertyUnitMapIdx(unit, map) {
    let unitIdx = null;
    map = map || this.getPropertyUnitMap();
    if (map[unit]) unitIdx = unit;
    else {
      for (let item in map) {
        if (map[item] == unit) {
          unitIdx = item;
          break;
        }
      }
    }
    if (!unitIdx) throw "getPropertyUnitMapIdx: Unit idx not found";
    return unitIdx;
  }
  stripPropertyValueUnit(value) {
    if (!isNaN(value)) return parseFloat(value[0]);
    value = value.match(/[-]{0,1}[\d]*[\.]{0,1}[\d]+/g);
    return value === null ? null : parseFloat(value[0]);
  }
  getPropertyUnitByValue(value) {
    if (!isNaN(value)) return null;
    value = value.match(/\D+$/);
    if (value === null) return this.getPropertyUnitMap().pixel;
    value = value[0];
    if (value.endsWith && value.endsWith(")"))
      value = value.substring(0, value.length - 1);
    return value === null ? this.getPropertyUnitMap().pixel : value;
  }
  getPropertyValueByUnit(unit, target, property) {
    unit = this.getPropertyUnitMapIdx(unit);
    let ret = this.getPropertyUnitValues(target, property, unit);
    return ret[unit];
  }
  // styles: element style object
  // returns a standard javascript style object
  parseElementStyle(styles) {
    let hasTransforms = false;
    let transforms = {};
    let transform = null;

    if (styles.transform) {
      if (typeof styles.transform === "string")
        throw "TODO: Allow transform strings in element reactStyle";

      // Check for specific transform options
      if (styles.transform.origin) {
        styles.transformOrigin = styles.transform.origin;
        delete styles.transform.origin;
      }
      styles.transform = this.renderElementTransformStyle(styles.transform);
    }
    return styles;
  }
  renderElementTransformStyle(transforms) {
    let transformArr = [];
    for (let p in transforms) {
      switch (p) {
        case "scale":
        case "translate":
          let rProps = null;
          if (Array.isArray(transforms)) {
            if (
              transforms.length < 2 ||
              transforms[0] === null ||
              transforms[1] === null
            )
              throw "Element Error: Transform array must be [x,y,z,...]";
            if (transforms[0] === null || transforms[1] === null)
              throw "Element Error: Transforms array cannot have null values.";
            transformArr.push(`${p}(${transforms[p].join(",")})`);
          } else if (typeof transforms[p] === "object") {
            if (transforms[p].x === undefined || transforms[p].x === null)
              throw "Element Error: Transform must have an x and y";
            if (transforms[p].y === undefined || transforms[p].y === null)
              throw "Element Error: Transform must have an x and y";
            let pArr = [transforms[p].x, transforms[p].y];
            if (transforms[p].y) pArr.push(transforms[p].y);
            transformArr.push(`${p}(${pArr[p].join(",")})`);
          } else {
            transformArr.push(`${p}(${transforms[p]})`);
          }
          break;
        default:
          transformArr.push(`${p}(${transforms[p]})`);
      }
    }
    return transformArr.length ? transformArr.join(" ") : null;
  }
}
export class R14StringUtils {
  slugify(txt = "") {
    if (!txt) return "";
    let slug = txt
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text

    let slugArr = [];
    let len = 0;
    slug.split("-").forEach(val => {
      if (len + val.length < 60) {
        slugArr.push(val);
        len += val.length;
      }
    });
    return slugArr.join("-");
  }
  capitalize(w) {
    return w.replace(/^\w/, w => w.toUpperCase());
  }
  renderSocialText(text, tagRenderer) {
    if (!text) return text;
    if (text.indexOf("@[") === -1) return text;
    let regex = /@\[([a-z\d_]+):([a-z\d_ ]+):([a-z\d_-]+)\]/gi;
    let tags = text.match(regex);
    if (!tags || !tags.length) return text;
    let mentions = [];
    if (tags && tags.length) {
      tags.forEach(val => {
        let idx = 0;
        let tagInfo = val.match(/@\[([a-z\d_]+):([a-z\d_ ]+):([a-z\d_-]+)\]/i);
        if (tagInfo.length < 4) return;
        let tag = {
          match: tagInfo[0],
          type: tagInfo[1],
          label: tagInfo[2],
          value: tagInfo[3]
        };
        text = text.replace(tag.match, () => {
          return tagRenderer(tag.type, tag.value, tag.label, ++idx);
        });
      });
    }
    return text;
  }
  toHex(str) {
    var arr = [];
    for (var n = 0, l = str.length; n < l; n++) {
      arr.push( Number(str.charCodeAt(n)).toString(16));
    }
    return arr.join("");
  }
  fromHex(hex) {
    hex = hex.toString();
    let str = "";
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }
}
export class R14GraphQlUtils extends R14UtilsChild {
  /**
   * Parses an Array or Object, and returns a graphql fields str
   */
  fieldsToString(fields) {
    let fieldsStr = "";
    if (Array.isArray(fields)) {
      fields.forEach(field => {
        if (typeof field === "object") {
          for (let name in field) {
            fieldsStr += `${name} {\n${this.fieldsToString(field[name])}}`;
          }
        } else fieldsStr += `${field}\n`;
      });
    } else if (typeof fields === "object") {
      // This is aliases
      for (let alias in fields) {
        // if(defaultFields.indexOf())
        if (typeof fields[alias] === "object") {
          fieldsStr += `${alias}{\n${this.fieldsToString(fields[alias])}}\n`;
        } else if (fields[alias] === true || fields[alias] === alias)
          fieldsStr += `${alias}\n`;
        else fieldsStr += `${alias}: ${fields[alias]}\n`;
      }
    }
    return fieldsStr;
  }
  createEntityFindQuery(entityName, fields) {
    let entityNameCap = this._utils.str.capitalize(entityName);
    let fieldsStr = this.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error(
        `createEntityFieldQuery Error: No fields found for ${entityName} find query.`
      );
    return `
      query Find${entityNameCap}($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ${entityNameCap}Filter) {
        ${entityName}s(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`;
  }
}
export class R14FormUtils extends R14UtilsChild {
  /**
   * Returns true if given a form file
   */
  isFile(file) {
    return (file instanceof File);
  }
}