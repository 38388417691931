import { observer, inject } from 'mobx-react';

export default class R14Base {
  static connect(Component, r14) {
    const R14WrappedComponent = class R14WrappedComponent extends Component {
      constructor(props) {
        super(props);
        /** @todo R14 Web base connect, fix this hacky componentDidFocus code. Should probably hooked into navigation, like on native */
        if(this.componentDidFocus){
          this.handleMount = null;
          this.handleMount = this.componentDidMount;
          this.componentDidMount = this._componentDidMount;
          this.handleUpdate = this.componentDidUpdate;
          this.componentDidUpdate = this._componentDidUpdate;
        }
      }
      _componentDidMount(){
        this.handleMount();
        if(this.componentWillFocus) this.componentWillFocus();
        if(this.componentDidFocus) this.componentDidFocus();
      }
      _componentDidUpdate(prevProps, prevState){
        this.handleUpdate(prevProps, prevState);
        if(this.componentWillFocus) this.componentWillFocus();
        if(this.componentDidFocus) this.componentDidFocus();
      }
      render() {
        r14.rendering = true;
        let ret = super.render();
        r14.rendering = false;
        return ret;
      }
    }

    return inject("app", "r14")(observer(R14WrappedComponent));
  }
}