import React from "react";
import View from "./View";
import ScrollView from "./ScrollView";
import FloatingActionButton from "./FloatingActionButton";
import StyleSheet from "./StyleSheet";
import ScreenHeader from "./ScreenHeader";
import { Colors } from "./Theme";

export default class ScreenLayout extends React.PureComponent {
  render() {
    let component = (
      <View {...this.props} style={[styles.screenLayout, this.props.style]}>
        {(this.props.headerTitle ||
          this.props.HeaderTitleComponent ||
          this.props.HeaderRowTopComponent ||
          this.props.headerTitle ||
          this.props.headerControlsLeft ||
          this.props.headerControlsRight) && (
          <View style={styles.header}>
            <ScreenHeader
              style={this.props.headerStyle}
              title={this.props.headerTitle}
              TitleComponent={this.props.HeaderTitleComponent}
              controlsLeft={this.props.headerControlsLeft}
              controlsRight={this.props.headerControlsRight}
              RowTopComponent={this.props.HeaderRowTopComponent}
            />
          </View>
        )}
        <View style={styles.content}>{this.props.children}</View>
      </View>
    );
    if (this.props.scrollEnabled) {
      component = (
        <ScrollView overflow={this.props.scrollOverflow || null}>
          {component}
        </ScrollView>
      );
    }
    if (this.props.floatingActionButton) {
      component = (
        <React.Fragment>
          <FloatingActionButton {...this.props.floatingActionButton} />
          {component}
        </React.Fragment>
      );
    }
    return component;
  }
}
const styles = StyleSheet.create({
  header: {
    flex: 0,
    backgroundColor: Colors.background,
    color: Colors.onBackground,
  },
  content: {
    flex: 1,
  },
  screenLayout: {
    flex: 1,
  },
});
