import DashboardDomain from '../domains/DashboardDomain';
import ProjectDomain from '../domains/ProjectDomain';
import ResourceDomain from '../domains/ResourceDomain';
import ResourceScriptDomain from '../domains/ResourceScriptDomain';
import EventTaskDomain from '../domains/EventTaskDomain';
import ResourceStatusCheckDomain from '../domains/ResourceStatusCheckDomain';
import ResourceAlertDomain from '../domains/ResourceAlertDomain';
import EventDomain from '../domains/EventDomain';
import UserSessionDomain from '../domains/UserSessionDomain';
import ScriptDomain from '../domains/ScriptDomain';
import StatusCheckDomain from '../domains/StatusCheckDomain';
import LogDomain from '../domains/LogDomain';
import UserDomain from '../domains/UserDomain';
import UserPermissionDomain from '../domains/UserPermissionDomain';
import ManualEntryUserDomain from '../domains/ManualEntryUserDomain';
import ClientDomain from '../domains/ClientDomain';
import CloudAccessKeyDomain from '../domains/CloudAccessKeyDomain';
import SshKeyDomain from '../domains/SshKeyDomain';
import ContactGroupDomain from '../domains/ContactGroupDomain';
import ThemeDomain from '../domains/ThemeDomain';
import TimesheetDomain from '../domains/TimesheetDomain';
import DevDomain from '../domains/DevDomain';
import TodoDomain from '../domains/TodoDomain';
import MessageDomain from '../domains/MessageDomain';
import ActivityLogDomain from '../domains/ActivityLogDomain'
import AppModuleDomain from '../domains/AppModuleDomain';
import ResourceDockerTaskDomain from '../domains/ResourceDockerTaskDomain';
import ProjectHealthCheckDomain from '../domains/ProjectHealthCheckDomain';
import HealthCheckDomain from '../domains/HealthCheckDomain';
import PipelineDomain from '../domains/PipelineDomain';
import PipelineBlockDomain from '../domains/PipelineBlockDomain';
import BlockDomain from '../domains/BlockDomain';
import DatasetDomain from '../domains/DatasetDomain';
import PipelineUiDomain from '../domains/PipelineUiDomain';
import PipelineLogDomain from '../domains/PipelineLogDomain';

export default {
  domain: {
    activityLog: ActivityLogDomain,
    dashboard: DashboardDomain,
    project: ProjectDomain,
    resource: ResourceDomain,
    resourceScript: ResourceScriptDomain,
    resourceStatusCheck: ResourceStatusCheckDomain,
    eventTask: EventTaskDomain,
    resourceAlert: ResourceAlertDomain,
    event: EventDomain,
    script: ScriptDomain,
    statusCheck: StatusCheckDomain,
    log: LogDomain,
    userSession: UserSessionDomain,
    user: UserDomain,
    userPermission: UserPermissionDomain,
    manualEntryUser: ManualEntryUserDomain,
    client: ClientDomain,
    cloudAccessKey: CloudAccessKeyDomain,
    sshKey: SshKeyDomain,
    contactGroup: ContactGroupDomain,
    theme: ThemeDomain,
    timesheet: TimesheetDomain,
    dev: DevDomain,
    message: MessageDomain,
    todo: TodoDomain,
    appModule: AppModuleDomain,
    resourceDockerTask: ResourceDockerTaskDomain,
    projectHealthCheck: ProjectHealthCheckDomain,
    healthCheck: HealthCheckDomain,
    pipeline: PipelineDomain,
    pipelineLog: PipelineLogDomain,
    pipelineBlock: PipelineBlockDomain,
    block: BlockDomain,
    dataset: DatasetDomain
  },
  ui: {
    pipeline: PipelineUiDomain
  }
}