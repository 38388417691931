import React from "react";
import R14, {
  StyleSheet,
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField,
  FieldGroup,
  View,
  Text,
  ActivityIndicator,
  SectionHeader,
  Button,
  Colors,
  Icon,
} from "../core";
import EditForm from "../components/EditForm";
export default R14.connect(
  class CloudAccessKeyEditScreen extends React.Component {
    constructor(props) {
      super(props);
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTypeValueChange = this.handleTypeValueChange.bind(this);
      this.handleGenKeysPress = this.handleGenKeysPress.bind(this);
      this.formData = this.props.formData || {};
      this.cloudAccessKeyDomain = this.props.app.dm.cloudAccessKey;
      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
      this.state = {
        type: this.formData.values.type,
        regionSelections: this.formData.regionSelections,
        resourceTypeSelections: this.formData.resourceTypeSelections,
        genKeysState: this.isEditMode ? "loaded" : "init",
        genKeys: null,
        genSecretKey: null,
      };
    }
    // async handleSubmit(form) {
    //   let ret = null;
    //   let editMethod = this.isEditMode ? "update" : "create";
    //   this.props.app.ui.progressIndicator.show();
    //   try {
    //     let isSuccess = await this.cloudAccessKeyDomain[editMethod](
    //       form.values
    //     );
    //     if (isSuccess) {
    //       let dataTable = this.props.refreshDataTable || "cloudAccessKeys";
    //       this.props.app.ui.dataTable.exists(dataTable) &&
    //         (await this.props.app.ui.dataTable(dataTable).refresh());
    //       ret = <Redirect to={this.props.redirect || "cloudAccessKeys"} />;
    //     }
    //   } catch (err) {
    //     form.addError(err.message);
    //   }
    //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    //   return ret;
    // }
    handleTypeValueChange(type) {
      this.setState({
        type: type,
        regionSelections: this.cloudAccessKeyDomain.getRegionSelections(type),
        resourceTypeSelections: this.cloudAccessKeyDomain.getResourceTypeSelections(
          type
        ),
      });
      switch (type) {
        case this.cloudAccessKeyDomain.TYPE_R14:
          if (this.state.genKeysState === "init") this.initGenKeys();
          break;
        default:
          this.setState({
            genKeysState: "init",
            genKey: null,
            genSecretKey: null,
          });
          break;
      }
    }
    handleGenKeysPress() {
      this.initGenKeys();
    }
    async initGenKeys() {

      if(this.isEditMode) return;

      this.setState({
        genKeysState: "loading",
        genKey: null,
        genSecretKey: null,
      });

      let keys = await this.cloudAccessKeyDomain.generateKeys(
        this.cloudAccessKeyDomain.TYPE_R14
      );
      if (keys) {
        this.setState({
          genKeysState: "loaded",
          genKey: keys.key,
          genSecretKey: keys.secretKey,
        });
      } else
        this.setState({
          genKeysState: "error",
          genKey: null,
          genSecretKey: null,
        });
    }
    render() {
      return (
        <EditForm
          name='cloudAccessKeyForm'
          formData={this.props.formData}
          domainName='cloudAccessKey'
          redirect={this.props.redirect || "cloudAccessKeys"}
          refreshDataTable={this.props.refreshDataTable || "cloudAccessKeys"}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
            />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <SelectMenuField
            useValuesOnly
            name='type'
            label='Type'
            items={this.formData.typeSelections}
            onValueChange={this.handleTypeValueChange}
          />

          {this.state.type === this.cloudAccessKeyDomain.TYPE_AWS && (
            <React.Fragment>
              <TextInputField
                name='key'
                label='Access Key'
                required='Please enter an access key.'
              />
            </React.Fragment>
          )}
          {this.state.type === this.cloudAccessKeyDomain.TYPE_AZURE && (
            <React.Fragment>
              <TextInputField
                name='key'
                label='Subscription ID'
                required='Please enter a subscription id.'
              />
              <TextInputField
                name='clientId'
                label='Client ID'
                required='Please enter a client id.'
              />
              <TextInputField
                name='tenantId'
                label='Tenant ID'
                required='Please enter a tenant id.'
              />
            </React.Fragment>
          )}
          {!this.isEditMode &&
            this.state.type === this.cloudAccessKeyDomain.TYPE_R14 && (
              <View>
                {(this.state.genKeysState === "init" ||
                  this.state.genKeysState === "loading") && (
                  <View style={styles.genKeys}>
                    <ActivityIndicator
                      containerStyle={styles.genKeysActivityIndicator}
                    />
                    <Text style={styles.genKeysLoadingText}>
                      Generating Keys...
                    </Text>
                  </View>
                )}
                {this.state.genKeysState === "error" && (
                  <View style={styles.genKeys}>
                    <Text style={styles.genKeysErrorText}>
                      Error Generating Keys
                    </Text>
                    <Button
                      color={Colors.secondary}
                      title='Try Again'
                      onPress={this.handleGenKeysPress}
                    />
                  </View>
                )}
              </View>
            )}
          {(this.state.type === this.cloudAccessKeyDomain.TYPE_R14 && (this.isEditMode || this.state.genKeysState === "loaded")) && (
            <React.Fragment>
              <TextInputField
                name='key'
                label='Key'
                value={this.state.genKey}
                readOnly
              />
              <TextInputField
                name='secretKey'
                label='Secret Key'
                value={this.state.genSecretKey}
                readOnly
              />
              {!this.isEditMode && (
                <View style={styles.important}>
                  <Icon name='help' color={Colors.secondary} />
                  <Text style={styles.importantText}>
                    Please save keys for your records. You will only see the
                    secret key ONCE.
                  </Text>
                </View>
              )}
            </React.Fragment>
          )}
          {this.state.type &&
            this.state.type !== this.cloudAccessKeyDomain.TYPE_R14 && (
              <TextInputField
                name='secretKey'
                label='Secret Key'
                required='Please enter a secret key.'
              />
            )}
          {this.state.regionSelections.length > 0 && (
            <SelectMenuField
              useValuesOnly
              name='regions'
              label='Regions'
              items={this.state.regionSelections}
              multiple
            />
          )}
          {this.state.resourceTypeSelections.length > 0 && (
            <SelectMenuField
              useValuesOnly
              name='resourceTypes'
              label='Resource Types'
              items={this.state.resourceTypeSelections}
              multiple
            />
          )}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  genKeys: {
    // ...StyleSheet.padding(16, 0, 32, 0),
    flex: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    marginBottom: 16,
  },
  genKeysActivityIndicator: {
    ...StyleSheet.padding(0),
    paddingBottom: 8,
  },
  genKeysLoadingText: {
    fontSize: 14,
    fontWeight: 600,
  },
  genKeysErrorText: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 8,
  },
  genKeysTryAgainButton: {
    marginTop: 8,
  },
  important: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: 24,
  },
  importantText: {
    ...StyleSheet.padding(1, 0, 0, 4),
  },
});
