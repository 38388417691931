import React from "react";
import R14, { StyleSheet, ScreenLayout, View } from "../core";
import ResourceMetricsChart from "../components/ResourceMetricsChart";
import ResourceProcessMetricsChart from "../components/ResourceProcessMetricsChart";
export default R14.connect(
  class AppModuleMonitorScreen extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      let appModule = this.props.appModule;
      let resourceDockerTaskUid = null;
      switch (appModule.resource.type) {
        case this.props.app.dm.resource.TYPE_ECS_CLUSTER:
          appModule.serverState.forEach((serverState) => {
            if (serverState.type === this.props.serverType)
              resourceDockerTaskUid = serverState.resourceDockerTaskUid;
          });
          if (!resourceDockerTaskUid) {
            console.error("Resource instance not found");
            return null;
          }
          break;
      }

      if (
        !this.props.appModule.serverState &&
        !this.props.appModule.serverState.length
      )
        return null;

      return (
        <View style={styles.appModuleMonitor}>
          <ResourceMetricsChart
            resourceUid={appModule.resource.uid}
            resourceDockerTaskUid={resourceDockerTaskUid}
            resourceType={appModule.resource.type}
            // metric={"CPU_UTILIZATION"}
            style={styles.chart}
            // initialData={this.props.metrics[metric]}
          />
          <ResourceProcessMetricsChart
            resourceUid={appModule.resource.uid}
            resourceDockerTaskUid={resourceDockerTaskUid}
            resourceType={appModule.resource.type}
            // metric={"CPU_UTILIZATION"}
            style={styles.chart}
            // initialData={this.props.metrics[metric]}
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  chart: {
    width: 480,
  },
  appModuleMonitor: {
    // screen: ({ width }) => {
    //   if (width <= 640)
    //     return {
    //       ...StyleSheet.padding(16, 0, 0, 0),
    //     };
    // },
  },
});
