import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  Text,
  View,
  Form,
  BackButton,
  TextInputField,
  SubmitButton,
  Surface,
  HiddenInputField
} from "../core";
import MessageList from '../components/MessageList';
export default R14.connect(
  class MessageConversationScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.formData = {
        values: {
          messageGroupUid: this.props.messageGroupUid
        }
      };
      this.messageListRef = React.createRef();
    }
   
    componentDidMount() {}
    async handleSubmit(form) {
      this.props.app.ui.progressIndicator.show();
      try {

        // uid: types.ID,
        // clientUid: types.ForeignID.isRequired,
        // userUid: types.ForeignID.isRequired,
        // messageGroupUid: types.ForeignID.isRequired,
        // text: types.String.isRequired,
        // active: types.Boolean.default(true),
        // user: types.Entity({
        //   entity: "user",
        //   localField: "userUid",
        //   foreignField: "uid"
        // }),

        let res = await this.props.app.dm.message.create(form.values);
        if(res && res.message){
          form.elmts.text.value = null;
          // console.log(this.messageListRef.current);
          //this.messageListRef.current.wrappedInstance.addNewMessage(res.message);
        }
       
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    render() {
      return (
        <ScreenLayout style={styles.screenLayout}>
          <MessageList 
            messageGroupUid={this.props.messageGroupUid}
            ref={this.messageListRef}
          />
          <Surface style={styles.messageFormSurface}>
            <Form
              name='messageForm'
              onSubmit={this.handleSubmit}
              validateBeforeSubmit
              initialValues={this.formData.values}
              controlsBottomRight={[<SubmitButton title='Send' key='submit' />]}
            >
              <HiddenInputField name='messageGroupUid' />
              <TextInputField
                name='text'
                label='Message'
                required='Please enter a message...'
                multiline
                autoSize
              />
            </Form>
          </Surface>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
    flex: 1,
    flexDirection: "column"
  },
  messages: {
    flexGrow: 1
  },
  messageFormSurface: {
    alignSelf: "flex-end",
    width: "100%",
    flexGrow: 0,
    ...StyleSheet.padding(0)
  }
});
