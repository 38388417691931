import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class ResourceScriptDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.handleResetStatePress = this.handleResetStatePress.bind(this);
      this.resourceScriptDomain = this.props.app.dm.resourceScript;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("resource", "script");

      this.state = {
        date: null,
        datePickerVisible: false
      };
      // console.log('figure out clean way to forward back to grid and update, maybe use object for selectedrows, check error on select all.');
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        resourceUid: { eq: this.props.resourceUid }
      };
      if (search) {
        filter.script = {
          search: { like: `%${search}%` }
        };
      }
      let res = await this.props.app.dm.resourceScript.find(
        `
        state
        uid
        script {
          uid
          name
          description
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          state: row.state,
          name: row.script.name,
          description: row.script.description,
          scriptUid: row.script.uid
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("resourceScripts");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "state":
          ret = (
            <StateIndicatorText
              label={this.resourceScriptDomain.getStateLabel(cell)}
              color={this.resourceScriptDomain.getStateIndicatorColor(cell)}
            />
          );
          break;
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.resourceScript.delete(uid);
        dataTable.refresh({ indicator: true });
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      }
    }
    async handleResetStatePress(uid, dataTable) {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.resourceScript.resetState(uid);
      await dataTable.refresh({ indicator: true });
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    async handleRunPress(uid) {
      this.props.app.ui.progressIndicator.show();
      await this.resourceScriptDomain.run(uid);
      await this.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    async handleShowLogPress(resourceScriptUid) {
      this.props.app.nav.to("resourceScriptLog", {
        uid: this.props.resourceUid,
        resourceScriptUid: resourceScriptUid
      });
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <DataTable
          name='resourceScripts'
          columns={{
            name: {
              label: "Script",
              sortable: true
            },
            state: {
              label: "State",
              sortable: true
            }
          }}
          maxHeight={300}
          autoRefresh={30}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Scripts"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={
            this.perms.create &&
            this.props.widget !== false && [
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={() =>
                  this.props.app.nav.to("resourceScriptAdd", {
                    uid: this.props.resourceUid
                  })
                }
              />
            ]
          }
          rowControlsRight={
            (this.perms.read ||
              this.perms.edit ||
              this.perms.execute ||
              this.perms.delete) &&
            ((row, dataTable) => {
              return [
                this.perms.execute &&
                  (row.state === "READY" || row.state === "ERROR") && (
                    <IconButton
                      icon='play'
                      size='small'
                      key='run'
                      onPress={() => this.handleRunPress(row.uid)}
                    />
                  ),
                this.perms.read && (
                  <IconButton
                    icon='console'
                    size='small'
                    key='console'
                    onPress={() => this.handleShowLogPress(row.uid)}
                  />
                ),
                (this.perms.edit || this.perms.execute || this.perms.read) && (
                  <PopUpMenu
                    controlIcon='dotsHorizontal'
                    controlIconSize='small'
                    key='resourceMenu'
                    direction='downLeft'
                  >
                    {this.perms.edit && (
                      <PopUpMenuItem
                        label='Edit'
                        icon='edit'
                        key='edit'
                        onPress={() =>
                          this.props.app.nav.to(
                            this.props.editAction || "resourceScriptEdit",
                            {
                              uid: this.props.resourceUid,
                              scriptUid: row.scriptUid
                            }
                          )
                        }
                      />
                    )}
                    {this.perms.execute && (
                      <PopUpMenuItem
                        label='Reset State'
                        icon='restore'
                        key='reset'
                        onPress={() =>
                          this.handleResetStatePress(row.uid, dataTable)
                        }
                      />
                    )}
                    {this.perms.delete && (
                      <PopUpMenuItem
                        label='Remove'
                        icon='close'
                        key='remove'
                        onPress={() =>
                          this.handleRemovePress(row.uid, dataTable)
                        }
                      />
                    )}
                  </PopUpMenu>
                )
              ];
            })
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
