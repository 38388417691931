import R14 from "./R14";

export default class R14Route extends R14.Domain {
  constructor(
    name,
    config,
    portalName,
    location = null,
    match = null,
    navigation = null
  ) {
    super();
    this._name = name;
    this._data = {
      params: match ? match.params : {},
      query: {},
      state: {},
      forms: {}
    };

    this._app = R14.getInstance().app;
    this._portal = portalName;
    this._path = location && location.pathname;
    this._location = location || {};
    this._navigation = navigation || {};
    this._component = null;

    if (location && location.state) {
      for (let prop in location.state) {
        if (prop === "_r14") continue;
        this._data.state[prop] = location.state[prop];
      }
    }
    if (location && location.search) {
      let searchParams = new URLSearchParams(location.search.substring(1));
      for (let key of searchParams.keys()) {
        this._data.query[key] = searchParams.get(key);
      }
    }
    /** @todo Abstract the data from platforms. Match is web, navigation is native */
    if (match && match.params) {
      this._data.params = match.params;
    } else if (navigation && navigation.state.params) {
      this._data.params = navigation.state.params;
    }
    if (this.ui.form.activeRouteName === this._name) {
      let submittedForm = this.ui.form.submittedForm;
      this._data.forms[submittedForm.name] = submittedForm;
    }
    this._config = this._parseConfig(config || {});
  }
  // get data(){
  // 	return this._data;
  // }
  _parseConfig(initialConfig) {
    let config = { ...initialConfig };
    if (
      initialConfig.metadata &&
      typeof initialConfig.metadata === "function"
    ) {
      config.metadata = initialConfig.metadata({
        route: this,
        app: this._app
      });
    }
    if (initialConfig.options) {
      config.options =
        typeof initialConfig.options === "function"
          ? initialConfig.options({ route: this, app: this._app })
          : config.options;
      config = { ...config, ...config.options };
      delete config.options;
    }

    return config;
  }
  get component() {
    return this._component;
  }
  setComponent(component) {
    this._component = component;
    return this;
  }
  get location() {
    return this._location;
  }
  get portal() {
    return this._portal;
  }
  get params() {
    return this._data.params;
  }
  get forms() {
    return this._data.forms;
  }
  get query() {
    return this._data.query;
  }
  get state() {
    return this._data.state;
  }
  get qry() {
    return this.query;
  }
  get data() {
    return {
      ...this.metadata,
      ...this.state,
      ...this.query,
      ...this.params,
      ...this.forms
    };
  }
  get dt() {
    return this.data;
  }
  get path() {
    return this._path;
  }
  get name() {
    return this._name || null;
  }
  get metadata() {
    return this._config.metadata || {};
  }
}
