import React from 'react';
import { StyleSheet, View, Text } from '../../';

export default class AnimatedElementBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.htmlRef = React.createRef();
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleAnimationStart = this.handleAnimationStart.bind(this);
    this.eventListeners = {};
    this.hasMounted = false;
    this.type = this.props.type || 'view';
    this.state = {
      unmount: (! this.props.in && this.props.unmountOnExit)
    }
  }
  parseAnimation(animation) {
    for (let k in animation) {
      if (animation[k].transform && typeof animation[k].transform !== 'string') {
        animation[k].transform = StyleSheet.utils.renderCssTransformStr(animation[k].transform);
      }
    }
    return animation;
  }
  init() {
    this.updateEventListeners();
    let shouldUnmount = (!this.props.in && this.props.unmountOnExit);
    if (!shouldUnmount && this.state.unmount) this.setState({
      unmount: false
    });
  }
  updateEventListeners() {
    if (!this.htmlRef.current) return;
    if (this.props.onStart) {
      this.eventListeners.onAnimationStart = this.htmlRef.current.addEventListener("animationstart", this.handleAnimationStart);
    }
    //
    if ((
      this.props.onExit ||
      this.props.unmountOnExit ||
      this.props.onEnd
    ) && !this.eventListeners.onAnimationEnd) {
      this.eventListeners.onAnimationEnd = this.htmlRef.current.addEventListener("animationend", this.handleAnimationEnd);
    }
  }
  removeEventListeners() {
    if (this.eventListeners.onAnimationEnd) {
      document.removeEventListener('animationend', this.handleAnimationEnd);
      delete this.eventListeners.onAnimationEnd;
    }
  }
  handleAnimationStart() {
    if (this.props.onStart) this.props.onStart();
  }
  handleAnimationEnd() {
    if (this.props.onEnd) this.props.onEnd();
    if (!this.props.in) {
      if (this.props.onExit) this.props.onExit();
      if (this.props.onEnd) this.props.onEnd();
      if (!this.props.in && this.props.unmountOnExit) this.setState({
        unmount: true
      });
    }

  }
  componentDidUpdate() {
    this.init();
  }
  componentDidMount() {
    this.hasMounted = true;
    this.init();
  }
  componentWillUnmount() {
    this.removeEventListeners();
  }
  render() {
    let styles = this.styles();
    if (this.state.unmount) return null;
    let animatedElementStyles = [styles.animatedElement];
    if (this.props.style) animatedElementStyles.push(this.props.style);
    let Component = (this.type === "text") ? Text : View;
    return (
      <Component
        {...this.props}
        style={animatedElementStyles}
        htmlRef={this.htmlRef}
      >
        {this.props.children}
      </Component>
    );
  }
  get animationCss() {
    let animation = null;
    let duration = this.props.duration || '250ms';
    let delay = this.props.delay || null;

    // Transition exit not required, because it will transition back to the entered state
    if (!this.props.animation &&
      !(this.props.enter) && 
      !(this.props.to && this.props.from)
    ) return null;
    if(this.props.to && this.props.from){
      animation = {
        from: this.props.from,
        to: this.props.to
      }
    }
    else if ((this.props.animation && this.props.animation.enter) || this.props.enter) {
      let animationExit = this.props.exit || (this.props.animation && this.props.animation.exit) || {};
      let animationEnter = this.props.enter || (this.props.animation && this.props.animation.enter);
      animation = {
        from: this.props.in ? animationExit : animationEnter,
        to: this.props.in ? animationEnter : animationExit
      }
      if (!this.hasMounted && !this.props.animateOnMount) duration = 0;
    }
    else animation = this.props.animation;

    if (typeof duration !== 'string') duration = `${duration}ms`;
    if (delay && typeof delay !== 'string') delay = `${delay}ms`;

    animation = this.parseAnimation(animation);
    let initialCss = {};
    if (animation.from) initialCss = animation.from;
    return {
      animationName: animation,
      animationDuration: duration,
      animationDelay: delay,
      animationTimingFunction: this.props.timingFunction || "ease-in",
      animationIterationCount: this.props.iterationCount || null,
      animationFillMode: this.props.fillMode || "forwards",
      ...initialCss
    };
  }
  styles() {
    return StyleSheet.create({
      animatedElement: {
        ...this.animationCss
      },
    });
  }
}