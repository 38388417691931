import React from "react";
import R14 from "../R14";
import Touchable from "./Touchable";
import View from "./View";
import Text from "./Text";
import ControlsView from "./ControlsView";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

export default class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this._loadingIndicator = this.props.loadingIndicator;
    this._month = null;
    this._currMonth = new Date();
    this._startDay = null;
    this._endDay = null;
    this._onSelectDay = this.props.onSelectDay || null;
    this.handleNextMonthButtonClick = this.handleNextMonthButtonClick.bind(
      this
    );
    this.handlePreviousMonthButtonClick = this.handlePreviousMonthButtonClick.bind(
      this
    );
    this.initialize();
  }
  initialize() {
    this.initializeDays();
  }
  initializeDays() {
    let startDay = new Date(this.month);
    startDay.setHours(0, 0, 0, 0);
    startDay.setDate(1);

    let endDay = new Date(startDay);
    endDay.setHours(23, 59, 59, 999);
    endDay.setDate(endDay.getDate() + 41);

    // Figure out the first sunday
    if (startDay.getDay() > 0) {
      for (let day = startDay.getDay(); day > 0; day--) {
        startDay.setDate(startDay.getDate() - 1);
      }
    }
    this._startDay = startDay;
    this._endDay = endDay;
  }
  get month() {
    return this.props.month;
  }
  get startDay() {
    return this._startDay;
  }
  get endDay() {
    return this._endDay;
  }
  get loadingIndicator() {
    return this._loadingIndicator;
  }
  handleNextMonthButtonClick() {
    this.calendar.loadNextMonth();
  }
  handlePreviousMonthButtonClick() {
    this.calendar.loadPreviousMonth();
  }
  handleSelectDay(date) {
    this._onSelectDay(date);
  }
  renderMonthHeader() {
    let monthLabel = R14.utils.date.getMonthLabel(this.month);
    let label = `${monthLabel} ${this.month.getFullYear()}`;
    return (
      <View style={styles.monthLabel}>
        <Text style={styles.monthLabelText}>{label}</Text>
      </View>
    );
  }
  renderDaysHeader() {
    let ret = [];
    for (let d = 0; d <= 6; d++) {
      ret.push(
        <View style={styles.cell} key={`${this.keyPrefix}}dh${d}`}>
          <Text style={styles.dayHeaderText}>
            {R14.utils.date.getDayLabel(
              d,
              R14.utils.date.LABEL_TYPE_SINGLE_LETTER
            )}
          </Text>
        </View>
      );
    }
    return ret;
  }
  dayRenderer(date) {
    let key = R14.utils.date.toShortDate(date);
    if (this.dayContent && this.dayContent(key)) {
      return this.dayContent(key);
    } else return null;
  }
  renderDays() {
    // let startDate = this.props.minDate;
    let currDay = new Date(this.startDay);
    let weeks = [];
    let days = [];
    let today = new Date();
    while (currDay <= this.endDay) {
      let isDisabled = false;
      let d = currDay.getDay();
      let dayStyles = [styles.cell];
      let dayTextStyles = [styles.cellText, styles.dayText];
      let onSelectDay = this._onSelectDay
        ? this.handleSelectDay.bind(this, new Date(currDay))
        : null;

      if (currDay.getMonth() < this.month.getMonth())
        dayTextStyles.push(styles.dayPreviousMonthText);
      else if (currDay.getMonth() > this.month.getMonth())
        dayTextStyles.push(styles.dayNextMonthText);

      // console.log(
      //   "check",
      //   this.props.minDate,
      //   currDay < this.props.minDate,
      //   !R14.utils.date.areDatesSameDay(currDay, this.props.minDate),this.props.minDate &&
      //   currDay < this.props.minDate &&
      //   !R14.utils.date.areDatesSameDay(currDay, this.props.minDate)
      // );
      // check if after startDate
      if (
        this.props.minDate &&
        currDay < this.props.minDate &&
        !R14.utils.date.areDatesSameDay(currDay, this.props.minDate)
      ) {
        dayTextStyles.push(styles.dayDisabledText);
        isDisabled = true;
      }
      // check if before endDate
      else if (
        this.props.maxDate &&
        currDay > this.props.maxDate &&
        !R14.utils.date.areDatesSameDay(currDay, this.props.maxDate)
      ) {
        dayTextStyles.push(styles.dayDisabledText);
        isDisabled = true;
      } else if (R14.utils.date.areDatesSameDay(currDay, today)) {
        dayTextStyles.push(styles.dayTodayText);
        dayStyles.push(styles.dayToday);
      } else if (currDay < today) dayTextStyles.push(styles.dayPastText);
      if (
        this.props.day &&
        R14.utils.date.areDatesSameDay(currDay, this.props.day)
      ) {
        dayTextStyles.push(styles.daySelectedText);
        dayStyles.push(styles.daySelected);
      }
      console.log(isDisabled);
      days.push(
        <Touchable
          key={`${this.keyPrefix}}d${currDay.getDate()}`}
          onPress={isDisabled ? null : onSelectDay}
        >
          <View style={dayStyles}>
            <View style={styles.dayLabel}>
              <Text style={dayTextStyles}>{currDay.getDate()}</Text>
            </View>
            <View style={styles.dayContent}>
              <Text style={styles.dayContentText}>
                {this.dayRenderer(currDay)}
              </Text>
            </View>
          </View>
        </Touchable>
      );
      if (d === 6) {
        weeks.push(
          <View style={styles.week} key={`${this.keyPrefix}}d${weeks.length}`}>
            {days}
          </View>
        );
        days = [];
      }
      currDay.setDate(currDay.getDate() + 1);
    }
    return weeks;
  }
  render() {
    /** @todo should this render on update? ok in render? */
    if (this.props.month && this._month !== this.props.month) {
      this.initialize();
      this._month = new Date(this.props.month);
    }
    let calendarStyles = ["calendar"];
    if (this.props.style) calendarStyles.push(this.props.style);
    return (
      <View style={styles.calendar}>
        <View style={styles.header}>
          {this.props.headerControlsLeft && (
            <ControlsView align='left'>
              {this.props.headerControlsLeft}
            </ControlsView>
          )}
          {this.renderMonthHeader()}
          {this.props.headerControlsRight && (
            <ControlsView align='right'>
              {this.props.headerControlsRight}
            </ControlsView>
          )}
        </View>
        <View style={styles.dayHeader}>{this.renderDaysHeader()}</View>
        <View style={styles.month}>
          <View style={styles.days}>{this.renderDays()}</View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  calendar: {
    width: 280,
    flex: 0,
    // marginRight: "auto",
    // marginLeft: "auto",
    paddingBottom: 16,
    backgroundColor: Colors.surface,
  },
  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 6,
    marginRight: 6,
    marginBottom: -12,
  },
  dayHeader: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dayHeaderText: {
    opacity: 0.6,
    color: Colors.onSurface,
  },
  week: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cell: {
    borderRadius: 20,
    height: 40,
    width: 40,
    flex: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cellText: {
    lineHeight: 20,
    color: Colors.onSurface,
  },
  dayToday: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
  },
  dayText: {},
  dayDisabledText: {
    opacity: 0.2,
  },
  dayPreviousMonthText: {
    opacity: 0.3,
  },
  dayNextMonthText: {
    opacity: 0.3,
  },
  monthLabel: {
    height: 64,
  },
  monthLabelText: {
    fontWeight: "500",
    lineHeight: 64,
    color: Colors.onSurface,
  },
  daySelected: {
    backgroundColor: Colors.secondary,
  },
  daySelectedText: {
    color: Colors.onSecondary,
  },
});
