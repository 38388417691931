import React from "react";
import R14, {
  StyleSheet,
} from "../core";

import Dashboard from "../components/Dashboard";
import DashboardCard from "../components/DashboardCard";
import AppModuleWidget from "../components/AppModuleWidget";
export default R14.connect(
  class ProjectAppModuleDashboard extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
    }
    render() {
      let project = this.props.project;
      let projectKey = this.props.app.dm.project.getKeyByType(project.type);
      if (
        !this.userDomain.checkPermissions(
          projectKey,
          "appModule",
          this.userDomain.PERMISSION_ACCESS_READ
        ) ||
        ![
          this.props.app.dm.project.TYPE_DEV,
          this.props.app.dm.project.TYPE_AI,
        ].includes(project.type)
      )
        return null;
      return (
        <Dashboard name='appModules' minWidth={352}>
          {this.props.project.appModules.map((appModule) => (
            <DashboardCard key={`appModule-${appModule.uid}`}>
              <AppModuleWidget appModule={appModule} project={project} />
            </DashboardCard>
          ))}
        </Dashboard>
      );
    }
  }
);
const styles = StyleSheet.create({});