import React from "react";
import R14, {
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  SelectMenuField,
  TextInputField,
  Redirect,
  StyleSheet,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";

export default R14.connect(
  class ProjectPipelineEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.handleAfterSave = this.handleAfterSave.bind(this);
      this.state = {
        connectionType: this.props.formData.values.connectionType,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleAfterSave(isSuccess, form, options = {}) {
      if (
        isSuccess &&
        this.isEditMode &&
        this.props.app.ui.pipeline.exists(this.props.formData.values.uid)
      ) {
        let pipeline = await this.props.app.ui.pipeline.instance(
          this.props.formData.values.uid
        );
        await pipeline.refresh();
      }
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='pipeline'
          redirect={this.props.redirect || "projectPipelines"}
          refreshDataTable={this.props.refreshDataTable || "projectPipelines"}
          onAfterSave={this.handleAfterSave}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectUid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
              helper='Used as remote folder name'
            />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
          />
          <SelectMenuField
            useValuesOnly
            name='userUids'
            multiple
            label='Users'
            items={this.props.formData.userSelections}
            required='Please select at least 1 user.'
          />
          <FieldGroup>
            <TextInputField
              name='rows'
              label='Rows'
              helper='Optional'
              validator={"positiveInt"}
            />
            <TextInputField
              name='columns'
              label='Columns'
              helper='Optional'
              validator={"positiveInt"}
            />
          </FieldGroup>
        </EditForm>
      );
    }
  }
);
