import React from "react";
import R14, {
  StyleSheet,
  SafeAreaView,
  ScrollView,
  DrawerItems,
  View,
  Text,
  Colors,
  SelectMenu,
} from "../core";

export default R14.connect(
  class Drawer extends React.Component {
    constructor(props) {
      super(props);
      // this.initializer = this.initializer.bind(this);
      // this.pageLoader = this.pageLoader.bind(this);
      this.handleSearchChangeText = this.handleSearchChangeText.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.state = {
        projectValue: {
          label: "Test Project",
          value: "asdf1234",
          image: "text",
        },
        projectsLoading: false,
        projectItems: [],
        projectsInitialized: false,
      };
    }
    async handleSearchChangeText(text) {
      await this.loadProjectItems({ search: text });
    }
    async handleOpen() {
      if (this.state.projectsInitialized) return;
      await this.loadProjectItems();
    }
    async handleValueChange(value){
      console.log(value);
      this.setState({
        projectValue: value
      });

    }
    async loadProjectItems(filters = {}){
      this.setState({
        projectsLoading: true,
      });
      let projects = await this.projectsItemLoader(filters);
      console.log(projects);
      this.setState({
        projectsLoading: false,
        projectItems: projects,
        projectsInitialized: true
      });
    }
    async projectsItemLoader(filters) {
      let filter = {
        type: { eq: this.props.app.dm.project.TYPE_ROOT },
      };
      if (filters.search) {
        filter.search = { like: `%${filters.search}%` };
      }

      let projects = await this.props.app.dm.project.find(
        `
        uid
        name
      `,
        {
          page: 1,
          resultsPerPage: 20,
          filter: filter,
          sort: [
            {
              field: "name",
              order: "ASC",
            },
          ],
          totalCount: false,
        }
      );
      return projects && projects.nodes
        ? projects.nodes.map((val) => ({
            value: val.uid,
            label: val.name,
          }))
        : [];
    }
    // async getPageData(
    //   { page, rowsPerPage, sortColumnName, sortDirection, search = null },
    //   options = {}
    // ) {
    //   console.log("GETTING PAGE DATA");
    //   let filter = {};
    //   if (search) filter.search = { like: `%${search}%` };
    //   filter.type = { eq: this.props.app.dm.project.TYPE_ROOT };
    //   let res = await this.props.app.dm.project.find(
    //     `
    //     uid
    //     name
    //     `,
    //     {
    //       page: page,
    //       resultsPerPage: rowsPerPage,
    //       filter: filter,
    //       sort: [
    //         {
    //           field: sortColumnName,
    //           order: sortDirection.toUpperCase()
    //         }
    //       ],
    //       totalCount: options.totalCount || false
    //     }
    //   );
    //   console.log(res);
    //   return {
    //     pageData: res.nodes,
    //     totalRows: res.totalCount || null
    //   };
    // }
    labelRenderer(value) {
      console.log(value);
      return (
        <View style={styles.projectSelectMenuLabel}>
          <View style={styles.projectSelectMenuThumbnail}>
            <Text style={styles.projectSelectMenuThumbnailText}>TP</Text>
          </View>
          <View>
            <Text style={styles.projectSelectMenuLabelText}>{value.label}</Text>
          </View>
        </View>
      );
    }
    render() {
      return (
        <ScrollView>
          <SafeAreaView
            style={styles.container}
            forceInset={{ top: "always", horizontal: "never" }}
          >
            <View style={styles.projectsHeader}>
              <SelectMenu
                label={"Projects"}
                itemRenderer={this.labelRenderer}
                // initializer={this.initializer}
                // pageLoader={this.pageLoader}
                // rowKeyExtractor={this.rowKeyExtractor}
                labelRenderer={this.labelRenderer}
                inputStyle={styles.selectInputStyle}
                valueLabelTextStyle={styles.headerLabelStyle}
                popupMenuStyle={styles.selectPopUpMenuStyle}
                value={this.state.projectValue}
                searchable
                items={this.state.projectItems}
                onOpen={this.handleOpen}
                onSearchChangeText={this.handleSearchChangeText}
                loading={this.state.projectsLoading}
                onValueChange={this.handleValueChange}
              />
            </View>
            <DrawerItems {...this.props} />
          </SafeAreaView>
        </ScrollView>
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  projectsHeader: {
    height: 56,
    backgroundColor: Colors.surface,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
        };
    },
  },
  projectSelectMenuLabel: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  projectSelectMenuLabelText: {
    fontWeight: 500,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  projectSelectMenuThumbnail: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 40,
    backgroundColor: Colors.secondaryDark,
    borderRadius: 20,
    ...StyleSheet.margin(0, 12, 0, 2),
  },
  projectSelectMenuThumbnailText: {
    fontWeight: 500,
    fontSize: 18,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
});
