import React from "react";
import PropTypes from "prop-types";
import R14, { View, StyleSheet } from "../core";
import DashboardCard from "./DashboardCard";
import DashboardWidget from "./DashboardWidget";
export default R14.connect(
  class Dashboard extends React.Component {
    static propTypes = {
      /** Name of the dashboard */
      name: PropTypes.string.isRequired,
    };
    static defaultProps = {};
    constructor(props) {
      super(props);
      this.state = { columnCount: null, size: "large" };
      this.handleLayout = this.handleLayout.bind(this);
    }
    handleLayout(event) {
      this.updateLayout(event.nativeEvent.layout.width);
    }
    renderColumns() {
      if (!this.state.columnCount) return null;
      let cols = [];
      for (let i = 0; i < this.state.columnCount; i++) {
        cols.push([]);
      }
      let totalItems = React.Children.count(this.props.children);
      let i = 0;
      React.Children.forEach(this.props.children, (child) => {
        if (child) {
          let colIdx = i % this.state.columnCount;
          cols[colIdx].push(
            React.cloneElement(child, {
              size: this.state.size,
              key: `dashboard-item-${i}`,
            })
          );
          i++;
        }
      });
      return cols.map((items, i) => (
        <View key={`dash-${this.props.name}-${i}`} style={styles.column}>
          {items}
        </View>
      ));
    }
    updateLayout(width) {
      let minWidth = this.props.minWidth || 320;
      let maxColumns = this.props.maxColumns || 4;

      let newColumnCount = 1;

      for (let c = 1; c <= maxColumns; c++) {
        if (width / c < minWidth) break;
        newColumnCount = c;
      }

      let size = width <= 800 && newColumnCount === 1 ? "small" : "large";

      if (newColumnCount !== this.state.columnCount || size !== this.state.size)
        this.setState({
          columnCount: newColumnCount,
          size: size,
        });
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <View
          onLayout={this.handleLayout}
          style={[styles.dashboardItems, dynamicStyles.dashboardItems]}
        >
          {this.renderColumns()}
          {/* {this.props.children} */}
        </View>
      );
    }
    createDynamicStyles() {
      let marginRight = -16;
      let paddingLeft = 16;
      let paddingRight = 16;
      switch (this.state.size) {
        case "small":
          marginRight = 0;
          paddingRight = 0;
          paddingLeft = 0;
          break;
      }
      return new StyleSheet({
        dashboardItems: {
          ...StyleSheet.margin(0, marginRight, 16, 0),
          ...StyleSheet.padding(0, paddingRight, 16, paddingLeft),
        },
      });
    }
  }
);
const styles = StyleSheet.create({
  dashboardItems: {
    ...StyleSheet.padding(0, 16, 16, 16),
    //...StyleSheet.margin(0, 'auto', 0, 'auto'),
    marginRight: -16,
    // screen: ({ width }) => {
    //   if (width <= 640)
    //     return {
    //       marginRight: 0,
    //       ...StyleSheet.padding(0, 0, 16, 0),
    //     };
    // },
    flex: 1,
    flexGrow: 0,
    flexBasis: 0,
    justifyContent: "space-around",
    // flexWrap: "wrap",
    flexDirection: "row",
    //maxWidth: 1200,
    // screen: ({ width }) => {
    //   if (width <= 640)
    //     return {
    //       ...StyleSheet.padding(0)
    //     };
    // }
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: "flex-start",
    flexDirection: "column",
  },
});
