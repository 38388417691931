import React from "react";
import R14 from "../R14";

export default class DraggableUiDomain extends R14.DomainInstances {
  create(props) {
    if (!props.name) throw "Draggable Error: Must have a name.";
    let draggableInstance = new DraggableUiInstance(props, this);
    this.addInstance(props.name, draggableInstance);
    return draggableInstance;
  }
  remove(name) {
    this.removeInstance(name);
  }
}
class DraggableUiInstance extends R14.DomainInstances {
  constructor(props, containers) {
    super();
    this._containers = containers;
    this._props = props;
    this._activeView = null;
    this.state = {
      activeViewName: null,
      x: null,
      y: null,
      height: null,
      width: null,
    };
  }
  remove() {
    this._containers.removeInstance(this._props.name);
  }
  setActiveView(view = null) {
    this.setState({
      activeViewName: view ? view.name : null,
    });
    this._activeView = view || null;
  }
  get active() {
    return this.state.activeViewName ? true : false;
  }
  get activeView() {
    return this._activeView || null;
  }
  handleMeasure(measure) {
    this.setState({
      ...measure,
    });
  }
  addView(props) {
    if (!props.name) throw "Draggable View Error: Must have a name.";
    let draggableInstance = new DraggableViewUiInstance(props, this);
    this.addInstance(props.name, draggableInstance);
    return draggableInstance;
  }
  removeView(name) {
    this.removeInstance(name);
    return this;
  }
  handlePressIn(event) {
    //console.log("draggable handlePRessIn", event);
  }
  handlePressCancel(event) {
    if (this.active) {
      this.activeView.handleContainerPressCancel(event);
      this.setActiveView(null);
    }
  }
  handlePressOut(event) {
    //console.log("draggable handlePRessOut", event);
  }
  handlePressMove(event) {
    if (this.active) {
      //this.detectCollisions(event);
      this.activeView.handleContainerPressMove(event);
    }
  }
  // detectCollisions() {
  //   if (! this.activeView.props.onDragCollision) return false;
  //   let collisions = [];
  //   if (this.activeView.state.layout) {
  //     let activeViewPos = {
  //       top: this.activeView.props.offsetTop+this.activeView.state.dragY,
  //       left: this.activeView.props.offsetLeft+this.activeView.state.dragX,
  //       bottom: this.activeView.props.offsetTop+this.activeView.state.dragY+this.activeView.state.layout.height,
  //       right: this.activeView.props.offsetLeft+this.activeView.state.dragX+this.activeView.state.layout.width,
  //     }
  //     this.forEach((view) => {
  //       if (this.activeView.name === view.name) return false;
  //       let viewPos = {
  //         top: view.props.offsetTop,
  //         left: view.props.offsetLeft,
  //         bottom: view.props.offsetTop + view.state.layout.height,
  //         right: view.props.offsetLeft + view.state.layout.width,
  //       }

  //       if(activeViewPos.left > viewPos.left && activeViewPos.right < viewPos.right){
  //         console.log('CHECKING X', view.name);
  //       }

  //     });
  //   }
  // }
}
// class DraggableViewUiDomain extends R14.DomainInstances {
//   add(props) {
//     if (!props.name) throw "Draggable View Error: Must have a name.";
//     let draggableInstance = new DraggableViewUiInstance(props.name, this);
//     this.addInstance(props.name, draggableInstance);
//     return draggableInstance;
//   }
//   remove(name) {
//     this.removeInstance(name);
//     return this;
//   }
// }
class DraggableViewUiInstance extends R14.Domain {
  constructor(props, container) {
    super();
    this._props = props;
    this._container = container;
    this._pressInEvent = null;
    this.state = {
      dragX: 0,
      dragY: 0,
      layout: null,
    };
  }
  remove() {
    this._container.removeInstance(this.name);
  }
  get name() {
    return this.props.name;
  }
  get active() {
    return this._container.state.activeViewName === this.name;
  }
  get container() {
    return this._container;
  }
  get pressInEvent() {
    return this._pressInEvent;
  }
  get props() {
    return this._props;
  }
  normalizeEvent(event) {}
  handlePressIn(event) {
    //console.log("draggableVIEW handlePRessIn", event, this.container.state.x, this.container.state.y);
    this._pressInEvent = event;
    this.container.setActiveView(this);
    if (this.props.onDragStart) {
      this.props.onDragStart(event);
    }
  }
  handlePressOut(event) {
    //console.log("draggableVIEW handlePRessOut", event);
    this._pressInEvent = null;
    this.reset();
    if (this.props.onDragEnd) {
      this.props.onDragEnd(event);
    }
  }
  handlePressMove(event) {
    //console.log('draggableVIEW handlePRessMove', event);
    // if(this.props.onDragEnd){
    //   this.props.onDragEnd(event);
    // }
  }
  handlePressCancel(event) {
    //console.log('draggableVIEW handlePRessMove', event);
    // if(this.props.onDragEnd){
    //   this.props.onDragEnd(event);
    // }
  }
  handleContainerPressCancel(event) {
    console.log("draggableVIEW handleContainerPressCancel", event);
    this.setState({
      dragX: 0,
      dragY: 0,
    });
    if (this.props.onDragCancel) {
      this.props.onDragCancel(event);
    }
  }
  handleContainerPressMove(event) {
    if (this.active) {
      this.setState({
        dragX: event.nativeEvent.pageX - this.pressInEvent.nativeEvent.pageX,
        dragY: event.nativeEvent.pageY - this.pressInEvent.nativeEvent.pageY,
      });
      if (this.props.onDrag) {
        this.props.onDrag(event);
        // throw new Error('TEST ON DRAG');
      }
    }
  }
  handleLayout(event) {
    this.setState({
      layout: event && event.nativeEvent ? event.nativeEvent.layout : null,
    });
  }
  reset() {
    this.setState({
      dragX: 0,
      dragY: 0,
    });
    this.container.setActiveView(null);
  }
}
