import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";
import AddRemoveWidgetField from "../components/AddRemoveWidgetField";
import LabelView from "../components/LabelView";

export default R14.connect(
  class DatasetEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.state = {
        type: this.props.formData.values && this.props.formData.values.type || null ,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    handleTypeChange(val) {
      this.setState({ type: val });
    }
    render() {
      console.log(this.props.formData.values);
      return (
        <EditForm
          name='datasetForm'
          formData={this.props.formData}
          domainName='dataset'
          redirect={this.props.redirect || "datasets"}
          refreshDataTable={this.props.refreshDataTable || "datasets"}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectType' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='key'
              label='Key'
              validator='key'
              required='Enter Key'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
            <SelectMenuField
              name='type'
              label='Type'
              useValuesOnly
              items={this.formData.typeSelections}
              onValueChange={this.handleTypeChange}
              required='Select a type.'
            />
          </FieldGroup>
          {this.state.type === this.props.app.dm.dataset.TYPE_AWS_S3 && (
            <FieldGroup>
              <SelectMenuField
                useValuesOnly
                name='cloudAccessKeyUid'
                label='Cloud Access Key'
                items={this.props.formData.cloudAccessKeySelections}
                required='Select a cloud access key'
              />
              <TextInputField
                name='bucketName'
                label='Bucket Name'
                required='Enter the bucket name'
              />
            </FieldGroup>
          )}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  ioItemRow: {
    flex: 1,
    flexDirection: "row",
  },
  ioItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  ioItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
