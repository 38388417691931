import React from "react";
import R14, { StyleSheet, Theme } from "../core";
import HeaderControlsLeft from "./HeaderControlsLeft";

export default R14.connect(
  class DrawerContentMain extends React.Component {
    render() {
      console.log('HERE HERE HERE');
      let logoColorScheme =
        Theme.componentOptions("drawerLogo").colorScheme || null;
      let sidebarOnBackground =
        Theme.componentColors("sidebar").onBackground || null;

      if(! logoColorScheme) logoColorScheme = (Theme.key === 'light') ? 'onLight' : 'onDark';

      return (
        <HeaderControlsLeft
          menuIconColor={sidebarOnBackground}
          menuIcon='arrowBack'
          logoColorScheme={logoColorScheme}
        />
      );
    }
  }
);

const styles = StyleSheet.create({});
