import React from "react";
import R14, { HiddenInputField, SelectMenuField } from "../core";
import RelatedCreateButton from "./RelatedCreateButton";
import EditForm from "./EditForm";
export default R14.connect(
  class RelatedAddForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleSave = this.handleSave.bind(this);
    }
    async handleSave(form, options) {
      let addMethod = this.props.domainAddMethodName || "add";
      console.log(
        "Calling method and domain",
        this.props.domainName,
        this.props.domainAddMethodName
      );
      return await this.props.app.dm[this.props.domainName][addMethod](
        form.values,
        options
      );
    }
    componentDidMount() {
      if (
        !this.props.selectMenuFieldSelections ||
        !this.props.selectMenuFieldSelections.length
      ) {
        this.props.app.nav.replace(this.props.createAction, {
          uid: this.props.formData.values[this.props.relatedFieldName],
        });
      }
    }
    render() {
      console.log(this.props.fields, this.props.formData, this.props.relatedFieldName);
      return (
        <EditForm
          name={this.props.name || `${this.props.domainName}AddForm`}
          formData={this.props.formData}
          domainName={this.props.domainName}
          redirect={this.props.redirect}
          refreshDataTable={this.props.refreshDataTable}
          submitButtonLabel='Add'
          onSave={this.handleSave}
        >
          <HiddenInputField name={this.props.relatedFieldName} />
          {this.props.fields &&
            this.props.fields.map((name) => (
              <HiddenInputField name={name} key={name} />
            ))}
          <RelatedCreateButton
            onPress={() => {
              this.props.app.nav.replace(this.props.createAction, {
                uid: this.props.formData.values[this.props.relatedFieldName],
              });
            }}
            title={this.props.createButtonTitle}
          />
          <SelectMenuField
            useValuesOnly
            name={this.props.selectMenuFieldName}
            label={this.props.selectMenuFieldLabel}
            // multiple
            items={this.props.selectMenuFieldSelections}
          />
        </EditForm>
      );
    }
  }
);