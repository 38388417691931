import React from "react";
import PropTypes from "prop-types";
import Link from "../Link";
import StyleSheet from "../StyleSheet";
import ResizeObserver from "resize-observer-polyfill";

export default class TouchableBase extends React.Component {
  static propTypes = {
    /** The route the the touchable goes to on press. Can either be a route name, or a route object containing route and params. If set, press events are disabled. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The function to be called when the touchable is pressed. */
    onPress: PropTypes.func,
    /** The function to be called when the touchable press has started. */
    onPressIn: PropTypes.func,
    /** The function to be called when the touchable press has ended. */
    onPressOut: PropTypes.func,
    /** The function to be called when the touchable press has moved. */
    onPressMove: PropTypes.func,
    /** The function to be called when the touchable press has canceled or left the touchable. */
    onPressCancel: PropTypes.func,
    /** Controls whether touchable shows touch feedback */
    feedback: PropTypes.bool,
  };
  static defaultProps = {
    feedback: true,
  };
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
    this.handlePressMove = this.handlePressMove.bind(this);
    this.handlePressCancel = this.handlePressCancel.bind(this);
    this.htmlRef = React.createRef();
    if (this.props.onLayout || this.props.styleOnLayout) {
       /** @todo SHOULD MERGE WITH VIEW! */
      this.resizeObserver = new ResizeObserver((entries) => {
        this.triggerOnLayout();
      });
    }
  }
  componentDidMount() {
    if (this.props.onLayout) {
      setTimeout(() => {
        this.triggerOnLayout();
      }, 5);
      this.resizeObserver.observe(this.htmlRef.current);
    }
    if(this.props.onPressIn && this.htmlRef.current){
      this.htmlRef.current.addEventListener('touchstart', this.preventDefault);
    }
  }
  componentWillUnmount() {
    if (this.props.onLayout) this.resizeObserver.disconnect();
    if(this.props.onPressIn && this.htmlRef.current){
      this.htmlRef.current.removeEventListener('touchstart', this.preventDefault);
    }
  }
  triggerOnLayout() {
    /** @todo SHOULD MERGE WITH VIEW! */
    let event = { nativeEvent: { layout: {} } };
    if (
      (!this.props.onLayout && !this.props.styleOnLayout) ||
      !this.htmlRef.current
    )
      return false;
    event = {
      nativeEvent: {
        layout: {
          x: this.htmlRef.current.offsetLeft,
          y: this.htmlRef.current.offsetTop,
          width: this.htmlRef.current.offsetWidth,
          height: this.htmlRef.current.offsetHeight,
        },
      },
    };
    if (this.props.onLayout) this.props.onLayout(event);
    // if (this.props.styleOnLayout) {
    //   let styleOnLayout = this.props.styleOnLayout(event) || null;
    //   if (styleOnLayout !== this.state.styleOnLayout) {
    //     this.setState({ styleOnLayout: styleOnLayout });
    //   }
    // }
  }
  normalizeEvent(e) {
    let event = {
      nativeEvent: {
        locationX: null,
        locationY: null,
        pageX: null,
        pageY: null,
        movementX: null,
        movementY: null,
      },
    };
    if (!e.nativeEvent) return event;
    if (window.TouchEvent && e.nativeEvent instanceof window.TouchEvent) {
      let touch = null;
      if (e.nativeEvent.touches && e.nativeEvent.touches.length)
        touch = e.nativeEvent.touches[0];
      else if (
        // For touch end, get the last touch
        e.nativeEvent.changedTouches &&
        e.nativeEvent.changedTouches.length
      ) {
        touch = e.nativeEvent.changedTouches[0];
      }

      if (!touch) return event;
      event.nativeEvent.pageX = touch.pageX;
      event.nativeEvent.pageY = touch.pageY;
      if (this.htmlRef.current) {
        let pPos = this.htmlRef.current.getBoundingClientRect();
        if (pPos) {
          event.nativeEvent.locationX = touch.pageX - pPos.x;
          event.nativeEvent.locationY = touch.pageY - pPos.y;
        }
      }
      if (this._lastEvent) {
        event.nativeEvent.movementX =
          touch.pageX - this._lastEvent.nativeEvent.pageX;
        event.nativeEvent.movementY =
          touch.pageY - this._lastEvent.nativeEvent.pageY;
      }
    } else if (window.MouseEvent && e.nativeEvent instanceof window.MouseEvent) {
      event.nativeEvent.locationX = e.nativeEvent.offsetX;
      event.nativeEvent.locationY = e.nativeEvent.offsetY;
      event.nativeEvent.pageX = e.nativeEvent.pageX;
      event.nativeEvent.pageY = e.nativeEvent.pageY;
      if ("movementX" in e.nativeEvent) {
        event.nativeEvent.movementX = e.nativeEvent.movementX;
        event.nativeEvent.movementY = e.nativeEvent.movementY;
      }
    }
    this._lastEvent = event;
    return event;
  }
  handlePress(e) {
    //e.preventDefault();
    if (this.props.onPress) this.props.onPress(this.normalizeEvent(e));
  }
  handlePressIn(e) {
    e.preventDefault();
    if (this.props.onPressIn) this.props.onPressIn(this.normalizeEvent(e));
    return false;
  }
  handlePressOut(e) {
    //e.preventDefault();
    if (this.props.onPressOut) this.props.onPressOut(this.normalizeEvent(e));
  }
  handlePressMove(e) {
    //e.preventDefault();
    if (this.props.onPressMove) this.props.onPressMove(this.normalizeEvent(e));
  }
  handlePressCancel(e) {
    //e.preventDefault();
    if (this.props.onPressCancel)
      this.props.onPressCancel(this.normalizeEvent(e));
  }
  preventDefault(e){
    e.preventDefault();
  }
  render() {
    if (this.props.to) return <Link {...this.props} ref={this.htmlRef} />;
    let props = {
      className: StyleSheet.className([
        styles.touchable,
        this.props.feedback === false && styles.touchableNoFeedback,
        this.props.style,
      ]),
    };
    if (this.props.onPress) {
      props.onClick = this.handlePress;
    }
    if (this.props.onPressIn) {
      props.onMouseDown = this.handlePressIn;
      props.onTouchStart = this.handlePressIn;
    }
    if (this.props.onPressOut) {
      props.onMouseUp = this.handlePressOut;
      props.onTouchEnd = this.handlePressOut;
    }
    if (this.props.onPressMove) {
      props.onMouseMove = this.handlePressMove;
      props.onTouchMove = this.handlePressMove;
    }
    if (this.props.onPressCancel) {
      props.onMouseLeave = this.handlePressCancel;
      props.onTouchCancel = this.handlePressCancel;
    }
    if(this.props.tooltip) props.title = this.props.tooltip;

    if(typeof this.props.children === 'string'){
      console.error(`Touchable Component Error: String '${this.props.children}' passed to View. Please enclose strings in a Text component.`);
    }
    
    return (
      <div {...props} ref={this.htmlRef}>
        {this.props.children}
      </div>
    );
  }
}
const styles = StyleSheet.create({
  touchable: {
    cursor: "pointer",
  },
  touchableNoFeedback: {
    outline: 0,
    '-webkit-tap-highlight-color': "transparent",
    '-webkit-touch-callout:': "none",
    touchAction: "none",
    userSelect: "none"
  },
});
