import R14 from "../core";

export default class LogDomain extends R14.Domain {
  constructor(key) {
    super();
    this.STATE_RUNNING = "RUNNING";
    this.STATE_READY = "READY";
    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindLogs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: LogFilter) {
        logs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.logs;
  }
  async create(values, { resourceUid = null }) {
    // Check for resourceUid. If exists, create resourceLog
    if (resourceUid)
      values.resources = {
        add: [resourceUid]
      };
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateLog($input: CreateLogInput!) {
        createLog(input: $input){
          log {
            uid
            name
            description
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateLog($input: UpdateLogInput!) {
        updateLog(input: $input){
          log {
            uid
            name
            description
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteLog($uid: ID!) {
        deleteLog(uid: $uid){
          log {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async fetchEditFormData(uid = null) {
    let qry = "";
    let res = {};
    if (uid) {
      qry = `
        query LogEditFormData($uid: ID!) {
          log(uid: $uid){
            uid
            name
            description
            path
          },
        }
      `;
      res = await this.api.qry(qry, { uid: uid });
    }
    let formVals = (res.data && res.data.log) || {};
    return {
      values: formVals
    };
  }
}
