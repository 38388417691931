import R14 from "../core";

export default class ResourceInstanceDomain extends R14.Domain {
  constructor(uid, options = {}) {
    super();
    this._options = options;
    this._resourceSubscription = null;
    this.state = {
      uid: uid
    };
  }
  async init() {
    await this.refresh();
    if (this._options.autoRefresh) {
      await this.startSync();
    }
  }
  remove() {
    this.clearSync();
    this.dm.resource.removeInstance(this.state.uid);
  }
  async refresh() {
    let resource = await this.dm.resource.get(this.state.uid, this._options);
    this.setState(resource);
  }
  async update(values) {
    let updateVals = { ...values, uid: this.state.uid };
    try {
      let newVals = await this.dm.resource.update(updateVals);
      if (newVals) this.setState(newVals);
      return newVals ? true : false;
    } catch (err) {
      console.error(err.message)
      return false;
    }
  }
  async updateState(command) {
    this.setState(await this.dm.resource.updateState(this.state.uid, command));
  }
  async startSync() {
    try{
      this._resourceSubscription = await this.dm.resource.onUpdate(this.state.uid,resource=>{
        this.setState(resource)
      });
    }
    catch(err){
      console.error('Error Syncing Resource: ',err.message);
    }
  }
  clearSync() {
    if(this._resourceSubscription ) this._resourceSubscription.unsubscribe();
  }
  get uid(){
    return this.state.uid;
  }
  get type(){
    return this.state.type;
  }
}
