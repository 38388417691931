import React from "react";
import R14, { StyleSheet, ScreenLayout, View } from "../core";
import ResourceMetricsChart from "../components/ResourceMetricsChart";
import ResourceProcessMetricsChart from "../components/ResourceProcessMetricsChart";
export default R14.connect(
  class ProjectPipelineBlockMonitorScreen extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      let pipelineBlock = this.props.pipelineBlock;
      let appModule = pipelineBlock.block.appModule;
      let instanceResourceDockerTaskUid = null;
      if (
        pipelineBlock.instanceResourceDockerTaskUids &&
        pipelineBlock.instanceResourceDockerTaskUids.length
      ) {
        instanceResourceDockerTaskUid =
          pipelineBlock.instanceResourceDockerTaskUids[0];
      }

      if(! instanceResourceDockerTaskUid) return null;

      return (
        <View style={styles.pipelineBlockMonitor}>
          <ResourceMetricsChart
            resourceUid={appModule.resource.uid}
            resourceDockerTaskUid={instanceResourceDockerTaskUid}
            resourceType={appModule.resource.type}
            style={styles.chart}
          />
          <ResourceProcessMetricsChart
            resourceUid={appModule.resource.uid}
            resourceDockerTaskUid={instanceResourceDockerTaskUid}
            resourceType={appModule.resource.type}
            style={styles.chart}
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  chart: {
    width: 480,
  },
  // pipelineBlockMonitor: {
  //   screen: ({ width }) => {
  //     if (width <= 1280)
  //       return {
  //         width: 1280
  //       };
  //   },
  // },
});
