import React from "react";
import PropTypes from "prop-types";
import StyleSheet from "./StyleSheet";
import View from "./View";
import Text from "./Text";
import Touchable from "./Touchable";
import { Colors } from "./Theme";

export default class ShowHideText extends React.PureComponent {
  static propTypes = {
    /** The string to text to be displayed. */
    text: PropTypes.string,
    /** The max number of characters before text is hidden. */
    maxCharacters: PropTypes.number
  };
  static defaultProps = {
    maxCharacters: 20
  };
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.state = {
      isHidden: this.text.length > this.props.maxCharacters
    };
  }
  handlePress() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }
  get text(){
    return `${this.props.text}`;
  }
  renderText() {
    if (
      this.state.isHidden === false ||
      this.text.length <= this.props.maxCharacters
    )
      return this.text;
    else {
      return `${this.text.substring(0, this.props.maxCharacters)}...`;
    }
  }
  render() {
    let styles = this.styles();
    let showTouchable = this.text.length > this.props.maxCharacters;
    let touchableText = this.state.isHidden ? "show" : "hide";
    return (
      <View style={styles.showHideText}>
        <Text style={styles.text}>{this.renderText()}</Text>
        {showTouchable && (
          <Touchable onPress={this.handlePress}>
            <Text style={styles.touchableText}>{touchableText}</Text>
          </Touchable>
        )}
      </View>
    );
  }
  styles() {
    return StyleSheet.create({
      showHideText: {
        flex: 0,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: 'flex-end'
      },
      text: {
        paddingRight: 8
      },
      touchableText: {
        color: Colors.secondary,
        fontSize: 14,
        fontWeight: '600'
      }
    });
  }
}
