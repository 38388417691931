import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  SelectMenuField,
  Colors
} from "../core";
import RelatedCreateButton from "../components/RelatedCreateButton";
export default R14.connect(
  class ResourceSshKeyManageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.resourceDomain = this.props.app.dm.resource;
    }
    async handleRemovePress() {
      if (window.confirm("Are you sure?")) {
        let isSuccess = await this.updateResource({
          sshKeyUid: null
        });
        if(isSuccess) this.props.app.nav.to(this.props.redirect || "resource", {
          uid: this.props.resourceUid
        });
      }
    }
    refreshDataTable() {
      this.props.app.ui.dataTable.exists(this.dataTable) &&
        this.props.app.ui.dataTable(this.dataTable).refresh();
    }
    get formData() {
      return this.props.formData;
    }
    get sshKeyUid() {
      return this.formData.values.sshKeyUid || null;
    }

    async updateResource(values){
      /** @todo Should resource be initialized in the action, add get form data method */
      this.props.app.ui.progressIndicator.show();
      let resource = await this.props.app.dm.resource.instance(
        this.props.resourceUid
      );
      let isSuccess = resource.update(values);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return isSuccess
    }

    async handleSubmit(resourceSshKeyAddForm) {
      let isSuccess = await this.updateResource({
        sshKeyUid: resourceSshKeyAddForm.values.sshKeyUid
      });
      if (isSuccess)
        return (
          <Redirect
            to={this.props.redirect || "resource"}
            params={{
              uid: resourceSshKeyAddForm.values.resourceUid
            }}
          />
        );
    }
    render() {
      let createButton = (
        <RelatedCreateButton
          onPress={() => {
            this.props.app.nav.replace("resourceSshKeyCreate", {
              uid: this.props.formData.values.resourceUid
            });
          }}
          title='Create New SSH Key'
          showOr={this.sshKeyUid ? false : true}
        />
      );
      return (
        <Form
          name='resourceSshKeyManageForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Add' key='submit' />
          ]}
        >
          <HiddenInputField name='resourceUid' />
          {!this.sshKeyUid && createButton}
          <SelectMenuField
            useValuesOnly
            name='sshKeyUid'
            label='Select SSH Key'
            items={this.props.formData.sshKeySelections}
            required='Please select an SSH key'
          />
          {this.sshKeyUid && createButton}
          {this.sshKeyUid && (
            <RelatedCreateButton
              onPress={this.handleRemovePress}
              color={Colors.secondary}
              title='Remove SSH Key'
              showOr={false}
            />
          )}
        </Form>
      );
    }
  }
);
