import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
} from "../core";

export default R14.connect(
  class UserEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRoleValueChange = this.handleRoleValueChange.bind(this);
      this.state = {
        showPermGroups: this.shouldShowPermGroupByRole(
          this.props.formData.values && this.props.formData.values.role
            ? this.props.formData.values.role
            : null
        ),
      };
    }
    handleRoleValueChange(value) {
      this.setState({
        showPermGroups: this.shouldShowPermGroupByRole(value),
      });
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    shouldShowPermGroupByRole(role) {
      let ret = false;
      let userDomain = this.props.app.dm.user;
      switch (role) {
        case userDomain.ROLE_GROUP:
          ret = true;
          break;
      }
      return ret;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.user[editMethod](form.values);

        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "users";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "users"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='userForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          {/* <SelectMenuField
            useValuesOnly
            name="clientUid"
            label="Client"
            required="Select Client"
            items={this.props.formData.clientSelections}
          /> */}

          <TextInputField name='name' label='Name' required='Enter Name' />
          {/* <TextInputField
            name="username"
            label="Username"
            required="Enter Username"
          /> */}

          <TextInputField
            name='email'
            label='Email'
            validator='email'
            required='Enter Email'
          />

          <TextInputField
            name='password'
            label={this.isEditMode ? "Change Password" : "Password"}
            secure
            required={!this.isEditMode && "Enter Password"}
          />

          <SelectMenuField
            useValuesOnly
            name='role'
            label='Role'
            onValueChange={this.handleRoleValueChange}
            required='Select role'
            items={this.props.formData.roleSelections}
          />

          {this.state.showPermGroups &&
            this.props.formData.userPermissionGroupSelections.length > 0 && (
              <SelectMenuField
                useValuesOnly
                name='userPermissionGroupUid'
                label='Permission Group'
                required='Select Permission Group'
                items={this.props.formData.userPermissionGroupSelections}
              />
            )}
          <SwitchField name='active' label='Is Active' value={true} />
        </Form>
      );
    }
  }
);
