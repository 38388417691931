import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";

export default R14.connect(
  class ProjectPipelineDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.projectDomain = this.props.app.dm.project;
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.state = {};
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    get perms() {
      return this.props.app.dm.user.getPermissions(this.projectKey, "pipeline");
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        projectUid: { eq: this.props.projectUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };
      let res = await this.pipelineDomain.find(
        `
        uid
        name
        description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("pipelines");
    }
    get title() {
      return "Pipelines";
      // return this.props.type
      //   ? `${this.props.app.dm.project.getTypeLabel(this.props.type)} Pipelines`
      //   : "Pipelines";
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(row, dataTable) {
      if (window.confirm(`Are you sure you want to remove ${row.name}?`)) {
        await this.pipelineDomain.delete(row.uid);
        dataTable.refresh({ indicator: true });
        this.props.app.ui.snackBar.show({
          message: `${row.name} has been removed.`,
        });
      }
    }
    handleRowPress(row) {
      this.props.app.nav.to("projectPipeline", {
        uid: this.props.projectUid,
        key: this.props.app.dm.project.getKeyByType(this.props.projectType),
        pipelineUid: row.uid,
      });
    }
    render() {
      return (
        <DataTable
          name='projectPipelines'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          onRowPress={this.handleRowPress}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || this.title}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={
            this.perms.create
              ? [
                  <PopUpMenu
                    controlIcon='dotsVertical'
                    key='adminMenu'
                    direction='downLeft'
                    onSelect={(value, label) => {}}
                  >
                    <PopUpMenuItem
                      icon='add'
                      iconSize='small'
                      label='Create Pipeline'
                      value='test1'
                      to='projectPipelineCreate'
                      key='add'
                    />
                  </PopUpMenu>,
                ]
              : null
          }
          rowControlsRight={
            this.perms.edit || this.perms.delete
              ? (row, dataTable) => {
                  let ret = [];
                  if (this.perms.edit)
                    ret.push(
                      <IconButton
                        icon='edit'
                        size='small'
                        key='edit'
                        onPress={() =>
                          this.props.app.nav.to(
                            this.props.editAction || "projectPipelineEdit",
                            {
                              uid: this.props.projectUid,
                              key: this.props.app.dm.project.getKeyByType(
                                this.props.projectType
                              ),
                              pipelineUid: row.uid,
                            }
                          )
                        }
                      />
                    );
                  if (this.perms.create || this.perms.delete)
                    ret.push(
                      <PopUpMenu
                        controlIcon='dotsHorizontal'
                        controlIconSize='small'
                        key='projectPipelineMenu'
                        direction='downLeft'
                      >
                        {this.perms.create && (
                          <PopUpMenuItem
                            label='Create Copy'
                            icon='documents'
                            key='copy'
                            onPress={() =>
                              this.props.app.nav.to("projectPipelineCopy", {
                                uid: this.props.projectUid,
                                key: this.props.app.dm.project.getKeyByType(
                                  this.props.projectType
                                ),
                                pipelineUid: row.uid,
                              })
                            }
                          />
                        )}
                        {this.perms.delete && (
                          <PopUpMenuItem
                            icon='close'
                            label='Remove'
                            key='remove'
                            onPress={() =>
                              this.handleRemovePress(row, dataTable)
                            }
                          />
                        )}
                      </PopUpMenu>
                    );
                  return ret;
                }
              : null
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
});
