import R14 from "../core";

export default class ResourceStatusCheckDomain extends R14.Domain {
  constructor(key) {
    super();
    this.STATE_PASS = "PASS";
    this.STATE_FAIL = "FAIL";
    this.STATE_ERROR = "ERROR";
    this.STATE_PENDING = "PENDING";
    this.STATE_RUNNING = "RUNNING";
    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindResourceStatusChecks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceStatusCheckFilter) {
        resourceStatusChecks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.resourceStatusChecks;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_PASS:
        label = "Pass";
        break;
      case this.STATE_FAIL:
        label = "Fail";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
      case this.STATE_PENDING:
        label = "Pending";
        break;
      case this.STATE_RUNNING:
        label = "Running";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_PENDING:
      case this.STATE_RUNNING:
        color = "yellow";
        break;
      case this.STATE_FAIL:
      case this.STATE_ERROR:
        color = "red";
        break;
      case this.STATE_PASS:
        color = "green";
        break;
    }
    return color;
  }
  async run(uid) {
    let result = await this.api.mut(
      `
      mutation runResourceStatusCheck($uid: ID!) {
        runResourceStatusCheck(uid: $uid){
          uid
          state
        }
      }`,
      {
        uid: uid
      }
    );
    return result.runResourceStatusCheck;
  }
  async add(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;

    let res = await this.api.mutate(
      `
      mutation AddResourceStatusCheck($input: CreateResourceStatusCheckInput!) {
        createResourceStatusCheck(input: $input){
          resourceStatusCheck {
            uid
            statusCheck {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteResourceStatusCheck($uid: ID!) {
        deleteResourceStatusCheck(uid: $uid){
          resourceStatusCheck {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async fetchLog(uid) {
    let res = await this.api.qry(
      `
      query FetchResourceStatusCheckLog($uid: ID!) {
       resourceStatusCheck(uid: $uid){
          log
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.resourceStatusCheck && res.data.resourceStatusCheck.log
      ? res.data.resourceStatusCheck.log
      : "";
  }
  async fetchStatusCheckAddFormData(uid = null) {
    let qry = "";
    let qryVals = {
      statusCheckFilter: { clientUid: { eq: this.dm.userSession.clientUid } }
    };
    if (!uid) return {};
    qry = `
      query ResourceStatusCheckAddFormData($statusCheckFilter: StatusCheckFilter){
        statusChecks(filter:$statusCheckFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;
    qryVals.uid = uid;

    let res = await this.api.qry(qry, qryVals);
    let formData = {
      values: {
        resourceUid: uid
      },
      statusCheckSelections: res.statusChecks.nodes.map(val => ({
        label: val.name,
        value: val.uid
      }))
    };
    return formData;
  }
}
