import React from "react";
import R14, { View, Text } from "../core";
import LogViewer from "../components/LogViewer";
export default R14.connect(
  class AppModuleLogViewer extends React.Component {
    constructor(props) {
      super(props);
      this.textStreamer = this.textStreamer.bind(this);
      this.stream = null;
      this.appModuleLogSubscription = null;
    }
    async textStreamer(stream) {
      this.appModuleLogSubscription = await this.props.app.dm.appModule.onStreamLog(
        this.props.uid,
        this.props.logType,
        ({ text }) => stream(text)
      );
    }
    componentWillUnmount() {
      if (this.appModuleLogSubscription)
        this.appModuleLogSubscription.unsubscribe();
    }
    render() {
      return (
        <LogViewer
          style={this.props.style}
          height={this.props.height}
          textStreamer={this.textStreamer}
        />
      );
    }
  }
);
