import React from "react";
import StyleSheet from "./StyleSheet";
import View from "./View";
import Theme, { Colors } from "./Theme";
import R14 from "../R14";

export default R14.connect(
  class Header extends React.Component {
    constructor(props) {
      super(props);
      this.updateLayoutStyles();
    }
    updateLayoutStyles() {
      if (this.props.onLayoutStylesUpdate) {
        this.props.onLayoutStylesUpdate(this.layoutStyles);
      }
    }
    render() {
      return (
        <View {...this.props} htmlTag='header' style={styles.header}>
          <View style={styles.content}>
            {this.props.controlsLeft && (
              <View style={styles.controlsLeft}>{this.props.controlsLeft}</View>
            )}
            {this.props.title && (
              <View style={styles.title}>{this.props.title}</View>
            )}
            {this.props.controlsRight && (
              <View style={styles.controlsRight}>
                {this.props.controlsRight}
              </View>
            )}
          </View>
        </View>
      );
    }
    get layoutStyles() {
      return layoutStyles;
    }
  }
);
const layoutStyles = StyleSheet.create({
  offset: {
    marginTop: 64,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          marginTop: 56
        };
    }
  }
});
const styles = StyleSheet.create({
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    height: 64,
    // lineHeight: 64,
    elevation: 3,
    zIndex: 5,
    minWidth: 360,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 56
          // lineHeight: 56
        };
    }
  },
  content: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    // ...StyleSheet.padding(8, 4, 8, 4),
    margin: 0,
    alignItems: "center",
    backgroundColor: Theme.componentColors("header", {
      default: Colors.primary
    }).background,
    color: Theme.componentColors("header", {
      default: Colors.onPrimary
    }).onBackground,
    height: 64,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 56
        };
    }
  },
  title: {
    flex: 0,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 20,
    fontWeight: "500"
  },
  controlsLeft: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    alignItems: "center",
    opacity: 0.94
  },
  controlsRight: {
    flex: 0,
    flexDirection: "row",
    margin: 0,
    marginLeft: "auto",
    alignItems: "center",
    opacity: 0.94
  }
});
