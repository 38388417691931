import R14 from '../core';

export default class ClientDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
  }
  async find(fieldsStr, options = null) {
    if (!fieldsStr)
      throw new Error("Client Domain Find Error: No fields found");
    let result = await this.api.qry(
      `
      query FindClients($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ClientFilter) {
        clients(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.clients;
  }
  async fetchEditFormData(uid = null) {
    let res = uid? await this.api.qry(`
      query ClientEditFormData($uid: ID!) {
        client(uid: $uid){
          uid
          name
          description
          active
          repoCoreUsername
        }
      }`, {
        uid: uid
      }
    ) : {};
    return {
      values: res.client || {}
    };
  }
  async create(values) {
    let res = await this.api.mutate(`
      mutation CreateClient($input: CreateClientInput!) {
        createClient(input: $input){
          client {
            uid
            name
          }
        }
      }`, {
        input: values
      }
    );
    console.log(res);
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(`
      mutation UpdateClient($input: UpdateClientInput!) {
        updateClient(input: $input){
          client {
            uid
            name
          }
        }
      }`, {
        input: values
      }
    );
    console.log(res);
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(`
      mutation DeleteClient($uid: ID!) {
        deleteClient(uid: $uid){
          client {
            uid
            name
          }
        }
      }`, {
        uid: uid
      }
    );
    return true;
  }
}