import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  FadeView,
  SubmitButton
} from "../core";
import AddRemoveWidgetField from "../components/AddRemoveWidgetField";

export default R14.connect(
  class UserPermissionsEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleAccessValueChange = this.handleAccessValueChange.bind(this);
      this.handleKeyValueChange = this.handleKeyValueChange.bind(this);
      this.handlePermissionRenderer = this.handlePermissionRenderer.bind(this);
      this.handlePermissionValueFormat = this.handlePermissionValueFormat.bind(
        this
      );
      this.state = {
        hasChildKeySelections: (this.formData.values.key && this.formData.childKeySelections[this.formData.values.key] || null) ? true : false
      }
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    getChildKeySelections(){
      let form = this.props.app.ui.form('userPermissionsForm');
      let childKeySelections = this.formData.values.key && this.formData.childKeySelections[this.formData.values.key] || null;
      if(form && form.elmts.key.value){
        childKeySelections = this.formData.childKeySelections[form.elements.key.value] || null
      }
      return childKeySelections;
    }
    handleAccessValueChange(value, elmt) {
      if (value === this.props.app.dm.userPermission.PERMISSION_FULL) {
        if (elmt.value.length > 1)
          elmt.value = [this.props.app.dm.userPermission.PERMISSION_FULL];
      } else if (
        elmt.value.includes(this.props.app.dm.userPermission.PERMISSION_FULL)
      ) {
        let nValues = [...elmt.value];
        nValues.splice(
          nValues.indexOf(this.props.app.dm.userPermission.PERMISSION_FULL),
          1
        );
        elmt.value = nValues;
      }
    }
    handleKeyValueChange(value, label, elmt, form) {
      let childKeySelections = this.getChildKeySelections();
      if(form.elmts.childKey){
        form.elmts.childKey.setItems(childKeySelections);
      } 
      this.setState({
        hasChildKeySelections: childKeySelections ? true : false
      });
    }
    async handleSubmit(form) {
      console.log("SUBMIT SUBMIT SUBMIT", form.values);
      // let editMethod = this.isEditMode ? "update" : "create";
      // let ret = null;
      // this.props.app.ui.progressIndicator.show();
      // try {
      //   let isSuccess = await this.props.app.dm.user[editMethod](form.values);

      //   if (isSuccess) {
      //     let dataTable = this.props.refreshDataTable || "users";
      //     this.props.app.ui.dataTable.exists(dataTable) &&
      //       (await this.props.app.ui.dataTable(dataTable).refresh());
      //     ret = <Redirect to={this.props.redirect || "users"} />;
      //   }
      // } catch (err) {
      //   form.addError(err.message);
      // }
      // this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      // return ret;
    }
    handlePermissionValueFormat(values) {
      console.log("handlePermissionValueFormat", values);
      return {
        key: values.key,
        childKey: values.childKey,
        access: values.access,
      };
    }
    handlePermissionRenderer(values) {
      console.log('handlePermissionRenderer',values);
    }
    render() {
      return (
        <Form
          name='userPermissionsForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='userUid' />
          <SelectMenuField
            useValuesOnly
            name='role'
            label='Role'
            required='Select role'
            items={this.props.formData.roleSelections}
          />
          <AddRemoveWidgetField
            name='permissions'
            label='Permissions'
            formName='userPermissionsForm'
            itemValueFormatter={this.handlePermissionValueFormat}
            itemRenderer={this.handlePermissionRenderer}
          >
            <SelectMenuField
              useValuesOnly
              name='key'
              label='Type'
              required='Select permission'
              onValueChange={this.handleKeyValueChange}
              items={this.props.formData.keySelections}
            />
             <FadeView visible={this.state.hasChildKeySelections}>
              <SelectMenuField
                useValuesOnly
                name='childKey'
                label='Sub Type'
                items={this.getChildKeySelections()}
                helper='Optional'
              />
            </FadeView>
            <SelectMenuField
              useValuesOnly
              name='access'
              label='Access'
              required='Select access levels'
              onValueChange={this.handleAccessValueChange}
              multiple
              items={this.props.formData.accessSelections}
            />
          </AddRemoveWidgetField>
        </Form>
      );
    }
  }
);
