import R14 from "../core";
import ResourceInstanceDomain from "./ResourceInstanceDomain";

export default class ResourceDomain extends R14.DomainInstances {
  constructor(key) {
    super();
    this.TYPE_EC2_INSTANCE = "EC2_INST";
    this.TYPE_EC2_VOLUME = "EC2_VOL";
    this.TYPE_EFS_FILESYSTEM = "EFS_FILESYSTEM";
    this.TYPE_ECS_CLUSTER = "ECS_CLUSTER";
    this.TYPE_AWS_S3_BUCKET = "AWS_S3_BUCKET";
    this.TYPE_AWS_FSX_LUSTRE_FILESYSTEM = "AWS_XFS_LUSTRE_FILESYSTEM";
    this.TYPE_AZURE_VM = "AZURE_VM";
    this.TYPE_R14_COMPUTER = "R14_COMPUTER";
    this.COMMAND_START = "START";
    this.COMMAND_STOP = "STOP";
    this.COMMAND_REBOOT = "REBOOT";

    this.METRIC_CPU_UTILIZATION = "CPU_UTILIZATION";
    this.METRIC_MEMORY_UTILIZATION = "MEMORY_UTILIZATION";
    this.METRIC_DISK_READ_OPS = "DISK_READ_OPS";
    this.METRIC_DISK_WRITE_OPS = "DISK_WRITE_OPS";
    this.METRIC_DISK_READ_BYTES = "DISK_READ_BYTES";
    this.METRIC_DISK_WRITE_BYTES = "DISK_WRITE_BYTES";
    this.METRIC_NETWORK_IN = "NETWORK_IN";
    this.METRIC_NETWORK_OUT = "NETWORK_OUT";
    this.METRIC_CLIENT_CONNECTIONS = "CLIENT_CONNECTIONS";
    this.METRIC_DATA_WRITE_IO_BYTES = "DATA_WRITE_IO_BYTES";
    this.METRIC_DATA_READ_IO_BYTES = "DATA_READ_IO_BYTES";

    this.PERIOD_30_MINS = "30_MIN";
    this.PERIOD_1_HOUR = "1_HOUR";
    this.PERIOD_6_HOURS = "6_HOURS";
    this.PERIOD_1_DAY = "1_DAY";
    this.PERIOD_3_DAYS = "3_DAYS";
    this.PERIOD_7_DAYS = "7_DAYS";
    this.PERIOD_2_WEEKS = "2_WEEKS";
    this.PERIOD_30_DAYS = "30_DAYS";

    this.SSH_STATE_READY = "READY";
    this.SSH_STATE_ERROR = "ERROR";
    this.SSH_STATE_NO_KEY = "NO_KEY";

    this.state = {};
  }

  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let resource = new ResourceInstanceDomain(uid, options);
    await resource.init();
    this.addInstance(uid, resource);
    return resource;
  }

  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }

  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);

    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    /** @todo handle filter state with terminated is state given */
    if (!options.filter.state) options.filter.state = { neq: "terminated" };

    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    let result = await this.api.qry(
      `
      query FindResources($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceFilter) {
        resources(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }
      `,
      options
    );
    return result.data.resources;
  }
  async findLogs(uid, fieldsStr, options = {}) {
    options.uid = uid;

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindResourceLogs($uid: ID!, $page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: LogFilter) {
        resource(uid: $uid) {
          logs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
            totalCount @include(if: $totalCount)
            nodes {
              ${fieldsStr}
            }
          }
          uid
          name
        }
      }`,
      options
    );
    return res.data.resource.logs;
  }

  async fetchLog(uid, logUid) {
    let res = await this.api.qry(
      `
      query GetResourceLog($uid: ID!, $logUid: ID!) {
       resourceLog(uid: $uid, logUid: $logUid){
          name
          description
          path
          text
        }
      }`,
      {
        uid: uid,
        logUid: logUid,
      }
    );
    return res.data.resourceLog;
  }
  async removeLog(values) {
    await this.api.mutate(
      `
      mutation RemoveResourceLog($input: UpdateResourceInput!) {
        updateResource(input: $input){
          resource {
            uid
            name
          }
          success
        }
      }`,
      {
        input: {
          logs: {
            remove: [values.logUid],
          },
          uid: values.resourceUid,
        },
      }
    );
    return true;
  }
  async addLog(values) {
    console.log("add log with values", values);
    let res = await this.api.mutate(
      `
      mutation AddResourceLog($input: UpdateResourceInput!) {
        updateResource(input: $input){
          resource {
            uid
            name
          }
          success
        }
      }`,
      {
        input: {
          logs: {
            add: [values.logUid],
          },
          uid: values.resourceUid,
        },
      }
    );
    return true;
  }
  async fetchLogAddFormData(uid = null) {
    let qry = "";
    if (!uid) return {};
    let qryVals = {
      uid: uid,
      logFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
    };
    qry = `
      query ResourceLogAddFormData($logFilter: LogFilter){
        logs(filter: $logFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    let formData = {
      values: {
        resourceUid: uid,
      },
      logSelections: res.logs.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }
  async fetchSshKeyManageFormData(uid = null) {
    let qry = "";
    if (!uid) return {};
    let qryVals = {
      resourceUid: uid,
      sshKeyFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
    };
    qry = `
      query ResourceSshKeyManageFormData($resourceUid: ID!, $sshKeyFilter: SshKeyFilter){
        resource(uid: $resourceUid){
          sshKeyUid
        }
        sshKeys(filter: $sshKeyFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    let formData = {
      values: {
        resourceUid: uid,
        sshKeyUid: res.data.resource.sshKeyUid || null,
      },
      sshKeySelections: res.data.sshKeys.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }
  async fetchAllMetricsData(resource, options = {}) {
    return await this.fetchMetricsData(
      resource.uid,
      this.getMetricTypesByType(resource.type),
      options
    );
  }
  async fetchMetricData(uid, metric, options) {
    let res = await this.fetchMetricsData(uid, [metric], options);
    return res[metric] ? res[metric] : { nodes: [] };
  }
  async fetchMetricsData(uid, metrics, options = {}) {
    let res = await this.api.qry(
      `
      query GetResourceMetrics($uid: ID!, $resourceDockerTaskUid: ID, $metrics: [String!]!, $period: Int, $startTime: Date, $endTime: Date) {
       resourceMetrics(uid: $uid, resourceDockerTaskUid: $resourceDockerTaskUid, metrics: $metrics, period: $period, startTime: $startTime, endTime: $endTime){
          metrics {
            metric
            nodes {
              name
              data {
                x
                y
              }
            }
          }
        }
      }`,
      {
        uid: uid,
        resourceDockerTaskUid: options.resourceDockerTaskUid || null,
        metrics: metrics,
        period: options.period,
        startTime: options.startTime || null,
        endTime: options.endTime || null,
      }
    );

    let ret = {};
    metrics.forEach((metric) => (ret[metric] = { metric: metric, nodes: [] }));
    res.data &&
      res.data.resourceMetrics &&
      res.data.resourceMetrics.metrics &&
      res.data.resourceMetrics.metrics.forEach(
        (metric) => (ret[metric.metric] = metric)
      );
    return ret;
  }
  async fetchProcessMetricData(uid, metric, options = {}) {
    let res = await this.fetchProcessMetricsData(uid, [metric], options);
    return res[metric] ? res[metric] : { nodes: [] };
  }
  async fetchProcessMetricsData(uid, metrics, options = {}) {
    let res = await this.api.qry(
      `
      query GetProcessResourceMetrics($uid: ID!, $resourceDockerTaskUid: ID, $metrics: [String!]!, $period: Int, $startTime: Date, $endTime: Date) {
        resourceProcessMetrics(uid: $uid, resourceDockerTaskUid: $resourceDockerTaskUid, metrics: $metrics, period: $period, startTime: $startTime, endTime: $endTime){
          metrics {
            metric
            nodes {
              name
              data {
                x
                y
              }
            }
          }
        }
      }`,
      {
        uid: uid,
        resourceDockerTaskUid: options.resourceDockerTaskUid || null,
        metrics: metrics,
        period: options.period,
        startTime: options.startTime || null,
        endTime: options.endTime || null,
      }
    );
    let ret = {};
    metrics.forEach((metric) => (ret[metric] = { metric: metric, nodes: [] }));
    res.data.resourceProcessMetrics.metrics &&
      res.data.resourceProcessMetrics.metrics.forEach(
        (metric) => (ret[metric.metric] = metric)
      );
    return ret;
  }
  async fetchEditFormData(uid) {
    if (!uid) return {};
    let res = await this.api.qry(
      `
      query ResourceFormData($uid: ID!){
        resource(uid: $uid){
          uid
          name
        },
      }
    `,
      {
        uid: uid,
      }
    );
    return {
      values: {
        uid: uid,
        name: res.data.resource.name,
      },
    };
  }
  async get(uid, { metrics = false } = {}) {
    let res = await this.api.qry(
      `
      query GetResource($uid: ID!) {
       resource(uid: $uid){
          uid
          name
          description
          type
          state
          publicDnsName
          publicIpAddress
          lastAgentSyncAt
          ipAddress
          resourceId
          sshKeyUid
          sshState
          sshKey {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return {
      ...res.data.resource,
      metrics: res.data.resourceMetrics || null,
    };
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateResource($input: UpdateResourceInput!) {
        updateResource(input: $input){
          resource {
            uid
            name
            type
            state
            publicDnsName
            publicIpAddress
            resourceId
            sshKeyUid
            sshKey {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values,
      }
    );
    return res.data.updateResource.resource || null;
  }
  async fetchDataGridFiltersFormData() {
    return {
      typeSelections: this.getTypeSelections(),
      cloudAccessKeySelections: await this.getCloudAccessKeySelections(),
    };
  }
  async getCloudAccessKeySelections() {
    let selections = await this.dm.cloudAccessKey.find(
      `
      uid
      name
    `,
      {
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
      }
    );
    return selections && selections.nodes
      ? selections.nodes.map((val) => ({ label: val.name, value: val.uid }))
      : null;
    console.log(selections);
  }
  getTypeSelections() {
    return [
      {
        label: this.getTypeLabel(this.TYPE_EC2_INSTANCE),
        value: this.TYPE_EC2_INSTANCE,
      },
      {
        label: this.getTypeLabel(this.TYPE_AWS_FSX_LUSTRE_FILESYSTEM),
        value: this.TYPE_AWS_FSX_LUSTRE_FILESYSTEM,
      },
      {
        label: this.getTypeLabel(this.TYPE_AWS_S3_BUCKET),
        value: this.TYPE_AWS_S3_BUCKET,
      },
      {
        label: this.getTypeLabel(this.TYPE_EFS_FILESYSTEM),
        value: this.TYPE_EFS_FILESYSTEM,
      },
      {
        label: this.getTypeLabel(this.TYPE_ECS_CLUSTER),
        value: this.TYPE_ECS_CLUSTER,
      },
      {
        label: this.getTypeLabel(this.TYPE_AZURE_VM),
        value: this.TYPE_AZURE_VM,
      },
      {
        label: this.getTypeLabel(this.TYPE_R14_COMPUTER),
        value: this.TYPE_R14_COMPUTER,
      },
    ];
  }
  getTypeLabel(type) {
    let label = null;
    switch (type) {
      case this.TYPE_EC2_INSTANCE:
        label = "AWS EC2";
        break;
      case this.TYPE_EC2_VOLUME:
        label = "EC2 Volume";
        break;
      case this.TYPE_EFS_FILESYSTEM:
        label = "AWS EFS";
        break;
      case this.TYPE_AWS_S3_BUCKET:
        label = "AWS S3 Bucket";
        break;
      case this.TYPE_ECS_CLUSTER:
        label = "AWS ECS";
        break;
      case this.TYPE_AWS_FSX_LUSTRE_FILESYSTEM:
        label = "AWS FSX LUSTRE";
        break;
      case this.TYPE_AZURE_VM:
        label = "Azure VM";
        break;
      case this.TYPE_R14_COMPUTER:
        label = "R14 Agent Computer";
        break;
    }
    return label;
  }
  getMetricTypesByType(type, options = {}) {
    let ret = [];
    switch (type) {
      case this.TYPE_AZURE_VM:
      case this.TYPE_EC2_INSTANCE:
      case this.TYPE_ECS_CLUSTER:
      case this.TYPE_R14_COMPUTER:
        ret.push(this.METRIC_CPU_UTILIZATION);
        if (type === this.TYPE_R14_COMPUTER || options.resourceDockerTaskUid)
          ret.push(this.METRIC_MEMORY_UTILIZATION);
        ret = [
          ...ret,
          ...[
            this.METRIC_DISK_READ_OPS,
            this.METRIC_DISK_WRITE_OPS,
            this.METRIC_DISK_READ_BYTES,
            this.METRIC_DISK_WRITE_BYTES,
            this.METRIC_NETWORK_IN,
            this.METRIC_NETWORK_OUT,
          ],
        ];
        break;
      case this.TYPE_EFS_FILESYSTEM:
        ret = [
          this.METRIC_CLIENT_CONNECTIONS,
          this.METRIC_DATA_WRITE_IO_BYTES,
          this.METRIC_DATA_READ_IO_BYTES,
        ];
        break;
    }
    return ret;
  }
  getStateLabel(state) {
    let label = this.utils.str.capitalize(state);
    switch (state.toLowerCase()) {
      case "pending":
        label = "Pending";
        break;
      case "running":
        label = "Running";
        break;
      case "available":
        label = "Available";
        break;
      case "active":
        label = "Active";
        break;
      case "initializing":
        label = "Initializing";
        break;
      case "rebooting":
        label = "Rebooting";
        break;
      case "starting":
        label = "starting";
        break;
      case "shutting-down":
        label = "Shutting Down";
        break;
      case "terminated":
        label = "Terminated";
        break;
      case "stopping":
        label = "Stopping";
        break;
      case "stopped":
        label = "Stopped";
        break;
      case "deallocating":
        label = "Deallocating";
        break;
      case "deallocated":
        label = "Deallocated";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state.toLowerCase()) {
      case "stopping":
      case "shutting-down":
      case "pending":
      case "rebooting":
      case "initializing":
      case "deallocating":
      case "creating":
      case "updating":
        color = "yellow";
        break;
      case "running":
      case "available":
      case "active":
        color = "green";
        break;
    }
    return color;
  }
  async updateState(uid, command) {
    let res = await this.api.mut(
      `
      mutation UpdateResourceState($uid: ID!, $command: ResourceCommandEnum!) {
        updateResourceState(uid: $uid, command: $command){
          uid
          name
          type
          state
          publicDnsName
          publicIpAddress
          resourceId
          sshKeyUid
          sshKey {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
        command: command,
      }
    );
    return res.data.updateResourceState;
  }
  async onUpdate(uid, callback) {
    return await this.api.subscribe(
      `
      subscription OnUpdateResource($uid: ID!) {
        onUpdateResource(uid: $uid){
          uid
          name
          type
          state
          publicDnsName
          publicIpAddress
          resourceId
          sshKeyUid
          sshKey {
            uid
            name
          }
         }
       }`,
      {
        uid: uid,
      },
      (res) => {
        callback(res.data.onUpdateResource);
      }
    );
  }
  async sync() {
    return await this.api.mut(`
      mutation SyncResources {
        syncResources {
          success
        }
      }`);
  }
  getSshStateLabel(state) {
    let label = null;
    switch (state) {
      case this.SSH_STATE_ERROR:
        label = "Error";
        break;
      case this.SSH_STATE_READY:
        label = "Ready";
        break;
      case this.SSH_STATE_NO_KEY:
        label = "No Key";
        break;
    }
    return label;
  }
  getSshStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.SSH_STATE_NO_KEY:
        color = "yellow";
        break;
      case this.SSH_STATE_ERROR:
        color = "red";
        break;
      case this.SSH_STATE_READY:
        color = "green";
        break;
    }
    return color;
  }
  getProcessMetricSelections() {
    return [
      {
        label: this.getMetricTypeLabel(this.METRIC_CPU_UTILIZATION),
        value: this.METRIC_CPU_UTILIZATION,
      },
      {
        label: this.getMetricTypeLabel(this.METRIC_MEMORY_UTILIZATION),
        value: this.METRIC_MEMORY_UTILIZATION,
      },
    ];
  }
  getMetricSelections(type, options = {}) {
    return this.getMetricTypesByType(type, options).map((val) => ({
      label: this.getMetricTypeLabel(val),
      value: val,
    }));
    // return [
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_CPU_UTILIZATION),
    //     value: this.METRIC_CPU_UTILIZATION
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DISK_READ_OPS),
    //     value: this.METRIC_DISK_READ_OPS
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DISK_WRITE_OPS),
    //     value: this.METRIC_DISK_WRITE_OPS
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DISK_READ_BYTES),
    //     value: this.METRIC_DISK_READ_BYTES
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DISK_WRITE_BYTES),
    //     value: this.METRIC_DISK_WRITE_BYTES
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_NETWORK_IN),
    //     value: this.METRIC_NETWORK_IN
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_NETWORK_OUT),
    //     value: this.METRIC_NETWORK_OUT
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_CLIENT_CONNECTIONS),
    //     value: this.METRIC_CLIENT_CONNECTIONS
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DATA_READ_IO_BYTES),
    //     value: this.METRIC_DATA_READ_IO_BYTES
    //   },
    //   {
    //     label: this.getMetricTypeLabel(this.METRIC_DATA_WRITE_IO_BYTES),
    //     value: this.METRIC_DATA_WRITE_IO_BYTES
    //   }
    // ];
  }
  getMetricTypeLabel(type) {
    let ret = "";
    switch (type) {
      case this.METRIC_CPU_UTILIZATION:
        ret = "CPU";
        break;
      case this.METRIC_MEMORY_UTILIZATION:
        ret = "Memory";
        break;
      case this.METRIC_DISK_READ_OPS:
        ret = "Disk Read (ops)";
        break;
      case this.METRIC_DISK_WRITE_OPS:
        ret = "Disk Write (Ops)";
        break;
      case this.METRIC_DISK_READ_BYTES:
        ret = "Disk Read (bytes)";
        break;
      case this.METRIC_DISK_WRITE_BYTES:
        ret = "Disk Write (bytes)";
        break;
      case this.METRIC_NETWORK_IN:
        ret = "Network In";
        break;
      case this.METRIC_NETWORK_OUT:
        ret = "Network Out";
        break;
      case this.METRIC_CLIENT_CONNECTIONS:
        ret = "Client Connections";
        break;
      case this.METRIC_DATA_READ_IO_BYTES:
        ret = "Data Read IO (Bytes)";
        break;
      case this.METRIC_DATA_WRITE_IO_BYTES:
        ret = "Data Write IO (Bytes)";
        break;
    }
    return ret;
  }
  getPeriodSelections() {
    return [
      {
        label: this.getPeriodLabel(this.PERIOD_30_MINS),
        value: this.PERIOD_30_MINS,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_1_HOUR),
        value: this.PERIOD_1_HOUR,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_6_HOURS),
        value: this.PERIOD_6_HOURS,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_1_DAY),
        value: this.PERIOD_1_DAY,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_3_DAYS),
        value: this.PERIOD_3_DAYS,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_7_DAYS),
        value: this.PERIOD_7_DAYS,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_2_WEEKS),
        value: this.PERIOD_2_WEEKS,
      },
      {
        label: this.getPeriodLabel(this.PERIOD_30_DAYS),
        value: this.PERIOD_30_DAYS,
      },
    ];
  }
  getPeriodLabel(period) {
    let ret = "";
    switch (period) {
      case this.PERIOD_30_MINS:
        ret = "30 Mins";
        break;
      case this.PERIOD_1_HOUR:
        ret = "1 Hour";
        break;
      case this.PERIOD_6_HOURS:
        ret = "6 Hours";
        break;
      case this.PERIOD_1_DAY:
        ret = "1 Day";
        break;
      case this.PERIOD_3_DAYS:
        ret = "3 Days";
        break;
      case this.PERIOD_7_DAYS:
        ret = "7 Days";
        break;
      case this.PERIOD_2_WEEKS:
        ret = "2 Weeks";
        break;
      case this.PERIOD_30_DAYS:
        ret = "30 Days";
        break;
    }
    return ret;
  }
  getPeriodFilterInfo(period) {
    let val = {};
    switch (period) {
      case this.PERIOD_30_MINS:
        val = {
          interval: 60 * 5,
          period: 60 * 30,
        };
        break;
      case this.PERIOD_1_HOUR:
        val = {
          interval: 60 * 5,
          period: 60 * 60 * 1,
        };
        break;
      case this.PERIOD_6_HOURS:
        val = {
          interval: 60 * 5,
          period: 60 * 60 * 6,
        };
        break;
      case this.PERIOD_1_DAY:
        val = {
          interval: 60 * 15,
          period: 60 * 60 * 24,
        };
        break;
      case this.PERIOD_3_DAYS:
        val = {
          interval: 60 * 60,
          period: 60 * 60 * 24 * 3,
        };
        break;
      case this.PERIOD_7_DAYS:
        val = {
          interval: 60 * 60 * 6,
          period: 60 * 60 * 24 * 7,
        };
        break;
      case this.PERIOD_2_WEEKS:
        val = {
          interval: 60 * 60 * 12,
          period: 60 * 60 * 24 * 14,
        };
        break;
      case this.PERIOD_30_DAYS:
        val = {
          interval: 60 * 60 * 12,
          period: 60 * 60 * 24 * 30,
        };
        break;
      default:
        throw new Error("Period not found.");
    }
    let startTime = new Date();
    startTime.setMinutes(startTime.getMinutes() - val.period / 60);
    let ret = {
      period: val.interval,
      startTime: startTime,
    };
    return ret;
  }
}
