import React from "react";
import R14, {
  StyleSheet,
  HiddenInputField,
  BackButton,
  SwitchField,
  Text,
  View,
  SubmitButton,
  TextInputField,
  ScreenLayout,
  Colors,
  Theme
} from "../core";
import CenteredForm from "../components/CenteredForm";
import ThemeDataTable from "../components/ThemeDataTable";

export default R14.connect(
  class ThemesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        isSuccess: null
      };
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(accountForm) {
      // let values = accountForm.values;
      // let updateValues = {
      //   uid: values.uid,
      //   name: values.name,
      //   email: values.email
      // };
      // if(values.newPassword) updateValues.password = values.newPassword
      // this.props.app.ui.progressIndicator.show();
      // let isSuccess = await this.props.app.dm.user.update(updateValues);
      // console.log(this.props.app.ui.theme);
      // Theme.setColorTheme('light');
      // if(isSuccess){
      //   this.props.app.dm.userSession.setName(values.name);
      //   //this.props.app.dm.userSession.setEmail(values.email);
      //   this.setState({
      //     success: isSuccess
      //   });
      // }
      // this.props.app.ui.progressIndicator.hide({timeout: 750});
    }
    render() {
      return (
        <ScreenLayout
        floatingActionButton={{
          to: "themeCreate",
          icon: "add"
        }}
        scrollEnabled
      >
        <ThemeDataTable />
      </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  success: {
    paddingBottom: 24
  },
  successText: {
    color: Colors.primary
  }
});
