import React from "react";
import View from "../View";
import Text from "../Text";
import ScrollView from "../ScrollView";
import StyleSheet from "../StyleSheet";

export default class FileInputBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this._clicked = false;
    //this._fileName = null;
    this.ref = React.createRef();
  }

  handleChange(event) {
    let file = null;
    if (event.target && event.target.files && event.target.files.length) {
      file = event.target.files[0];
      this._fileName =
        event.target && event.target.files && event.target.files.length
          ? event.target.files[0].name
          : null;
    }
    else this._fileName = null;
    this.props.onValueChange && this.props.onValueChange(event.target.files[0]);
    this._clicked = false;
  }
  handleFocus(event) {
    !this._clicked && this.props.onFocus && this.props.onFocus(event);
    this._clicked = false;
  }
  handleBlur(event) {
    !this._clicked && this.props.onBlur && this.props.onBlur(event);
  }
  handleClick(event) {
    this.props.onPress && this.props.onPress(event);
    this._clicked = true;
  }
  renderLabel(){
    return this.props.label && <Text>{this.props.label}</Text>;
  }


  render() {
    console.log(this.props);
    return (
      <View style={[styles.fileInput, this.props.style]}>
        <input
          type='file'
          name={this.props.name}
          ref={this.ref}
          placeholder={this.props.placeholder || null}
          value={''}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onClick={this.handleClick}
          onBlur={this.handleBlur}
          autoFocus={this.props.autoFocus || null}
          className={StyleSheet.className([styles.input])}
        />
        {this.renderLabel()}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  fileInput: {
    position: "relative",
    overflow: "hidden",
  },
  input: {
    position: "absolute",
    height: "300%",
    width: "300%",
    top: -50,
    left: -50,
    cursor: 'pointer',
  },
});
