import React from "react";
import R14, {
  StyleSheet,
  Colors,
  Modal,
  View,
  Text,
  Button,
  Surface,
  IconButton,
  SectionHeader,
} from "../core";

export default R14.connect(
  class TestScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleClosePress = this.handleClosePress.bind(this);
      this.handleShowModalPress = this.handleShowModalPress.bind(this);
      this.state = {
        modalVisible: false,
      };
    }
    handleShowModalPress() {
      this.setState({
        modalVisible: true,
      });
    }
    handleClosePress() {
      this.setState({
        modalVisible: false,
      });
    }
    render() {
      return (
        <View>
          <Modal
            animationType='fade'
            backdropStyle={styles.backdrop}
            onBackdropPress={this.handleClosePress}
            transparent={true}
            visible={this.state.modalVisible}
            style={styles.modal}
            onRequestClose={() => {}}
            unmountOnExit
            backdrop
            scrollEnabled
          >
            <Surface
              title='Test Modal'
              titleStyle={[styles.title]}
              titleControlsRight={[,]}
              style={styles.dialog}
              elevation={12}
            >
              <View style={styles.header}>
                <View style={styles.title}>
                  <SectionHeader style={styles.sectionHeader} level={2}>
                    Test Modal
                  </SectionHeader>
                </View>
                <View style={styles.titleControlsRight}>
                  <IconButton icon='close' onPress={this.handleClosePress} />
                </View>
              </View>
              <View style={styles.content}>
                <Text>Hello World</Text>
              </View>
              <View style={styles.buttons}>
                <Button
                  title='cancel'
                  variant='text'
                  onPress={this.handleClosePress}
                  style={styles.cancelButton}
                />
                <Button title='ok' variant="text" onPress={this.handleClosePress} />
              </View>
            </Surface>
          </Modal>
          <Button
            title='Show Modal'
            onPress={this.handleShowModalPress}
            style={styles.showModalButton}
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  showModalButton: {
    ...StyleSheet.margin(16, 0, 0, 16),
  },
  modal: {},
  backdrop: {
    backgroundColor: StyleSheet.color(Colors.background).rgba(0.8),
  },
  dialog: {
    flex: 0,
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 4,
    ...StyleSheet.padding(0),
    minWidth: 300,
    maxWidth: 640,
    minHeight: 300,
    marginTop: 80,
    marginBottom: 80,
    zIndex: 2,
    backgroundColor: Colors.background,
    overflow: "hidden",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          maxWidth: null,
          width: "100%",
          minHeight: "100%",
          borderRadius: 0,
          ...StyleSheet.margin(0),
        };
    },
  },
  header: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    elevation: 2,
    marginBottom: 16
  },
  titleControlsRight: {
    ...StyleSheet.padding(0, 4, 0, 16),
  },
  title: {
    ...StyleSheet.padding(0, 16, 0, 16),
  
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: 600,
    ...StyleSheet.padding(0),
    ...StyleSheet.margin(0)
  },
  content: {
    alignSelf: "flex-start",
    ...StyleSheet.padding(0, 16, 0, 16),
    marginBottom: "auto",
  },
  buttons: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 52,
    ...StyleSheet.padding(0, 8, 0, 8),
  },
  cancelButton: {
    marginRight: 8,
   
  },
});
