import React from "react";
import R14, {
  ScreenLayout
} from "../core";
import TimesheetDataTable from "../components/TimesheetDataTable";

export default R14.connect(
  class TimesheetScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
        floatingActionButton={{
          to: "timesheetCreate",
          icon: "add"
        }}
        scrollEnabled
      >
        <TimesheetDataTable />
      </ScreenLayout>
      );
    }
  }
);