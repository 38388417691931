import R14NavigationBase from "./base/R14NavigationBase";
import R14Portal from "./R14Portal";
import R14Route from "./R14Route";

export default class R14Navigation extends R14NavigationBase {
  constructor() {
    super();
    this._routesConfig = null;
    this._routes = {};
    this._screens = {};
    // url / deep link paths
    this._paths = {};
    this._navigators = {};
    this._portals = {};
    this._initialScreen = null;
    this._history = null;
    this._location = null;
    this._match = null;
    this._activeRouteParams = {};
    // this._route = {};
    this.state = {
      activeRoute: null,
    };
    this._currRoute = null;
    this._prevRoute = null;
  }
  setActiveRoute(route) {
    if (route !== null && route instanceof R14Route === false)
      throw new Error("Active Route Error. Bad route type.");

    if (route && this.currRoute) {
      if (route.path === this._currRoute.path) return;
    }
    // if (route && this._currRoute && this._currRoute.name === route.name) return;
    // if (route && this._currRoute && this._currRoute.name === route.name) return;

    this._prevRoute = this._currRoute;
    this._currRoute = route;

    this.setState({
      activeRouteName: route.name || null,
      activeRoutePath: route.path || null,
    });
  }
  get currRoute() {
    return this._currRoute;
  }
  get prevRoute() {
    return this._prevRoute;
  }
  /** @todo Should this require the app context? */
  async handleBeforeLoad(appContext) {
    let ret = true;
    let shouldActionLoadMethod =
      appContext._metadata.actions.shouldActionLoad ||
      this._navigators["r14NavRoot"].shouldActionLoad;
    let actionWillLoadMethod =
      appContext._metadata.actions.actionWillLoad ||
      this._navigators["r14NavRoot"].actionWillLoad;
    if (shouldActionLoadMethod) {
      ret = await shouldActionLoadMethod({
        to: this.currRoute,
        from: this.prevRoute,
        app: appContext,
      });
    }
    if (ret === true && actionWillLoadMethod)
      await actionWillLoadMethod({
        to: this.currRoute,
        from: this.prevRoute,
        app: appContext,
      });
    return ret;
  }
  /** TODO Change to active route name. */
  get activeRouteName() {
    return this.state.activeRouteName;
  }
  get activeRoutePath() {
    return this.state.activeRoutePath;
  }
  screens(key) {
    return this._screens[key] || null;
  }
  portals(name) {
    return this._portals[name] || null;
  }
  addPortal(name, options = {}) {
    this._portals[name] = new R14Portal(name, options);
  }
  removePortal(name) {
    if (this._portals[name]) delete this._portals[name];
  }
  navigators(key) {
    return this._navigators[key] || null;
  }
  initializeRoutes(routes) {
    this._routesConfig = typeof routes === "function" ? routes() : routes;
    this._initNavigation(this._routesConfig);

    // let out = this._printRoutes("root", this._routesConfig);
    // console.log(out);
  }

  _printProps(props) {
    let out = "";
    for (let prop in props) {
      if (prop === "props") {
        console.log(prop, props[prop]);
        throw new Error("Error: Navigation prop error");
      }
      if (prop === "type" || prop === "routes") continue;
      else if (prop.endsWith("Navigator")) {
        out += this._printProps(props[prop]);
        continue;
      }
      out += `${prop}=`;
      switch (typeof props[prop]) {
        case "string":
          out += `'${props[prop]}'\n`;
          break;
        case "object":
          out += `{${JSON.stringify(props[prop])}}\n`;
          break;
        case "boolean":
          out += `{${props[prop] ? "true" : "false"}}\n`;
          break;
        case "function":
          console.log("FUNCTION", props[prop].toString());
          out += `{${props[prop].toString()}}\n`;
          break;
        default:
          console.log("add type prop", prop, typeof props[prop]);
          throw new Error("sldkjf lsdkjf lksdjf");
      }
    }
    return out;
  }
  _printRoute(name, route) {
    let out = `<Route\nname='${name}'\n`;
    out += this._printProps(route);
    out += `/>\n`;
    return out;
  }
  _printRoutes(name, navigator = null) {
    let out = "";
    let nav = {
      name: name,
      type: null,
      props: {},
    };
    if (!navigator) {
      navigator = this._routesConfig;
      nav.type = "root";
    }
    for (let prop in navigator) {
      if (prop === "routes") continue;
      nav.props[prop] = navigator[prop];
    }

    console.log("NAVIGATOR START", nav.name);
    let navTag = null;
    switch (navigator.type || "stack") {
      case "stack":
        navTag = "StackNavigator";
        break;
      case "tab":
        navTag = "TabNavigator";
        break;
      case "drawer":
        navTag = "DrawerNavigator";
        break;
      case "modal":
        navTag = "ModalNavigator";
        break;
      default:
        console.log("TYPE", navigator.type, navigator);
        throw new Error("LKSDJF LKSDJ FLKJSD LKF JSDF");
    }

    out += `<${navTag}\n`;
    out += this._printProps(navigator);
    out += `>\n`;

    if (navigator.routes) {
      for (let routeName in navigator.routes) {
        let route = navigator.routes[routeName];
        if (route.type && route.routes && Object.keys(route.routes).length) {
          console.log(
            "route.type, route.routes",
            route.type,
            route.routes,
            Object.keys(route.routes).length
          );
          out += this._printRoutes(routeName, route);
          continue;
        }
        out += this._printRoute(routeName, route);
        console.log("ROUTE", routeName);
      }
    }

    console.log("NAVIGATOR END", nav.name);
    out += `</${navTag}>\n`;
    return out;
  }

  addNavigator(navigator, name, portal, parent, routePath) {
    this._navigators[name] = {
      type: navigator.type,
      portal: portal,
      routePath: [...routePath],
      parent: parent,
      routes: navigator.routes,
      initialRoute: navigator.initialRoute || null,
    };
    if (navigator.shouldActionLoad) {
      this._navigators[name].shouldActionLoad = navigator.shouldActionLoad;
    }
    if (navigator.actionWillLoad) {
      this._navigators[name].actionWillLoad = navigator.actionWillLoad;
    }
    switch (navigator.type) {
      case "tab":
        if (navigator.tabNavigator) {
          this._navigators[name].tabNavigator = navigator.tabNavigator || {};
        }
        break;
      case "drawer":
        if (navigator.drawerNavigator) {
          this._navigators[name].drawerNavigator =
            navigator.drawerNavigator || {};
        }
        break;
      case "modal":
        if (navigator.header) {
          this._navigators[name].header = navigator.header;
        }
        break;
      case "stack":
      default:
        if (navigator.header) this._navigators[name].header = navigator.header;
        break;
    }
  }
  _initNavigation(routesConfig, portal = null, parent = null, routePath = []) {
    if (!routesConfig.routes) return null;
    if (parent === null) {
      parent = "r14NavRoot";
      if (!portal) portal = routesConfig.type === "modal" ? "modal" : "root";
      this.addNavigator(routesConfig, "r14NavRoot", null, null, [...routePath]);
      routePath.push(parent);
    }

    if (routesConfig.path) {
      this._paths[parent] = routesConfig.path;
    }

    for (let name in routesConfig.routes) {
      if (routesConfig.routes[name].routes) {
        let childRoutePath = [...routePath];
        childRoutePath.push(name);
        if (!portal || routesConfig.routes[name].type === "modal")
          portal =
            routesConfig.routes[name].type === "modal" ? "modal" : "root";
        this.addNavigator(
          routesConfig.routes[name],
          name,
          portal,
          parent,
          childRoutePath
        );
        this._initNavigation(
          routesConfig.routes[name],
          portal,
          name,
          childRoutePath
        );
        continue;
      }

      if (routesConfig.routes[name].path) {
        this._paths[name] = routesConfig.routes[name].path;
      }

      this._screens[name] = {
        routePath: [...routePath],
        portal: portal,
        parent: parent,
        ...routesConfig.routes[name],
      };
    }
  }
  getPortalByRouteName(routeName) {
    let portalName = this.getPortalNameByRouteName(routeName);
    return this.portals(portalName);
  }

  getPortalNameByRouteName(routeName) {
    let portal = null;
    if (this._screens[routeName]) {
      return this._navigators[this._screens[routeName].parent].portal;
    } else if (this._navigators[routeName])
      return this._navigators[routeName].portal;
    else return null;
  }

  getRouteByRouteName(routeName) {
    let portal = null;
    if (this._screens[routeName]) return this._screens[routeName];
    else if (this._navigators[routeName]) return this._navigators[routeName];
  }
  initActiveRoute(routeName, props = {}) {
    let portalName = this.getPortalNameByRouteName(routeName);

    let portal = this.portals(portalName);

    if (!portal) return false;

    let routeConfig = this.screens(routeName);

    // let route = new R14Route();

    // let data = {
    //   params: (this._match && this._match.params) || {},
    //   query: {},
    //   state: this._location.state || {},
    //   forms: {}
    // }
    // if (this._location && this._location.search) {
    //   let searchParams = new URLSearchParams(this._location.search.substring(1));
    //   for (let key of searchParams.keys()) {
    //     data.query[key] = searchParams.get(key);
    //   }
    // }
    // if (this.ui.form.activeRouteName === routeName) {
    //   let submittedForm = this.ui.form.submittedForm;
    //   data.forms[submittedForm.name] = submittedForm;
    //   console.log("SETTING THE FORM DATA");
    // }

    if (portal.name === "root") {
      /** @todo Navigation base refactor. This is too static, find a dynamic way to close routes. */
      for (let k in this._portals) {
        if (this._portals[k].name !== "root") {
          //console.log('setting portal inactive: ',this._portals[k].name);
          this._portals[k].setActiveRoute(null);
        }
      }
    }

    let route = new R14Route(
      routeName,
      routeConfig,
      portal.name,
      this._location,
      this._match,
      props.navigation
    );
    portal.setActiveRoute(route);
    this.setActiveRoute(route);

    // if (portal.name === "root") {
    //   // make sure the root route has an activeRoute
    // }
    /** @todo Navigation base needs testing to make sure that this works ok. */
    /** @todo Navigation base maybe use route object instead of name. */
    if (routeConfig && routeConfig.initialParentRoute) {
      // Make sure that the parent route has an active route
      let parentRouteConfig = this.screens(routeConfig.initialParentRoute);

      if (
        parentRouteConfig &&
        parentRouteConfig.portal !== routeConfig.portal
      ) {
        let parentPortal = this.portals(parentRouteConfig.portal);

        if (parentPortal && !parentPortal.activeRouteName) {
          // figure out path and data from portal
          let parentUrl = parentRouteConfig.path;
          let parentParams = {};
          for (let param in portal.route.data) {
            let key = `:${param}`;
            if (parentUrl.indexOf(key) !== -1) {
              parentUrl = parentUrl.replace(key, portal.route.data[param]);
              parentParams[param] = portal.route.data[param];
            }
          }

          let parentMatch = {
            params: parentParams,
            path: parentRouteConfig.path,
            url: parentUrl,
            isExact: true,
          };
          /** @todo seperate state by portal */
          let parentLocation = {
            pathname: parentUrl,
            search: "",
            state: this._location.state,
          };

          let parentRoute = new R14Route(
            routeConfig.initialParentRoute,
            this.screens[routeConfig.initialParentRoute],
            parentPortal.name,
            parentLocation,
            parentMatch
          );
          parentPortal.setActiveRoute(parentRoute);
        }
      }
    }
  }

  isScreenInNavigator(screen, navigator) {
    if (!this._navigators[navigator]) throw "No navigator found";
    if (!this._screens[screen]) throw "No screen found";
    return this._navigators[navigator].routes[screen] ? true : false;
  }
  getNextNavigatorName(screen, navigator) {
    if (!this._navigators[navigator]) throw "No navigator found Err 2";
    if (!this._screens[screen]) throw "No screen found Err 2";
    let nextNav = null;
    let foundCurr = false;
    for (let name of this._screens[screen].routePath) {
      if (name === navigator) foundCurr = true;
      else if (foundCurr) return name;
    }
    return null;
  }

  parseNavigationProps(props) {
    let routeProps = {
      route: props.route || null,
      //portal: props.portal || "default",
      delay: props.delay || 0,
    };
    if (!props.to) throw "Navigation props Error: No to.";
    if (typeof props.to === "object") {
      if (props.to.pathname) routeProps.route = props.to.pathname;
      else if (props.to.route) routeProps.route = props.to.route;

      if (props.to.portal) routeProps.portal = props.to.portal;
      //if(props.to.delay) routeProps.delay = props.to.delay;
      if (props.to.state) routeProps.state = props.to.state;
    } else routeProps.route = props.to;
    if (!routeProps.route) throw "Route Link Error: No pathname.";
    return routeProps;
  }
  isActiveRoute(route) {
    // if(typeof route === 'string'){
    //   console.error('IS ACTIVE ROUTE GOT STRING!');
    //   return false;
    // }
    if (typeof route === "string") route = { route, params: {} };

    let routeName = route.route;
    let routeInfo = this.getRouteByRouteName(route.route);
    let routePath = null;

    // return true;
    if (this.activeRouteName === routeName) return true;
    // Check the active routes of the portals
    for (let i in this._portals) {
      if (!this._portals[i].route) break;
      let portalRouteName = this._portals[i].route.name;

      if (portalRouteName === routeName) return true;
      // Check if it is a parent of the routePath
      let portalPathName = this._portals[i].route.path;
      let portalRoute = this.getRouteByRouteName(portalRouteName);
      if (
        portalRoute &&
        portalRoute.routePath &&
        portalRoute.routePath.indexOf(routeName) !== -1
      ) {
        let portalRouteParams = this._portals[i].route.params;
        if (!route.params || Object.keys(route.params).length === 0)
          return true;
        for (let i in route.params) {
          if (!portalRouteParams[i] || portalRouteParams[i] !== route.params[i])
            return false;
        }
        return true;
      } else return false;
    }
  }
}
