import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import LogDataTable from '../components/LogDataTable';
export default R14.connect(
  class LogsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "logCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <LogDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});