import React from "react";
import R14 from "../core";
import RelatedAddForm from "../components/RelatedAddForm";
export default R14.connect(
  class ResourceLogAddScreen extends React.Component {
    render() {
      return (
        <RelatedAddForm
          name='resourceLogAddForm'
          formData={this.props.formData}
          domainName='resource'
          domainAddMethodName='addLog'
          refreshDataTable={this.props.refreshDataTable || "resourceLogs"}
          redirect={
            this.props.redirect || {
              route: "resource",
              params: { uid: this.props.resourceUid }
            }
          }
          relatedFieldName='resourceUid'
          createButtonTitle='Create New Log'
          createAction={this.props.createAction || "resourceLogCreate"}
          selectMenuFieldName='logUid'
          selectMenuFieldLabel='Select Log'
          selectMenuFieldSelections={this.props.formData.logSelections}
        />
      );
    }
  }
);
