import React from "react";
import R14, { View, Text } from "../core";
import LogViewer from "../components/LogViewer";
export default R14.connect(
  class ResourceScriptLogScreen extends React.Component {
    constructor(props) {
      super(props);
      this.textLoader = this.textLoader.bind(this);
    }
    async textLoader() {
      let text = await this.props.app.dm.resourceStatusCheck.fetchLog(this.props.uid);
      return text;
    }
    render() {
      return (
        <View>
          <LogViewer 
            textLoader={this.textLoader} 
            autoRefresh={5}
          />
        </View>
      );
    }
  }
);
