import React from 'react';
import Surface from './Surface';
import IconButton from './IconButton'
import StyleSheet from './StyleSheet';
import { Colors } from './Theme';

export default class FloatingActionButton extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
    let styles = this.styles();
		return (
			<Surface
				{...this.props}
        style={[styles.floatingActionButton, this.props.style]}
        elevation={8}
			>
        <IconButton 
          icon={this.props.icon}
          to={this.props.to}
          onPress={this.props.onPress}
          style={styles.iconButton}
          color = {this.props.iconColor || Colors.onSecondary}
        />
			</Surface>
    );
  }
  styles(){
    return StyleSheet.create({
      floatingActionButton: {
        position: "absolute",
        bottom: 16,
        right: 16,
        zIndex: 4,
        height:56,
        width:56,
        padding:0,
        borderRadius: 28,
        backgroundColor: this.props.color || Colors.secondary
      },
      iconButton: {
        height: 56,
        width: 56,
        padding:16,
        ...StyleSheet.margin(0),
        
      }
    });
  }
}