import R14 from "./R14";
export default class R14Portal extends R14.Domain {
  constructor(name, options = {}) {
    super();
    this._name = name;
    this._data = options.data || {};
    this.state = {
      activeRouteName: options.route || null,
      activeRoutePath: options.path || null
    };
  }
  createAppInstance(extendProps = {}) {
    return new Proxy(this, {
      get: function(obj, prop) {
        switch (prop) {
          case "route":
          case "rt":
            return obj.route;
            break;
          default:
            return R14.instance.app[prop];
        }
      }
    });
  }
  get activeRouteName() {
    return this.state.activeRouteName;
  }
  get activeRoutePath() {
    return this.state.activeRoutePath;
  }
  setActiveRoute(route) {
    this.setState({
      activeRouteName: route ? route.name : null,
      activeRoutePath: route ? route.path : null
    });
    this._route = route;

    return this;
  }
  get data() {
    return this._route ? this._route.data : {};
  }
  get route() {
    return this._route;
  }
  get name() {
    return this._name;
  }
}
