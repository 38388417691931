import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  View
} from "../core";
import StateIndicatorText from "./StateIndicatorText";
import StateIndicatorIcon from "./StateIndicatorIcon";
import ResourceControls from "../components/ResourceControls";

export default R14.connect(
  class ProjectResourceDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.resourceDomain = this.props.app.dm.resource;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.projectType),
        "resources"
      );
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };

      let res = await this.props.app.dm.project.findResources(
        this.props.projectUid,
        `
        uid
        name
        state
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          state: row.state,
          name: row.name
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("projectResources");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "state":
          ret = (
            <StateIndicatorText
              label={this.resourceDomain.getStateLabel(cell)}
              color={this.resourceDomain.getStateIndicatorColor(cell)}
            />
          );
          break;
        case "name":
          ret = (
            <React.Fragment>
              <View>
                <Text style={[styles.cellText, styles.nameCellNameText]}>
                  {row.name}
                </Text>
                {row.description && (
                  <Text
                    style={[styles.cellText, styles.nameCellDescriptionText]}
                  >
                    {row.description}
                  </Text>
                )}
              </View>
              {columns.state.collapsed && (
                <ChipGroup style={styles.cellChipGroup}>
                  {columns.state.collapsed && (
                    <Chip
                      iconLeft={
                        <StateIndicatorIcon
                          color={this.resourceDomain.getStateIndicatorColor(
                            row.state
                          )}
                        />
                      }
                      label={this.resourceDomain.getStateLabel(row.state)}
                    />
                  )}
                </ChipGroup>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    handleRowPress(row) {
      this.props.app.nav.to("resource", { uid: row.uid });
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.project.removeResource({
          projectUid: this.props.projectUid,
          resourceUid: uid
        });
        await dataTable.refresh({ indicator: true });
        this.props.app.ui.progressIndicator.hide();
      }
    }
    render() {
      return (
        <DataTable
          name='projectResources'
          columns={{
            name: {
              label: "Name"
              // sortable: true
            },
            state: {
              label: "State",
              sortable: true,
              collapse: ({ width }) => {
                return width <= 640;
              }
            }
          }}
          maxHeight={300}
          autoRefresh={30}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Resources"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={
            this.props.widget &&
            this.perms.create && (
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={() =>
                  this.props.app.nav.to("projectResourceAdd", {
                    uid: this.props.projectUid,
                    key: this.props.app.dm.project.getKeyByType(
                      this.props.projectType
                    )
                  })
                }
              />
            )
          }
          rowControlsRight={(row, dataTable) => {
            let ret = [];
            if (this.perms.execute)
              ret.push(
                <ResourceControls
                  key='resourceControls'
                  resourceType={row.type}
                  resourceUid={row.uid}
                  resourceState={row.state}
                  dataTable={this.dataTable}
                />
              );
            if (this.perms.delete)
              ret.push(
                <PopUpMenu
                  controlIcon='dotsHorizontal'
                  controlIconSize='small'
                  key='projectResourceMenu'
                  direction='downLeft'
                >
                  <PopUpMenuItem
                    label='Remove'
                    icon='close'
                    key='remove'
                    onPress={() => this.handleRemovePress(row.uid, dataTable)}
                  />
                </PopUpMenu>
              );

            return ret;
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  cellChipGroup: {
    marginTop: 8
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
