import React from 'react';
import View from '../View';
import StyleSheet from '../StyleSheet';
import { Colors } from '../Theme';

export default class DrawerNavigator extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
    return (
      <View>
        reout4e reoute laskdjflkjsdf
      </View>
    );
	}
}
const styles = StyleSheet.create({
});