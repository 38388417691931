import React from "react";
import R14, { View } from "../core";
import LogViewer from "../components/LogViewer";
export default R14.connect(
  class ResourceScriptLogScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        text: this.props.text || ''
      }
      this._logSubscription = null;
    }
    componentDidMount(){
      this.init();
    }
    componentWillUnmount(){
      if(this._logSubscription) this._logSubscription.unsubscribe();
    }
    async init(){
      this._logSubscription = await this.props.app.dm.resourceScript.onUpdateLog(this.props.uid, res=>{
        if(this.state.text !== res.log) this.setState({
          text: res.log
        });
      });
    }
    render() {
      return (
        <View>
          <LogViewer 
            text={this.state.text}
          />
        </View>
      );
    }
  }
);