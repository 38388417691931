import R14 from "../core";

export default class UserDomain extends R14.Domain {
  constructor(key) {
    super();
    this.ROLE_SUPER_ADMIN = "SUPER_ADMIN";
    this.ROLE_OWNER = "OWNER";
    this.ROLE_ADMIN = "ADMIN";
    // this.ROLE_USER = "USER";
    // this.ROLE_DEVELOPER = "DEVELOPER";
    this.ROLE_GROUP = "GROUP";

    this.PERMISSION_ACCESS_FULL = "FULL";
    this.PERMISSION_ACCESS_READ = "READ";
    this.PERMISSION_ACCESS_CREATE = "CREATE";
    this.PERMISSION_ACCESS_EDIT = "EDIT";
    this.PERMISSION_ACCESS_DELETE = "DELETE";
    this.PERMISSION_ACCESS_EXECUTE = "EXECUTE";

    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindUsers($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: UserFilter) {
        users(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.users;
  }
  async fetchEditFormData(uid = null) {
    let res = uid
      ? await this.api.qry(
          `
          query UserEditFormData($uid: ID!,$userPermissionGroupFilter: UserPermissionGroupFilter) {
            user(uid: $uid){
              uid
              name
              email
              role
              active
              userPermissionGroupUid
            }
            userPermissionGroups(filter: $userPermissionGroupFilter){
              nodes {
                uid
                name
              }
            }
          }`,
          {
            uid: uid,
            userPermissionGroupFilter: {
              clientUid: { eq: this.dm.userSession.clientUid },
            },
          }
        )
      : await this.api.qry(
          `
          query UserCreateFormData($userPermissionGroupFilter: UserPermissionGroupFilter) {
            userPermissionGroups(filter: $userPermissionGroupFilter){
              nodes {
                uid
                name
              }
            }
          }`,
          {
            userPermissionGroupFilter: {
              clientUid: { eq: this.dm.userSession.clientUid },
            },
          }
        );
    let formVals = (res.data && res.data.user) || {};
    return {
      values: formVals,
      roleSelections: this.getRoleSelections(),
      userPermissionGroupSelections:
        res.data.userPermissionGroups && res.data.userPermissionGroups.nodes
          ? res.data.userPermissionGroups.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
    };
  }
  getRoleSelections() {
    return [
      {
        label: "Super Admin",
        value: this.ROLE_SUPER_ADMIN,
      },
      {
        label: "Owner",
        value: this.ROLE_OWNER,
      },
      {
        label: "Admin",
        value: this.ROLE_ADMIN,
      },
      // {
      //   label: "User",
      //   value: this.ROLE_USER
      // },
      // {
      //   label: "Developer",
      //   value: this.ROLE_DEVELOPER
      // },
      {
        label: "Permission Group",
        value: this.ROLE_GROUP,
      },
    ];
  }
  async fetchAccountSettingsFormData(uid = null) {
    // clients{
    //   nodes {
    //     value: uid
    //     label: name
    //   }
    // }
    let res = uid
      ? await this.api.qry(
          `
      query UserEditFormData($uid: ID!) {
        user(uid: $uid){
          uid
          name
          email
          profileImage {
            key
            uid
            name
            height
            width
          }
        }
      }`,
          {
            uid: uid,
          }
        )
      : {};
    let formVals = res.user || {};
    if (formVals.profileImage) {
      formVals.profileImage = {
        value: formVals.profileImage.uid,
        label: formVals.profileImage.name,
      };
    }
    return {
      values: formVals,
    };
  }
  async create(values) {
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input){
          user {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input){
          user {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  parseFormValues(values) {
    let ret = values;
    if (
      "role" in ret &&
      (!("userPermissionGroupUid" in ret) || ret.role !== this.ROLE_GROUP)
    )
      ret.userPermissionGroupUid = null;
    if (!values.password) delete ret.password;

    ret.profileImage = values.profileImage;

    return ret;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteUser($uid: ID!) {
        deleteUser(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }

  async fetchPermissions(uid) {
    let ret = {};
    let res = await this.api.qry(
      `
      query FetchPermissions($uid: ID!) {
       user(uid: $uid){
          uid
          userPermissionGroup{
            uid
            name
            permissions {
              type
              subtype
              access
            }
          }
        }
      }`,
      {
        uid: uid,
      }
    );

    if (
      res.data &&
      res.data.user &&
      res.data.user.userPermissionGroup &&
      res.data.user.userPermissionGroup.permissions
    ) {
      ret = this.parsePermissions(
        res.data.user.userPermissionGroup.permissions
      );
    }

    return ret;
  }

  parsePermissions(permissions) {
    let ret = {};
    if (!permissions || !permissions.length) return null;
    permissions.forEach(({ type, subtype, access }) => {
      if (!ret[type])
        ret[type] = {
          type: type,
          subtypes: {},
          access: [],
        };

      if (subtype && !ret[type].subtypes[subtype]) {
        ret[type].subtypes[subtype] = {
          subtype: subtype,
          access: [],
        };
      }

      if (subtype) {
        let subtypeNode = ret[type].subtypes[subtype];
        subtypeNode.access = [...subtypeNode.access, ...access];
        if (subtypeNode.access.includes(this.PERMISSION_ACCESS_FULL))
          subtypeNode.access = [this.PERMISSION_ACCESS_FULL];
        ret[type].subtypes[subtype] = subtypeNode;
      } else {
        let typeNode = ret[type];
        typeNode.access = [...typeNode.access, ...access];
        if (typeNode.access.includes(this.PERMISSION_ACCESS_FULL))
          typeNode.access = [this.PERMISSION_ACCESS_FULL];
        ret[type] = typeNode;
      }
    });
    return ret;
  }

  getPermissions(type, subtype = null) {
    let ret = {};
    [
      this.PERMISSION_ACCESS_READ,
      this.PERMISSION_ACCESS_CREATE,
      this.PERMISSION_ACCESS_EDIT,
      this.PERMISSION_ACCESS_DELETE,
      this.PERMISSION_ACCESS_EXECUTE,
    ].forEach((access) => {
      ret[access.toLowerCase()] = this.checkPermissions(
        type,
        subtype || access,
        subtype && access
      );
    });
    return ret;
  }
  get hasAdminRole() {
    return (
      this.dm.userSession.isLoggedIn &&
      this.dm.userSession.role !== this.ROLE_GROUP
    );
  }
  checkPermissions(type, subtype = null, access = null, options = {}) {
    let permissions = this.dm.userSession.permissions;
    let role = this.dm.userSession.role;

    if (type && subtype && (!access || typeof access === "object")) {
      access = subtype;
      options = subtype || {};
      subtype = null;
    }
    /**@todo This should all be moved */
    let projectType = this.dm.project.getTypeByKey(type);

    // if (projectType === this.dm.project.TYPE_CLOUD){
    //   console.log(type, projectType, subtype);
    // }

    if (projectType) {
      switch (projectType) {
        case this.dm.project.TYPE_CLOUD:
          if (
            [
              "codeEditor",
              "doc",
              "log",
              "appModule",
              "build",
              "block",
              "pipeline",
              "dataset"
            ].includes(subtype)
          )
            return false;
          break;
        case this.dm.project.TYPE_DEV:
          if (["healthCheck", "dataset"].includes(subtype))
            return false;
          break;
        case this.dm.project.TYPE_AI:
          if (
            ["healthCheck", "build", "resources", "logs", "doc"].includes(
              subtype
            )
          )
            return false;
          break;
      }
    }

    if (role === this.ROLE_SUPER_ADMIN) return true;
    else if (
      role !== this.ROLE_SUPER_ADMIN &&
      type === "admin" &&
      subtype === "client"
    )
      return false;
    else if (role === this.ROLE_OWNER || role === this.ROLE_ADMIN) return true;

    // Format to make sure access is uppercase
    if (access) access = access.toUpperCase();

    let hasPerm = false;

    if (!permissions[type]) {
      if (role === this.ROLE_ADMIN) hasPerm = true;
      // console.log("CHECK DEFAULT BY ROLE!!! No type found.");
    } else if (!subtype) {
      hasPerm =
        permissions[type].access.includes(access) ||
        permissions[type].access.includes(this.PERMISSION_ACCESS_FULL);
    } else if (!permissions[type].subtypes[subtype]) {
      // If no subtypes are defined, check by parent type
      // If there are subtypes, all subtypes need to be defined for permissions
      if (
        !permissions[type].subtypes ||
        !Object.keys(permissions[type].subtypes).length
      ) {
        hasPerm =
          permissions[type].access.includes(access) ||
          permissions[type].access.includes(this.PERMISSION_ACCESS_FULL);
      }
    } else {
      hasPerm =
        permissions[type].subtypes[subtype].access.includes(access) ||
        permissions[type].subtypes[subtype].access.includes(
          this.PERMISSION_ACCESS_FULL
        );
    }
    return hasPerm;
  }
}
