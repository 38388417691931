import React from "react";
import R14, { View, Text } from "../core";
import AppModuleLogViewer from "../components/AppModuleLogViewer";
export default R14.connect(
  class AppModuleLogScreen extends React.Component {
    render() {
      return (
        <AppModuleLogViewer
          key={
            this.props.serverType
              ? `${this.props.uid}-${this.props.serverType}`
              : this.props.uid
          }
          uid={this.props.uid}
          logType={this.props.logType}
        />
      );
    }
  }
);
