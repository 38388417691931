import React from 'react';
import { observer, inject } from 'mobx-react';
import { set as mobxSet, configure as mobxConfig, decorate, observable, action, toJS } from "mobx";
import R14Utils from './R14Utils';
import R14Base from './base/R14Base';

if (process.env.NODE_ENV === "development") {
  mobxConfig({
    enforceActions: "observed",
  });
}
export default class R14 extends R14Base {
  constructor() {
    super();
    this._metadata = {
      config: {},
      themeConfig: {},
      app: null,
      isRendering: false
    };
    this.utils = new R14Utils();
  }
  static get utils() {
    return new R14Utils();
  };
  // Connects commpontent as app observer
  static connect(component) {
    return super.connect(component, R14.getInstance());
    // component.prototype._r14Render = component.prototype.render;
    // component.prototype.render = function(){
    // 	let r14 = R14.getInstance();
    // 	r14.rendering = true;
    // 	let ret = this._r14Render();
    // 	r14.rendering = false;
    // 	return ret;
    // }
    // return inject("app", "r14")(observer(component));
  }
  static connectForm(component) {
    return inject("app", "r14", "form")(observer(component));
  }
  static connectDraggableContainer(component) {
    return inject("app", "r14", "draggableContainer")(observer(component));
  }
  static getInstance() {
    if (global) {
      if (!global._r14) global._r14 = new R14();
      return global._r14;
    }
    else {
      if (!window._r14) window._r14 = new R14();
      return window._r14;
    }
  }
  static get instance() {
    return R14.getInstance();
  }
  static get Actions() {
    return R14Actions;
  }
  static get Domain() {
    return R14Domain;
  }
  static get Theme() {
    return R14ThemeProvider;
  }
  static get DomainInstances() {
    return R14DomainInstances;
  }
  static listen(domain, props = {}) {
    domain.prototype.__r14MemberStateProps = props;
    decorate(domain, props);
  }
  onExit() {
    // do nothing?
  }
  get app() {
    return this._metadata.app
  }
  set app(app) {
    this._metadata.app = app;
    return this;
  }
  get isRendering() {
    return this._metadata.rendering
  }
  set rendering(rendering) {
    this._metadata.rendering = rendering;
    return this;
  }
  get config() {
    return this.app.config
  }
}

class R14Actions {
  constructor(routerPortal, domain) {
    this.result = this.rslt = new R14ActionsResultFactory();
    if(this.shouldActionLoad) this.shouldActionLoad = this.shouldActionLoad.bind(this);
    if(this.actionWillLoad) this.actionWillLoad = this.actionWillLoad.bind(this);
    this._routerPortal = routerPortal;
  }
  get model() {
    return R14.getInstance().app.mdl;
  }
  get mdl() {
    return this.model;
  }
  get domain() {
    return this.mdl.domain;
  }
  get dm() {
    return this.domain;
  }
  get ui() {
    return this.mdl.ui;
  }
  get navigation() {
    return R14.getInstance().app.navigation;
  }
  get nav() {
    return this.navigation;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get utilities() {
    return R14.getInstance().app.utils;
  }
  get utils() {
    return this.utilities;
  }
}
class R14ActionsResultFactory {
  component(component) {
    return new R14ActionsComponentResult(component);
  }
}
class R14ActionsComponentResult {
  constructor(component) {
    this._metadata = {
      component: component
    };
    this.props = {};
  }
  render() {
    let Component = this._metadata.component;
    return React.cloneElement(<Component />, this.props);
  }
}

export class R14Domain {
  __r14DomainState = null;
  constructor() {
    // this.__createArrProxy = this.__createArrProxy.bind(this);
    // this.__stateArrPush = this.__stateArrPush.bind(this);
    // this.__stateArrRemove = this.__stateArrRemove.bind(this);
    this.isUpdatingState = false;
  }
  get model() {
    return R14.getInstance().app.mdl;
  }
  get mdl() {
    return this.model;
  }
  get domain() {
    return this.mdl.domain;
  }
  get dm() {
    return this.domain;
  }
  get entity() {
    return this.mdl.entity;
  }
  get ent() {
    return this.entity;
  }
  get database() {
    return this.mdl.database;
  }
  get db() {
    return this.database;
  }
  get query() {
    return this.db.qry;
  }
  get qry() {
    return this.query;
  }
  get ui() {
    return this.mdl.ui;
  }
  get navigation() {
    return R14.getInstance().app.navigation;
  }
  get nav() {
    return this.navigation;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get utilities() {
    return R14.getInstance().app.utils;
  }
  get utils() {
    return this.utilities;
  }
  get api() {
    return R14.getInstance().app.api;
  }
  get r14() {
    return R14.getInstance();
  }
  setState(values) {
    this.isUpdatingState = true;
    let memberStateProps = this.__r14MemberStateProps ? this.__r14MemberStateProps : {};
    for (let name in values) {
      if (name in memberStateProps) this[name] = values[name];
      else if (this.__r14DomainState) {
        this.__r14DomainState[name] = values[name];
      }
    }
    this.isUpdatingState = false;
  }
  get state() {
    return this.getState();
  }
  getState() {
    return new Proxy(this, {
      get: function (obj, prop) {
        let r14 = R14.getInstance();
        if (prop in obj.__r14DomainState) {
          if (r14.isRendering) return obj.__r14DomainState[prop];
          else return toJS(obj.__r14DomainState[prop]);

        }
        else return null;
      }
    });
  }
  set state(state) {
    if (this.__r14DomainState) throw ("State has already been set.");
    this.__r14DomainState = observable(state);
  }
}
decorate(R14Domain, {
  setState: action,
});

export class R14DomainInstances extends R14Domain {
  constructor() {
    super();
    this._instances = {};
    return new Proxy(() => { }, {
      get: (obj, prop) => {
        if (this[prop]) return this[prop];
        if (this.exists(prop)) return this.getInstance(prop);
        else return null;
      },
      set: (obj, prop, value) => {
        this[prop] = value;
        return true;
      },
      apply: (obj, context, args) => {
        return this.getInstance(args[0]);
      }
    });
  }
  addInstance(key, instance) {
    this._instances[key] = instance;
  }
  removeInstance(key) {
    if (this.exists(key)) delete this._instances[key];
    else return null;
  }
  getInstance(key) {
    if (this.exists(key)) return this._instances[key];
    else return null;
  }
  exists(key) {
    return this._instances[key] ? true : false;
  }
  forEach(fn) {
    for (let i in this._instances) {
      fn(this._instances[i], i);
    }
  }
  getInstances(){
    return this._instances;
  }
}

export class R14ThemeProvider{
  constructor(config){
    R14.getInstance()._metadata.themeConfig = config;
  }
}