import React from "react";
import R14, {
  StyleSheet,
  Colors,
  Chart,
  ScreenLayout,
  IconButton,
  View,
  Surface,
  SectionHeader,
  Text,
  PopUpMenu,
  PopUpMenuItem,
  ShowHideText,
} from "../core";
import Dashboard from "../components/Dashboard";

import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import DashboardCardSection from "../components/DashboardCardSection";
import ResourceMetricsChart from "../components/ResourceMetricsChart";
import ResourceProcessMetricsChart from "../components/ResourceProcessMetricsChart";
import StateIndicatorText from "../components/StateIndicatorText";
import StateIndicatorIcon from "../components/StateIndicatorIcon";

import EventTaskDataTable from "../components/EventTaskDataTable";
import ResourceScriptDataTable from "../components/ResourceScriptDataTable";
import ResourceLogDataTable from "../components/ResourceLogDataTable";
import ResourceStatusCheckDataTable from "../components/ResourceStatusCheckDataTable";
// import ResourceAlertDataTable from "../components/ResourceAlertDataTable";
import ResourceScreenLayout from "../components/ResourceScreenLayout";

export default R14.connect(
  class ResourceScreen extends React.Component {
    constructor(props) {
      super(props);
      if (!this.props.resource || !this.props.resource.state.uid)
        throw new Error("Resource not found.");
      this.resourceDomain = this.props.app.dm.resource;
      this.userDomain = this.props.app.dm.user;
      this.resource = this.props.resource;
      this.permissions = null;
    }
    // componentWillUnmount() {
    //   console.error(
    //     "resource is being removed from domain, please check error."
    //   );
    //   this.resource.remove();
    // }
    renderMenu() {
      let resource = this.resource.state;
      let items = [];
      if (!resource.sshKeyUid) {
        items.push(
          <PopUpMenuItem
            label='Add SSH Key'
            icon='lock'
            key='updateSshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyAdd", {
                uid: resource.uid,
              })
            }
          />
        );
      } else {
        items.push(
          <PopUpMenuItem
            label='Manage SSH Key'
            icon='consoleNetwork'
            key='sshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyManage", {
                uid: resource.uid,
                sshKeyUid: resource.sshKeyUid,
              })
            }
          />
        );
      }
      return (
        <PopUpMenu
          controlIcon='dotsVertical'
          key='adminMenu'
          direction='downLeft'
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    render() {
      let resource = this.resource.state;
      let hasAgent =
        this.resource.type === this.resourceDomain.TYPE_R14_COMPUTER;
      return (
        <ResourceScreenLayout resource={this.props.resource}>
          <Dashboard name='resources' minWidth={352}>
            <DashboardCard title='Resource'>
              <DashboardCardSection title='Name'>
                <Text>{resource.name}</Text>
              </DashboardCardSection>

              <DashboardCardSection title='Type'>
                <Text>
                  {this.props.app.dm.resource.getTypeLabel(resource.type)}
                </Text>
              </DashboardCardSection>

              <DashboardCardSection title='State'>
                <StateIndicatorText
                  label={this.props.app.dm.resource.getStateLabel(
                    resource.state
                  )}
                  color={this.props.app.dm.resource.getStateIndicatorColor(
                    resource.state
                  )}
                />
              </DashboardCardSection>

              {resource.resourceId && (
                <DashboardCardSection title='Instance Id'>
                  <ShowHideText text={resource.resourceId} />
                </DashboardCardSection>
              )}

              {resource.description && (
                <DashboardCardSection title='Description'>
                  <Text>{resource.description}</Text>
                </DashboardCardSection>
              )}
              {resource.publicIpAddress && (
                <DashboardCardSection title='Public Ip'>
                  <Text>{resource.publicIpAddress}</Text>
                </DashboardCardSection>
              )}
              {resource.ipAddress && (
                <DashboardCardSection title='Ip Address'>
                  <Text>{resource.ipAddress}</Text>
                </DashboardCardSection>
              )}
              {resource.publicDnsName && (
                <DashboardCardSection title='Public DNS'>
                  <ShowHideText text={resource.publicDnsName} />
                </DashboardCardSection>
              )}
              {resource.sshKeyUid && (
                <DashboardCardSection title='SSH Key'>
                  <Text key='labe'>{resource.sshKey.name}</Text>
                </DashboardCardSection>
              )}
              {resource.sshKeyUid && (
                <DashboardCardSection title='SSH State'>
                  <StateIndicatorText
                  label={this.props.app.dm.resource.getSshStateLabel(
                    resource.sshState
                  )}
                  color={this.props.app.dm.resource.getSshStateIndicatorColor(
                    resource.sshState
                  )}
                />
                </DashboardCardSection>
              )}
            </DashboardCard>

            {![
              this.resourceDomain.TYPE_ECS_CLUSTER,
              this.resourceDomain.TYPE_AWS_S3_BUCKET,
              this.resourceDomain.TYPE_AWS_FSX_LUSTRE_FILESYSTEM,
            ].includes(resource.type) &&
              this.userDomain.checkPermissions(
                "resource",
                "monitor",
                this.userDomain.PERMISSION_ACCESS_READ
              ) && (
                <DashboardWidget>
                  <ResourceMetricsChart
                    resourceUid={resource.uid}
                    resourceType={resource.type}
                  />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              "resource",
              "monitor",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              (resource.sshKeyUid ||
                [this.resourceDomain.TYPE_R14_COMPUTER].includes(
                  resource.type
                )) && (
                <DashboardWidget>
                  <ResourceProcessMetricsChart resourceUid={resource.uid} />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              "resource",
              "statusCheck",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              (hasAgent || resource.sshKeyUid) && (
                <DashboardWidget>
                  <ResourceStatusCheckDataTable resourceUid={resource.uid} />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              "resource",
              "script",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              (hasAgent || resource.sshKeyUid) && (
                <DashboardWidget>
                  <ResourceScriptDataTable resourceUid={resource.uid} />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              "resource",
              "log",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              (hasAgent || resource.sshKeyUid) && (
                <DashboardWidget>
                  <ResourceLogDataTable resourceUid={resource.uid} />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              "resource",
              "event",
              this.userDomain.PERMISSION_ACCESS_READ
            ) && (
              <DashboardWidget>
                <EventTaskDataTable resourceUid={resource.uid} />
              </DashboardWidget>
            )}

            {/* {this.userDomain.checkPermissions(
              "resource",
              "alert",
              this.userDomain.PERMISSION_ACCESS_READ
            ) && (
              <DashboardWidget>
                <ResourceAlertDataTable resourceUid={resource.uid} />
              </DashboardWidget>
            )} */}

            {[
              this.resourceDomain.TYPE_EC2_INSTANCE,
              this.resourceDomain.TYPE_AZURE_VM,
            ].includes(resource.type) &&
              this.userDomain.checkPermissions(
                "resource",
                "sshKey",
                this.userDomain.PERMISSION_ACCESS_ADD
              ) &&
              !resource.sshKeyUid && (
                <DashboardCard
                  title='SSH Private Key'
                  titleControlsRight={[
                    <IconButton
                      icon='add'
                      key='add'
                      variant='circle'
                      onPress={() =>
                        this.props.app.nav.to("resourceSshKeyManage", {
                          uid: resource.uid,
                        })
                      }
                    />,
                  ]}
                >
                  <View style={styles.dashboardCardInfo}>
                    <Text>
                      Please add a ssh private key to this resource to enable
                      scripts, logs, and other advanced features.
                    </Text>
                  </View>
                </DashboardCard>
              )}
          </Dashboard>
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  headerStateIndicatorIcon: {
    ...StyleSheet.margin(8, 12, 8, 16),
  },
  resource: {
    ...StyleSheet.margin(16),
  },
  dashboardCardSshKey: {
    flex: 0,
    flexDirection: "row",
  },
  dashboardCardInfo: {
    ...StyleSheet.padding(16, 64, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(16, 0, 0, 0),
        };
    },
  },
  dashboardCardInfoHeader: {
    fontSize: 14,
  },
});
