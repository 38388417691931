import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";

export default R14.connect(
  class UserPermissionGroupDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.userPermission.findGroups(
        `
          uid
          name
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("users");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let style = [styles.cellText];
      return <Text style={style}>{content}</Text>;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    handleRowPress(row) {
      this.props.app.nav.to("user", { uid: row.uid });
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.user.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}
    async updateUserState(uid, command) {
      this.props.app.ui.progressIndicator.show();
      let user = await this.userDomain.updateState(uid, command);
      await this.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide();
    }
    render() {
      let t = new Date();
      let startDate = new Date();
      let endDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setMonth(endDate.getMonth() + 1);

      // return (
      //   <Button
      //     title="Test Query"
      //     onPress={()=>this.props.app.dm.dev.test()}
      //   />
      // );
      return (
        <DataTable
          name='userPermissionGroups'
          columns={{
            name: {
              label: "Name",
              sortable: true
            }
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Permission Groups"}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Add Permission Group'
                to='userPermissionGroupCreate'
                key='add'
              />
            </PopUpMenu>
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to("userPermissionGroupEdit", {
                    uid: row.uid
                  })
                }
              />
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  }
});
