import React from "react";
import R14, { View, SectionHeader, StyleSheet } from "../core";

export default class LabelView extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <View style={[styles.labelView,this.props.style]}>
        <SectionHeader style={[styles.LabelSectionHeader, this.props.labelTextStyle]} level={3}>
          {this.props.label}
        </SectionHeader>
        {this.props.children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  labelView: {
    flex: 0,
    minWidth: '50%',
    ...StyleSheet.padding(0, 0, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(0, 0, 0, 0)
        };
    }
  },
  LabelSectionHeader: {
    fontSize: 14,
    paddingBottom: 8
  }
});
