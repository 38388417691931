import React from "react";
import R14, {
  ScreenLayout,
  View,
  Text,
  Colors,
  StyleSheet,
  ActivityIndicator,
  FadeView,
  Icon,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  ScrollView,
  Switch,
  SelectMenu,
  DraggableContainer,
  AnimatedView,
} from "../core";
import ProjectPipelineGrid from "../components/ProjectPipelineGrid";
import PipelineBlockWidget from "../components/PipelineBlockWidget";
import PipelineBlockIoWidget from "../components/PipelineBlockIoWidget";
import PipelineBlockIoPath from "../components/PipelineBlockIoPath";
// import DraggableContainer from "../components/DraggableContainer";
import HeaderLabelSelectMenu from "../components/HeaderLabelSelectMenu";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectPipelineScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleZoomIn = this.handleZoomIn.bind(this);
      this.handleZoomOut = this.handleZoomOut.bind(this);

      this.handleActiveChange = this.handleActiveChange.bind(this);
      this.handleBlockDrag = this.handleBlockDrag.bind(this);
      this.handleBlockDragStart = this.handleBlockDragStart.bind(this);
      this.handleBlockDragEnd = this.handleBlockDragEnd.bind(this);
      this.handleBlockDragCancel = this.handleBlockDragCancel.bind(this);
      this.handleMoveEnable = this.handleMoveEnable.bind(this);
      this.handleBlockCommandPress = this.handleBlockCommandPress.bind(this);

      this.handleBlockIoMoveEnable = this.handleBlockIoMoveEnable.bind(this);
      this.handleBlockIoDrag = this.handleBlockIoDrag.bind(this);
      this.handleBlockIoDragStart = this.handleBlockIoDragStart.bind(this);
      this.handleBlockIoDragEnd = this.handleBlockIoDragEnd.bind(this);
      this.handleBlockIoDragCancel = this.handleBlockIoDragCancel.bind(this);

      this.pipelinesItemLoader = this.pipelinesItemLoader.bind(this);

      this.userDomain = this.props.app.dm.user;
      this.perms = {
        pipeline: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "pipeline"
        ),
        pipelineBlock: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "pipelineBlock"
        ),
        appModule: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "appModule"
        ),
      };

      this.state = {
        layout: null,
      };
    }

    componentWillUnmount() {
      this.pipeline.remove();
    }

    get pipeline() {
      return this.props.pipeline;
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.pipeline.project.type);
    }

    handleLayout({ nativeEvent }) {
      this.pipeline.setLayout(nativeEvent.layout);
    }

    handleZoomIn() {
      let scale = this.pipeline.state.scale;
      scale += 0.1;
      if (scale < 2) this.pipeline.setScale(scale);
    }
    handleZoomOut() {
      let scale = this.pipeline.state.scale;
      scale -= 0.1;
      if (scale > 0.1) this.pipeline.setScale(scale);
    }

    async pipelinesItemLoader(filters) {
      let filter = {
        projectUid: { eq: this.pipeline.project.uid },
      };
      if (filters.search) {
        filter.search = { like: `%${filters.search}%` };
      }
      let pipelines = await this.props.app.dm.pipeline.find(
        `
        uid
        name
      `,
        {
          page: 1,
          resultsPerPage: 20,
          filter: filter,
          sort: [
            {
              field: "name",
              order: "ASC",
            },
          ],
          totalCount: false,
        }
      );
      return pipelines && pipelines.nodes
        ? pipelines.nodes.map((val) => ({
            value: val.uid,
            label: val.name,
          }))
        : [];
    }

    async handleActiveChange(value) {
      if (
        !value &&
        !window.confirm("Are you sure you want to set this pipeline inactive?")
      )
        return;

      this.props.app.ui.progressIndicator.show();

      this.pipeline.updateActive(value);

      this.props.app.ui.progressIndicator.hide({ timeout: 750 });

      this.props.app.ui.snackBar.show({
        message: value
          ? "Pipeline is now running."
          : "Pipeline is now stopped.",
        variant: value ? "success" : "error",
      });
    }

    handleBlockUpdate(uid, values) {
      this.pipeline.blockUpdate(uid, values);
    }

    handleIoUpdate(uid, values) {
      this.pipeline.blockIoUpdate(uid, values);
    }

    handleMoveEnable(uid) {
      this.pipeline.blockMoveEnable(uid);
    }

    handleBlockDragStart(uid, event) {
      this.pipeline.blockDragStart(uid, event);
    }
    handleBlockDrag(uid, event) {
      this.pipeline.blockDrag(uid, event);
    }
    async updateBlock(uid) {
      this.pipeline.updateBlock(uid);
    }
    handleBlockDragEnd(uid, event) {
      this.pipeline.blockDragEnd(uid, event);
    }
    handleBlockDragCancel(uid, event) {
      this.pipeline.blockDragCancel(uid, event);
    }

    // Block Io Methods
    handleBlockIoMoveEnable(key) {
      this.pipeline.blockIoMoveEnable(key);
    }

    handleBlockIoDragStart(key, event) {
      this.pipeline.blockIoDragStart(key, event);
    }
    handleBlockIoDrag(key, event) {
      this.pipeline.blockIoDrag(key, event);
    }
    handleBlockIoDragEnd(key, event) {
      this.pipeline.blockIoDragEnd(key, event);
    }
    handleBlockIoDragCancel(key, event) {
      this.pipeline.blockIoDragCancel(key, event);
    }

    async handleBlockCommandPress(uid, command) {
      this.pipeline.runBlockCommand(uid, command);
    }

    get width() {
      return this.pipeline.state.cellSize * this.pipeline.state.totalCols;
    }
    get height() {
      return this.pipeline.state.cellSize * this.pipeline.state.totalRows;
    }

    renderIoWidgets() {
      let ioWidgets = [];
      // let ioWidgetData = [];
      // let ioStyles = {};
      // let ioWidgetOffset = Math.round(this.pipeline.state.ioWidgetSize / 2);
      let dynamicBlockIoStyles = this.createDynamicBlockIoStyles();

      for (let pipelineBlockUid in this.pipeline.state.blocks) {
        ["inputs", "outputs"].forEach((ioType) => {
          let block = this.pipeline.state.blocks[pipelineBlockUid];
          let blockIos = block[ioType];
          if (!blockIos) return;
          for (let blockIoUid in blockIos) {
            let blockIo = blockIos[blockIoUid];

            if (!blockIo || !blockIo.io) continue;

            let blockIoKey = this.pipeline.encodeBlockKey(
              pipelineBlockUid,
              blockIoUid,
              ioType
            );

            // Check for any collisions
            let hasIoPathCollision = false;
            let hasIoPath = true;
            if (this.pipeline.state.ioPaths) {
              for (let pipelineBlockIoUid in blockIo.io) {
                if (!this.pipeline.state.ioPaths[pipelineBlockIoUid]) continue;
                if (
                  this.pipeline.state.ioPaths[pipelineBlockIoUid].path === false
                ) {
                  hasIoPath = false;
                }
                if (this.pipeline.state.ioPaths[pipelineBlockIoUid].collision) {
                  hasIoPathCollision = true;
                  break;
                }
              }
            }

            ioWidgets.push(
              <PipelineBlockIoWidget
                key={blockIoKey}
                permissions={this.perms}
                blockIoKey={blockIoKey}
                type={ioType === "inputs" ? "input" : "output"}
                projectUid={this.pipeline.project.uid}
                projectType={this.pipeline.project.type}
                pipelineUid={this.pipeline.uid}
                block={this.pipeline.state.blocks[pipelineBlockUid]}
                size={this.pipeline.state.ioWidgetSize}
                {...blockIo}
                cellSize={this.pipeline.state.cellSize}
                collision={this.pipeline.hasBlockIoCollision(blockIoKey)}
                ioPathCollision={hasIoPathCollision}
                pathError={!hasIoPath}
                onDrag={(event) => this.handleBlockIoDrag(blockIoKey, event)}
                onDragStart={(event) =>
                  this.handleBlockIoDragStart(blockIoKey, event)
                }
                onDragEnd={(event) =>
                  this.handleBlockIoDragEnd(blockIoKey, event)
                }
                onDragCancel={(event) =>
                  this.handleBlockIoDragCancel(blockIoKey, event)
                }
                onMoveEnable={this.handleBlockIoMoveEnable}
                moveEnabled={
                  this.pipeline.state.activeBlockIo &&
                  this.pipeline.state.activeBlockIo.key === blockIoKey
                }
                onDragCollision={(blocks) => {
                  // console.log("COLLISION!!!!!", blocks);
                }}
              />
            );
          }
        });
      }
      if (this.pipeline.state.previewBlockIo) {
        ioWidgets.push(
          <View
            key='previewBlockIo'
            // pointerEvents='none'
            style={[
              styles.previewBlockIo,
              this.pipeline.state.previewBlockIo.collision &&
                styles.previewBlockIoCollision,
              dynamicBlockIoStyles.previewBlockIo,
            ]}
          />
        );
      }
      if (this.pipeline.state.placeholderBlockIo) {
        ioWidgets.push(
          <View
            key='placeholderBlockIo'
            style={[
              styles.placeholderBlockIo,
              dynamicBlockIoStyles.placeholderBlockIo,
            ]}
          />
        );
      }
      return ioWidgets;
    }
    createDynamicBlockIoStyles() {
      let blockIoStyles = {};
      let borderRadius = Math.round(this.pipeline.state.ioWidgetSize / 2);
      if (this.pipeline.state.previewBlockIo) {
        blockIoStyles.previewBlockIo = {
          top: this.pipeline.state.previewBlockIo.y,
          left: this.pipeline.state.previewBlockIo.x,
          height: this.pipeline.state.ioWidgetSize,
          width: this.pipeline.state.ioWidgetSize,
          borderRadius: borderRadius,
        };
      }
      if (this.pipeline.state.placeholderBlockIo) {
        blockIoStyles.placeholderBlockIo = {
          top: this.pipeline.state.placeholderBlockIo.y,
          left: this.pipeline.state.placeholderBlockIo.x,
          height: this.pipeline.state.ioWidgetSize,
          width: this.pipeline.state.ioWidgetSize,
          borderRadius: borderRadius,
        };
      }
      return StyleSheet.create(blockIoStyles);
    }

    // createDynamicStyles() {
    //   let styles = {};
    //   // if (this.pipeline.state.layout)
    //   //   this.pipeline.state.layout &&
    //   //     console.log(
    //   //       this.pipeline.state.layout.width,
    //   //       this.pipeline.state.layout.height,
    //   //       this.pipeline.state.layout.width +
    //   //         (this.pipeline.state.layout.width * this.pipeline.state.scale) /
    //   //           2,
    //   //       this.pipeline.state.layout.height * this.pipeline.state.scale
    //   //     );
    //   if (this.pipeline.state.scale !== 1 && this.pipeline.state.layout) {
    //     styles.draggableContainer = {
    //       transform: [
    //         {
    //           // scale: this.pipeline.state.scale,
    //           // translateX: -(
    //           //   // this.pipeline.state.layout.width * this.pipeline.state.scale
    //           //   1500
    //           // ),
    //           // translateY: -(
    //           //   2000
    //           // ),
    //         },
    //       ],
    //     };
    //     styles.pipelineWrapper = {
    //       transform: [
    //         {
    //           // scale: this.pipeline.state.scale,
    //           // translateX: -(
    //           //   this.pipeline.state.layout.width *
    //           //   ((1 - this.pipeline.state.scale) / 2)
    //           // ),
    //           // translateY: -(
    //           //   this.pipeline.state.layout.height *
    //           //   ((1 - this.pipeline.state.scale) / 2)
    //           // ),
    //           // height: this.pipeline.state.layout.height,
    //           // width: this.pipeline.state.layout.width,
    //           // translateY: -(
    //           //   2000
    //           // ),
    //         },
    //       ],
    //     };
    //   }

    //   return StyleSheet.create(styles);
    // }

    renderIo() {
      return [this.renderIoLines(), this.renderIoWidgets()];
    }
    renderIoLines() {
      if (!this.pipeline.state.ioPaths) return null;
      let ioLines = [];
      for (let pipelineBlockIoUid in this.pipeline.state.ioPaths) {
        ioLines.push(
          <PipelineBlockIoPath
            key={`${pipelineBlockIoUid}`}
            pipelineBlockIoUid={pipelineBlockIoUid}
            cellSize={this.pipeline.state.cellSize}
            onUpdate={(uid, update) => this.handleIoUpdate(uid, update)}
            {...this.pipeline.state.ioPaths[pipelineBlockIoUid]}
          />
        );
      }
      return ioLines;
    }
    renderBlocks() {
      let ret = [];
      let dynamicBlockStyles = this.createDynamicBlockStyles();
      for (let uid in this.pipeline.state.blocks) {
        ret.push(
          <PipelineBlockWidget
            key={uid}
            uid={uid}
            perms={this.perms}
            projectUid={this.pipeline.project.uid}
            projectType={this.pipeline.project.type}
            pipelineUid={this.pipeline.uid}
            pipelineActive={this.pipeline.state.active}
            {...this.pipeline.state.blocks[uid]}
            cellSize={this.pipeline.state.cellSize}
            collision={this.pipeline.hasBlockCollision(uid)}
            onUpdate={(uid, update) => this.handleBlockUpdate(uid, update)}
            onDrag={(event) => this.handleBlockDrag(uid, event)}
            onDragStart={(event) => this.handleBlockDragStart(uid, event)}
            onDragEnd={(event) => this.handleBlockDragEnd(uid, event)}
            onDragCancel={(event) => this.handleBlockDragCancel(uid, event)}
            onMoveEnable={this.handleMoveEnable}
            onCommandPress={this.handleBlockCommandPress}
            moveEnabled={
              this.pipeline.state.activeBlock &&
              this.pipeline.state.activeBlock.uid === uid
            }
          />
        );
      }
      if (this.pipeline.state.previewBlock) {
        ret.push(
          <View
            key='previewBlock'
            style={[
              styles.previewBlock,
              this.pipeline.state.previewBlock.collision &&
                styles.previewBlockCollision,
              dynamicBlockStyles.previewBlock,
            ]}
          />
        );
      }
      if (this.pipeline.state.placeholderBlock) {
        ret.push(
          <View
            key='placeholderBlock'
            style={[
              styles.placeholderBlock,
              dynamicBlockStyles.placeholderBlock,
            ]}
          />
        );
      }
      return ret;
    }
    createDynamicBlockStyles() {
      let blockStyles = {};
      if (this.pipeline.state.previewBlock) {
        blockStyles.previewBlock = {
          top: this.pipeline.state.previewBlock.y,
          left: this.pipeline.state.previewBlock.x,
          width: this.pipeline.state.previewBlock.width,
          height: this.pipeline.state.previewBlock.height,
        };
      }
      if (this.pipeline.state.placeholderBlock) {
        blockStyles.placeholderBlock = {
          top: this.pipeline.state.placeholderBlock.y,
          left: this.pipeline.state.placeholderBlock.x,
          width: this.pipeline.state.placeholderBlock.width,
          height: this.pipeline.state.placeholderBlock.height,
        };
      }
      return StyleSheet.create(blockStyles);
    }
    renderFloatingActionButton() {
      let fob = null;
      if (this.pipeline.state.activeBlock)
        fob = {
          onPress: () => {
            this.pipeline.reset();
          },
          style: styles.cancelMoveButton,
          icon: "close",
        };
      else if (this.perms.pipelineBlock.create)
        fob = {
          onPress: () =>
            this.props.app.nav.to("projectPipelineBlockCreate", {
              uid: this.pipeline.project.uid,
              key: this.props.app.dm.project.getKeyByType(
                this.pipeline.project.type
              ),
              pipelineUid: this.pipeline.uid,
            }),
          icon: "add",
        };

      return fob;
    }
    render() {
      //let dynamicStyles = this.createDynamicStyles();
      return (
        <ProjectScreenLayout
          // headerTitle={this.pipeline.name}
          project={this.pipeline.project}
          HeaderTitleComponent={[
            <HeaderLabelSelectMenu
              key='headerLabel'
              value={{
                label: this.pipeline.name,
                value: this.pipeline.uid,
              }}
              style={styles.headerLabelSelectMenu}
              searchable
              itemLoader={this.pipelinesItemLoader}
              onValueChange={(value) => {
                value.value !== this.pipeline.uid &&
                  this.props.app.nav.to("projectPipeline", {
                    uid: this.pipeline.project.uid,
                    key: this.props.app.dm.project.getKeyByType(
                      this.pipeline.project.type
                    ),
                    pipelineUid: value.value,
                  });
              }}
            />,
          ]}
          headerControlsRight={
            (this.perms.pipeline.edit || this.perms.pipeline.create) && [
              this.perms.pipeline.edit && (
                <View key='active' style={styles.switchContainer}>
                  <Switch
                    onValueChange={this.handleActiveChange}
                    value={this.pipeline.state.active}
                  />
                </View>
              ),
              <PopUpMenu
                controlIcon='dotsVertical'
                key='adminMenu'
                direction='downLeft'
                onSelect={(value, label) => {}}
              >
                <PopUpMenuItem
                  label='View Log'
                  icon='log'
                  key='log'
                  icon='console'
                  tooltip={`View Log`}
                  onPress={() =>
                    this.props.app.nav.to("projectPipelineLog", {
                      uid: this.pipeline.project.uid,
                      key: this.props.app.dm.project.getKeyByType(
                        this.pipeline.project.type
                      ),
                      pipelineUid: this.pipeline.uid,
                    })
                  }
                />
                {this.perms.pipeline.edit && (
                  <PopUpMenuItem
                    label='Edit Pipeline'
                    icon='edit'
                    key='edit'
                    onPress={() =>
                      this.props.app.nav.to("projectPipelineEdit", {
                        uid: this.pipeline.project.uid,
                        key: this.props.app.dm.project.getKeyByType(
                          this.pipeline.project.type
                        ),
                        pipelineUid: this.pipeline.uid,
                      })
                    }
                  />
                )}
                {this.perms.pipeline.create && (
                  <PopUpMenuItem
                    label='Create Copy'
                    icon='documents'
                    key='copy'
                    onPress={() =>
                      this.props.app.nav.to("projectPipelineCopy", {
                        uid: this.pipeline.project.uid,
                        key: this.props.app.dm.project.getKeyByType(
                          this.pipeline.project.type
                        ),
                        pipelineUid: this.pipeline.uid,
                      })
                    }
                  />
                )}
              </PopUpMenu>,
            ]
          }
          style={styles.screenLayout}
          floatingActionButton={this.renderFloatingActionButton()}
        >
          <View style={styles.pipeline}>
            <ScrollView style={styles.scrollView} overflow='auto'>
              {/* <View
                onLayout={this.handleLayout}
                style={dynamicStyles.pipelineWrapper}
              > */}
              <AnimatedView
                to={{
                  transform: [
                    {
                      // scale: this.pipeline.state.scale,
                      translateX: this.pipeline.state.layout
                        ? -(
                            this.pipeline.state.layout.width *
                            ((1 - this.pipeline.state.scale) / 2)
                          )
                        : 0,
                      translateY: this.pipeline.state.layout
                        ? -(
                            this.pipeline.state.layout.height *
                            ((1 - this.pipeline.state.scale) / 2)
                          )
                        : 0,
                    },
                  ],
                }}
                from={{
                  transform: [
                    {
                      // scale: this.pipeline.state.scale,
                      translateX: this.pipeline.state.layout
                        ? -(
                            this.pipeline.state.layout.width *
                            ((1 - this.pipeline.state.prevScale) / 2)
                          )
                        : 0,
                      translateY: this.pipeline.state.layout
                        ? -(
                            this.pipeline.state.layout.height *
                            ((1 - this.pipeline.state.prevScale) / 2)
                          )
                        : 0,
                    },
                  ],
                }}
                duration={150}
                key='selectionIndicator'
                //style={dynamicStyles.selectionIndicator}
              >
                <AnimatedView
                  to={{
                    transform: [
                      {
                        scale: this.pipeline.state.scale,
                        // translateX: this.pipeline.state.layout ? -(
                        //   this.pipeline.state.layout.width *
                        //   ((1 - this.pipeline.state.scale) / 2)
                        // ) : 0,
                        // translateY: this.pipeline.state.layout ? -(
                        //   this.pipeline.state.layout.height *
                        //   ((1 - this.pipeline.state.scale) / 2)
                        // ) : 0,
                      },
                    ],
                  }}
                  from={{
                    transform: [{ scale: this.pipeline.state.prevScale }],
                  }}
                  duration={150}
                  key='selectionIndicator'
                  //style={dynamicStyles.selectionIndicator}
                >
                  <DraggableContainer
                    name='pipeline'
                    style={[
                      styles.draggableContainer,
                      //dynamicStyles.draggableContainer,
                    ]}
                    onLayout={this.handleLayout}
                  >
                    <ProjectPipelineGrid
                      cellSize={this.pipeline.state.cellSize}
                      totalRows={this.pipeline.rows}
                      totalCols={this.pipeline.columns}
                    />
                    {!this.pipeline.state.isLoaded && (
                      <FadeView
                        visible={this.pipeline.state.isLoaded}
                        style={styles.fields}
                      >
                        <ActivityIndicator
                          size='large'
                          containerStyle={styles.activityIndicator}
                        />
                      </FadeView>
                    )}
                    {this.renderBlocks()}
                    {this.renderIo()}
                  </DraggableContainer>
                </AnimatedView>
              </AnimatedView>
              {/* </View> */}
            </ScrollView>
            <View style={styles.zoom}>
              <IconButton
                icon='add'
                size='small'
                style={[
                  styles.zoomInButton,
                  this.pipeline.state.scale > 1 && styles.zoomButtonActive,
                ]}
                onPress={this.handleZoomIn}
              />
              <IconButton
                icon='minus'
                size='small'
                style={[
                  styles.zoomOutButton,
                  this.pipeline.state.scale < 1 && styles.zoomButtonActive,
                ]}
                onPress={this.handleZoomOut}
              />
            </View>
          </View>
        </ProjectScreenLayout>
      );
    }
  }
);
let styles = StyleSheet.create({
  screenLayout: {
    ...StyleSheet.margin(0),
    position: "relative",
  },
  pipeline: {
    flex: 1,
    flexDirection: "column",
    position: "relative",
    marginTop: -16,
  },
  scrollView: {
    ...StyleSheet.padding(16),
  },
  cancelMoveButton: {
    backgroundColor: "#B00020",
  },
  gridRow: {
    flex: 1,
    flexDirection: "row",
  },
  gridCellHighlighted: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
  },
  draggableContainer: {
    // transform: [{ scale: .8 }],
  },
  point: {
    width: 5,
    height: 5,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.5),
    borderRadius: 3,
    ...StyleSheet.margin(-3, 0, 0, -3),
  },
  previewBlock: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    zIndex: -1,
  },
  previewBlockCollision: {
    backgroundColor: StyleSheet.color(Colors.error).rgba(0.2),
  },
  placeholderBlock: {
    position: "absolute",
    zIndex: -1,
    borderStyle: "solid",
    borderWidth: 1,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
  },
  previewBlockIo: {
    position: "absolute",
    borderWidth: 2,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    zIndex: 1,
  },
  previewBlockIoCollision: {
    zIndex: 1,
    backgroundColor: StyleSheet.color(Colors.error).rgba(0.2),
  },
  placeholderBlockIo: {
    position: "absolute",
    zIndex: 1,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
  },
  switchContainer: {
    ...StyleSheet.padding(0, 8, 0, 8),
  },
  zoom: {
    position: "absolute",
    right: 26,
    top: 16,
    backgroundColor: StyleSheet.color(Colors.surface).rgba(1),
    borderRadius: 16,
    elevation: 8,
    zIndex: 6,
  },
  zoomInButton: {
    ...StyleSheet.margin(0),
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomOutButton: {
    ...StyleSheet.margin(0),
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomButtonActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
});
