import R14 from "../core";

export default class TimesheetDomain extends R14.Domain {
  constructor(key) {
    super();
  }

  async find(fields, options = {}) {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setMonth(1);
    startDate.setDate(3);
    endDate.setMonth(1);
    endDate.setDate(28);

    //this.autofillTimesheet(startDate, endDate);

    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Timesheet Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindTimesheets($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: TimesheetFilter) {
        timesheets(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.timesheets;
  }

  async create(values) {
    let submitValues = this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation CreateTimesheet($input: CreateTimesheetInput!) {
        createTimesheet(input: $input){
          timesheet {
            uid
            description
          }
        }
      }`,
      {
        input: submitValues
      }
    );
    return true;
  }
  async update(values) {
    let submitValues = this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation UpdateTimesheet($input: UpdateTimesheetInput!) {
        updateTimesheet(input: $input){
          timesheet {
            uid
            description
          }
        }
      }`,
      {
        input: submitValues
      }
    );
    return true;
  }
  parseFormValues(values) {
    console.log(values);
    let ret = {
      projectName: values.projectName || null,
      clientName: values.clientName || null,
      description: values.description,
      userUid: this.dm.userSession.uid,
      clientUid: this.dm.userSession.clientUid,
      startAt: new Date(`${values.date} ${values.startAt}`),
      endAt: new Date(`${values.date} ${values.endAt}`)
    };
    if (values.uid) ret.uid = values.uid;
    return ret;
  }
  async get(uid) {
    let res = await this.api.qry(
      `
      query GetTimesheet($uid: ID!) {
       timesheet(uid: $uid){
          uid
          description
        }
      }`,
      {
        uid: uid
      }
    );
    return res.timesheet;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteTimesheet($uid: ID!) {
        deleteTimesheet(uid: $uid){
          timesheet {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }

  async fetchEditFormData(uid = null) {
    let formData = {};
    let qry = "";

    if (uid) {
      let qry = `
      query TimesheetCreateFormData($uid: ID!) {
        timesheet(uid: $uid){
          uid
          description
          clientName
          projectName
          startAt
          endAt
        }
      }
    `;
      let qryVals = {
        uid: uid
      };
      let res = await this.api.qry(qry, qryVals);
      if (res && res.timesheet) {
        formData = {
          values: {
            uid: res.timesheet.uid,
            description: res.timesheet.description,
            date: this.utils.date.toShortDate(new Date(res.timesheet.startAt)),
            startAt: this.utils.date.toTime(new Date(res.timesheet.startAt)),
            endAt: this.utils.date.toTime(new Date(res.timesheet.endAt)),
            projectName: res.timesheet.projectName,
            clientName: res.timesheet.clientName
          }
        };
      }
    }
    return formData;
  }

  async autofillTimesheet(startDate, endDate) {
    let inRange = true;
    let startTimes = [
      "9:45 AM",
      "9:45 AM",
      "9:45 AM",
      "9:45 AM",
      "9:45 AM",
      "10:00 AM",
      "10:00 AM",
      "10:00 AM",
      "10:15 AM",
      "10:15 AM",
    ];
    let endTimes = [
      "6:45 PM",
      "6:30 PM",
      "6:30 PM",
      "6:30 PM",
      "6:30 PM",
      "6:15 PM",
      "6:15 PM",
      "6:15 PM",
      "6:00 PM",
      "6:00 PM",
      "5:45 PM",
      "5:30 PM",
      "5:15 PM",
    ];

    if (startDate >= endDate)
      throw new Error("Autofill timesheet out of range");

    let currDate = new Date(startDate);
    let count = 0;
    while (inRange) {
      if (
        count > 1000 ||
        currDate.getMonth() > endDate.getMonth() ||
        (currDate.getMonth() === endDate.getMonth() &&
          currDate.getDate() >= endDate.getDate())
      )
        inRange = false;
      count++;

      if (currDate.getDay() >= 1 && currDate.getDay() <= 5) {
        // let startAt = startTimes[Math.floor(Math.random() * startTimes.length)];
        // let endTime = endTimes[Math.floor(Math.random() * endTimes.length)];
        let vals = {
          date: this.utils.date.toShortDate(currDate),
          startAt: startTimes[Math.floor(Math.random() * startTimes.length)],
          endAt: endTimes[Math.floor(Math.random() * endTimes.length)],
          projectName: "R14 Managed Dev",
          clientName: null,
          description: "TBD"
        }
        await this.create(vals);
      }

      currDate.setDate(currDate.getDate() + 1);
    }

    // console.log(currDate, endDate);
  }
}
