import React from "react";
import R14, {
  StyleSheet,
  ControlsView,
  Card,
  Surface,
  TextInput,
  Colors,
  ActivityIndicator,
  View,
  ScrollView,
  Text,
  IconButton,
  Link,
  SearchableText
} from "../core";
export default R14.connect(
  class LogViewer extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = React.createRef();
      this.handleTextStream = this.handleTextStream.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.shouldStream = true;
      this.state = {
        text: this.props.text || "",
        loaded: this.props.text ? true : false
      };
      this.textLoaderTimeout = null;
    }
    async load() {
      let newState = {}
      if (this.props.textLoader) {
        let text = await this.props.textLoader();
        newState.loaded = true;
        if (this.state.text !== text) {
          newState.text = text;
        }
        if (this.props.autoRefresh) {
          this.clearAutoRefresh();
          this.textLoaderTimeout = setTimeout(() => {
            this.load();
          }, this.props.autoRefresh * 1000);
        }
      } 
      else if(this.props.textStreamer) this.props.textStreamer(this.handleTextStream);
      else newState.loaded = true;
      if(Object.keys(newState).length) this.setState(newState);
    }
    handleTextStream(text) {
      if(! this.shouldStream) return null;
      this.setState({
        text:  this.state.text + text,
        loaded: true
      });
    }
    handleLayout(event){

    }
    clearAutoRefresh() {
      if (this.textLoaderTimeout) clearTimeout(this.textLoaderTimeout);
    }
    closeStream(){
      this.shouldStream = false;
    }
    componentWillUnmount() {
      this.clearAutoRefresh();
      this.closeStream();
    }
    componentDidMount() {
      this.load();
      this.scrollToEnd();
    }
    componentDidUpdate(prevProps, prevState) {
      if(this.props.text !== prevProps.text){
        this.setState({
          text: this.props.text
        });
      }
      if (this.state.text !== prevState.text) {
        this.scrollToEnd();
      }
    }
    scrollToEnd() {
      if (this.scrollRef.current)
        this.scrollRef.current.scrollToEnd({ animated: false });
    }
    render() {
      let dynamicStyles = StyleSheet.create({
        logViewer: {
          height: this.props.height || null
        }
      });

      return (
        <View style={[styles.logViewer, this.props.style]} onLayout={this.handleLayout}>
            {!this.state.loaded && (
              <ActivityIndicator
                size='large'
                containerStyle={styles.activityIndicator}
              />
            )}
             <SearchableText
              text={this.state.text}
              scrollRef={this.scrollRef}
              style={styles.searchableText}
              height={this.props.height || null}
              textStyle={styles.logText}
            />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  logViewer: {
    flex: 1,
    borderWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    paddingBottom: 0,
    width:480,
    minHeight: 160,
    screen: ({width}) => {
      if(width <= 600) return {
        width: '100%'
      }
    }
  },
  activityIndicator: {
    flex: 1,
    // position: absolote;
  },
  // scrollView: {
  //   ...StyleSheet.margin(0),
  //   ...StyleSheet.padding(16, 16, 56, 16),
  //   backgroundColor: Colors.background
  // },
  logText: {
    fontSize: 13,
    fontFamily: "monospace"
  },
  logTextMatch: {
    color: Colors.primary,
    fontWeight: "500"
  },
  logTextHighlighted: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.1),
    color: Colors.secondary
  },
  logTextSearchResults: {
    // display: 'relative'
    // flex: 1,
    // flexDirection: 'row',
    // flexWrap: 'wrap'
  },
  searchWidgetSurface: {
    position: "absolute",
    flex: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 0,
    right: 0,
    left: 0,
    // borderBottomRightRadius: 4,
    // borderBottomLeftRadius: 4,
    ...StyleSheet.padding(6, 12, 6, 12),
    backgroundColor: Colors.surface
  },
  searchWidgetControlsRight: {
    flex: 0,
    flexDirection: "row",
    alignItems: "center"
  },
  searchWidgetIcon: {
    ...StyleSheet.margin(0)
  },
  searchWidgetTextInput: {
    backgroundColor: "transparent",
    color: Colors.onSurface,
    fontSize: 16,
    width: 144
  },
  searchWidgetControlRight: {},
  searchWidgetInfoText: {
    fontSize: 14,
    fontWeight: "300",
    minWidth: 100,
    // borderStyle: "solid",
    // borderRightWidth: 1,
    // borderColor: StyleSheet.color(Colors.onSurface).rgba(.5),
    ...StyleSheet.padding(4, 8, 4, 8)
    // marginRight: 4
  }
});
