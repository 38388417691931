import React from "react";
import R14, {
  StyleSheet,
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  FieldGroup,
  Colors,
} from "../core";
import EventEditScheduleWidget from "../components/EventEditScheduleWidget";
import EventEditTriggerWidget from "../components/EventEditTriggerWidget";
// import EventScheduleDialogForm from "../components/EventScheduleDialogForm";
export default R14.connect(
  class EventEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTypeValueChange = this.handleTypeValueChange.bind(this);
      this.eventDomain = this.props.app.dm.event;
      this.formName = "eventForm";
      this.state = {
        type: this.props.formData.values.type || null,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let opts = {};
      let ret = null;
      if (this.props.type) opts.type = this.props.type;
      if (this.props.resourceUid) opts.resourceUid = this.props.resourceUid;
      if (this.props.projectUid) opts.projectUid = this.props.projectUid;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.event[editMethod](
          form.values,
          opts
        );
        if (isSuccess) {
          this.props.app.ui.snackBar.show({
            message: "Event has been saved.",
          });
          let dataTable = this.props.refreshDataTable || "events";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "events"} />;
        }
      } catch (err) {
        //form.addError(err.message);
        this.props.app.ui.snackBar.show({
          message: err.message,
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    handleTypeValueChange(type) {
      this.setState({
        type: type,
      });
    }
    render() {
      return (
        <Form
          name={this.formName}
          backgroundColor={Colors.surface}
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.props.formData.values}
          style={styles.eventForm}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              backgroundColor={Colors.background}
              autoFocus
            />
            <TextInputField
              name='description'
              label='Description'
              backgroundColor={Colors.background}
            />
          </FieldGroup>
          <EventEditScheduleWidget
            formData={this.props.formData}
            formName={this.formName}
          />
          {/* <EventScheduleDialogForm
            label='Event Triggers'
            dialogTitle='Add Event Trigger'
            formName='resourceEventScheduleForm'
            name='resourceSchedule'
            formData={this.props.formData}
            targetType={this.props.app.dm.event.TARGET_TYPE_RESOURCE}
          /> */}
          <EventEditTriggerWidget
            formData={this.props.formData}
            formName={this.formName}
          />
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  eventForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          width: null,
        };
      }
    },
  },
  // scheduleDayRowTime: {
  //   width: 160,
  //   ...StyleSheet.margin(0, 0, 0, 'auto'),
  //   flex: 0,
  //   alignSelf: 'center'
  // }
});
