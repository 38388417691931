import React from "react";
import PropTypes from "prop-types";
import R14 from '../R14';
import Touchable from './Touchable';
import StyleSheet from './StyleSheet';
export default R14.connectDraggableContainer(
  class DraggableView extends React.Component {
    static propTypes = {
      /** The name for the view */
      name: PropTypes.string.isRequired,
      /** The draggable ui domain instance */
      draggableContainer: PropTypes.func.isRequired,
      /** The top offset in pixels from the container */
      offsetTop: PropTypes.number,
      /** The left offset in pixels from the container */
      offsetLeft: PropTypes.number,
      /** Disables draggable when given true */
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      offsetTop: 0,
      offsetLeft: 0,
      disabled: false
    };
    constructor(props) {
      super(props);
      this.draggableView = this.props.draggableContainer.addView(this.props);
      this.handlePressMove = this.handlePressMove.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handlePressIn = this.handlePressIn.bind(this);
      this.handlePressOut = this.handlePressOut.bind(this);
      this.handlePressCancel = this.handlePressCancel.bind(this);
      this.state = {
        dragging: false,
      };
    }
    componentWillUnmount() {
      this.draggableView.remove();
    }
    handlePressMove(event) {
      this.draggableView.handlePressMove(event);
    }
    handlePressOut(event) {
      this.draggableView.handlePressOut(event);
    }
    handlePressIn(event) {
      this.draggableView.handlePressIn(event);
    }
    handlePressCancel(event) {
      this.draggableView.handlePressCancel(event);
    }
    handleLayout(event) {
      this.draggableView.handleLayout(event);
    }
    createDynamicStyles() {
      return StyleSheet.create({
        draggable: {
          position: "absolute",
          top: this.props.offsetTop,
          left: this.props.offsetLeft,
          zIndex: this.draggableView.active ? 1 : 0,
          opacity: this.draggableView.active ? 0.8 : 1,
          transform: [
            { translateX: this.draggableView.state.dragX },
            { translateY: this.draggableView.state.dragY },
          ],
          cursor: this.props.disabled ? null : this.draggableView.active ? "grabbing" : "grab"
        },
      });
    }
    getDelta(event) {
      let left = event.pageX;
      let top = event.pageY;
      let delta = {
        left: left - this.previousLeft,
        top: top - this.previousTop,
      };
      this.previousLeft = left;
      this.previousTop = top;
      return delta;
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <Touchable
          onLayout={this.props.onLayout || null}
          onPressOut={this.props.disabled ? null : this.handlePressOut}
          onPressIn={this.props.disabled ? null : this.handlePressIn}
          // onPressMove={this.handlePressMove}
          // onPressCancel={this.handlePressCancel}
          feedback={false}
          style={[dynamicStyles.draggable, this.props.style]}
        >
          {this.props.children}
        </Touchable>
      );
    }
  }
);
