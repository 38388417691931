import React from "react";
import R14, {
  StyleSheet,
  Colors,
  FadeView,
  HiddenInputField,
  SelectMenuField,
  FieldGroup,
} from "../core";
import EventWidget from "./EventWidget";
export default R14.connect(
  class EventEditTriggerWidget extends React.Component {
    constructor(props) {
      super(props);
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.handleItemAddPress = this.handleItemAddPress.bind(this);
      this.handleItemRemovePress = this.handleItemRemovePress.bind(this);
      this.formData = this.props.formData || { values: null };
      this.formName = this.props.formName || "events";

      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
      this.eventDomain = this.props.app.dm.event;

      this.state = {
        type: null,
        items: this.formData.values.triggers,
        showForm: this.formData.values.triggers.length ? false : true,
      };
    }
    async handleTypeChange(value) {
      this.setState({
        type: value,
      });
    }
    async handleItemRemovePress(i) {
      let form = this.props.app.ui.form(this.formName);
      let items = this.state.items;
      items.splice(i, 1);
      form.elmts.triggers.value = items;
      this.setState({
        items: items,
        showForm: this.state.showForm || items.length === 0,
      });
    }
    getScriptName(uid) {
      for (let val of this.formData.scriptSelections) {
        if (val.value === uid) return val.label;
      }
      return null;
    }
    getContactGroupName(uid) {
      console.log(uid, this.formData.contactGroupSelections);
      for (let val of this.formData.contactGroupSelections) {
        if (val.value === uid) return val.label;
      }
      return null;
    }
    async handleItemAddPress() {
      // Get the form from the context
      console.log(this.props.app.ui.forms);
      let form = this.props.app.ui.form(this.formName);
      let items = this.state.items;

      if (!this.state.showForm) {
        this.setState({
          showForm: true,
        });
        return true;
      }

      if (await form.validate()) {
        items.push(this.eventDomain.createTriggerNode(form.values));
        form.elmts.triggers.value = items;
        this.setState({
          items: items,
          showForm: false,
          type: null,
        });
      }
    }
    renderItemLabel(item, idx) {
      let displayText = "";
      switch (item.type) {
        case this.eventDomain.TYPE_SCRIPT:
          displayText = this.getScriptName(item.scriptUid);
          break;
        case this.eventDomain.TYPE_ALERT:
          displayText = `Alert ${this.getContactGroupName(item.contactGroupUid)}`;
          break;
        default:
          displayText = this.eventDomain.getTypeLabel(item.type);
          break;
      }
      return displayText;
    }
    render() {
      console.log(this.state.showForm);
      return (
        <EventWidget
          title='Tasks'
          onItemAddPress={this.handleItemAddPress}
          addMoreButton={!this.state.items.length || !this.state.showForm}
          onItemRemovePress={this.handleItemRemovePress}
          items={
            this.state.items.length &&
            this.state.items.map((val) => this.renderItemLabel(val))
          }
        >
          <HiddenInputField name='triggers' />
          <FadeView visible={this.state.showForm} style={styles.formFields}>
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Type'
              items={this.formData.typeSelections}
              required='Please select a type.'
              onValueChange={this.handleTypeChange}
            />
            {this.state.type === this.eventDomain.TYPE_ALERT && (
              <SelectMenuField
                useValuesOnly
                name='contactGroupUid'
                label='Contact Group'
                items={this.props.formData.contactGroupSelections}
                // required='Please select an alert.'
              />
            )}

            {this.state.type === this.eventDomain.TYPE_SCRIPT && (
              <SelectMenuField
                useValuesOnly
                name='scriptUid'
                label='Script'
                items={this.props.formData.scriptSelections}
                required='Please select a script.'
              />
            )}
          </FadeView>
        </EventWidget>
      );
    }
  }
);
const styles = StyleSheet.create({
  eventEditTriggerWidget: {
    // position: "relative",
    ...StyleSheet.margin(0, -8, 16, -8),
    ...StyleSheet.padding(8),
    elevation: 1,
    // ...StyleSheet.padding(12, 12, 12, 12),
    // borderColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    // borderWidth: 1,
    // borderStyle: "solid",
  },
  sectionHeader: {
    paddingBottom: 16,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: "500",
  },
  itemAddButton: {
    marginTop: -8,
    marginLeft: "auto",
  },
  formFields: {
    marginTop: 20,
  },
  // headerItemAddButton: {
  //   position: "absolute",
  //   right: 4,
  //   top: 12,
  //   // width: 36
  // },
  items: {
    ...StyleSheet.margin(4, 0, 0, 0),
  },
  itemTypeText: {
    fontWeight: "300",
  },
  item: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    borderRadius: 4,
    ...StyleSheet.padding(2, 4, 2, 8),
    marginBottom: 4,
  },
  itemDark: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
  },
});
