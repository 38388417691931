import React from "react";
import PropTypes from "prop-types";
import R14, { StyleSheet, Colors, View, Text } from "../core";
export default class StateIndicatorIcon extends React.PureComponent {
  static propTypes = {
    /** The label for the indicator */
    label: PropTypes.string,
    /** The color of the indicator */
    color: PropTypes.oneOf(["red", "green", "yellow"])
  };
  static defaultProps = {
    color: "red"
  };
  render() {
    return (
      <View
        tooltip={this.props.tooltip || null}
        style={[styles.indicator, styles[this.props.color], this.props.style]}
      />
    );
  }
}

const styles = StyleSheet.create({
  indicator: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.5)
  },
  red: {
    backgroundColor: "#e53935"
  },
  yellow: {
    backgroundColor: "#ffeb3b"
  },
  green: {
    backgroundColor: "#43a047"
  }
});
