import React from "react";
import PropTypes from "prop-types";
import View from "./View";
import StyleSheet from "./StyleSheet";
import ActivityIndicatorBase from "./base/ActivityIndicatorBase";
import { Colors } from "./Theme";

/** A spinning activity indicator */
export default class ActivityIndicator extends React.Component {
  static propTypes = {
    /** The color activity indicator */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The size of the activity indicator */
    size: PropTypes.oneOf(["large", "small"])
  };
  static defaultProps = {
    color: Colors.primary,
    size: "small"
  };

  render() {
    return (
      <View style={[this.style.container, this.props.containerStyle]}>
        <ActivityIndicatorBase
          color={StyleSheet.parseColor(this.props.color || Colors.primary)}
          size={this.props.size || "small"}
        />
      </View>
    );
  }
  get style() {
    return StyleSheet.create({
      container: {
        flex: 0,
        // flexDirection: "column",
        alignItems: 'center',
        justifyContent: "center",
        padding: 64
      }
    });
  }
}
