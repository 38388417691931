import R14 from "../core";

export default class ResourceDockerTaskDomain extends R14.Domain {
  constructor(key) {
    super();
    this.TYPE_APP_MODULE_SERVER = "APP_MODULE_SERVER";
    this.TYPE_APP_MODULE_INSTALLER = "APP_MODULE_INSTALLER";
    this.TYPE_PIPELINE_BLOCK_INSTANCE = "PIPELINE_BLOCK_INSTANCE";
    this.STATE_RUNNING = "RUNNING";
    this.STATE_STOPPED = "STOPPED";
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("ResourceDockerTask Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!options.totalCount) options.totalCount = false;

    let res = await this.api.qry(
      `
      query FindResourceDockerTasks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceDockerTaskFilter) {
        resourceDockerTasks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.resourceDockerTasks;
  }
  getTypeLabel(type, subtype){
    let label = null;
    switch(type){
      case this.TYPE_PIPELINE_BLOCK_INSTANCE:
        label = this.utils.str.capitalize(subtype.toLowerCase());
        break;
      case this.TYPE_APP_MODULE_SERVER:
        label = this.utils.str.capitalize(subtype.toLowerCase());
        break;
      case this.TYPE_APP_MODULE_INSTALLER:
        label = "Installer";
        break;
    }
    return label;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_RUNNING:
        label = "Running";
        break;
      case this.STATE_STOPPED:
        label = "Stopped";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.STATE_RUNNING:
        color = "green";
        break;
      case this.STATE_STOPPED:
        color = "red";
        break;
    }
    return color;
  }
};