import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  DatePickerField,
  TimePickerField,
  FieldGroup,
} from "../core";

export default R14.connect(
  class ThemeEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.formData = this.props.formData || {};
      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;

      //if (!this.isEditMode) this.formData.values = Theme.colors;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.timesheet[editMethod](form.values);
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "timesheet";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect reload to={this.props.redirect || "timesheet"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }

    render() {
      return (
        <Form
          name='themeForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <FieldGroup>
            <DatePickerField
              name='date'
              label='Date'
              textInputEditable
              required='Please select a date.'
            />

            <TimePickerField
              name='startAt'
              label='Start Time'
              visible='True'
              required='Please select a start time.'
            />

            <TimePickerField
              name='endAt'
              label='End Time'
              required='Please select an end time.'
            />
          </FieldGroup>

          <FieldGroup>
            <TextInputField name='projectName' label='Project' />
            <TextInputField name='clientName' label='Client' />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            multiline
            autoSize
            required='Please add a description.'
          />
        </Form>
      );
    }
  }
);
