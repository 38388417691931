import React from "react";
import R14, {
  StyleSheet,
  Colors,
  Chart,
  ScreenLayout,
  IconButton,
  View,
  Surface,
  SectionHeader,
  Text,
  PopUpMenu,
  PopUpMenuItem,
  ShowHideText
} from "../core";
import Dashboard from "../components/Dashboard";

import DashboardWidget from "../components/DashboardWidget";
import TodoDataTable from "../components/TodoDataTable";

export default R14.connect(
  class ResourceScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    renderMenu() {
      let resource = this.resource.state;
      let items = [];
      if (!resource.sshKeyUid) {
        items.push(
          <PopUpMenuItem
            label='Add SSH Key'
            icon='lock'
            key='updateSshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyAdd", {
                uid: resource.uid
              })
            }
          />
        );
      } else {
        items.push(
          <PopUpMenuItem
            label='Manage SSH Key'
            icon='consoleNetwork'
            key='sshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyManage", {
                uid: resource.uid,
                sshKeyUid: resource.sshKeyUid
              })
            }
          />
        );
      }
      return (
        <PopUpMenu
          controlIcon='dotsVertical'
          key='adminMenu'
          direction='downLeft'
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "todoCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <Dashboard name='todosBoard' minWidth={256} maxColumns={4}>
            <DashboardWidget>
              <TodoDataTable
                name='todosTodo'
                title={this.props.app.dm.todo.getStatusLabel(
                  this.props.app.dm.todo.STATUS_TODO
                )}
                widget={true}
                status={this.props.app.dm.todo.STATUS_TODO}
              />
            </DashboardWidget>
            <DashboardWidget>
              <TodoDataTable
                name='todosActive'
                title={this.props.app.dm.todo.getStatusLabel(
                  this.props.app.dm.todo.STATUS_ACTIVE
                )}
                widget={true}
                status={this.props.app.dm.todo.STATUS_ACTIVE}
              />
            </DashboardWidget>
            <DashboardWidget>
              <TodoDataTable
                name='todosReview'
                title={this.props.app.dm.todo.getStatusLabel(
                  this.props.app.dm.todo.STATUS_REVIEW
                )}
                widget={true}
                status={this.props.app.dm.todo.STATUS_REVIEW}
              />
            </DashboardWidget>
            <DashboardWidget>
              <TodoDataTable
                name='todosComplete'
                title={this.props.app.dm.todo.getStatusLabel(
                  this.props.app.dm.todo.STATUS_COMPLETE
                )}
                widget={true}
                status={this.props.app.dm.todo.STATUS_REVIEW}
              />
            </DashboardWidget>
          </Dashboard>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  headerStateIndicatorIcon: {
    ...StyleSheet.margin(8, 12, 8, 16)
  },
  resource: {
    ...StyleSheet.margin(16)
  },
  dashboardCardSshKey: {
    flex: 0,
    flexDirection: "row"
  },
  dashboardCardInfo: {
    ...StyleSheet.padding(16, 64, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(16, 0, 0, 0)
        };
    }
  },
  dashboardCardInfoHeader: {
    fontSize: 14
  }
});
