import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  View,
  AnimatedView,
  Text,
  InfiniteList
} from "../core";

export default R14.connect(
  class MessageList extends React.Component {
    constructor(props) {
      super(props);
      this.messageDomain = this.props.app.dm.message;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.rowRenderer = this.rowRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      // this.handleRemovePress = this.handleRemovePress.bind(this);
      this.state = {
        newMessages: []
      };
      this.messageDomain.onCreateMessageGroupMessage(
        this.props.messageGroupUid,
        message => {
          this.addNewMessage(message);
        }
      );
    }
    async getPageData({ page, rowsPerPage, search = null }, options = {}) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.messageGroupUid)
        filter.messageGroupUid = { eq: this.props.messageGroupUid };
      let res = await this.props.app.dm.message.find(
        `
        uid
        text
        createdAt
        user {
          uid
          name
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: "createdAt",
              order: "DESC"
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get infiniteList() {
      return this.props.app.ui.infiniteList("messages");
    }
    addNewMessage(message) {
      let newMessages = this.state.newMessages;
      newMessages.push(message);
      this.setState({
        newMessages: newMessages
      });
    }
    rowRenderer({ row }) {
      return this.renderMessage(row);
    }
    renderMessage(message) {
      return (
        <View key={message.uid}>
          <View>
            <Text style={[styles.messageUserName]}>{message.user.name}</Text>
          </View>
          <View>
            <Text style={[styles.messageText]}>{message.text}</Text>
          </View>
        </View>
      );
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    // async handleRemovePress(uid, list) {
    //   if (window.confirm("Are you sure?")) {
    //     await this.props.app.dm.message.delete(uid);
    //     list.refresh({ indicator: true });
    //   }
    // }
    renderFooter() {
      return (
        <View>
          {this.state.newMessages.map(message => this.renderMessage(message))}
          <Text>Typing...</Text>
        </View>
      );
    }
    render() {
      return (
        <InfiniteList
          name='messages'
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          rowRenderer={this.rowRenderer}
          initialRowsPerPage={25}
          headerTitle={this.props.title || "Messages"}
          ListFooterComponent={this.renderFooter()}
          // headerControlsRight={[
          //   <IconButton
          //     icon='filter'
          //     key='filter'
          //     onPress={() => {
          //       this.props.app.ui.list(this.props.name).showFilters();
          //     }}
          //   />,
          //   <PopUpMenu
          //     controlIcon='dotsVertical'
          //     key='adminMenu'
          //     direction='downLeft'
          //     onSelect={(value, label) => {}}
          //   >
          //     <PopUpMenuItem
          //       icon='add'
          //       iconSize='small'
          //       label='Create Message'
          //       value='test1'
          //       to='messageCreate'
          //       key='add'
          //     />
          //   </PopUpMenu>
          // ]}
          // rowControlsRight={(row, list) => {
          //   return [
          //     <IconButton
          //       icon='edit'
          //       size='small'
          //       key='edit'
          //       onPress={() =>
          //         this.props.app.nav.to("messageEdit", {
          //           uid: row.uid
          //         })
          //       }
          //     />,
          //     <PopUpMenu
          //       controlIcon='dotsHorizontal'
          //       controlIconSize='small'
          //       key='messageMenu'
          //       direction='downLeft'
          //     >

          //       <PopUpMenuItem
          //         icon='close'
          //         label='Remove'
          //         key='remove'
          //         onPress={() => this.handleRemovePress(row.uid, list)}
          //       />
          //     </PopUpMenu>
          //   ];
          // }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  message: {
    paddingBottom: 16
  },
  messageText: {
    
  },
  messageUserName: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
