import R14 from "../core";

export default class HealthCheckDomain extends R14.Domain {
  constructor(key) {
    super();
    this.CONNECTION_TYPE_SFTP = "SFTP";
    this.TYPE_PROJECT = "PROJECT";
    this.TYPE_RESOURCE = "RESOURCE";
    this.STATE_PASS = "PASS";
    this.STATE_FAIL = "FAIL";
    this.state = {};
  }
  async find(fields, options = null) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!fieldsStr)
      throw new Error("ProjectHealthCheck Domain Find Error: No fields found");

    let res = await this.api.qry(
      `
      query FindHealthChecks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: HealthCheckFilter) {
        healthChecks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.healthChecks;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_PASS:
        label = "Pass";
        break;
      case this.STATE_FAIL:
        label = "Fail";
        break;
      default:
        label = "N/A";
    }

    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_PASS:
        color = "green";
        break;
      case this.STATE_FAIL:
        color = "red";
        break;
      default:
        color = "yellow";
    }
    return color;
  }
  getStateSelections(){
    return [
      {
        label: this.getStateLabel(this.STATE_PASS),
        value: this.STATE_PASS
      },
      {
        label: this.getStateLabel(this.STATE_FAIL),
        value: this.STATE_FAIL
      }
    ];
  }
}
