import R14 from "../core";

export default class SshKeyDomain extends R14.Domain {
  constructor(key) {
    super();
    this.TYPE_AWS = "AWS";
    this.TYPE_AZURE = "AZURE";
    this.TYPE_GCP = "GCP";

    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindSshKeys($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: SshKeyFilter) {
        sshKeys(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.sshKeys;
  }
  async fetchEditFormData(uid = null) {
    console.log();
    let res = uid
      ? await this.api.qry(
          `
      query SshKeyEditFormData($uid: ID!) {
        sshKey(uid: $uid){
          uid
          name
          description
          username
          port
          privateKey
          active
        }
      }`,
          {
            uid: uid
          }
        )
      : {};
    let formVals = (res && res.data && res.data.sshKey) || {};
    if (!uid) {
      formVals.port = "22";
    }
    return {
      values: formVals
    };
  }
  async create(values, { resourceUid = null }) {
    // Check for resourceUid. If exists, create resourceLog
    if (resourceUid)
      values.resources = {
        add: [resourceUid]
      };
    values.clientUid = this.dm.userSession.clientUid;

    console.log("CREATE KEY WITH VALUES", values);

    let res = await this.api.mutate(
      `
      mutation CreateSshKey($input: CreateSshKeyInput!) {
        createSshKey(input: $input){
          sshKey {
            uid
            name
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateSshKey($input: UpdateSshKeyInput!) {
        updateSshKey(input: $input){
          sshKey {
            uid
            name
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteSshKey($uid: ID!) {
        deleteSshKey(uid: $uid){
          sshKey {
            uid
            name
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
}
