import R14 from "../R14";
import { Colors } from "../components/Theme";
import StyleSheet from "../components/StyleSheet";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default class ChartUiDomain extends R14.DomainInstances {
  create(props) {
    let chart = new ChartUiInstanceDomain(props);
    this.addInstance(props.name, chart);
    return chart;
  }
}

export class ChartUiInstanceDomain extends R14.Domain {
  constructor(props) {
    super();
    if (!props.name) throw "Chart Error: Chart must have a name.";
    this._name = props.name;
    this._props = props;
    this.state = {};
  }
  get domElementId() {
    return `chart_${this._name}`;
  }
  remove() {
    if (this.chart) this.chart.dispose();
  }
  initialize(data = {}) {
    // am4core.useTheme(am4themes_animated);

    // Create chart instance
    var chart = am4core.create(this.domElementId, am4charts.XYChart);
    //chart.paddingRight = 20;
    /* Create a cursor */
chart.cursor = new am4charts.XYCursor();

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.labels.template.fontSize = 13;
    // dateAxis.skipEmptyPeriods = true;
    // dateAxis.baseInterval = {
    //   timeUnit: "minute",
    //   count: 5
    // };
    if(this._props.dateAxis){
      // let range = dateAxis.axisRanges.create();
      // range.date = this._props.dateRange.start;
      // range.endValue = this._props.dateRange.end;
      if(this._props.dateAxis.dateRangeStart) dateAxis.min = this._props.dateAxis.dateRangeStart.getTime();
      if(this._props.dateAxis.dateRangeEnd) dateAxis.max = this._props.dateAxis.dateRangeEnd.getTime();
      if(this._props.dateAxis.dateRangeStart && this._props.dateAxis.dateRangeEnd) dateAxis.strictMinMax = true;
      if(this._props.dateAxis.intervalUnit && this._props.dateAxis.intervalValue){
        dateAxis.baseInterval = {
          timeUnit: this._props.dateAxis.intervalUnit,
          count: this._props.dateAxis.intervalValue
        };
      }
      if(this._props.dateAxis.labelFormats){
        for(let key in this._props.dateAxis.labelFormats){
          dateAxis.dateFormats.setKey(key, this._props.dateAxis.labelFormats[key]);
        }
      }
    }

    dateAxis.renderer.labels.template.fill = Colors.onBackground.toString();
    dateAxis.renderer.grid.template.stroke = Colors.onBackground.toString();

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = Colors.onBackground.toString();
    valueAxis.renderer.grid.template.stroke = Colors.onBackground.toString();
    valueAxis.renderer.labels.template.fontSize = 13;


    // Create series
    if (data && data.nodes) {
      data.nodes.forEach(node => {
        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        
        series.connect = false;
        series.autoGapCount = 1;
        series.name = node.name;
        series.tooltipText = `${node.name}: [bold]{valueY}[/]`;
        series.cursorTooltipEnabled = true;
        // series.tooltip.pointerOrientation = "vertical";
        series.snapTooltip = true;
        series.tooltipPosition="fixed";
        // console.log("tooltiptext",node.name);
        
        series.strokeWidth = 1.5;
        
        // series.tensionX = 0.8;
        // series.bullets.push(new am4charts.CircleBullet());
        let seriesData = [];
        if(node && node.data && node.data.length) node.data.forEach(val => {
          seriesData.push({
            date: new Date(val.x),
            value: parseFloat(val.y)
          });
        });
        series.data = seriesData;
      });
    }

    //     /**
    //  * ---------------------------------------
    //  * This demo was created using amCharts 4.
    //  *
    //  * For more information visit:
    //  * https://www.amcharts.com/
    //  *
    //  * Documentation is available at:
    //  * https://www.amcharts.com/docs/v4/
    //  * ---------------------------------------
    //  */

    // // Use themes
    // am4core.useTheme(am4themes_animated);

    // // Create chart instance
    // var chart = am4core.create(this.domElementId, am4charts.XYChart);
    // chart.paddingRight = 20;

    // // Create axes
    // var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.renderer.minGridDistance = 50;
    // dateAxis.renderer.grid.template.location = 0.5;
    // dateAxis.startLocation = 0.5;
    // dateAxis.endLocation = 0.5;

    // // Create value axis
    // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // // Create series
    // if(data.nodes){
    //   data.nodes.forEach(node=>{
    //     let series = chart.series.push(new am4charts.LineSeries());
    //     let data = [];
    //     series.dataFields.dateX = "date";
    //     series.strokeWidth = 3;
    //     series.tensionX = 0.8;
    //     series.bullets.push(new am4charts.CircleBullet());
    //     node.data.forEach(val=>{
    //       console.log(val);
    //       data.push({
    //         date: new Date(val.x),
    //         value: parseFloat(val.y)
    //       });
    //     });
    //     console.log('SETTING SERIES DATA',data);
    //     //series.data = data;
    //     series.data = [{
    //       "date": new Date(2018, 3, 20),
    //       "value": 90
    //     }, {
    //       "date": new Date(2018, 3, 23),
    //       "value": 125
    //     }, {
    //       "date": new Date(2018, 3, 26),
    //       "value": 77
    //     }, {
    //       "date": new Date(2018, 3, 28),
    //       "value": 113
    //     }];
    //   });
    // }

    // var series1 = chart.series.push(new am4charts.LineSeries());
    // series1.dataFields.valueY = "value";
    // series1.dataFields.dateX = "date";
    // series1.strokeWidth = 3;
    // series1.tensionX = 0.8;
    // series1.bullets.push(new am4charts.CircleBullet());
    // series1.data = [{
    //   "date": new Date(2018, 3, 20),
    //   "value": 90
    // }, {
    //   "date": new Date(2018, 3, 23),
    //   "value": 125
    // }, {
    //   "date": new Date(2018, 3, 26),
    //   "value": 77
    // }, {
    //   "date": new Date(2018, 3, 28),
    //   "value": 113
    // }];

    // var series2 = chart.series.push(new am4charts.LineSeries());
    // series2.dataFields.valueY = "value";
    // series2.dataFields.dateX = "date";
    // series2.strokeWidth = 3;
    // series2.tensionX = 0.8;
    // series2.bullets.push(new am4charts.CircleBullet());
    // series2.data = [{
    //   "date": new Date(2018, 3, 22),
    //   "value": 101
    // }, {
    //   "date": new Date(2018, 3, 23),
    //   "value": 79
    // }, {
    //   "date": new Date(2018, 3, 24),
    //   "value": 90
    // }, {
    //   "date": new Date(2018, 3, 25),
    //   "value": 60
    // }, {
    //   "date": new Date(2018, 3, 26),
    //   "value": 115
    // }];

    //     let chart = am4core.create(this.domElementId, am4charts.XYChart);

    //     // let data = [];
    //     // let visits = 10;
    //     let chartData = [];
    //     for (let i in data) {
    //       chartData.push({
    //         date: new Date(data[i].x),
    //         name: "name" + i,
    //         value: data[i].y
    //       });
    //     }
    //     chart.data = chartData;

    //     let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //     dateAxis.baseInterval = {
    //       "timeUnit": "minute",
    //       "count": 5
    //     }
    //     dateAxis.renderer.labels.template.fill = Colors.onBackground;
    //     dateAxis.renderer.grid.template.stroke = Colors.onBackground;

    //     // dateAxis.renderer.grid.template.location = 0;

    //     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //     valueAxis.tooltip.disabled = true;
    //     // valueAxis.renderer.minWidth = 35;

    //     let series = chart.series.push(new am4charts.LineSeries());
    //     series.dataFields.dateX = "date";
    //     series.dataFields.valueY = "value";

    //     valueAxis.renderer.labels.template.fill = Colors.onBackground;
    //     valueAxis.renderer.grid.template.stroke = Colors.onBackground;

    //     // series.tooltipText = "{valueY.value}";
    //     // chart.cursor = new am4charts.XYCursor();

    //     // let scrollbarX = new am4charts.XYChartScrollbar();
    //     // scrollbarX.series.push(series);
    //     // chart.scrollbarX = scrollbarX;

    this.chart = chart;
  }
}
