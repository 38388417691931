import React from "react";
import R14, {
  ScreenLayout,
  ExternalLink,
  StyleSheet,
  Colors,
  SectionHeader
} from "../core";
import LabelView from "../components/LabelView";
export default R14.connect(
  class ProjectDocsScreen extends React.Component {
    render() {
      return (
        <ScreenLayout scrollEnabled style={styles.screenLayout}>
          <SectionHeader style={styles.sectionHeader} level={1}>R14 Development Documentation</SectionHeader>
          <LabelView style={styles.labelView} key='app' label='R14 React App'>
            <ExternalLink
              url='https://github.com/hgmventures/r14-docs/blob/master/r14-react-app-readme.md'
              style={styles.externalLink}
            />
          </LabelView>
          <LabelView
            style={styles.labelView}
            key='components'
            label='R14 React App Components'
          >
            <ExternalLink
              url='https://r14.dev/docs/dev/components/index.html'
              style={styles.externalLink}
            />
          </LabelView>
          <LabelView style={styles.labelView} key='server' label='R14 Server'>
            <ExternalLink
              url='https://github.com/hgmventures/r14-docs/blob/master/r14-server-readme.md'
              style={styles.externalLink}
            />
          </LabelView>
        </ScreenLayout>
      );
    }
  }
);
let styles = StyleSheet.create({
  screenLayout: {
    ...StyleSheet.padding(16, 16, 16, 16)
  },
  sectionHeader: {
    fontSize: 18
  },
  externalLink: {
    color: Colors.onBackground
  },
  labelView: {
    ...StyleSheet.padding(16, 0, 24, 0)
  }
});
