import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem
} from "../core";
import StateIndicatorIcon from "../components/StateIndicatorIcon";
import ResourceControls from "../components/ResourceControls";
export default R14.connect(
  class ResourceScreenLayout extends React.Component {
    constructor(props) {
      super(props);
      this.resourceDomain = this.props.app.dm.resource;
      this.perms = this.props.app.dm.user.getPermissions("resource");
      this.sshKeyPerms = this.props.app.dm.user.getPermissions(
        "resource",
        "sshKey"
      );
    }
    // componentWillUnmount() {
    //   console.error('resource is being removed from domain, please check error.');
    //   this.props.resource.remove();
    // }
    renderMenu() {
      if (!this.sshKeyPerms.edit || !this.sshKeyPerms.create) return null;
      let resource = this.props.resource.state;
      let items = [];
      if (!resource.sshKeyUid && this.sshKeyPerms.create)
        items.push(
          <PopUpMenuItem
            label='Add SSH Key'
            icon='lock'
            key='updateSshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyAdd", {
                uid: resource.uid
              })
            }
          />
        );
      else if (this.sshKeyPerms.edit)
        items.push(
          <PopUpMenuItem
            label='Manage SSH Key'
            icon='consoleNetwork'
            key='sshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyManage", {
                uid: resource.uid,
                sshKeyUid: resource.sshKeyUid
              })
            }
          />
        );

      return (
        <PopUpMenu
          controlIcon='dotsVertical'
          key='adminMenu'
          direction='downLeft'
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    render() {
      let resource = this.props.resource.state;
      return (
        <ScreenLayout
          floatingActionButton={this.props.floatingActionButton}
          headerTitle={resource.name}
          headerControlsLeft={[
            <StateIndicatorIcon
              color={this.props.app.dm.resource.getStateIndicatorColor(
                resource.state
              )}
              style={styles.headerStateIndicatorIcon}
              key='resourceStateIndicator'
            />
          ]}
          headerControlsRight={[
            this.perms.execute && (
              <ResourceControls
                key='resourceControls'
                resourceType={resource.type}
                resourceUid={resource.uid}
                resourceState={resource.state}
              />
            ),
            this.renderMenu()
          ]}
          scrollEnabled
        >
          {this.props.children}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  headerStateIndicatorIcon: {
    ...StyleSheet.margin(8, 12, 8, 16)
  }
});
