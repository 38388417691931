import React from 'react';
import R14, { ScreenLayout} from '../core';
import SshKeyDataTable from '../components/SshKeyDataTable';
export default R14.connect(
  class SshKeysScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "sshKeyCreate",
            icon: "add"
          }}
          scrollEnabled
        >
          <SshKeyDataTable />
        </ScreenLayout>
      );
    }
  }
);