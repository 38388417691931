import R14, { AsyncStorage } from "../core";

export default class UserSessionDomain extends R14.Domain {
  constructor() {
    super();
    this.state = {
      uid: null,
      username: null,
      name: null,
      clientUid: null,
      clientName: null,
      loggedIn: null,
      role: null,
      loggedIn: false,
      permissions: {}
    };
  }
  async domainDidLoad() {
    let promises = [];
    let userState = {
      uid: (await AsyncStorage.getItem("uid")) || null,
      username: (await AsyncStorage.getItem("username")) || null,
      name: (await AsyncStorage.getItem("name")) || null,
      clientUid: (await AsyncStorage.getItem("clientUid")) || null,
      clientName: (await AsyncStorage.getItem("clientName")) || null,
      role: (await AsyncStorage.getItem("role")) || null,
      loggedIn: await this.api.accessTokenExists(),
      permissions: {}
    };
    for (let i in userState) {
      promises.push(userState[i]);
    }
    await Promise.all(promises);
    if (userState.uid) {
      // Get permissions
      userState.permissions = await this.dm.user.fetchPermissions(
        userState.uid
      );
    }
    this.setState(userState);
    return true;
  }
  async auth({ email, password }) {
    await this.logout();
    let res = await this.api.mutate(
      `
        mutation AuthUser($input: AuthUserInput!) {
          authUser(input: $input){
            user {
              uid
              name
              role
              client {
                uid
                name
              }
              userPermissionGroup {
                uid
                name
                permissions {
                  type
                  subtype
                  access
                }
              }
            }
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          email: email,
          password: password
        }
      }
    );
    if (res.data.authUser.error) throw new Error(res.data.authUser.error);
    return await this._processAuthResult(res.data.authUser);
  }

  async switchClient(clientUid) {
    let res = await this.api.mutate(
      `
       mutation SwitchUserClient($clientUid: ID!) {
         switchUserClient(clientUid: $clientUid){
           user {
             uid
             name
             role
             client {
               uid
               name
             }
             userPermissionGroup {
              uid
              name
              permissions {
                type
                subtype
                access
              }
            }
           }
          accessToken
          success
          error
         }
       }`,
      {
        clientUid: clientUid
      }
    );
    if (res.error) throw new Error(res.error);
    return await this._processAuthResult(res.switchUserClient);
  }
  async _processAuthResult(res) {
    if (!res.user || !res.accessToken)
      throw new Error("Unknown Auth Error: No Access.");
    let user = res.user;
    if (!user || !user.uid) new Error("Unknown Auth Error: User not found.");
    if (!user.client || !user.client.uid)
      new Error("Unknown Auth Error: Client not found.");
    await this.api.setAccessToken(res.accessToken);

    this.setUid(user.uid);
    this.setName(user.name);
    // this.setUsername(user.username);
    this.setClientUid(user.client.uid);
    this.setClientName(user.client.name);
    this.setRole(user.role);
    this.setLoggedIn(await this.api.accessTokenExists());
    this.setPermissions(
      this.dm.user.parsePermissions(user.userPermissionGroup.permissions)
    );
    return this.isLoggedIn;
  }
  async logout() {
    await this.api.deleteAccessToken();
    this.setLoggedIn(false);
    return true;
  }
  async setUid(uid) {
    AsyncStorage.setItem("uid", uid);
    this.setState({ uid: uid });
  }
  get uid() {
    return this.state.uid;
  }
  async setRole(role) {
    AsyncStorage.setItem("role", role);
    this.setState({ role: role });
  }
  get role() {
    return this.state.role;
  }
  get permissions() {
    return this.state.permissions;
  }
  setPermissions(permissions) {
    this.setState({ permissions: permissions });
  }
  setName(name) {
    AsyncStorage.setItem("name", name);
    this.setState({ name: name });
  }
  get name() {
    return this.state.name;
  }
  // setUsername(username) {
  //   AsyncStorage.setItem('username', username);
  //   this.setState({ username: username });
  // }
  // get username() {
  //   return this.state.username
  // }
  setClientUid(clientUid) {
    AsyncStorage.setItem("clientUid", clientUid);
    this.setState({ clientUid: clientUid });
  }
  get clientUid() {
    return this.state.clientUid;
  }
  setClientName(clientName) {
    AsyncStorage.setItem("clientName", clientName);
    this.setState({ clientName: clientName });
  }
  get clientName() {
    return this.state.clientName;
  }
  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn
    });
  }
  get isLoggedIn() {
    return this.state.loggedIn;
  }
}
