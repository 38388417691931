import React from "react";
import PropTypes from "prop-types";
import R14App from "../R14App";
import { Provider } from "mobx-react";
import AppBase from "./base/AppBase";
export { default as App } from "./base/AppBase";
// import StyleSheet from './StyleSheet';

/** The main app component for the R14 React App. Should be placed inside App.js. */
export default class App extends React.PureComponent {
  static propTypes = {
    /** Main configuration options */
    config: PropTypes.object.isRequired,
    /** Routing configuration.  */
    routes: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    /** IN DEVELOPMENT Callback called when moving back in screens. Return false for default, or an object {x,y} to manually set the scroll position.
     *
     * @param {Object} from The previous route
     * @param {Object} to The route that is loading
     * @param {Object} savedPosition The saved scroll position
     */
    scrollBehavior: PropTypes.func,
    /** IN DEVELOPMENT Callback called before a route is loaded.
     *
     * @param {Object} from The previous route
     * @param {Object} to The route that will load
     * @param {Object} app The r14 app instance
     */
    shouldActionLoad: PropTypes.func,
    /** IN DEVELOPMENT Callback called before if a route will be loaded.
     *
     * @param {Object} from The previous route
     * @param {Object} to The route that has loaded
     * @param {Object} app The r14 app instance
     */
    actionWillLoad: PropTypes.func,
    /** IN DEVELOPMENT Callback called before a route has loaded.
     *
     * @param {Object} from The previous route
     * @param {Object} to The route that has loaded
     */
    onLoaded: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.app = new R14App(this.props);
    this.state = {
      initialized: false,
    };
    this.initialize();
  }
  async initialize() {
    await this.app.initialize();
    this.setState({ initialized: true });
  }
  render() {
    /** @todo add a loader / loading screen? */
    if (!this.state.initialized) return null;
    return (
      <Provider app={this.app} r14={this.app.r14Instance}>
        <AppBase {...this.props} />
      </Provider>
    );
  }
}
