import React from "react";
import PropTypes from "prop-types";
import View from "./View";
import Surface from "./Surface";
import SectionHeader from "./SectionHeader";
import ControlsView from "./ControlsView";
import StyleSheet, { css } from "./StyleSheet";
import R14 from "../R14";
// import { Colors } from '../styles/Theme';

/** A surface based card component */
export default R14.connect(
  class ScreenHeader extends React.Component {
    static propTypes = {
      /** Optional title for the card. */
      title: PropTypes.string,
      /** Controls such as buttons, icon buttons, etc... Placed at the right of the title.*/
      consolsLeft: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      /** Controls such as buttons, icon buttons, etc... Placed at the right of the title.*/
      controlsRight: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      /** Component to be displayed on top of header, like bread crumbs*/
      RowTopComponent: PropTypes.node,
      /** The elevation (z index shadow effect) of the surface. */
      elevation: PropTypes.number,
    };
    render() {
      return (
        <Surface
          style={[styles.screenHeader, this.props.style]}
          elevation={this.props.elevation || 4}
        >
          {this.props.RowTopComponent ? this.props.RowTopComponent : null}
          {(this.props.controlsLeft ||
            this.props.TitleComponent ||
            this.props.title ||
            this.props.controlsRight) && (
            <View style={styles.content}>
              {this.props.controlsLeft && (
                <ControlsView style={styles.controlsLeft} align='left'>
                  {this.props.controlsLeft}
                </ControlsView>
              )}
              {this.props.TitleComponent ? this.props.TitleComponent : null}
              {!this.props.TitleComponent && (
                <SectionHeader style={styles.title} level={1}>
                  {this.props.title}
                </SectionHeader>
              )}
              {this.props.controlsRight && (
                <ControlsView style={styles.controlsRight} align='right'>
                  {this.props.controlsRight}
                </ControlsView>
              )}
            </View>
          )}
        </Surface>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenHeader: {
    marginBottom: 16,
    ...StyleSheet.padding(0),
  },
  content: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    ...StyleSheet.padding(16),
  },
  controlsRight: {
    marginLeft: "auto",
  },
  title: {
    fontSize: 20,
    paddingBottom: 0,
    lineHeight: 36,
  },
});
