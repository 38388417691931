import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class EventTaskDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
      this.eventTaskDomain = this.props.app.dm.eventTask;
      this.eventTaskDomain = this.props.app.dm.eventTask;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("resource", "event");
      this.state = {};
    }
    getEventTaskAddRoute(){
      let ret = null;
      switch(this.type){
        case this.eventTaskDomain.TYPE_RESOURCE:
          ret = {
            route: "resourceEventTasksAdd",
            params: { uid: this.props.resource.uid }
          };
          break;
        case this.eventTaskDomain.TYPE_PROJECT:
          throw new Error('TODO');
          ret = {
            route: "eventTasksAdd",
            params: { uid: this.props.resource.uid }
          }
          break;
        default:
          throw new Error(`No Event Task route for type ${this.type}`);
      }
      return ret;
    }

    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        resourceUid: { eq: this.props.resourceUid }
      };
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };

      let res = await this.props.app.dm.eventTask.find(
        `
        state
        uid
        event {
          uid
          name
          description
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          state: row.state,
          name: row.event.name,
          description: row.event.description,
          eventUid: row.event.uid
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("eventTasks");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "state":
          ret = (
            <StateIndicatorText
              label={this.eventTaskDomain.getStateLabel(cell)}
              color={this.eventTaskDomain.getStateIndicatorColor(cell)}
            />
          );
          break;
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.eventTask.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}
    async handleRunPress(uid) {
      this.props.app.ui.progressIndicator.show();
      await this.eventTaskDomain.run(uid);
      await this.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide();
    }
    async handleShowLogPress(uid) {
      this.props.app.nav.to("eventTaskLog", { uid: uid });
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <DataTable
          name='eventTasks'
          columns={{
            name: {
              label: "Event",
              sortable: true
            },
            state: {
              label: "State",
              sortable: true
            }
          }}
          searchable
          maxHeight={300}
          autoRefresh={30}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Events"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          headerControlsRight={
            this.perms.create &&
            this.props.widget !== false && [
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={() =>
                  this.props.app.nav.to("resourceEventTaskAdd", {
                    uid: this.props.resourceUid
                  })
                }
              />
            ]
          }
          rowControlsRight={
            (this.perms.edit || this.perms.delete) &&
            ((row, dataTable) => {
              return [
                <PopUpMenu
                  controlIcon='dotsHorizontal'
                  controlIconSize='small'
                  key='resourceMenu'
                  direction='downLeft'
                >
                  {this.perms.edit && (
                    <PopUpMenuItem
                      label='Edit'
                      icon='edit'
                      key='edit'
                      onPress={() =>
                        this.props.app.nav.to(
                          this.props.editAction || "resourceEventTaskEdit",
                          {
                            uid: this.props.resourceUid,
                            eventUid: row.eventUid
                          }
                        )
                      }
                    />
                  )}
                  {this.perms.delete && (
                    <PopUpMenuItem
                      label='Remove'
                      icon='close'
                      key='remove'
                      onPress={() => this.handleRemovePress(row.uid, dataTable)}
                    />
                  )}
                </PopUpMenu>
              ];
            })
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
