import React from "react";
import R14, {
  StyleSheet,
  Colors,
  Chart,
  ScreenLayout,
  IconButton,
  View,
  Surface,
  SectionHeader,
  Text,
  PopUpMenu,
  PopUpMenuItem,
  ShowHideText,
} from "../core";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import ResourceMetricsChart from "../components/ResourceMetricsChart";
import ResourceProcessMetricsChart from "../components/ResourceProcessMetricsChart";
import ResourceScreenLayout from "../components/ResourceScreenLayout";

export default R14.connect(
  class ResourceScreen extends React.Component {
    constructor(props) {
      super(props);
      if (!this.props.resource || !this.props.resource.state.uid)
        throw new Error("Resource not found.");
      this.resourceDomain = this.props.app.dm.resource;
      this.resource = this.props.resource;
    }
    // componentWillUnmount() {
    //   console.error("resource is being removed from domain, please check");
    //   this.resource.remove();
    // }
    renderMenu() {
      let resource = this.resource.state;
      let items = [];
      if (!resource.sshKeyUid) {
        items.push(
          <PopUpMenuItem
            label='Add SSH Key'
            icon='lock'
            key='updateSshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyAdd", {
                uid: resource.uid,
              })
            }
          />
        );
      } else {
        items.push(
          <PopUpMenuItem
            label='Manage SSH Key'
            icon='consoleNetwork'
            key='sshKey'
            onPress={() =>
              this.props.app.nav.to("resourceSshKeyManage", {
                uid: resource.uid,
                sshKeyUid: resource.sshKeyUid,
              })
            }
          />
        );
      }
      return (
        <PopUpMenu
          controlIcon='dotsVertical'
          key='adminMenu'
          direction='downLeft'
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    renderMetricCharts() {
      let resource = this.resource.state;
      let metrics = [];
      switch (resource.type) {
        case this.resourceDomain.TYPE_EC2_INSTANCE:
        case this.resourceDomain.TYPE_AZURE_VM:
        case this.resourceDomain.TYPE_R14_COMPUTER:
          metrics = [
            this.resourceDomain.METRIC_CPU_UTILIZATION,
            //this.resourceDomain.METRIC_MEMORY_UTILIZATION,
            this.resourceDomain.METRIC_DISK_READ_OPS,
            this.resourceDomain.METRIC_DISK_WRITE_OPS,
            this.resourceDomain.METRIC_DISK_READ_BYTES,
            this.resourceDomain.METRIC_DISK_WRITE_BYTES,
            this.resourceDomain.METRIC_NETWORK_IN,
            this.resourceDomain.METRIC_NETWORK_OUT,
          ];
          resource.type === this.resourceDomain.TYPE_R14_COMPUTER &&
            metrics.splice(1, 0, this.resourceDomain.METRIC_MEMORY_UTILIZATION);

          break;
        case this.resourceDomain.TYPE_EFS_FILESYSTEM:
          metrics = [
            this.resourceDomain.METRIC_CLIENT_CONNECTIONS,
            this.resourceDomain.METRIC_DATA_WRITE_IO_BYTES,
            this.resourceDomain.METRIC_DATA_READ_IO_BYTES,
          ];
          break;
      }

      return metrics.map((metric) => (
        <DashboardWidget key={metric}>
          <ResourceMetricsChart
            resourceUid={resource.uid}
            metric={metric}
            initialData={this.props.metrics[metric]}
          />
        </DashboardWidget>
      ));
    }
    render() {
      let resource = this.resource.state;
      return (
        <ResourceScreenLayout resource={this.props.resource}>
          <Dashboard name='resourceMonitor' minWidth={352}>
            {/* <DashboardWidget>
              <ResourceMetricsChart
                resourceUid={resource.uid}
                metric={this.resourceDomain.METRIC_CPU_UTILIZATION}
              />
            </DashboardWidget> */}
            {(resource.sshKeyUid ||
              resource.type === this.resourceDomain.TYPE_R14_COMPUTER) && (
              <DashboardWidget>
                <ResourceProcessMetricsChart resourceUid={resource.uid} />
              </DashboardWidget>
            )}
            {this.renderMetricCharts()}
          </Dashboard>
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
