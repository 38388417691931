import React from 'react';
import PropTypes from 'prop-types'
import StyleSheet from './StyleSheet';
import View from './View';

/**
* A container for the layout of controls (buttons, icons, etc...) Passes props to container.
*/
export default class ControlsView extends React.PureComponent {
  static propTypes = {
    /** Alignment of the controls container */
    align: PropTypes.oneOf(["left", "center", "right"]),
    /** Type of controls in the container, icons are automatically aligned */
    type: PropTypes.oneOf(["buttons", "icons"]),
    /** Controls such as buttons, icon buttons, etc... */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  }
  static defaultProps = {
    align: "left",
    type: "icons"
  }
  constructor(props) {
    super(props);
  }
  render() {
    let controlsViewStyles = [styles.controlsView];
    
    if (styles[this.props.align]) controlsViewStyles.push(styles[this.props.align]);
    if (this.props.type === "icons") {
      switch (this.props.align) {
        case "left":
          controlsViewStyles.push(styles.iconsLeft);
          break;
        case "right":
          controlsViewStyles.push(styles.iconsRight);
          break;
      }
    }
    if (this.props.style) controlsViewStyles.push(this.props.style);

    return (
      <View
        {...this.props}
        style={controlsViewStyles}
      >
        {this.props.children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  controlsView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  left: {
    //marginRight: "auto",
    justifyContent: "flex-start",
  },
  iconsLeft: {
    marginLeft: -8
  },
  center: {
    marginRight: "auto",
    marginLeft: "auto",
    justifyContent: "space-around"
  },
  right: {
    justifyContent: "flex-end",
  },
  iconsRight: {
    marginRight: -8
  }

});