import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField
} from "../core";
export default R14.connect(
  class TodoEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.formData = this.props.formData || {};
      console.log(this.formData);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    async handleSubmit(todoForm) {
      let isSuccess = this.isEditMode
        ? await this.props.app.dm.todo.update(todoForm.values)
        : await this.props.app.dm.todo.create(todoForm.values);
      if (this.props.app.ui.dataTable("todos")) {
        this.props.app.ui.dataTable("todos").refresh();
      }
      if (isSuccess) return <Redirect to={this.props.redirect || "todos"} />;
    }
    render() {
      return (
        <Form
          name='todoForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <SelectMenuField
            useValuesOnly
            name='type'
            label='Type'
            items={this.formData.typeSelections}
            required='Please select a type.'
            defaultValue={this.props.app.domain.todo.TYPE_TASK}
          />
          <TextInputField
            name='title'
            label='Title'
            required='Please enter a title...'
          />
          <TextInputField
            name='description'
            label='What needs to be done?'
            required='Please enter a description...'
            multiline
            autoSize
          />
          <SelectMenuField
            useValuesOnly
            name='status'
            label='Status'
            items={this.formData.statusSelections}
            required='Please select a status.'
            defaultValue={this.props.app.domain.todo.STATUS_TODO}
          />
          <SelectMenuField
            useValuesOnly
            name='priority'
            label='Priority'
            items={this.formData.prioritySelections}
            required='Please select a priority.'
            defaultValue={this.props.app.domain.todo.PRIORITY_NORMAL}
          />
          {/* <DatePickerField
            name='createdAt'
            label='Created At'
            mode='modal'
            // required="Please select a created at date."
            // autoFocus
            // autoSize
            // multiline
            // rowHeight={100}
            // maxRows={3}
          /> */}
        </Form>
      );
    }
  }
);
