import R14 from "../core";

export default class ResourceAlertDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindResourceAlerts($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ResourceAlertFilter) {
        resourceAlerts(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.resourceAlerts;
  }

  async add(values) {
    // Add client filter
    values.clientUid = this.dm.userSession.clientUid;

    let res = await this.api.mutate(
      `
      mutation AddResourceAlert($input: CreateResourceAlertInput!) {
        createResourceAlert(input: $input){
          resourceAlert {
            uid
            alert {
              uid
              name
            }
          }
        }
      }`,
      {
        input: values
      }
    );
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteResourceAlert($uid: ID!) {
        deleteResourceAlert(uid: $uid){
          resourceAlert {
            uid
          }
        }
      }`,
      {
        uid: uid
      }
    );
    return true;
  }
  async fetchLog(uid) {
    let res = await this.api.qry(
      `
      query FetchResourceAlertLog($uid: ID!) {
       resourceAlert(uid: $uid){
          log
        }
      }`,
      {
        uid: uid
      }
    );
    return res.data.resourceAlert && res.data.resourceAlert.log
      ? res.data.resourceAlert.log
      : "";
  }
  async fetchAlertAddFormData(uid = null) {
    let qry = "";
    let qryVals = {
      alertFilter: { clientUid: { eq: this.dm.userSession.clientUid } }
    };
    if (!uid) return {};
    qry = `
      query ResourceAlertAddFormData($alertFilter: AlertFilter){
        alerts(filter:$alertFilter){
          nodes {
            uid
            name
          }
        },
      }
    `;
    qryVals.uid = uid;

    let res = await this.api.qry(qry, qryVals);
    let formData = {
      values: {
        resourceUid: uid
      },
      alertSelections: res.alerts.nodes.map(val => ({
        label: val.name,
        value: val.uid
      }))
    };
    return formData;
  }
}
