import React from 'react';
import R14App from '../R14App';
import ProgressIndicator from './ProgressIndicator';

export default class RootComponents extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ProgressIndicator />
      </React.Fragment>
    );
  }
}