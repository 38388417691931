import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  ColorInputField,
  SectionHeader,
  StyleSheet,
  View,
  SwitchField,
  FileInputField,
  Image,
  Text,
  Colors,
} from "../core";
import PipelineBlockMetricsWidget from "../components/PipelineBlockMetricsWidget";

export default R14.connect(
  class projectPipelineBlockMetricsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <PipelineBlockMetricsWidget
          // title='Metrics'
          pipelineBlock={this.props.pipelineBlock}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  stats: {
    ...StyleSheet.padding(16, 16, 0, 16),
  },
  labelValue: {
    marginBottom: 16,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 8,
  },
  confusionMatrix: {
    width: 320,
    height: 268,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
    borderRadius: 4,
    overFlow: "hidden",
  },
  confusionMatrixImage: {
    display: "block",
    backgroundPosition: "center",
  },
  percentageMatrixText: {
    fontSize: 20,
  },
});
