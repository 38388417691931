import React from "react";
import StyleSheet from "../StyleSheet";
import View from "../View";
import R14 from "../../R14";

export default class ScrollViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.lastScrollTop = 0;
    this.scrollEnabled = true;
    this.doResetScroll = false;
    this.htmlRef = React.createRef();
    this.ref = React.createRef();
    this.contentHtmlRef = React.createRef();
    this.handleLayout = this.handleLayout.bind(this);
    this.handleContentSizeChange = this.handleContentSizeChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.lastContentSize = {
      x: null,
      y: null,
    };
  }
  componentDidMount() {}
  scrollTo(params) {
    if (!this.htmlRef.current) return false;
    let scrollParams = {
      left: params.x || 0,
      top: params.y || 0,
    };
    if (params.animated !== false) scrollParams.behavior = "smooth";
    this.htmlRef.current.scrollTo(scrollParams);
  }
  scrollToEnd(params = {}) {
    if (!this.htmlRef.current) return false;
    params.y =
      this.htmlRef.current.scrollHeight + this.htmlRef.current.clientHeight ||
      0;
    setTimeout(() => this.scrollTo(params));
  }
  handleLayout(val) {
    if (this.props.onLayout) return this.props.onLayout(val);
  }
  handleScroll() {
    this.triggerOnScroll();
  }
  triggerOnScroll() {
    this.props.onScroll &&
      this.props.onScroll({
        nativeEvent: {
          contentOffset: {
            x: this.htmlRef.current.scrollLeft,
            y: this.htmlRef.current.scrollTop,
          },
          layout: {
            width: this.htmlRef.current.offsetWidth,
            height: this.htmlRef.current.offsetHeight,
          },
          contentSize: {
            width: this.contentHtmlRef.current.scrollWidth,
            height: this.contentHtmlRef.current.scrollHeight,
          },
        },
      });
  }
  measureInWindow(callback) {
    if (this.ref && this.ref.current)
      this.ref.current.measureInWindow(callback);
  }
  measure(callback) {
    if (this.ref && this.ref.current) this.ref.current.measure(callback);
  }
  handleContentSizeChange(val) {
    /** @todo this is not tested */
    let contentSize = {
      width: val.nativeEvent.layout.width,
      height: val.nativeEvent.layout.height,
    };
    if (
      contentSize.width === this.lastContentSize.width &&
      contentSize.height === this.lastContentSize.height
    )
      return;
    this.lastContentSize = contentSize;
    if (this.props.onContentSizeChange)
      this.props.onContentSizeChange(contentSize.width, contentSize.height);
  }
  render() {
    let styles = this.styles();
    return (
      <View
        htmlRef={this.htmlRef}
        onLayout={this.props.onLayout && this.handleLayout}
        ref={this.ref}
        style={[
          styles.scrollView,
          !this.scrollEnabled && styles.disabled,
          this.props.style,
        ]}
        onScroll={this.props.onScroll && this.handleScroll}
      >
        <View
          style={styles.scrollViewContent}
          htmlRef={this.contentHtmlRef}
          onLayout={
            this.props.onContentSizeChange && this.handleContentSizeChange
          }
        >
          {this.props.children}
        </View>
      </View>
    );
  }
  styles() {
    /** @todo ScrollView render issue: when padding is added to scrollview, it behaves differantly than native, native overflows. */
    let height = this.props.height || null;
    if (this.props.autoSize) {
      height = "100%";
    }

    //let scrollViewStyle = {};
    // if (this.props.horizontal) {
    //   scrollViewStyle = {
    //     overflowY: "hidden",
    //     overflowX: "auto",
    //     height: this.props.height
    //   };
    // } else
    let overflowX = this.props.overflowX || this.props.overflow || "hidden"; 
    let overflowY = this.props.overflowY || this.props.overflow || "auto"; 
    if(this.props.horizontal){
      overflowX = "auto";
      overflowY = "hidden";
    }
    let scrollViewStyle = height
      ? {
          overflowY: overflowY,
          overflowX: overflowX,
          height: this.props.height,
        }
      : {
          overflowY: overflowY,
          overflowX: overflowX,
          position: "absolute",
          flex: 1,
          ...StyleSheet.absoluteFill,
        };

    if (this.props.showsHorizontalScrollIndicator === false) {
      scrollViewStyle["::-webkit-scrollbar"] = {
        width: 0,
        backgroundColor: "transparent",
      };
      scrollViewStyle["::-webkit-scrollbar *"] = {
        backgroundColor: "transparent",
      };
      scrollViewStyle.boxSizing = "content-box";
      // scrollViewStyle.height = 'auto';
      // scrollViewStyle.paddingBottom = 16;
    }
    return StyleSheet.create({
      scrollView: scrollViewStyle,
      disabled: {
        marginTop: `-${this.lastScrollTop}px`,
        position: "relative",
      },
      scrollViewContent: {
        flex: 1,
      },
    });
  }
}

// const TabNavigatorScrollView = R14.connect(
//   class TabNavigatorScrollView extends React.Component {
//     constructor(props) {
//       super(props);
//       this.scrollRef = React.createRef();
//       this.handleScroll = this.handleScroll.bind(this);
//       this.handleLayout = this.handleLayout.bind(this);
//       this.handleControlLeftPress = this.handleControlLeftPress.bind(this);
//       this.handleControlRightPress = this.handleControlRightPress.bind(this);
//       this.isInitialized = false;
//       this.offset = null;
//       this.contentSize = null;
//       this.size = null;
//       this.lastScrollEvent = null;
//       this.state = {
//         controlLeftVisible: false,
//         controlRightVisible: false,
//       };
//     }
//     componentDidMount() {
//       /** @todo This is a hack to get the initial position, check with react native and make it work the same */
//       //setTimeout(() => this.scrollRef.current.handleScroll(), 1);
//       // this.scrollRef.current.scrollTo({x: 10})
//       //setTimeout(() => this.updateScrollEvent(), 1);
//       // throw('FINISH ADDING ACTIVE SCROLLTO');
//       if (
//         this.props.tabNavigator &&
//         this.props.tabNavigator.activeItemRef.current
//       ) {
//         /** @todo Check to make sure that it is scrolling */
//         setTimeout(() => {
//           if (
//             this.props.tabNavigator &&
//             this.props.tabNavigator.activeItemRef &&
//             this.props.tabNavigator.activeItemRef.current
//           )
//             this.props.tabNavigator.activeItemRef.current.measure(
//               (offsetLeft, b, c, d) => {
//                 if (offsetLeft >= 32)
//                   this.scrollRef.current.scrollTo({ x: offsetLeft - 32 });
//               }
//             );
//         }, 100);
//       }
//     }
//     updateScrollEvent() {
//       this.scrollRef.current.triggerOnScroll();
//     }
//     handleLayout() {
//       this.updateScrollEvent();
//     }
//     handleScroll(ev) {
//       this.lastScrollEvent = ev.nativeEvent;
//       let width = ev.nativeEvent.layout.width;
//       let contentWidth = ev.nativeEvent.contentSize.width;
//       let offset = ev.nativeEvent.contentOffset;
//       let controlLeftVisible = false;
//       let controlRightVisible = false;
//       if (contentWidth > width) {
//         if (offset.x > 16) controlLeftVisible = true;
//         if (offset.x + width < contentWidth) controlRightVisible = true;
//       }
//       if (
//         this.state.controlLeftVisible !== controlLeftVisible ||
//         this.state.controlRightVisible !== controlRightVisible
//       )
//         this.setState({
//           controlLeftVisible: controlLeftVisible,
//           controlRightVisible: controlRightVisible,
//         });
//     }
//     handleControlLeftPress() {
//       let { contentOffset, contentSize, layout } = this.lastScrollEvent;
//       let scrollTo = contentOffset.x - layout.width * 0.8;
//       if (scrollTo < 0) scrollTo = 0;
//       this.scrollRef.current.scrollTo({ x: scrollTo });
//     }
//     handleControlRightPress() {
//       let { contentOffset, contentSize, layout } = this.lastScrollEvent;
//       let scrollTo = contentOffset.x + layout.width * 0.8;
//       //if(scrollTo )
//       console.log("SCROLLING TO", scrollTo);
//       if (scrollTo > contentSize.width) scrollTo = contentSize.width;
//       this.scrollRef.current.scrollTo({ x: scrollTo });
//     }
//     render() {
//       let styles = TabNavigatorScrollViewStyles;
//       return (
//         <View style={[styles.tabNavigatorScrollViewWrapper, this.props.style]}>
//           <ScrollView
//             onScroll={this.handleScroll}
//             ref={this.scrollRef}
//             style={styles.tabNavigatorScrollView}
//             horizontal={true}
//             showsHorizontalScrollIndicator={false}
//             onLayout={this.handleLayout}
//           >
//             {this.props.children}
//           </ScrollView>
//           <FadeView
//             visible={this.state.controlLeftVisible}
//             style={[styles.controls, styles.controlsLeft]}
//           >
//             <IconButton
//               style={styles.controlIcon}
//               onPress={this.handleControlLeftPress}
//               icon='keyboardArrowLeft'
//             />
//           </FadeView>
//           <FadeView
//             visible={this.state.controlRightVisible}
//             style={[styles.controls, styles.controlsRight]}
//           >
//             <IconButton
//               style={styles.controlIcon}
//               onPress={this.handleControlRightPress}
//               icon='keyboardArrowRight'
//             />
//           </FadeView>
//         </View>
//       );
//     }
//   }
// );
// const TabNavigatorScrollViewStyles = StyleSheet.create({
//   tabNavigatorScrollViewWrapper: {
//     flex: 1,
//     flexDirection: "row",
//     overflow: "hidden",
//     height: 56,
//     screen: ({ width, height }) => {
//       if (width <= 640) {
//         return { height: 48 };
//       }
//     },
//   },
//   tabNavigatorScrollView: {
//     paddingBottom: 128,
//   },
//   controls: {
//     position: "absolute",
//     flex: 1,
//     height: 56,
//     justifyContent: "space-around",
//     screen: ({ width, height }) => {
//       if (width <= 640) {
//         return { height: 48 };
//       }
//     },
//   },
//   controlIcon: {
//     ...StyleSheet.margin(0),
//   },
//   controlsLeft: {
//     left: 0,
//     screen: ({ width }) =>
//       width <= 640
//         ? {
//             backgroundImage: `linear-gradient(to right, ${Colors.surface}, ${Colors.surface}, transparent)`,
//           }
//         : {
//             backgroundImage: `linear-gradient(to right, ${Colors.background}, ${Colors.background}, transparent)`,
//           },
//   },
//   controlsRight: {
//     right: 0,
//     screen: ({ width }) =>
//       width <= 640
//         ? {
//             backgroundImage: `linear-gradient(to left, ${Colors.surface}, ${Colors.surface}, transparent)`,
//           }
//         : {
//             backgroundImage: `linear-gradient(to left, ${Colors.background}, ${Colors.background}, transparent)`,
//           },
//   },
// });
