import React from "react";
import StyleSheet from "../StyleSheet";

export default class WebViewBase extends React.Component {
  render() {
    console.log();
    console.log(this.props);
    let uri = this.props.source.uri;
    let props = {};
    if (this.props.style)
      props.className = StyleSheet.className([this.props.style]);

    return (
      <iframe
        src={uri}
        {...props}
      />
    );
  }
}
const styles = StyleSheet.create({});