import React from "react";
import R14 from "../core";
import AppModuleIde from "../components/AppModuleIde";
export default R14.connect(
  class ProjectPipelineBlockIdeScreen extends React.Component {
    render() {
      console.log("pipelineBlock", this.props.pipelineBlock);
      return (
        <AppModuleIde
          appModule={this.props.pipelineBlock.block.appModule}
          pipelineBlock={this.props.pipelineBlock}
          block={this.props.pipelineBlock.block}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
        />
      );
    }
  }
);
