import React from "react";
import R14, {
  StyleSheet,
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
} from "../core";
export default R14.connect(
  class ScriptEditScreen extends React.Component {
    constructor(props) {
      throw('not in user please fix');
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.resourceDomain = this.props.app.dm.resource;
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(resourceForm) {
      await this.resourceDomain.update(resourceForm.values);
      return <Redirect to={this.props.redirect || "resources"} />;
    }
    render() {
      return (
        <Form
          name='resourceForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Update' key='submit' />
          ]}
        >
          <HiddenInputField name='uid' />
          <TextInputField
            name='sshUsername'
            label='SSH Username'
            required='Please enter a ssh username.'
          />
          <TextInputField
            name='sshPrivateKey'
            label='SSH Private Key'
            required='Please enter an ssh private key.'
            style={styles.sshPrivateKeyField}
            multiline
            autoSize
          />
        </Form>
      );
    }
  }
);
let styles = StyleSheet.create({
  sshPrivateKeyField: {
    maxHeight: 160
    // flex: 1
  }
});
