import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";

export default R14.connect(
  class BlockEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleIoValueFormat = this.handleIoValueFormat.bind(this);
      this.handleIoRenderer = this.handleIoRenderer.bind(this);
      this.handleOptionsRenderer = this.handleOptionsRenderer.bind(this);
      this.appModuleItemLoader = this.appModuleItemLoader.bind(this);
      this.handleRemoveBlockIoItem = this.handleRemoveBlockIoItem.bind(this);
      this.handleFieldTypeChange = this.handleFieldTypeChange.bind(this);
      this.handleAppModuleValueChange = this.handleAppModuleValueChange.bind(
        this
      );

      this.pipelineDomain = this.props.app.dm.pipeline;
      this.state = {
        connectionType: this.props.formData.values.connectionType,
      };
      this.removedBlockIoUids = [];
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async appModuleItemLoader(filters) {
      let res = await this.props.app.dm.block.fetchAppModuleSelections(filters);
      return res;
    }
    async handleAppModuleValueChange(val) {}
    async handleRemoveBlockIoItem(item) {
      if (item && item.uid && !this.removedBlockIoUids.includes(item.uid))
        this.removedBlockIoUids.push(item.uid);
    }

    handleFieldTypeChange(type){
      console.log(type);
    }

    handleOptionsRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='label' label='Label'>
              <Text>{values.label}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='required'
              label='Required'
            >
              <Text>{values.required ? "Yes" : "No"}</Text>
            </LabelView>
          </View>
        </View>
      );
    }

    handleIoValueFormat(values) {
      return values;
    }
    handleIoRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='name' label='Name'>
              <Text>{values.name}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='datatype'
              label='Data Type'
            >
              <Text>
                {this.props.app.dm.block.getIoDataTypeLabel(values.dataType)}
              </Text>
            </LabelView>
          </View>
          {values.description && (
            <View style={styles.ioItemRowBottom}>
              <LabelView
                style={styles.ioItemLabelView}
                key='description'
                label='Description'
              >
                <ShowHideText text={values.description} />
              </LabelView>
            </View>
          )}
        </View>
      );
    }
    renderIoForm() {
      return (
        <React.Fragment>
          <HiddenInputField name='uid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='key'
              label='Key'
              validator={{
                key: true,
              }}
              required='Please enter a key'
              helper='Used by App Module'
            />
            <SelectMenuField
              useValuesOnly
              name='dataType'
              label='Data Type'
              required='Select data type'
              items={this.props.formData.blockIoDataTypeSelections}
            />
          </FieldGroup>
        </React.Fragment>
      );
    }
    render() {
      console.log(this.props.formData.values);
      return (
        <EditForm
          name='blockForm'
          formData={this.props.formData}
          domainName='block'
          redirect={this.props.redirect || "blocks"}
          refreshDataTable={this.props.refreshDataTable || "blocks"}
          saveOptions={{
            removedBlockIoUids: this.removedBlockIoUids,
          }}
          validators={{
            uniqueKey: (val) => {
              let keys = {};
              let isValid = true;
              if (val) {
                for (let i in val) {
                  if (keys[val[i].key]) {
                    isValid = false;
                    break;
                  }
                  keys[val[i].key] = true;
                }
              }
              return isValid ? true : { error: "All keys must be unique." };
            },
          }}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectType' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <SelectMenuField
            name='appModule'
            label='App Module'
            searchable
            itemLoader={this.appModuleItemLoader}
            items={this.formData.appModuleSelections}
          />
          {this.props.formData.cloudAccessKeySelections.length ? (
            <SelectMenuField
              useValuesOnly
              name='cloudAccessKeyUid'
              label='Cloud Access Key'
              items={this.props.formData.cloudAccessKeySelections}
              helper='Optional'
            />
          ) : null}
          <DialogFormField
            name='inputs'
            dialogTitle='Add Input'
            label='Inputs'
            formName='blockForm'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
            validator='uniqueKey'
            // onValueChange={(val) => {
            //   console.log("INPUT VALUE CHANGE", val);
            // }}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='outputs'
            dialogTitle='Add Output'
            label='Outputs'
            formName='blockForm'
            validator='uniqueKey'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='options'
            dialogTitle='Add Option'
            label='Options'
            formName='blockForm'
            itemRenderer={this.handleOptionsRenderer}
            validator='uniqueKey'
          >
            <TextInputField name='label' label='Label' required='Enter Label' />
            <TextInputField
              name='key'
              label='Key'
              validator='key'
              required='Enter Key'
            />
            <SwitchField
              key='isRequired'
              name='required'
              label='Is Required?'
            />
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Field Type'
              items={this.formData.fieldTypeSelections}
              required='Please select a field type.'
              // onValueChange={this.handleFieldTypeChange}
            />
            <TextInputField
              name='helperText'
              label='Field Helper Text'
              helper="Text displayed under field"
            />
          </DialogFormField>
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  ioItemRow: {
    flex: 1,
    flexDirection: "row",
  },
  ioItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  ioItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
