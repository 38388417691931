import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectHealthCheckDataTable from "../components/ProjectHealthCheckDataTable";
export default R14.connect(
  class HealthChecksScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          // floatingActionButton={{
          //   to: {
          //     route: "projectCreate",
          //     params: {
          //       key: this.props.app.dm.project.getKeyByType(this.props.type),
          //     },
          //   },
          //   icon: "add",
          // }}
          scrollEnabled
        >
          <ProjectHealthCheckDataTable
            widget={false}
            projectType={this.props.type}
          />
        </ScreenLayout>
      );
    }
  }
);
