import React from "react";
import R14, {
  Colors,
  StyleSheet,
  AnimatedView,
  View,
} from "../core";

export default R14.connect(
  class PipelineBlockIoPath extends React.Component {
    constructor(props) {
      super(props);
      this.handleAnimationExit = this.handleAnimationExit.bind(this);
      this.pipelineBlockIoSubscription = null;
      this.pipelineBlockDm = this.props.app.dm.pipelineBlock;
      this.state = {
        animate: true,
      };
    }
    componentDidMount() {
      this.init();
    }
    componentWillUnmount() {
      this.pipelineBlockIoSubscription &&
        this.pipelineBlockIoSubscription.unsubscribe();
    }
    async init() {
      if (this.props.onUpdate)
        this.pipelineBlockIoSubscription = await this.pipelineBlockDm.onIoUpdate(
          this.props.pipelineBlockIoUid,
          (res) => {
            this.props.onUpdate(this.props.pipelineBlockIoUid, res);
          }
        );
    }
    componentDidUpdate(prevProps) {
      if (
        this.state.animate &&
        prevProps.queueItemCount !== this.props.queueItemCount
      ) {
        this.setState({ animate: false });
      }
    }
    handleAnimationExit() {
      this.setState({ animate: true });
    }
    render() {
      if (!this.props.path) return null;
      let ioLines = [];

      let lines = {};

      let pipelineBlockIoUid = this.props.pipelineBlockIoUid;
      let line = null;
      let direction = null;
      let initLine = (point) => ({
        x: point[0],
        y: point[1],
        height: 0,
        width: 0,
        collision: this.props.collision,
        queueItemCount: this.props.queueItemCount,
      });
      for (let i = 0; i < this.props.path.length; i++) {
        let point = this.props.path[i];
        let nextPoint =
          i + 1 < this.props.path.length ? this.props.path[i + 1] : null;
        //let lastPoint = i - 1 > 0 ? io.path[i - 1] : null;
        let nextDirection = nextPoint
          ? nextPoint[0] === point[0]
            ? nextPoint[1] > point[1]
              ? "y"
              : "-y"
            : nextPoint[0] > point[0]
            ? "x"
            : "-x"
          : null;
        if (!line) {
          line = initLine(point);
          line.direction = nextDirection;
        } else {
          if (direction && ["x", "-x"].includes(direction)) line.width++;
          else if (direction && ["y", "-y"].includes(direction)) line.height++;
          if (direction !== nextDirection || nextDirection === null) {
            if (!lines[pipelineBlockIoUid]) lines[pipelineBlockIoUid] = [];
            lines[pipelineBlockIoUid].push(line);
            line = initLine(point);
            line.direction = nextDirection;
            line.lastDirection = direction;
          }
        }
        direction = nextDirection;
      }

      let lineStyles = null;
      let lineCornerStyles = null;
      for (let pipelineBlockIoUid in lines) {
        lines[pipelineBlockIoUid].forEach((line, i) => {
          let lineCount = lines[pipelineBlockIoUid].length;
          let hasDirectionChange = false;
          if (
            line.lastDirection &&
            line.direction &&
            line.lastDirection !== line.direction
          ) {
            hasDirectionChange = true;
          }
          let lineKey = this.props.app.utils.str.toHex(
            `${line.x}${line.y}${line.height}${line.width}${
              line.lastDirection || ""
            }${line.direction || ""}`
          );
          let lineStyleName = `ioLine${lineKey}`;
          let lineBackgroundColor = line.collision
            ? Colors.error
            : line.queueItemCount
            ? "#2e7d32"
            : Colors.onBackground;
          if (!lineStyles) lineStyles = {};

          let height = line.height ? line.height * this.props.cellSize + 3 : 3;
          let width = line.width ? line.width * this.props.cellSize + 3 : 3;
          let top =
            line.y * this.props.cellSize -
            1 -
            (line.direction === "-y" ? line.height * this.props.cellSize : 0);
          let left =
            line.x * this.props.cellSize -
            1 -
            (line.direction === "-x" ? line.width * this.props.cellSize : 0);

          let halfCellSize = Math.round(this.props.cellSize / 2);

          // Calculate the first line with corners
          if (lineCount > 1) {
            if (i === 0) {
              if (["y", "-y"].includes(line.direction)) {
                height = line.height * this.props.cellSize - halfCellSize + 2;
                if (line.direction === "-y") top += halfCellSize;
              } else {
                width = line.width * this.props.cellSize - halfCellSize + 2;
                if (line.direction === "-x") left += halfCellSize;
              }
              //color = "red";
            } // If line count > 1 shorten all lines for turns
            else {
              if (["y", "-y"].includes(line.direction)) {
                height = (line.height - 1) * this.props.cellSize + 2;
                top += halfCellSize;
              } else {
                width = (line.width - 1) * this.props.cellSize + 2;
                left += halfCellSize;
              }
            }
          }

          if (hasDirectionChange) {
            // Figure out which direction the corner is
            let cornerRotate = 0;
            let backgroundColor = "#FFFFFF";
            let cornerTopOffset = 0;
            let cornerLeftOffset = 0;
            switch (`${line.lastDirection},${line.direction}`) {
              case "-x,y":
              case "-y,x":
                cornerRotate = 0;
                // backgroundColor = 'red';
                cornerTopOffset = -1;
                cornerLeftOffset = -1;
                break;
              case "y,-x":
              case "x,-y":
                cornerTopOffset = -halfCellSize;
                cornerLeftOffset = -halfCellSize;
                cornerRotate = 180;
                break;
              case "y,x":
              case "-x,-y":
                cornerTopOffset = -halfCellSize;
                cornerLeftOffset = -1;
                cornerRotate = 270;
                break;
              case "x,y":
              case "-y,-x":
                cornerTopOffset = -1;
                cornerLeftOffset = -halfCellSize;
                cornerRotate = 90;
                break;
              default:
            }
            let lineCornerStyleName = `ioLineCorner${lineKey}`;
            if (!lineCornerStyles) lineCornerStyles = {};
            lineCornerStyles[lineCornerStyleName] = {
              top: line.y * this.props.cellSize + cornerTopOffset,
              left: line.x * this.props.cellSize + cornerLeftOffset,
              height: halfCellSize + 2,
              width: halfCellSize + 2,
              transform: [{ rotate: `${cornerRotate}deg` }],
              borderColor: lineBackgroundColor,
              zIndex: line.collision || line.queueItemCount ? 1 : 0,
            };
          }
          lineStyles[lineStyleName] = {
            top: top,
            left: left,
            backgroundColor: lineBackgroundColor,
            height: height,
            width: width,
            zIndex: line.collision || line.queueItemCount ? 1 : 0,
          };
        });
      }

      let dynamicLineStyles = StyleSheet.create(lineStyles);

      for (let styleName in lineStyles) {
        ioLines.push(
          <View
            key={styleName}
            style={[styles.ioLine, dynamicLineStyles[styleName]]}
          />
        );
      }

      let dynamicLineCoverStyles = StyleSheet.create(lineCornerStyles);
      for (let styleName in lineCornerStyles) {
        ioLines.push(
          <View
            key={styleName}
            style={[styles.ioLineCorner, dynamicLineCoverStyles[styleName]]}
          />
        );
      }
      return (
        <AnimatedView
          enter={{
            opacity: this.props.collision ? 0.6 : 0.9,
          }}
          exit={{
            opacity: 0.6,
          }}
          in={this.state.animate}
          onExit={this.handleAnimationExit}
          duration={400}
          style={[
            this.props.collision || this.props.queueItemCount
              ? styles.ioPathRaised
              : null,
          ]}
        >
          {ioLines}
        </AnimatedView>
      );
    }
  }
);
let styles = StyleSheet.create({
  ioLine: {
    position: "absolute",
    borderRadius: 4,
    backgroundColor: Colors.onBackground,
  },
  ioLineCorner: {
    position: "absolute",
    borderTopWidth: 3,
    borderLeftWidth: 3,
    borderStyle: "solid",
    borderColor: Colors.onBackground,
    borderTopLeftRadius: 9,
  },
  // ioPathCollision: {
  //   opacity: 0.6,
  // },
  ioPathRaised: {
    zIndex: 1,
  },
});
