import React from "react";
import R14, { Redirect } from "./core";
import DashboardScreen from "./screens/DashboardScreen";
import ResourcesScreen from "./screens/ResourcesScreen";
import ResourceScreen from "./screens/ResourceScreen";
import ResourceMonitorScreen from "./screens/ResourceMonitorScreen";
import ProjectScreen from "./screens/ProjectScreen";
import ProjectsScreen from "./screens/ProjectsScreen";
import HealthChecksScreen from "./screens/HealthChecksScreen";
import ProjectEditScreen from "./screens/ProjectEditScreen";
// import EventScreen from "./screens/EventScreen";
import EventsScreen from "./screens/EventsScreen";
import EventEditScreen from "./screens/EventEditScreen";
import ScriptsScreen from "./screens/ScriptsScreen";
import ScriptEditScreen from "./screens/ScriptEditScreen";
import ResourceScriptAddScreen from "./screens/ResourceScriptAddScreen";
import ResourceScriptLogScreen from "./screens/ResourceScriptLogScreen";
import LogsScreen from "./screens/LogsScreen";
import LogEditScreen from "./screens/LogEditScreen";
import ResourceLogScreen from "./screens/ResourceLogScreen";
import ResourceLogsScreen from "./screens/ResourceLogsScreen";
import EventTaskAddScreen from "./screens/EventTaskAddScreen";
import UsersScreen from "./screens/UsersScreen";
import UserEditScreen from "./screens/UserEditScreen";
import UserPermissionsEditScreen from "./screens/UserPermissionsEditScreen";
import UserPermissionGroupsScreen from "./screens/UserPermissionGroupsScreen";
import UserPermissionGroupEditScreen from "./screens/UserPermissionGroupEditScreen";

import ManualEntryUsersScreen from "./screens/ManualEntryUsersScreen";
import ManualEntryUserEditScreen from "./screens/ManualEntryUserEditScreen";

import LoginScreen from "./screens/LoginScreen";
import ResourceLogAddScreen from "./screens/ResourceLogAddScreen";
import StatusChecksScreen from "./screens/StatusChecksScreen";
import StatusCheckEditScreen from "./screens/StatusCheckEditScreen";
import ContactGroupsScreen from "./screens/ContactGroupsScreen";
import ContactGroupEditScreen from "./screens/ContactGroupEditScreen";
import ResourceEditScreen from "./screens/ResourceEditScreen";
import ResourceStatusChecksScreen from "./screens/ResourceStatusChecksScreen";
import ResourceStatusCheckAddScreen from "./screens/ResourceStatusCheckAddScreen";
import ResourceStatusCheckLogScreen from "./screens/ResourceStatusCheckLogScreen";
import ClientsScreen from "./screens/ClientsScreen";
import ClientEditScreen from "./screens/ClientEditScreen";
import CloudAccessKeysScreen from "./screens/CloudAccessKeysScreen";
import CloudAccessKeyEditScreen from "./screens/CloudAccessKeyEditScreen";
import SshKeysScreen from "./screens/SshKeysScreen";
import SshKeyEditScreen from "./screens/SshKeyEditScreen";
import ResourceSshKeyManageScreen from "./screens/ResourceSshKeyManageScreen";
import ProjectResourceAddScreen from "./screens/ProjectResourceAddScreen";
import AccountSettingsScreen from "./screens/AccountSettingsScreen";
import ThemesScreen from "./screens/ThemesScreen";
import ThemeEditScreen from "./screens/ThemeEditScreen";
import TimesheetScreen from "./screens/TimesheetScreen";
import TimesheetEditScreen from "./screens/TimesheetEditScreen";
import ResourceAlertsScreen from "./screens/ResourceAlertsScreen";
import ResourceAlertAddScreen from "./screens/ResourceAlertAddScreen";
import ResourceAlertLogScreen from "./screens/ResourceAlertLogScreen";

import SupportScreen from "./screens/SupportScreen";
import ComingSoonScreen from "./screens/ComingSoonScreen";
import EventTasksScreen from "./screens/EventTasksScreen";
import ResourceScriptsScreen from "./screens/ResourceScriptsScreen";
import TodoScreen from "./screens/TodoScreen";
import TodosBoardScreen from "./screens/TodosBoardScreen";
import TodosScreen from "./screens/TodosScreen";
import TodoEditScreen from "./screens/TodoEditScreen";

import MessagesConversationScreen from "./screens/MessagesConversationScreen";
import MessagesScreen from "./screens/MessagesScreen";

import ActivityLogScreen from "./screens/ActivityLogScreen";
import ResourceActivityLogScreen from "./screens/ResourceActivityLogScreen";

import AppModuleEditScreen from "./screens/AppModuleEditScreen";
import AppModuleLogScreen from "./screens/AppModuleLogScreen";
import AppModuleMonitorScreen from "./screens/AppModuleMonitorScreen";

import BlocksScreen from "./screens/BlocksScreen";
import BlockEditScreen from "./screens/BlockEditScreen";
import BlockCopyScreen from "./screens/BlockCopyScreen";

import DatasetsScreen from "./screens/DatasetsScreen";
import DatasetEditScreen from "./screens/DatasetEditScreen";

import ProjectCodeEditorScreen from "./screens/ProjectCodeEditorScreen";
import ProjectAppModulesScreen from "./screens/ProjectAppModulesScreen";
import ProjectResourcesScreen from "./screens/ProjectResourcesScreen";
import ProjectLogsScreen from "./screens/ProjectLogsScreen";
import ProjectDocsScreen from "./screens/ProjectDocsScreen";
import ProjectPipelineScreen from "./screens/ProjectPipelineScreen";
import ProjectPipelineLogScreen from "./screens/ProjectPipelineLogScreen";
import ProjectPipelineBlockScreen from "./screens/ProjectPipelineBlockScreen";
import ProjectPipelinesScreen from "./screens/ProjectPipelinesScreen";
import ProjectPipelineEditScreen from "./screens/ProjectPipelineEditScreen";
import ProjectPipelineCopyScreen from "./screens/ProjectPipelineCopyScreen";
import ProjectPipelineBlockIdeScreen from "./screens/ProjectPipelineBlockIdeScreen";
import ProjectPipelineBlockEditScreen from "./screens/ProjectPipelineBlockEditScreen";
import ProjectPipelineBlockIoEditScreen from "./screens/ProjectPipelineBlockIoEditScreen";
import ProjectPipelineBlockEventEditScreen from "./screens/ProjectPipelineBlockEventEditScreen";
import ProjectPipelineBlockAddScreen from "./screens/ProjectPipelineBlockAddScreen";
import ProjectPipelineBlockLogScreen from "./screens/ProjectPipelineBlockLogScreen";
import ProjectPipelineBlockMonitorScreen from "./screens/ProjectPipelineBlockMonitorScreen";
import ProjectPipelineBlockMetricsScreen from "./screens/ProjectPipelineBlockMetricsScreen";
import ProjectHealthCheckEditScreen from "./screens/ProjectHealthCheckEditScreen";
import ProjectHealthChecksScreen from "./screens/ProjectHealthChecksScreen";
import ProjectHealthCheckScreen from "./screens/ProjectHealthCheckScreen";
import ProjectAppModuleIdeScreen from "./screens/ProjectAppModuleIdeScreen";

import UserPermissionsErrorScreen from "./screens/UserPermissionsErrorScreen";
import AppModulePackagesManageScreen from "./screens/AppModulePackagesManageScreen";
import AppModuleReposManageScreen from "./screens/AppModuleReposManageScreen";
import TestScreen from "./screens/TestScreen";

export default class Actions extends R14.Actions {
  async shouldActionLoad({ to, from, app }) {
    if (to.metadata.public) return true;
    // If not logged int, forward to login
    if (!app.dm.userSession.isLoggedIn) return <Redirect to='login' />;
    if (to.metadata.permissions) {
      let permissions = to.metadata.permissions;
      if (!permissions.type)
        throw new Error("Route Permissions Error: No type found.");
      if (
        !this.dm.user.checkPermissions(
          permissions.type,
          permissions.subtype || null,
          permissions.access
            ? permissions.access
            : this.dm.user.PERMISSION_ACCESS_READ
        )
      )
        return <UserPermissionsErrorScreen to='userPermissionsError' />;
    }
    return true;
  }

  async dashboard({ project }) {
    let projectDomain = this.dm.projects.exists(project)
      ? this.dm.projects(project)
      : this.dm.projects.create(project);

    await this.dm.projects.setActiveProject(projectDomain);

    return <DashboardScreen project={projectDomain} />;
  }

  /** User login actions */
  async landing() {
    let to = "login";

    if (this.dm.userSession.isLoggedIn) {
      to = "login";
      let role = this.dm.userSession.role;
      switch (role) {
        case this.dm.user.ROLE_SUPER_ADMIN:
          to = "clients";
          break;
        case this.dm.user.ROLE_ADMIN:
        case this.dm.user.ROLE_OWNER:
          to = "resources";
          break;
        default:
          for (let projectType of [
            this.dm.project.TYPE_CLOUD,
            this.dm.project.TYPE_DEV,
            this.dm.project.TYPE_AI,
          ]) {
            if (
              this.dm.user.checkPermissions(
                this.dm.project.getKeyByType(projectType),
                this.dm.user.PERMISSION_ACCESS_READ
              )
            ) {
              to = {
                route: "projectsNavigator",
                params: {
                  key: this.dm.project.getKeyByType(projectType),
                },
              };
              break;
            }
          }
          break;
      }
    }
    return <Redirect to={to} />;
  }

  async test() {
    return <TestScreen />;
  }

  /** User login actions */
  async login() {
    return <LoginScreen />;
  }

  /** Resource Actions */
  async resources() {
    let dataGridFiltersFormData = await this.dm.resource.fetchDataGridFiltersFormData();
    return (
      <ResourcesScreen dataGridFiltersFormData={dataGridFiltersFormData} />
    );
  }
  async resource({ uid }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceScreen resource={resource} />;
  }
  async resourceMonitor({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });

    let periodInfo = this.dm.resource.getPeriodFilterInfo(
      this.dm.resource.PERIOD_1_HOUR
    );

    let metrics = await this.dm.resource.fetchAllMetricsData(resource, {
      period: periodInfo.period,
      startTime: periodInfo.startTime,
    });

    return <ResourceMonitorScreen resource={resource} metrics={metrics} />;
  }
  async resourceEdit({ uid = null }) {
    let formData = await this.dm.resource.fetchEditFormData(uid);
    return (
      <ResourceEditScreen
        formData={formData}
        redirect={{
          route: "resource",
          params: { uid: uid },
        }}
      />
    );
  }
  /** Project Actions */
  async rootProjects({ key }) {
    return <ProjectsScreen type={this.dm.project.TYPE_ROOT} />;
  }
  async projects({ key }) {
    return <ProjectsScreen type={this.dm.project.getTypeByKey(key)} />;
  }
  async projectsHealthChecks({ key }) {
    return <HealthChecksScreen type={this.dm.project.getTypeByKey(key)} />;
  }
  async projectsBlocks({ key }) {
    return <ComingSoonScreen />;
  }
  async projectsResources({ key }) {
    return <ComingSoonScreen />;
  }
  async project({ uid, key }) {
    let project = await this.dm.project.initDashboard(
      uid,
      this.dm.project.getTypeByKey(key)
    );
    return <ProjectScreen project={project} />;
  }
  async projectEdit({ uid = null, key = null }) {
    let formData = await this.dm.project.fetchEditFormData(uid, {
      type: this.dm.project.getTypeByKey(key),
    });
    return <ProjectEditScreen formData={formData} />;
  }
  async projectResourceAdd({ uid, key }) {
    let formData = await this.dm.project.fetchResourceAddFormData(uid, {
      type: this.dm.project.getTypeByKey(key),
    });
    return <ProjectResourceAddScreen formData={formData} />;
  }
  async projectCodeEditor({ uid }) {
    let project = await this.dm.project.get(uid);
    return <ProjectCodeEditorScreen project={project} />;
  }
  async projectPipeline({ pipelineUid }) {
    // let { pipeline, pipelines } = await this.dm.pipeline.get(pipelineUid, {
    //   project: true,
    //   blocks: true,
    //   pipelineBlockIo: true,
    //   pipelines: true,
    // });
    let pipeline = await this.ui.pipeline.instance(pipelineUid);
    return (
      <ProjectPipelineScreen
        // project={pipeline.project}
        // pipelines={pipelines}
        // pipeline={pipeline}
        pipeline={pipeline}
      />
    );
  }
  async projectPipelineLog({ pipelineUid }) {
    let pipelineData = await this.dm.pipelineLog.fetchData(pipelineUid);
    console.log("SLKDJFLKSDJFLKJSDF", pipelineData);
    return <ProjectPipelineLogScreen {...pipelineData} />;
  }
  async projectPipelineBlock({ pipelineUid, pipelineBlockUid }) {
    // let { pipeline, pipelines } = await this.dm.pipeline.get(pipelineUid, {
    //   project: true,
    //   blocks: true,
    //   pipelineBlockIo: true,
    //   pipelines: true,
    // });
    // let pipeline = await this.ui.pipeline.instance(pipelineUid);
    let pipelineBlock = await this.dm.pipelineBlock.get(pipelineBlockUid, {
      appModule: true,
      pipeline: true,
      project: true,
      instances: true,
      resource: true,
      blockIo: true,
      metrics: true,
    });
    return (
      <ProjectPipelineBlockScreen
        project={pipelineBlock.pipeline.project}
        pipelineBlock={pipelineBlock}
      />
    );
  }
  async projectPipelineBlockMonitor({ pipelineUid, pipelineBlockUid }) {
    let pipelineBlock = await this.dm.pipelineBlock.get(pipelineBlockUid, {
      instances: true,
      appModule: true,
      resource: true,
    });
    return <ProjectPipelineBlockMonitorScreen pipelineBlock={pipelineBlock} />;
  }
  async projectPipelineBlockIde({ uid, key, pipelineUid, pipelineBlockUid }) {
    let pipelineBlock = await this.dm.pipelineBlock.get(pipelineBlockUid, {
      appModule: true,
      ideState: true,
    });
    return (
      <ProjectPipelineBlockIdeScreen
        pipelineBlock={pipelineBlock}
        projectUid={uid}
        projectType={this.dm.project.getTypeByKey(key)}
      />
    );
  }
  async projectPipelineBlockLog({ uid, pipelineBlockUid }) {
    return <ProjectPipelineBlockLogScreen uid={pipelineBlockUid} />;
  }
  async projectPipelineBlockMetrics({
    uid,
    key,
    pipelineUid,
    pipelineBlockUid,
  }) {
    let pipelineBlock = await this.dm.pipelineBlock.get(pipelineBlockUid, {
      metrics: true,
    });
    return (
      <ProjectPipelineBlockMetricsScreen
        pipelineBlock={pipelineBlock}
        projectUid={uid}
        projectType={this.dm.project.getTypeByKey(key)}
      />
    );
  }
  async blocks({ key }) {
    return <BlocksScreen projectType={this.dm.project.getTypeByKey(key)} />;
  }
  async blockEdit({ uid, key }) {
    let projectType = this.dm.project.getTypeByKey(key);
    let formData = await this.dm.block.fetchEditFormData(uid, {
      projectType: projectType,
    });
    return (
      <BlockEditScreen
        formData={formData}
        projectType={projectType}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key },
        }}
      />
    );
  }
  async blockCopy({ uid, key }) {
    let projectType = this.dm.project.getTypeByKey(key);
    let formData = await this.dm.block.fetchCopyFormData(uid, {
      projectType: projectType,
    });
    console.log("FORM DATA", formData);
    return (
      <BlockCopyScreen
        formData={formData}
        projectType={projectType}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key },
        }}
      />
    );
  }
  async datasets({ key }) {
    // return <ComingSoonScreen />;
    return <DatasetsScreen projectType={this.dm.project.getTypeByKey(key)} />;
  }
  async datasetEdit({ uid, key }) {
    let projectType = this.dm.project.getTypeByKey(key);
    let formData = await this.dm.dataset.fetchEditFormData(uid, {
      projectType: projectType,
    });
    return (
      <DatasetEditScreen
        formData={formData}
        projectType={projectType}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key },
        }}
      />
    );
  }
  async projectPipelines({ uid }) {
    // let project = await this.dm.project.get(uid);
    let project = await this.dm.project.instance(uid);
    return <ProjectPipelinesScreen project={project} />;
  }
  async projectPipelineEdit({ uid, pipelineUid, key }) {
    let formData = await this.dm.pipeline.fetchEditFormData(pipelineUid, {
      projectUid: uid,
      projectType: this.dm.project.getTypeByKey(key),
    });
    return (
      <ProjectPipelineEditScreen
        refreshDataTable='projectPipelines'
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key, pipelineUid },
        }}
        formData={formData}
      />
    );
  }
  async projectPipelineCopy({ uid, pipelineUid, key }) {
    let formData = await this.dm.pipeline.fetchCopyFormData(pipelineUid, {
      projectUid: uid,
      projectType: this.dm.project.getTypeByKey(key),
    });
    return (
      <ProjectPipelineCopyScreen
        projectUid={uid}
        projectType={this.dm.project.getTypeByKey(key)}
        refreshDataTable='projectPipelines'
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key, pipelineUid },
        }}
        formData={formData}
      />
    );
  }
  async projectPipelineBlockEdit({ uid, pipelineUid, pipelineBlockUid, key }) {
    let formData = await this.dm.pipelineBlock.fetchEditFormData(
      pipelineBlockUid,
      {
        pipelineUid: pipelineUid,
        projectUid: uid,
        projectType: this.dm.project.getTypeByKey(key),
      }
    );
    return (
      <ProjectPipelineBlockEditScreen
        pipelineUid={pipelineUid}
        projectUid={uid}
        projectType={this.dm.project.getTypeByKey(key)}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key, pipelineUid },
        }}
        formData={formData}
      />
    );
  }
  async projectPipelineBlockIoEdit({
    uid,
    pipelineUid,
    pipelineBlockUid,
    key,
  }) {
    let formData = await this.dm.pipelineBlock.fetchIoEditFormData(
      pipelineBlockUid,
      pipelineUid
    );
    return (
      <ProjectPipelineBlockIoEditScreen
        pipelineUid={pipelineUid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key, pipelineUid },
        }}
        formData={formData}
      />
    );
  }
  async projectPipelineBlockEventEdit({
    uid,
    pipelineUid,
    pipelineBlockUid,
    key,
  }) {
    let formData = await this.dm.pipelineBlock.fetchEventEditFormData(
      pipelineBlockUid,
      pipelineUid
    );
    return (
      <ProjectPipelineBlockEventEditScreen
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key, pipelineUid },
        }}
        formData={formData}
      />
    );
  }
  async projectPipelineBlockAdd({ uid, pipelineUid, key }) {
    let formData = await this.dm.pipelineBlock.fetchAddFormData(pipelineUid, {
      projectUid: uid,
      projectType: this.dm.project.getTypeByKey(key),
    });
    return (
      <ProjectPipelineBlockAddScreen
        redirect={{
          route: this.nav.route.initialParentRoute,
        }}
        // refreshDataTable='projectPipelines'
        // redirect={{
        //   route: this.nav.route.initialParentRoute,
        //   params: { uid, key },
        // }}
        formData={formData}
      />
    );
  }
  async projectLogs({ uid }) {
    let project = await this.dm.project.get(uid, {
      appModules: true,
    });
    return <ProjectLogsScreen project={project} />;
  }
  async projectDocs({ uid, key }) {
    return (
      <ProjectDocsScreen uid={uid} type={this.dm.project.getTypeByKey(key)} />
    );
  }
  async projectRepos({ uid }) {
    // let project = await this.dm.project.get(uid);
    return <ComingSoonScreen />;
  }
  async projectAppModules({ uid, key }) {
    let project = await this.dm.project.initDashboard(
      uid,
      this.dm.project.getTypeByKey(key)
    );
    return <ProjectAppModulesScreen project={project} />;
  }
  async projectResources({ uid, key }) {
    return (
      <ProjectResourcesScreen
        uid={uid}
        type={this.dm.project.getTypeByKey(key)}
      />
    );
  }
  async projectHealthChecks({ uid, key }) {
    let project = await this.dm.project.get(uid);
    return <ProjectHealthChecksScreen project={project} />;
  }
  async projectHealthCheck({ uid, key, projectHealthCheckUid }) {
    let projectHealthCheck = await this.dm.projectHealthCheck.get(
      projectHealthCheckUid
    );
    return <ProjectHealthCheckScreen projectHealthCheck={projectHealthCheck} />;
  }
  async projectAppModuleEdit({
    uid,
    key,
    copyAppModuleUid = null,
    appModuleUid = null,
  }) {
    let formData = await this.dm.appModule.fetchEditFormData(appModuleUid, {
      projectUid: uid,
      projectType: this.dm.project.getTypeByKey(key),
      copyUid: copyAppModuleUid,
    });
    if (copyAppModuleUid) formData.values.uid = null;
    return (
      <AppModuleEditScreen
        redirect={{
          route: this.nav.route.initialParentRoute,
        }}
        refreshDataTable='appModules'
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key },
        }}
        formData={formData}
      />
    );
  }
  async projectAppModuleManagePackages(params) {
    let formData = await this.dm.appModule.fetchManagePackagesFormData(
      params.appModuleUid
    );
    let forwardRoute = "projectAppModuleLog";
    switch (this.nav.route.name) {
      case "projectPipelineBlockIdeManagePackages":
        forwardRoute = "projectPipelineBlockIdeLog";
        break;
      case "projectAppModuleIdeManagePackages":
        forwardRoute = "projectAppModuleIdeLog";
        break;
    }
    return (
      <AppModulePackagesManageScreen
        formData={formData}
        redirect={{
          route: forwardRoute,
          params: params,
        }}
        uid={params.appModuleUid}
      />
    );
  }
  async projectAppModuleManageRepos(params) {
    let formData = await this.dm.appModule.fetchManageReposFormData(
      params.appModuleUid
    );
    let forwardRoute = "projectAppModuleLog";
    switch (this.nav.route.name) {
      case "projectPipelineBlockIdeManageRepos":
        forwardRoute = "projectPipelineBlockIdeLog";
        break;
      case "projectAppModuleIdeManageRepos":
        forwardRoute = "projectAppModuleIdeLog";
        break;
    }
    return (
      <AppModuleReposManageScreen
        formData={formData}
        redirect={{
          route: forwardRoute,
          params: params,
        }}
        uid={params.appModuleUid}
      />
    );
  }
  async projectAppModuleLog({ uid, appModuleUid }) {
    return <AppModuleLogScreen uid={appModuleUid} />;
  }
  async projectHealthCheckEdit({ uid, projectHealthCheckUid, key }) {
    let formData = await this.dm.projectHealthCheck.fetchEditFormData(
      projectHealthCheckUid,
      {
        projectUid: uid,
        projectType: this.dm.project.getTypeByKey(key),
      }
    );
    return (
      <ProjectHealthCheckEditScreen
        redirect={{
          route: this.nav.route.initialParentRoute,
        }}
        refreshDataTable='projectHealthChecks'
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid, key },
        }}
        formData={formData}
      />
    );
  }
  async projectAppModuleIde({ uid, key, appModuleUid }) {
    let appModule = await this.dm.appModule.get(appModuleUid, {
      serverState: true,
      resource: true,
      project: true,
      repos: true,
    });
    return (
      <ProjectAppModuleIdeScreen
        appModule={appModule}
        projectUid={uid}
        projectType={this.dm.project.getTypeByKey(key)}
      />
    );
  }

  /** Event Actions */
  async event() {
    return <EventsScreen />;
  }
  async events() {
    return <EventsScreen />;
  }
  async eventEdit({ uid = null }) {
    let formData = await this.dm.event.fetchEditFormData(uid);
    return <EventEditScreen formData={formData} />;
  }
  /** Resource Event Actions */
  async resourceEventTasks({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return (
      <EventTasksScreen
        type={this.dm.eventTask.TYPE_RESOURCE}
        resource={resource}
      />
    );
  }
  async resourceEventTaskAdd({ uid = null }) {
    let formData = await this.dm.eventTask.fetchEventTaskAddFormData(
      this.dm.eventTask.TYPE_RESOURCE,
      uid
    );

    let createAction =
      this.nav.route.initialParentRoute == "resource"
        ? "resourceEventTaskCreate"
        : `${this.nav.route.initialParentRoute}Create`;

    return (
      <EventTaskAddScreen
        type={this.dm.eventTask.TYPE_RESOURCE}
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
        createAction={createAction}
      />
    );
  }
  async resourceEventTaskEdit({ uid = null, eventUid = null }) {
    let formData = await this.dm.event.fetchEditFormData(eventUid, uid);
    return (
      <EventEditScreen
        type={this.dm.eventTask.TYPE_RESOURCE}
        formData={formData}
        refreshDataTable='eventTasks'
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
      />
    );
  }

  /** Activity Log Actions */
  async activityLog() {
    return <ActivityLogScreen />;
  }

  /** Resource Activity Logs Actions */
  async resourceActivityLog({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceActivityLogScreen resource={resource} />;
  }

  /** Script Actions */
  async scripts() {
    return <ScriptsScreen />;
  }
  async scriptEdit({ uid = null }) {
    let formData = await this.dm.script.fetchEditFormData(uid);
    return <ScriptEditScreen formData={formData} />;
  }
  /** Resource Script Actions */
  // async resourceScripts({ uid = null }) {
  //   return <ComingSoonScreen />
  // }
  async resourceScripts({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceScriptsScreen resource={resource} />;
  }
  async resourceScriptAdd({ uid = null }) {
    let formData = await this.dm.resourceScript.fetchScriptAddFormData(uid);
    let createAction =
      this.nav.route.initialParentRoute == "resource"
        ? "resourceScriptCreate"
        : `${this.nav.route.initialParentRoute}Create`;
    return (
      <ResourceScriptAddScreen
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
        createAction={createAction}
      />
    );
  }
  async resourceScriptEdit({ uid = null, scriptUid = null }) {
    let formData = await this.dm.script.fetchEditFormData(scriptUid);
    return (
      <ScriptEditScreen
        formData={formData}
        refreshDataTable='resourceScripts'
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
      />
    );
  }
  async resourceScriptLog({ resourceScriptUid }) {
    let text = await this.dm.resourceScript.fetchLog(resourceScriptUid);
    return <ResourceScriptLogScreen uid={resourceScriptUid} text={text} />;
  }
  /** Log Actions */
  async logs() {
    return <LogsScreen />;
  }
  async logEdit({ uid = null }) {
    let formData = await this.dm.log.fetchEditFormData(uid);
    return <LogEditScreen formData={formData} />;
  }
  async resourceLogs({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceLogsScreen resource={resource} />;
  }
  async resourceLog({ uid, logUid }) {
    return <ResourceLogScreen resourceUid={uid} logUid={logUid} />;
  }
  async resourceLogAdd({ uid = null }) {
    let formData = await this.dm.resource.fetchLogAddFormData(uid);
    let createAction =
      this.nav.route.initialParentRoute == "resource"
        ? "resourceLogCreate"
        : `${this.nav.route.initialParentRoute}Create`;
    return (
      <ResourceLogAddScreen
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
        createAction={createAction}
      />
    );
  }
  async resourceLogEdit({ uid = null, logUid }) {
    let formData = await this.dm.log.fetchEditFormData(logUid);
    return (
      <LogEditScreen
        formData={formData}
        refreshDataTable='resourceLogs'
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
      />
    );
  }
  /** Client Actions */
  async clients() {
    return <ClientsScreen />;
  }
  async clientEdit({ uid = null }) {
    let formData = await this.dm.client.fetchEditFormData(uid);
    return <ClientEditScreen formData={formData} />;
  }
  /** User Actions */
  async users() {
    return <UsersScreen />;
  }
  async userEdit({ uid = null }) {
    let formData = await this.dm.user.fetchEditFormData(uid);
    return <UserEditScreen formData={formData} />;
  }
  async userPermissionsEdit({ uid = null }) {
    let formData = await this.dm.userPermission.fetchEditFormData(uid);
    return <UserPermissionsEditScreen formData={formData} />;
  }
  async userPermissionGroups() {
    return <UserPermissionGroupsScreen />;
  }
  async userPermissionGroupEdit({ uid = null }) {
    let formData = await this.dm.userPermission.fetchGroupEditFormData(uid);
    return <UserPermissionGroupEditScreen formData={formData} />;
  }
  /** Manual Entry User Actions */
  async manualEntryUsers() {
    return <ManualEntryUsersScreen />;
  }
  async manualEntryUserEdit({ uid }) {
    let formData = await this.dm.manualEntryUser.fetchEditFormData(uid);
    return <ManualEntryUserEditScreen formData={formData} />;
  }
  /** Account Actions */
  async accountSettings() {
    let formData = await this.dm.user.fetchAccountSettingsFormData(
      this.dm.userSession.uid
    );
    return <AccountSettingsScreen formData={formData} />;
  }
  /** Theme Actions */
  async themes() {
    return <ThemesScreen />;
  }
  async themeEdit({ uid = null }) {
    let formData = await this.dm.theme.fetchEditFormData(uid);
    return <ThemeEditScreen formData={formData} />;
  }
  /** Timesheet Actions */
  async timesheet() {
    return <TimesheetScreen />;
  }
  async timesheetEdit({ uid = null }) {
    let formData = await this.dm.timesheet.fetchEditFormData(uid);
    return <TimesheetEditScreen formData={formData} />;
  }
  /** Status Checks */
  async statusChecks() {
    return <StatusChecksScreen />;
  }
  async statusCheckEdit({ uid = null }) {
    let formData = await this.dm.statusCheck.fetchEditFormData(uid);
    return <StatusCheckEditScreen formData={formData} />;
  }
  /** Alerts Actions */
  async contactGroups() {
    return <ContactGroupsScreen />;
  }
  async contactGroupEdit({ uid = null }) {
    let formData = await this.dm.contactGroup.fetchEditFormData(uid);
    return <ContactGroupEditScreen formData={formData} />;
  }

  /** Resource Alert Actions */
  async resourceAlerts({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceAlertsScreen resource={resource} />;
  }
  async resourceAlertAdd({ uid = null }) {
    let formData = await this.dm.resourceAlert.fetchAlertAddFormData(uid);
    let createAction =
      this.nav.route.initialParentRoute == "resource"
        ? "resourceAlertCreate"
        : `${this.nav.route.initialParentRoute}Create`;
    return (
      <ResourceAlertAddScreen
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
        createAction={createAction}
      />
    );
  }
  // async resourceAlertEdit({ uid = null, alertUid = null }) {
  //   return null;
  //   // let formData = await this.dm.alert.fetchEditFormData(alertUid);
  //   // return (
  //   //   <AlertEditScreen
  //   //     formData={formData}
  //   //     refreshDataTable='resourceAlerts'
  //   //     resourceUid={uid}
  //   //     redirect={{
  //   //       route: this.nav.route.initialParentRoute,
  //   //       params: { uid: uid },
  //   //     }}
  //   //   />
  //   // );
  // }
  async resourceAlertLog({ resourceAlertUid }) {
    return <ResourceAlertLogScreen uid={resourceAlertUid} />;
  }

  /** Resource Status Check Actions */
  async resourceStatusChecks({ uid = null }) {
    let resource = await this.dm.resource.instance(uid, { autoRefresh: 20 });
    return <ResourceStatusChecksScreen resource={resource} />;
  }
  async resourceStatusCheckAdd({ uid = null }) {
    let formData = await this.dm.resourceStatusCheck.fetchStatusCheckAddFormData(
      uid
    );
    let createAction =
      this.nav.route.initialParentRoute == "resource"
        ? "resourceStatusCheckCreate"
        : `${this.nav.route.initialParentRoute}Create`;
    return (
      <ResourceStatusCheckAddScreen
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
        createAction={createAction}
      />
    );
  }
  async resourceStatusCheckEdit({ uid = null, statusCheckUid = null }) {
    let formData = await this.dm.statusCheck.fetchEditFormData(statusCheckUid);
    return (
      <StatusCheckEditScreen
        formData={formData}
        refreshDataTable='resourceStatusChecks'
        resourceUid={uid}
        redirect={{
          route: this.nav.route.initialParentRoute,
          params: { uid: uid },
        }}
      />
    );
  }
  async resourceStatusCheckLog({ resourceStatusCheckUid = null }) {
    return <ResourceStatusCheckLogScreen uid={resourceStatusCheckUid} />;
  }
  /** Cloud Access Key Actions */
  async cloudAccessKeys() {
    return <CloudAccessKeysScreen />;
  }
  async cloudAccessKeyEdit({ uid = null }) {
    let formData = await this.dm.cloudAccessKey.fetchEditFormData(uid);
    return <CloudAccessKeyEditScreen formData={formData} />;
  }
  /** SSH Key Actions */
  async sshKeys() {
    return <SshKeysScreen />;
  }
  async sshKeyEdit({ uid = null }) {
    let formData = await this.dm.sshKey.fetchEditFormData(uid);
    return <SshKeyEditScreen formData={formData} />;
  }
  async resourceSshKeyManage({ uid = null }) {
    let formData = await this.dm.resource.fetchSshKeyManageFormData(uid);
    return <ResourceSshKeyManageScreen resourceUid={uid} formData={formData} />;
  }
  async resourceSshKeyEdit({ uid = null, sshKeyUid = null }) {
    let formData = await this.dm.sshKey.fetchEditFormData(sshKeyUid);
    return (
      <SshKeyEditScreen
        uid={sshKeyUid}
        formData={formData}
        resourceUid={uid}
        redirect={{
          route: "resource",
          params: { uid: uid },
        }}
      />
    );
  }
  /** Support Actions */
  async support() {
    return <SupportScreen />;
  }

  /** Messages */
  async messages() {
    let info = await this.dm.message.initUser();
    return <MessagesScreen navigationInfo={info} />;
  }

  /** To Do */
  async todo() {
    return <TodoScreen />;
  }
  async todosBoard() {
    return <TodosBoardScreen />;
  }
  async todosActive() {
    return (
      <TodosScreen name='activeTodos' title='Active Todos' active={true} />
    );
  }
  async todosComplete() {
    return (
      <TodosScreen
        name='completedTodos'
        title='Completed Todos'
        active={false}
      />
    );
  }

  async todoEdit({ uid = null }) {
    let formData = await this.dm.todo.fetchEditFormData(uid);
    return <TodoEditScreen formData={formData} />;
  }

  /** Messages Actions */
  async messagesConversation({ uid = null }) {
    return <MessagesConversationScreen messageGroupUid={uid} />;
  }

  async projectAppModuleServerLog({ uid, appModuleUid, serverType }) {
    return (
      <AppModuleLogScreen
        uid={appModuleUid}
        logType={serverType.toUpperCase()}
      />
    );
  }

  async projectAppModuleMonitor({ uid, appModuleUid, serverType }) {
    let appModule = await this.dm.appModule.get(appModuleUid, {
      serverState: true,
      resource: true,
    });

    return (
      <AppModuleMonitorScreen
        appModule={appModule}
        serverType={serverType.toUpperCase()}
      />
    );
  }
}
