import React from "react";
import PropTypes from "prop-types";
import { Button, Colors, Text, View, StyleSheet } from "../core";

export default class RelatedAddForm extends React.Component {
  static propTypes = {
    /** Title for the create button */
    title: PropTypes.string,
    /** Function to be called when create button is pressed. */
    onPress: PropTypes.func,
    /** Color for the create button */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };
  static defaultProps = {
    color: Colors.primary
  };
  render() {
    return (
      <View>
        <Button
          onPress={this.props.onPress}
          style={styles.createButton}
          color={this.props.color}
          title={this.props.title}
          key='create'
          variant='outlined'
        />
        {this.props.showOr !== false && (
          <View style={styles.orView}>
            <Text style={styles.orText}>OR</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  orView: {
    flex: 1,
    alignItems: "center",
    ...StyleSheet.margin(0, 0, 16, 0)
  },
  orText: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onBackground).rgba(0.5),
    fontWeight: "500"
  },
  createButton: {
    width: "100%",
    height: 56,
    marginBottom: 16
  }
});
