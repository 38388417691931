import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import ResourceScreenLayout from "../components/ResourceScreenLayout";
import ActivityLogDataTable from "../components/ActivityLogDataTable";
export default R14.connect(
  class ResourcesEventsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ResourceScreenLayout
          resource={this.props.resource}
        >
          <ActivityLogDataTable
            widget={false}
            // editAction='eventTasksEdit'
            resourceUid={this.props.resource.uid}
          />
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
