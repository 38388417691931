import React from "react";
import PropTypes from "prop-types";
import R14, {
  View,
  Text,
  IconButton,
  StyleSheet,
  Theme,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
  Image,
  FadeView,
  Touchable,
  ActivityIndicator,
  Icon,
  AnimatedView,
  ScrollView
} from "../core";

export default class FileExplorer extends React.Component {
  static propTypes = {
    /** The unique name for the file explorer  */
    name: PropTypes.string.isRequired,
    /** The root path of the file explorer  */
    rootPath: PropTypes.string,
    /** Requests listings by path  */
    directoryLoader: PropTypes.func.isRequired,
    /**
     * Callback for when a file is selected.
     *
     * @param {Object} fileName The file name selected
     *
     * */
    onFileSelect: PropTypes.func,
    /** Height of the scroll view  */
    height: PropTypes.number,
    /** Height of the scroll view  */
    width: PropTypes.number,
    /** A StyleSheet object, or array of StyleSheet objects to apply to the image */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };
  static defaultProps = {
    rootPath: "/",
    height: 320,
    width: 256
  };
  constructor(props) {
    super(props);
    this.isInitialized = false;
    this.NODE_TYPE_FILE = "FILE";
    this.NODE_TYPE_DIR = "DIR";
    this._dirs = {};
    this._dirIdx = 0;
  }
  componentDidMount() {
    // this.dir = [
    //   {
    //     root: true,
    //     path: this.props.rootPath,
    //     type: "DIR",
    //     hasLoaded: false,
    //     nodes: []
    //   }
    // ];
    //this.loadDirectory(this.props.rootPath, true);
  }
  async loadDirectory(path) {
    return await this.props.directoryLoader(path);
  }
  async selectFile(path) {
    this.props.onFileSelect && this.props.onFileSelect(path);
  }
  declareDir(path, component) {
    this._dirs[path] = {
      idx: this.createDirIdx(),
      path: path,
      component: component
    };
  }
  createDirIdx() {
    return ++this._dirIdx;
  }
  render() {
    let dynamicStyles = StyleSheet.create({
      fileExplorer: {
        height: this.props.height,
        width: this.props.width
      }
    });
    return (
      <View style={[styles.fileExplorer, dynamicStyles.fileExplorer, this.props.style]}>
        <ScrollView style={styles.scrollView}>
          <FileExplorerDir
            fileExplorer={this}
            path={this.props.rootPath}
            root={true}
            onFileSelect={this.props.onFileSelect}
          />
        </ScrollView>
      </View>
    );
  }
}

class FileExplorerDir extends React.Component {
  constructor(props) {
    super(props);
    this.handleDirPress = this.handleDirPress.bind(this);
    this.fileExplorer = this.props.fileExplorer;
    this.state = {
      loaded: false,
      opened: false
    };
    this.nodes = [];
    this.fileExplorer.declareDir(this.props.path, this);
  }
  componentDidMount() {
    this.props.root && this.loadDirectory();
  }
  async handleDirPress() {
    if (!this.state.loaded) this.loadDirectory();
    else
      this.setState({
        opened: !this.state.opened
      });
  }
  handleFileSelect(path) {
    this.props.fileExplorer.selectFile(path);
  }
  async loadDirectory() {
    this.setState({ loaded: false, opened: true });
    let res = await this.props.fileExplorer.loadDirectory(this.props.path);
    this.nodes = res.nodes || [];
    this.setState({ loaded: true, opened: true });
  }
  renderDir() {
    if (!this.nodes.length) return null;
    let nodes = [];
    let dirs = [];
    let files = [];

    this.nodes.forEach(node => {
      if (node.type === this.fileExplorer.NODE_TYPE_DIR) dirs.push(node);
      else files.push(node);
    });

    dirs.forEach(dir =>
      nodes.push(
        <FileExplorerDir
          fileExplorer={this.fileExplorer}
          path={`${this.props.path}/${dir.name}`}
          key={`${this.props.path}/${dir.name}`}
          title={dir.name}
        />
      )
    );
    files.forEach(file =>
      nodes.push(
        <Touchable
          style={styles.fileRow}
          key={`${this.props.path}/${file.name}`}
          onPress={() =>
            this.handleFileSelect(`${this.props.path}/${file.name}`)
          }
        >
          <Text style={styles.fileTitleText}>{file.name}</Text>
        </Touchable>
      )
    );
    return nodes;
  }
  render() {
    return (
      <View>
        {!this.props.root && (
          <Touchable style={styles.dirRow} onPress={this.handleDirPress}>
            <AnimatedView
              in={this.state.opened}
              enter={{
                transform: [{ rotate: "90deg" }]
              }}
              exit={{
                transform: [{ rotate: "0deg" }]
              }}
            >
              <Icon name='keyboardArrowRight' size='small' />
            </AnimatedView>
            <Text style={styles.fileTitleText}>{this.props.title}</Text>
          </Touchable>
        )}
        {this.state.opened && this.state.loaded && (
          <View style={styles.dirNodes}>{this.renderDir()}</View>
        )}
        {this.state.opened && !this.state.loaded && (
          <ActivityIndicator
            size='small'
            containerStyle={styles.dirRowActivityIndicator}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  fileExplorer: {
    position: "relative"
  },
  scrollView: {
    // position: 'relative'
    paddingLeft: 4
  },
  dirRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    minHeight: 24
  },
  dirRowActivityIndicator: {
    height: 24,
    ...StyleSheet.padding(0, 0, 0, 24),
    // justifyContent: 'flex-start',
    alignItems: "flex-start"
  },
  fileRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: 20,
    minHeight: 24
  },
  fileTitleText: {
    color: Colors.onBackground
  },
  dirNodes: {
    paddingLeft: 8
  }
});
