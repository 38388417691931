import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  View,
  Surface,
  SectionHeader,
  Text,
  Button,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
} from "../core";
import ProjectResourceDataTable from "../components/ProjectResourceDataTable";
// import AppModuleDataTable from "../components/AppModuleDataTable";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import AppModuleWidget from "../components/AppModuleWidget";
import ProjectHealthCheckDataTable from "../components/ProjectHealthCheckDataTable";
export default R14.connect(
  class ProjectScreen extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
    }
    render() {
      let project = this.props.project;
      let projectKey = this.props.app.dm.project.getKeyByType(project.type);
      return (
        <ProjectScreenLayout
          headerTitle="Dashboard"
          project={this.props.project}
        >
          <Dashboard name='projects' minWidth={352}>
            {/* <DashboardCard title='Project'>
              <DashboardCardSection title='Name'>
                <Text>{project.name}</Text>
              </DashboardCardSection>
              <DashboardCardSection title='Type'>
                <Text>
                  {this.props.app.dm.project.getTypeLabel(project.type)}
                </Text>
              </DashboardCardSection>
              {project.description && (
                <DashboardCardSection title='Description'>
                  <Text>{project.description}</Text>
                </DashboardCardSection>
              )}
            </DashboardCard> */}

            {this.userDomain.checkPermissions(
              projectKey,
              "appModule",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              (project.type === this.props.app.dm.project.TYPE_DEV ||
                project.type === this.props.app.dm.project.TYPE_AI) &&
              project.appModules.map((appModule) => (
                <DashboardCard key={`appModule-${appModule.uid}`}>
                  <AppModuleWidget appModule={appModule} project={project} />
                </DashboardCard>
              ))}

            {this.userDomain.checkPermissions(
              projectKey,
              "resource",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              project.type === this.props.app.dm.project.TYPE_CLOUD && (
                <DashboardWidget>
                  <ProjectResourceDataTable
                    widget
                    projectUid={project.uid}
                    projectType={project.type}
                  />
                </DashboardWidget>
              )}

            {this.userDomain.checkPermissions(
              projectKey,
              "healthCheck",
              this.userDomain.PERMISSION_ACCESS_READ
            ) &&
              project.type === this.props.app.dm.project.TYPE_CLOUD && (  
                <DashboardWidget>
                  <ProjectHealthCheckDataTable
                    widget
                    projectUid={project.uid}
                    projectType={project.type}
                  />
                </DashboardWidget>
                // <HealthCheckDashboardWidget project={project} />              
              )}
            {/* <DashboardCard title='CPU'></DashboardCard> */}

            {/* <DashboardCard title='Workflows'></DashboardCard> */}
          </Dashboard>
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  project: {
    ...StyleSheet.margin(16),
  },
  screenHeader: {
    marginBottom: 16,
  },
  resourceDataTable: {
    position: "relative",
    height: 400,
  },
  dashboardCardInfo: {
    ...StyleSheet.padding(16, 64, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(16, 0, 0, 0),
        };
    },
  },
});
