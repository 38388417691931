import R14 from "../core";

export default class PipelineDomain extends R14.Domain {
  constructor(key) {
    super();
    this.STATE_RUNNING = "RUNNING";
    this.STATE_STOPPED = "STOPPED";
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!this.dm.user.hasAdminRole)
      options.filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.api.qry(
      `
      query FindPipelines($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: PipelineFilter) {
        pipelines(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.pipelines;
  }

  async fetchBlockSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (filters.pipelineUid) {
      filter.pipelineUid = { eq: filters.pipelineUid };
    }

    let res = await this.dm.pipelineBlock.find(
      `
      uid
      name
      `,
      {
        // page: 1,
        // resultsPerPage: 10,
        filter: filter,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }

  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!this.dm.user.hasAdminRole)
      options.filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.api.qry(
      `
      query FindPipelines($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: PipelineFilter) {
        pipelines(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.pipelines;
  }

  async findBillingItems(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    //options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindBillingItems($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: BillingItemFilter) {
        billingItems(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.billingItems;
  }

  parseFormValues(values) {
    let ret = { ...values };
    ret.clientUid = this.dm.userSession.clientUid;

    // Fix height and width
    if (values.rows !== null) ret.rows = parseInt(ret.rows);
    if (values.columns !== null) ret.columns = parseInt(ret.columns);

    return ret;
  }

  async create(values, options = {}) {
    let input = options.parse === false ? values : this.parseFormValues(values);
    // values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreatePipeline($input: CreatePipelineInput!) {
        createPipeline(input: $input){
          pipeline {
            uid
            name
            description
          }
        }
      }`,
      {
        input,
      }
    );
    return true;
  }
  async update(values, options = {}) {
    let input = options.parse === false ? values : this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation UpdatePipeline($input: UpdatePipelineInput!) {
        updatePipeline(input: $input){
          pipeline {
            uid
            name
            description
          }
        }
      }`,
      {
        input,
      }
    );
    return true;
  }

  async get(uid, options = {}) {
    let projectQry = "";
    let blocksQry = "";
    let pipelineBlockIoQry = "";
    let pipelinesQry = "";
    let qryArgs = {
      uid: uid,
    };

    let filterStr = "";

    if (options.project)
      projectQry = `
    project {
      uid
      name
      type
    }
`;
    if (options.blocks) {
      blocksQry = `
    pipelineBlocks(filter: $blocksFilter) {
      nodes {
        name
        description
        uid
        height
        width
        x
        y
        backgroundColor
        pipelineUid
        agentCloudAccessKeyUid
        block {
          name
        }
        state
        activeInstanceCount
        icon
        instanceState {
          state
          port
          publicIpAddress
          appModuleUid
          appModuleType
        }
        inputs {
          blockIoUid
          x
          y
          queueItemCount
          lastChangedAt
        }
        outputs {
          blockIoUid
          x
          y
          queueItemCount
          lastChangedAt
        }
      }
    }
`;
      qryArgs.blocksFilter = {
        clientUid: { eq: this.dm.userSession.clientUid },
      };
      filterStr += ", $blocksFilter: PipelineBlockFilter";
    }
    if (options.pipelineBlockIo) {
      pipelineBlockIoQry = `
    pipelineBlockIo(filter: $pipelineBlockIoFilter) {
      nodes {
        uid
        inputPipelineBlockUid
        inputX
        inputY
        inputBackgroundColor
        outputPipelineBlockUid
        outputX
        outputY
        outputBackgroundColor
        queueItemCount
        blockInput {
          uid
          name
        }
        blockOutput {
          uid
          name
        }
      }
    }
`;
      if (options.pipelines) {
        pipelinesQry = `pipelines (filter: $pipelineFilter){
  nodes {
    uid
    name
  }
}
`;
        qryArgs.pipelineFilter = {
          clientUid: { eq: this.dm.userSession.clientUid },
        };
        if (!this.dm.user.hasAdminRole)
          qryArgs.pipelineFilter.userUids = { eq: this.dm.userSession.uid };

        filterStr += ", $pipelineFilter: PipelineFilter";
      }
      qryArgs.pipelineBlockIoFilter = {
        clientUid: { eq: this.dm.userSession.clientUid },
      };
      filterStr += ", $pipelineBlockIoFilter: PipelineBlockIoFilter";
    }
    let res = await this.api.qry(
      `
      query GetPipeline($uid: ID!${filterStr}) {
       pipeline(uid: $uid){
          uid
          name
          description
          rows
          columns
          state
          ${projectQry}
          ${blocksQry}
          ${pipelineBlockIoQry}
        }
        ${pipelinesQry}
      }`,
      qryArgs
    );
    if (options.pipelines) return res.data;
    else return res.data.pipeline;
  }
  async copy(input) {
    let res = await this.api.mutate(
      `
      mutation CopyPipeline($input: CopyPipelineInput!) {
        copyPipeline(input: $input){
          success
          pipeline {
            uid
            name
          }
        }
      }`,
      {
        input: input,
      }
    );
    if(res.errors && res.errors.length) return res;
    else return res.data.copyPipeline;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeletePipeline($uid: ID!) {
        deletePipeline(uid: $uid){
          pipeline {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }

  async fetchCopyFormData(uid, options = {}) {
    if (!uid) throw new Error(`Pipeline Copy: Error, uid required.`);

    let pipelineQry = `
      query PipelineCreateFormData($uid: ID!) {
        pipeline(uid: $uid){
          uid
          key
          name
          description
        }
      }
    `;
   
    let res = await this.api.qry(pipelineQry, { uid,
      // pipelineBlockFilter: {
       
      //   pipelineUid: { eq: uid },
      //   clientUid: { eq: this.dm.userSession.clientUid },
      // },
    });

  //   // Get the appModules
  //   let appModuleUids = []
  //   res.data.pipeline.pipelinesBlocks.nodes.forEach(pipelineBlock=>{
  //     if(! pipelineBlock.appModule) return;
  //     let appModuleUid = pipelineBlock.appModule.uid;
  //     if(! appModuleUids.includes(appModuleUid)) appModuleUids.push(appModuleUids);
  //   });


  //   let appModuleQry = `
  //   query PipelineCreateFormAppModuleData($uid: ID!, $appModuleFilter: AppModuleFilter) {
  //     appModules(filter: $appModuleFilter) {
  //       nodes {
  //         name
  //         uid
  //         key
  //       }
  //     }
  //   }
  // `;
  //   console.log(res.data);
    
    let formVals = {
      projectUid: options.projectUid || null,
      uid
    };
    // if (uid) formVals = { ...formVals, ...res.data.pipeline };
    let formData = {
      pipelineName: res.data.pipeline.name,
      values: formVals,
    };
    return formData;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    let qryVals = {
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    if (uid) {
      qry = `
      query PipelineCreateFormData($uid: ID!, $userFilter: UserFilter) {
        pipeline(uid: $uid){
          uid
          key
          name
          description
          userUids
          rows
          columns
        },
        users(filter:$userFilter) {
          nodes {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query PipelineEditFormData($userFilter: UserFilter) {
          users(filter:$userFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = {
      projectUid: options.projectUid || null,
    };
    if (uid) formVals = { ...formVals, ...res.data.pipeline };
    let formData = {
      values: formVals,
      userSelections: res.data.users.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }
  async fetchBlockEditFormData(uid = null, options = {}) {
    let qry = "";
    if (!uid && !options.pipelineUid)
      throw new Error(
        "Pipeline Block Form Error: Error pipelineUid not found."
      );
    let appModuleFilter = { clientUid: { eq: this.dm.userSession.clientUid } };
    if (options.projectUid)
      appModuleFilter.projectUid = { eq: options.projectUid };

    if (!this.dm.user.hasAdminRole)
      appModuleFilter.userUids = { eq: this.dm.userSession.uid };

    let qryVals = {
      appModuleFilter: appModuleFilter,
    };
    if (uid) {
      qry = `
      query PipelineBlockCreateFormData($uid: ID!, $appModuleFilter: AppModuleFilter) {
        pipelineBlock(uid: $uid){
          uid
          pipelineUid
          name
          description
          appModuleUid
        },
        appModules(filter:$appModuleFilter) {
          nodes {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query PipelineBlockEditFormData($appModuleFilter: AppModuleFilter) {
          appModules(filter:$appModuleFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = {
      pipelineUid: options.pipelineUid || null,
      projectUid: options.projectUid || null,
    };
    if (uid) formVals = { ...formVals, ...res.data.pipeline };
    let formData = {
      values: formVals,
      appModuleSelections: res.data.appModules.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }
  async fetchBlockAppModuleSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (!this.dm.user.hasAdminRole)
      filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.dm.appModule.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
}
