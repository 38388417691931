import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";

export default R14.connect(
  class StatuscheckDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.statusCheckDomain = this.props.app.dm.statusCheck;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };

      if ("active" in this.props) filter.active = this.props.active;
      let res = await this.props.app.dm.statusCheck.find(
        `
        uid
        name
        description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("statusChecks");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.statusCheck.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    render() {
      return (
        <DataTable
          name='statusChecks'
          columns={{
            name: {
              label: "Name",
              sortable: true
            }
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Status Checks"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='state'
          initialSortDirection='desc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Create StatusCheck'
                value='test1'
                to='statusCheckCreate'
                key='add'
              />
            </PopUpMenu>
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to("statusCheckEdit", {
                    uid: row.uid
                  })
                }
              />,
              <PopUpMenu
                controlIcon='dotsHorizontal'
                controlIconSize='small'
                key='statusCheckMenu'
                direction='downLeft'
              >
                
                <PopUpMenuItem
                  icon='close'
                  label='Remove'
                  key='remove'
                  onPress={() => this.handleRemovePress(row.uid, dataTable)}
                />
              </PopUpMenu>
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
