import React from "react";
import R14, { TextInputField, SelectMenuField } from "../core";
import EditForm from "../components/EditForm";
export default R14.connect(
  class ContactGroupEditScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='contactGroup'
          redirect={this.props.redirect || "contactGroups"}
          refreshDataTable={this.props.refreshDataTable || "contactGroups"}
          saveOptions={
            this.props.resourceUid && { resourceUid: this.props.resourceUid }
          }
          onSave={this.handleSave}
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField name='description' label='Description' />
          <SelectMenuField
            useValuesOnly
            name='userUids'
            multiple
            label='Users'
            items={this.props.formData.userSelections}
            //required='Please select at least 1 user.'
          />
          <TextInputField
            name='emails'
            label='Emails'
            multiple
            unique
            validator='email'
            //required='Please select at least 1 email.'
          />
        </EditForm>
      );
    }
  }
);
