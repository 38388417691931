import React from "react";
import R14, { StyleSheet } from "../core";
import ResourceScreenLayout from "../components/ResourceScreenLayout";
import ResourceAlertDataTable from "../components/ResourceAlertDataTable";
export default R14.connect(
  class ResourcesEventsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ResourceScreenLayout
          floatingActionButton={{
            to: {
              route: "resourceAlertsAdd",
              params: { uid: this.props.resource.uid }
            },
            icon: "add"
          }}
          resource={this.props.resource}
        >
          <ResourceAlertDataTable
            widget={false}
            editAction='resourceAlertsEdit'
            resourceUid={this.props.resource.uid}
          />
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
