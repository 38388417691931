import React from "react";
import R14, {
  Colors,
  View,
  Text,
  Icon,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  DataTable,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class ProjectHealthCheckDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.projectHealthCheckDomain = this.props.app.dm.projectHealthCheck;
      this.healthCheckDomain = this.props.app.dm.healthCheck;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.projectType),
        "healthCheck"
      );
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) {
        filter.search = { like: `%${search}%` };
      }
      if (this.props.projectUid)
        filter.projectUid = { eq: this.props.projectUid };

      let res = await this.props.app.dm.projectHealthCheck.find(
        `
          uid
          name
          state
          error
          project {
            uid
            type
            name
          }
          healthCheckState {
            state
            total
            totalFail
          }         
        `,
        {
          // projectUid: this.props.projectUid,
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes.map((row) => ({
          uid: row.uid,
          name: row.name,
          state: row.state,
          error: row.error,
          project: row.project,
          healthCheckState: row.healthCheckState,
        })),
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("projectHealthChecks");
    }
    rowBottomRenderer({ row, columns }) {
      if (row.state !== this.projectHealthCheckDomain.STATE_ERROR) return null;
      return (
        <View style={styles.rowBottomError}>
          <Icon name='error' color={Colors.onError}/>
          <Text style={styles.rowBottomErrorText}>
            {row.error || "An error has occurred."}
          </Text>
        </View>
      );
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "project":
          ret = <Text style={[styles.cellText]}>{row.project.name}</Text>;
          break;
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        case "state":
          if (row.healthCheckState && row.healthCheckState.state)
            ret = (
              <StateIndicatorText
                label={this.healthCheckDomain.getStateLabel(
                  row.healthCheckState.state
                )}
                color={this.healthCheckDomain.getStateIndicatorColor(
                  row.healthCheckState.state
                )}
              />
            );
          break;
        case "healthCheckState":
          if (row.healthCheckState && row.healthCheckState.state)
            ret = (
              <Text style={style}>
                {cell.total - cell.totalFail} of {cell.total}
              </Text>
            );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.projectHealthCheck.delete(uid);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        dataTable.refresh({ indicator: true });
      }
    }
    get columns() {
      let columns = {
        name: {
          label: "Name",
          sortable: true,
        },
        state: {
          label: "State",
          sortable: true,
        },
        healthCheckState: {
          label: "Passed",
          // sortable: true,
          // align: 'right'
        },
      };
      if (!this.props.projectUid)
        columns = {
          project: {
            label: "Project",
            sortable: true,
          },
          ...columns,
        };
      return columns;
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <DataTable
          name='projectHealthChecks'
          columns={this.columns}
          // maxHeight={300}
          // autoRefresh={30}
          searchable={true}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          rowBottom={this.rowBottomRenderer}
          initialRowsPerPage={this.props.widget === false ? 20 : 5}
          headerTitle={this.props.title || "Health Checks"}
          onRowPress={(row) =>
            this.props.app.nav.to("projectHealthCheck", {
              uid: row.project.uid,
              key: this.props.app.dm.project.getKeyByType(row.project.type),
              projectHealthCheckUid: row.uid,
            })
          }
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName={this.props.projectUid ? "name" : "project"}
          initialSortDirection='asc'
          headerControlsRight={
            this.props.projectUid &&
            this.perms.create &&
            this.props.widget !== false && [
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={() =>
                  this.props.app.nav.to("projectHealthCheckCreate", {
                    uid: this.props.projectUid,
                    key: this.props.app.dm.project.getKeyByType(
                      this.props.projectType
                    ),
                  })
                }
              />,
            ]
          }
          rowControlsRight={
            this.props.projectUid &&
            (this.perms.edit || this.perms.delete) &&
            ((row, dataTable) => [
              <PopUpMenu
                controlIcon='dotsVertical'
                controlIconSize='small'
                key='resourceMenu'
                direction='downLeft'
              >
                {this.perms.edit && (
                  <PopUpMenuItem
                    label='Edit'
                    icon='edit'
                    key='edit'
                    onPress={() =>
                      this.props.app.nav.to(
                        this.props.editAction || "projectHealthCheckEdit",
                        {
                          uid: this.props.projectUid,
                          key: this.props.app.dm.project.getKeyByType(
                            this.props.projectType
                          ),
                          projectHealthCheckUid: row.uid,
                        }
                      )
                    }
                  />
                )}
                {this.perms.delete && (
                  <PopUpMenuItem
                    label='Remove'
                    icon='close'
                    key='remove'
                    onPress={() => this.handleRemovePress(row.uid, dataTable)}
                  />
                )}
              </PopUpMenu>,
            ])
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
  healthCheckCell: {
    flex: 1,
    flexDirection: "row",
  },
  healthCheckTestTotalText: {
    marginLeft: 16,
  },
  cellText: {
    color: Colors.onSurface,
  },
  rowBottomError: {
    flex: 1,
    flexDirection: "row",
    marginTop: 12,
    backgroundColor: Colors.error,
    borderRadius: 16,
    ...StyleSheet.padding(8, 8, 8, 4),
  },
  rowBottomErrorText: {
    color: Colors.onError,
    fontSize: 14,
    ...StyleSheet.padding(3, 0, 0, 4),
  },
});
