import React from "react";
import R14, { Colors, Icon, Surface, Text, StyleSheet } from "../core";
import LabelView from "../components/LabelView";
import HealthCheckDataTable from "../components/HealthCheckDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import StateIndicatorText from "../components/StateIndicatorText";
export default R14.connect(
  class ProjectsHealthCheckScreen extends React.Component {
    constructor(props) {
      super(props);
      this.healthCheckDomain = this.props.app.dm.healthCheck;
      this.projectHealthCheckDomain = this.props.app.dm.projectHealthCheck;
    }
    render() {
      let projectHealthCheck = this.props.projectHealthCheck;
      let project = projectHealthCheck.project;
      return (
        <ProjectScreenLayout project={project} >
          {projectHealthCheck.state ===
            this.projectHealthCheckDomain.STATE_ERROR && (
            <Surface style={styles.errorSurface}>
               <Icon name="error" color={Colors.onError}/>
              <Text style={styles.errorSurfaceText}>
                {projectHealthCheck.error || "An error has occurred."}
              </Text>
            </Surface>
          )}
          <Surface style={styles.infoSurface}>
            <LabelView style={styles.infoLabelView} label='Name'>
              <Text>{projectHealthCheck.name}</Text>
            </LabelView>
            {projectHealthCheck.healthCheckState.state &&
              projectHealthCheck.healthCheckState.state && (
                <LabelView style={styles.infoLabelView} label='State'>
                  <StateIndicatorText
                    label={this.healthCheckDomain.getStateLabel(
                      projectHealthCheck.healthCheckState.state
                    )}
                    color={this.healthCheckDomain.getStateIndicatorColor(
                      projectHealthCheck.healthCheckState.state
                    )}
                  />
                </LabelView>
              )}
            {projectHealthCheck.healthCheckState.state &&
              projectHealthCheck.healthCheckState.state && (
                <LabelView style={styles.infoLabelView} label='Passed'>
                  <Text>
                    {projectHealthCheck.healthCheckState.total -
                      projectHealthCheck.healthCheckState.totalFail}{" "}
                    of {projectHealthCheck.healthCheckState.total}
                  </Text>
                </LabelView>
              )}
          </Surface>
          <HealthCheckDataTable
            projectUid={project.uid}
            projectType={project.type}
            projectHealthCheckUid={projectHealthCheck.uid}
          />
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  infoSurface: {
    flex: 0,
    flexDirection: "row",
    marginBottom: 16,
  },
  infoLabelView: {
    minWidth: "33%",
    flexGrow: 0,
  },
  errorSurface: {
    flex: 0,
    flexDirection: "row",
    marginBottom: 16,
    backgroundColor: Colors.error,
    ...StyleSheet.padding(8, 8, 8, 4)
  },
  errorSurfaceText: {
    color: Colors.onError,
    ...StyleSheet.padding(2, 0, 0, 4)
  }
});
