import R14 from "../R14";

// import R14Portal from '../R14Portal';
import { matchPath } from "react-router";
export default class R14NavigationBase extends R14.Domain {
  // constructor() {
  //   super();
  //   this._routesConfig = null;
  //   this._routes = {};
  //   this._screens = {};
  //   // url / deep link paths
  //   this._paths = {};
  //   this._navigators = {};
  //   this._portals = {};
  //   this._initialScreen = null;
  //   this._history = null;
  //   this._location = null;
  //   this._match = null;
  //   this._activeRouteParams = {};
  //   this._route = {};
  //   this.state = {
  //     activeRoute: null
  //   }
  // }

  initializeRouterProps(props) {
    if (!props.location) throw "Navigation error, router location not found.";
    if (!props.history) throw "Navigation error, router history not found.";
    this._location = props.location;
    this._history = props.history;
    this.update();
  }

  // initializeRoutes(routesConfig) {
  //   this._routesConfig = routesConfig;
  //   this._initNavigation(routesConfig);
  // }

  getPathByRouteName(routeName) {
    // Check if it is a route with no path,
    // If so forward to the first path or initial screen

    if (!this._paths[routeName] && this._navigators[routeName]) {
      if (this._navigators[routeName].initialRoute) {
        let initialRoute = null;
        if (typeof this._navigators[routeName].initialRoute === "function") {
          initialRoute = this._navigators[routeName].initialRoute({
            navigator: this._navigators[routeName],
            app: R14.getInstance().app,
          });
        } else initialRoute = this._navigators[routeName].initialRoute;
        return this.getPathByRouteName(initialRoute);
      } else if (this._navigators[routeName].routes) {
        return this.getPathByRouteName(
          Object.keys(this._navigators[routeName].routes)[0]
        );
      } else return null;
    } else return this._paths[routeName] || null;
  }

  isActiveRoute(route) {
    throw new Error("isActiveRoute?");
    let routeName = route.name;
    console.log("IS ACTIVE ROUTE", route);

    if (this.activeRoute === routeName) return true;
    // Check the active routes of the portals
    for (let i in this._portals) {
      if (!this._portals[i].route) break;
      let portalRouteName = this._portals[i].route.name;
      if (portalRouteName === routeName) return true;
      // Check if it is a parent of the routePath
      let portalRoute = this.getRouteByRouteName(portalRouteName);
      return (
        portalRoute &&
        portalRoute.routePath &&
        portalRoute.routePath.indexOf(routeName) !== -1
      );
    }
  }

  // addNavigator(navigator, name, portal, parent, routePath) {
  //   this._navigators[name] = {
  //     type: navigator.type,
  //     portal: portal,
  //     routePath: [...routePath],
  //     parent: parent,
  //     routes: navigator.routes
  //   };
  //   switch (navigator.type) {
  //     case "stack":
  //       if (navigator.header) this._navigators[name].header = navigator.header;
  //       break;
  //     case "tab":
  //       if (navigator.tabNavigator) {
  //         this._navigators[name].tabNavigator = navigator.tabNavigator;
  //       }
  //       break;
  //     case "modal":
  //       if (navigator.header) {
  //         this._navigators[name].header = navigator.header;
  //       }
  //       break;
  //   }
  // }

  // _initNavigation(routesConfig, portal = null, parent = null, routePath = []) {
  //   if (!routesConfig.routes) return null;

  //   if (parent === null) {
  //     parent = "r14NavRoot";
  //     if (!portal) portal = (routesConfig.type === 'modal') ? 'modal' : 'root'
  //     this.addNavigator(routesConfig, "r14NavRoot", null, null, [...routePath]);
  //     routePath.push(parent);
  //   }

  //   if (routesConfig.path) {
  //     this._paths[parent] = routesConfig.path;
  //   }

  //   for (let name in routesConfig.routes) {

  //     if (routesConfig.routes[name].routes) {
  //       let childRoutePath = [...routePath];
  //       childRoutePath.push(name);
  //       if (!portal || routesConfig.routes[name].type === 'modal') portal = (routesConfig.routes[name].type === 'modal') ? 'modal' : 'root'
  //       this.addNavigator(routesConfig.routes[name], name, portal, parent, childRoutePath);
  //       this._initNavigation(routesConfig.routes[name], portal, name, childRoutePath);
  //       continue;
  //     }

  //     if (routesConfig.routes[name].path) {
  //       this._paths[name] = routesConfig.routes[name].path;
  //     }

  //     this._screens[name] = {
  //       routePath: [...routePath],
  //       portal: portal,
  //       parent: parent,
  //       ...routesConfig.routes[name]
  //     };

  //   }
  // }
  update() {
    let foundMatch = false;
    let activeRoute = null;
    let matchCount = 0;
    for (let routeName in this._paths) {
      let match = matchPath(this._location.pathname, this._paths[routeName]);
      if (this._location.pathname === this._paths[routeName]) {
        activeRoute = routeName;
        this._match = match;
        break;
      } else if (match && match.isExact) {
        // Check the param count
        // Lowest param count of an exact match is a closer match
        if (match.params) {
          if (matchCount && Object.keys(match.params).length > matchCount)
            continue;
          matchCount = Object.keys(match.params).length;
        }
        activeRoute = routeName;
        this._match = match;
      }
    }
    if (activeRoute) {
      this.initActiveRoute(activeRoute);
    } else if (this._location.pathname === "/") {
      /** @todo refactor this code, is this the best way to forward? */
      // Emulate the behavior of the native navigator initial route
      let initialRoute = this.findInitialRoute(this._routesConfig);
      if (initialRoute) R14.getInstance().app.nav.to(initialRoute);
    }
  }

  findInitialRoute(routesConfig, initialRoute = null) {
    if (
      routesConfig.initialRoute &&
      routesConfig.routes &&
      routesConfig.routes[routesConfig.initialRoute]
    ) {
      return this.findInitialRoute(
        routesConfig.routes[routesConfig.initialRoute],
        routesConfig.initialRoute
      );
    } else return initialRoute;
  }

  // initActiveRoute(routeName) {
  //   let portalName = this.getPortalNameByRouteName(routeName);
  //   let portal = this.portals(portalName);
  //   if (!portal) return false;
  //   // let route = new R14Route();

  //   // let data = {
  //   //   params: (this._match && this._match.params) || {},
  //   //   query: {},
  //   //   state: this._location.state || {},
  //   //   forms: {}
  //   // }
  //   // if (this._location && this._location.search) {
  //   //   let searchParams = new URLSearchParams(this._location.search.substring(1));
  //   //   for (let key of searchParams.keys()) {
  //   //     data.query[key] = searchParams.get(key);
  //   //   }
  //   // }
  //   // if (this.ui.form.activeRouteName === routeName) {
  //   //   let submittedForm = this.ui.form.submittedForm;
  //   //   data.forms[submittedForm.name] = submittedForm;
  //   //   console.log("SETTING THE FORM DATA");
  //   // }

  //   /** @todo Navigation base needs testing to make sure that this works ok. */
  //   /** @todo Navigation base maybe use route object instead of name. */
  //   let routeConfig = this.screens(routeName);
  //   if (routeConfig && routeConfig.initialParentRoute) {
  //     // Make sure that the parent route has an active route
  //     let parentRouteConfig = this.screens(routeConfig.initialParentRoute);
  //     if (parentRouteConfig && parentRouteConfig.portal !== routeConfig.portal) {
  //       let parentPortal = this.portals(parentRouteConfig.portal);
  //       if (parentPortal && !parentPortal.activeRoute){
  //         let parentRoute = new R14Route(routeConfig.initialParentRoute, this.screens[routeConfig.initialParentRoute], parentPortal.name);
  //         parentPortal.setActiveRoute(parentRoute);
  //       }
  //     }
  //   }

  //   if (portal.name === 'root') {
  //     /** @todo Navigation base refactor. This is too static, find a dynamic way to close routes. */
  //     for(let k in this._portals){
  //       if(this._portals[k].name !== 'root'){
  //         //console.log('setting portal inactive: ',this._portals[k].name);
  //         this._portals[k].setActiveRoute(null);
  //       }
  //     }

  //   }

  //   let route = new R14Route(routeName, routeConfig, portal.name, this._location, this._match);
  //   portal.setActiveRoute(route);
  //   this.setActiveRoute(route);

  //   if (portal.name === "root") {
  //     // make sure the root route has an activeRoute

  //   }
  // }

  // setActiveRoute(route) {
  //   this.setState({
  //     activeRoute: route ? route.name : null
  //   });
  // }

  // get activeRoute() {
  //   return this.state.activeRoute;
  // }

  // isScreenInNavigator(screen, navigator) {
  //   if (!this._navigators[navigator]) throw ("No navigator found");
  //   if (!this._screens[screen]) throw ("No screen found");
  //   return this._navigators[navigator].routes[screen] ? true : false;
  // }
  // getNextNavigatorName(screen, navigator) {
  //   if (!this._navigators[navigator]) throw ("No navigator found Err 2");
  //   if (!this._screens[screen]) throw ("No screen found Err 2");
  //   let nextNav = null;
  //   let foundCurr = false;
  //   for (let name of this._screens[screen].routePath) {
  //     if (name === navigator) foundCurr = true;
  //     else if (foundCurr) return name;
  //   }
  //   return null;
  // }
}
