import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectResourceDataTable from "../components/ProjectResourceDataTable";
export default R14.connect(
  class ProjectsResourcesScreen extends React.Component {
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.app.dm.project.getKeyByType(this.props.type),
              "resource",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("projectResourceAdd", {
                  uid: this.props.uid,
                  key: this.props.app.dm.project.getKeyByType(this.props.type)
                }),
              icon: "add"
            }
          }
          scrollEnabled
        >
          <ProjectResourceDataTable
            projectUid={this.props.uid}
            projectType={this.props.type}
          />
        </ScreenLayout>
      );
    }
  }
);
