import React from "react";
import R14, {
  StyleSheet,
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField
} from "../core";
export default R14.connect(
  class LogEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      console.log("logEditScreen", this.props.formData.values);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      let opts = {};
      if (this.props.resourceUid) opts.resourceUid = this.props.resourceUid;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.log[editMethod](
          form.values,
          opts
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "logs";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "logs"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='logForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField name='description' label='Description' />
          <TextInputField
            name='path'
            label='Path'
            required='Please enter a path.'
          />
        </Form>
      );
    }
  }
);
