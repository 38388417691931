import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class TodoDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.todoDomain = this.props.app.dm.todo;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.state = {
        date: null,
        datePickerVisible: false
      };
      // console.log('figure out clean way to forward back to grid and update, maybe use object for selectedrows, check error on select all.');
    }
    handleRowPress(row){
      this.props.app.nav.to("todo", { uid: row.uid });
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (this.props.status) {
        filter.status = { eq: this.props.status };
      }

      let res = await this.props.app.dm.todo.find(
        `
          uid
          title
          description         
        `,
        {
          uid: this.props.uid,
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );

      return {
        pageData: res.nodes.map(row => ({
          uid: row.uid,
          title: row.title,
          description: row.description
        })),
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("todos");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      console.log(row);
      switch (columnName) {
        case "title":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.title}
              </Text>
            </React.Fragment>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    render() {
      return (
        <DataTable
          name={this.props.name || 'todos'}
          columns={{
            title: {
              label: "Todo",
              sortable: true
            }
          }}
          // maxHeight={300}
          // autoRefresh={30}
          searchable={true}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={50}
          headerTitle={this.props.title || "Logs"}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          // headerControlsRight={this.props.widget !== false && [
          //   <IconButton
          //     icon='add'
          //     key='add'
          //     variant='circle'
          //     onPress={() =>
          //       this.props.app.nav.to("todoAdd")
          //     }
          //   />
          // ]}
          // rowControlsRight={(row, dataTable) => {
          //   return [
          //     <IconButton
          //       icon='console'
          //       size='small'
          //       key='console'
          //       onPress={() => this.handleShowLogPress(row.uid)}
          //     />,
          //     <PopUpMenu
          //       controlIcon='dotsHorizontal'
          //       controlIconSize='small'
          //       key='resourceMenu'
          //       direction='downLeft'
          //     >
          //       <PopUpMenuItem
          //         label='Edit'
          //         icon='edit'
          //         key='edit'
          //         onPress={() =>
          //           this.props.app.nav.to(this.props.editAction || "todoEdit", {
          //             uid: this.props.resourceUid,
          //             logUid: row.uid
          //           })
          //         }
          //       />
          //       <PopUpMenuItem
          //         label='Remove'
          //         icon='close'
          //         key='remove'
          //         onPress={() => this.handleRemovePress(row.uid, dataTable)}
          //       />
          //     </PopUpMenu>
          //   ];
          // }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300"
  }
});
