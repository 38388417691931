import React from "react";
import R14, {
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  SelectMenuField,
  TextInputField,
  Redirect,
  StyleSheet,
  SwitchField,
} from "../core";
import EditForm from '../components/EditForm';

export default R14.connect(
  class ProjectHealthCheckEditScreen extends React.Component {
    constructor(props) {
      super(props);
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.resourceItemLoader = this.resourceItemLoader.bind(this);
      this.handleConnectionTypeChange = this.handleConnectionTypeChange.bind(
        this
      );
      this.projectHealthCheckDomain = this.props.app.dm.projectHealthCheck;
      this.state = {
        connectionType: this.props.formData.values.connectionType,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async resourceItemLoader(filters) {
      return await this.projectHealthCheckDomain.fetchResourceSelections(
        filters
      );
    }
    handleConnectionTypeChange(value) {
      this.setState({
        connectionType: value,
      });
    }
    // async handleSubmit(form) {
    //   let editMethod = this.isEditMode ? "update" : "create";
    //   let ret = null;
    //   this.props.app.ui.progressIndicator.show();
    //   try {
    //     let isSuccess = await this.props.app.dm.projectHealthCheck[editMethod](
    //       form.values
    //     );
    //     this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    //     if (isSuccess) {
    //       let dataTable = this.props.refreshDataTable || "projectHealthChecks";
    //       this.props.app.ui.dataTable.exists(dataTable) &&
    //         (await this.props.app.ui.dataTable(dataTable).refresh());
    //       ret = <Redirect to={this.props.redirect || "project"} />;
    //     }
    //   } catch (err) {
    //     form.addError(err.message);
    //   }
    //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    //   return ret;
    // }
    render() {
      console.log(this.formData.contactGroupSelections);
      return (
        <EditForm
          formData={this.props.formData}
          domainName='projectHealthCheck'
          redirect={this.props.redirect || "project"}
          refreshDataTable={
            this.props.refreshDataTable || "projectHealthChecks"
          }
        >
          {/* <Form
          name='projectHealthCheckForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        > */}
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectUid' />
          <HiddenInputField name='type' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField name='description' label='Description' helper='Optional'/>
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='syncFrequency'
              label='Sync Frequency (minutes)'
              required='Please enter a sync frequency.'
            />
            <TextInputField
              name='filePath'
              label='CSV File Path'
              required='Please enter a csv path.'
            />
          </FieldGroup>

          <SelectMenuField
            useValuesOnly
            name='mode'
            label='Mode'
            items={this.formData.modeSelections}
            required='Please select a mode.'
          />

          {this.formData.contactGroupSelections.length && (
            <SelectMenuField
              useValuesOnly
              name='contactGroupUid'
              label='Send Status Alert To'
              items={this.formData.contactGroupSelections}
              helper='Optional'
            />
          )}

          <SelectMenuField
            useValuesOnly
            name='connectionType'
            label='Connection Type'
            items={this.formData.connectionTypeSelections}
            onValueChange={this.handleConnectionTypeChange}
          />
          {this.state.connectionType ===
            this.projectHealthCheckDomain.CONNECTION_TYPE_RESOURCE && (
            <SelectMenuField
              useValuesOnly
              name='resourceUid'
              label='Resource'
              searchable
              initialValueLabels={this.formData.initialResourceValueLabels}
              itemLoader={this.resourceItemLoader}
              items={this.formData.resourceSelections}
            />
          )}
          {this.state.connectionType ===
            this.projectHealthCheckDomain.CONNECTION_TYPE_SFTP && (
            <FieldGroup label='SFTP Connection'>
              <TextInputField autoComplete={false} name='host' label='Host' />
              <TextInputField
                autoComplete={false}
                name='username'
                label='username'
                required='Please enter a sftp username.'
              />

              <TextInputField
                autoComplete={false}
                name='password'
                secure
                label={this.isEditMode ? "Change Password" : "Password"}
              />
            </FieldGroup>
          )}
          {/* </Form> */}
        </EditForm>
      );
    }
  }
);

const styles = StyleSheet.create({
  textRed: {
    color: "red",
  },
});
