import StyleSheetBase from './base/StyleSheetBase';
import {ThemeColorValue} from '../components/Theme';

export default class StyleSheet extends StyleSheetBase {
  static color(hex) {
    return new Color(hex);
  }
  static margin(top, right = null, bottom = null, left = null) {
    if(right === null){
      right = bottom = left = top;
    }
    return {
      margin: 0,
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left
    }
  }
  static padding(top, right = null, bottom = null, left = null) {
    if(right === null){
      right = bottom = left = top;
    }
    return {
      padding: 0,
      paddingTop: top,
      paddingRight: right,
      paddingBottom: bottom,
      paddingLeft: left
    }
  }
  static border(width, style, color) {
    return {
      borderWidth: width,
      borderColor: color,
      borderStyle: style,
    }
  }
  static parseColor(color){
    if(color instanceof ThemeColorValue) return color.toString();
    else return color;
  }
}



export class Color {
  constructor(hex) {
    this._hex = null;
    if(hex instanceof ThemeColorValue){
      this._themeColorValue = hex;
    }
    else{
      if (!this.validate(hex)) throw (`Invalid color ${hex}`);
      this._hex = hex;
    }
    this._alpha = 1;
  }
  hex() {
    throw('LSKDJ FLDSKJF  HEX HEX HEX HEX CHECK');
    return this._hex;
  }
  rgba(alpha = 1) {
    if(this._themeColorValue){
      this._themeColorValue.setOption('alpha',alpha);
      return this._themeColorValue;
    }
    else{
      return Color.hexToRgba(this._hex, alpha);
    }
  }
  static hexToRgba(hex, alpha){
    let color = hex;
    color = color.replace('#', '');
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${alpha})`;
  }
  validate(hex) {
    return /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(hex)
  }
}