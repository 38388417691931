import React from "react";
import R14, {
  Chart,
  StyleSheet,
  View,
  Form,
  SelectMenuField,
  ActivityIndicator,
  SectionHeader,
} from "../core";
export default R14.connect(
  class ResourceMetricsChart extends React.Component {
    constructor(props) {
      super(props);
      this.handleMetricValueChange = this.handleMetricValueChange.bind(this);
      this.handlePeriodValueChange = this.handlePeriodValueChange.bind(this);
      this.resourceDomain = this.props.app.dm.resource;
      this.state = {
        metric: this.props.metric || this.formData.values.metric,
        period: this.formData.values.period,
        data: this.props.initialData || null,
        isLoaded: this.props.initialData ? true : false,
      };
      // Check Permissions
      if (
        !this.props.app.dm.user.checkPermissions(
          "resource",
          "monitor",
          this.props.app.dm.user.PERMISSION_ACCESS_READ
        )
      )
        throw new Error("Permission Denied.");
    }
    componentDidMount() {
      if (!this.props.initialData) this.initMetrics();
    }
    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.metric !== this.state.metric ||
        prevState.period !== this.state.period
      )
        this.initMetrics();
    }
    handleMetricValueChange(metric) {
      this.setState({
        metric: metric,
        isLoaded: false,
      });
    }
    handlePeriodValueChange(period) {
      this.setState({
        period: period,
        isLoaded: false,
      });
    }
    get formData() {
      let metricSelectionsOpts = {};
      if (this.props.resourceDockerTaskUid)
        metricSelectionsOpts.resourceDockerTaskUid = this.props.resourceDockerTaskUid;
      let metricSelections = this.props.app.dm.resource.getMetricSelections(
        this.props.resourceType,
        metricSelectionsOpts
      );
      return {
        metricSelections: metricSelections,
        periodSelections: this.props.app.dm.resource.getPeriodSelections(),
        values: {
          metric: metricSelections.length ? metricSelections[0].value : null,
          period: this.props.app.dm.resource.PERIOD_1_HOUR,
        },
      };
    }
    async initMetrics() {
      let periodInfo = this.props.app.dm.resource.getPeriodFilterInfo(
        this.state.period
      );
      let opts = {
        period: periodInfo.period,
        startTime: periodInfo.startTime,
      };
      if (this.props.resourceDockerTaskUid)
        opts.resourceDockerTaskUid = this.props.resourceDockerTaskUid;

      let metrics = await this.props.app.dm.resource.fetchMetricData(
        this.props.resourceUid,
        this.state.metric,
        opts
      );
      this.setState({
        data: metrics,
        isLoaded: true,
      });
    }
    render() {
      let periodInfo = this.props.app.dm.resource.getPeriodFilterInfo(
        this.state.period
      );
      return (
        <View style={this.props.style}>
          <Form
            name={
              this.props.metric
                ? `metricChartForm_${this.props.metric}`
                : "metricChartForm"
            }
            validateBeforeSubmit
            initialValues={this.formData.values}
            style={styles.metricForm}
          >
            <SectionHeader level={2} style={styles.title}>
              {this.props.metric
                ? this.resourceDomain.getMetricTypeLabel(this.props.metric)
                : "Metrics"}
            </SectionHeader>
            {!this.props.metric && (
              <SelectMenuField
                useValuesOnly
                name='metric'
                items={this.formData.metricSelections}
                onValueChange={this.handleMetricValueChange}
                style={styles.headerSelectMenu}
                containerStyle={styles.headerSelectMenuContainer}
                // valueLabelTextStyle={styles.headerSelectMenuValueLabel}
              />
            )}

            <SelectMenuField
              useValuesOnly
              name='period'
              items={this.formData.periodSelections}
              onValueChange={this.handlePeriodValueChange}
              style={styles.headerSelectMenu}
              containerStyle={styles.headerSelectMenuContainer}
              // valueLabelTextStyle={styles.headerSelectMenuValueLabel}
            />
          </Form>
          {!this.state.isLoaded && (
            <ActivityIndicator
              size='large'
              containerStyle={styles.activityIndicator}
            />
          )}
          {this.state.isLoaded && (
            <Chart
              name={
                this.props.metric
                  ? `metricChartChart_${this.props.metric}`
                  : "metricChartChart"
              }
              data={this.state.data}
              style={styles.metricsChart}
              height={300}
              type='timeSeries'
              dateAxis={{
                dateRangeStart: this.props.app.utils.date.roundUpToMinuteInterval(
                  periodInfo.startTime,
                  5
                ),
                dateRangeEnd: new Date(),
                intervalUnit: "second",
                intervalValue: periodInfo.period,
                labelFormats: {
                  minute: "h:mm a",
                  hour: "h:mm a",
                  day: "MMMM d, yyyy",
                },
              }}
            />
          )}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  metricForm: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...StyleSheet.padding(0, 12, 0, 16),
  },
  metricsChart: {
    ...StyleSheet.padding(16, 0, 16, 0),
  },
  headerSelectMenu: {
    flex: 0,
    // width: 192,
    minWidth: 80,
    marginBottom: 0,
    // ...StyleSheet.margin(-12, 0, 0, -12)
  },
  headerSelectMenuContainer: {
    borderColor: "transparent",
    // borderBottomColor: '#FFF'
  },
  headerSelectMenuValueLabel: {
    fontWeight: "500",
    fontSize: 18,
  },
  activityIndicator: {
    height: 300,
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    ...StyleSheet.padding(0),
    marginRight: "auto",
  },
});
