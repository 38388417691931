import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import ResourceScreenLayout from "../components/ResourceScreenLayout";
import EventTaskDataTable from "../components/EventTaskDataTable";
export default R14.connect(
  class ResourcesEventsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.eventTaskDomain = this.props.app.dm.eventTask;
    }
    getEventTaskAddRoute(){
      let ret = null;
      switch(this.props.type){
        case this.eventTaskDomain.TYPE_RESOURCE:
          ret = {
            route: "resourceEventTasksAdd",
            params: { uid: this.props.resource.uid }
          };
          break;
        case this.eventTaskDomain.TYPE_PROJECT:
          throw new Error('TODO');
          ret = {
            route: "eventTasksAdd",
            params: { uid: this.props.project.uid }
          }
          break;
        default:
          throw new Error(`No Event Task route for type ${this.props.type}`);
      }
      return ret;
    }
    render() {
      if(! this.props.type) throw new Error('No type found');
      return (
        <ResourceScreenLayout
          floatingActionButton={{
            to: this.getEventTaskAddRoute(),
            icon: "add"
          }}
          resource={this.props.resource}
        >
          <EventTaskDataTable
            widget={false}
            // editAction='eventTasksEdit'
            type={this.props.type}
            resourceUid={this.props.resource && this.props.resource.uid}
            projectUid={this.props.project && this.props.project.uid}
          />
        </ResourceScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
