import React, { useCallback } from "react";
import R14, {
  Text,
  Card,
  IconButton,
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  FadeView,
  Icon,
  View,
  Chip,
  ChipGroup,
  DataTable,
  ShowHideText,
  Image,
} from "../core";
import DashboardCardSection from "./DashboardCardSection";
import PipelineBlockMetricsChart from "./PipelineBlockMetricsChart";
export default R14.connect(
  class PipelineBlockMetricsWidget extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineBlockDm = this.props.app.dm.pipelineBlock;
      this.metrics = {};
      let defaultMetricKey = null;
      this.props.pipelineBlock.latestPipelineBlockMetrics.nodes.forEach(
        (metric) => {
          if (!defaultMetricKey) defaultMetricKey = metric.key;
          this.metrics[metric.key] = metric;
        }
      );
      //defaultMetricKey = "performance";
      this.state = {
        metricKey: defaultMetricKey,
      };
    }
    handleChipPress(metricKey) {
      this.setState({ metricKey });
    }
    renderMetric() {
      let ret = null;
      if (!this.state.metricKey) return null;
      let metric = this.metrics[this.state.metricKey];
      switch (metric.type) {
        case this.pipelineBlockDm.METRIC_TYPE_NUMERIC:
          ret = (
            <DashboardCardSection title={metric.name}>
              <Text style={styles.numericText}>{metric.numericValue}</Text>
              <PipelineBlockMetricsChart
                pipelineBlockUid={this.props.pipelineBlock.uid}
                metricKey={metric.key}
              />
            </DashboardCardSection>
          );
          break;
        case this.pipelineBlockDm.METRIC_TYPE_IMAGE:
          ret = (
            <View style={styles.imageWrapper}>
              <Image
                autoSize
                // resizeMode='contain'
                height={metric.imageValue.height}
                width={metric.imageValue.width}
                source={{ key: metric.imageValue.key }}
              />
            </View>
          );
          break;
        case this.pipelineBlockDm.METRIC_TYPE_DATA_FRAME:
          if (!metric.dataFrameValue) return null;
          let dataFrameValue = JSON.parse(metric.dataFrameValue);
          if (!dataFrameValue["0"]) return null;
          let columns = null;
          let pageData = [];

          for (let i in dataFrameValue) {
            if (!columns) {
              let colIdx = 0;
              columns = {};
              let totalColumns = Object.keys(dataFrameValue).length;
              let columnWidth = 100 / totalColumns;
              for (let colName in dataFrameValue[i]) {
                columns[colName] = {
                  label: colName,
                  width: 120,
                  //sortable: true
                  // collapse: colIdx !== 0 ? ({ width }) => {
                  //   return width <= 640;
                  // } : null,
                };
                colIdx++;
              }
            }
            pageData.push({ ...dataFrameValue[i], idx: i });
          }
          let pageLoader = ({ page, rowsPerPage }) => {
            console.log();
            let startIdx = rowsPerPage * (page - 1);
            let endIdx = startIdx + rowsPerPage;
            let lastIdx = pageData.length;
            if (endIdx >= lastIdx) endIdx = lastIdx;
            return pageData.slice(startIdx, endIdx);
          };
          let initializer = (args) => {
            return {
              pageData: pageLoader(args),
              totalRows: pageData.length,
            };
          };
          let cellRenderer = ({ cell, columnName, row, columns }) => {
            return <ShowHideText text={`${cell}`} />;
          };
          ret = (
            <DataTable
              name={`metricDataTable${metric.key}`}
              // headerTitle={metric.name}
              hideColumnHeaders
              key={metric.key}
              columns={columns}
              initializer={initializer}
              pageLoader={pageLoader}
              rowKeyExtractor={(row, idx) => `metricRow${row.idx}`}
              cellRenderer={cellRenderer}
            />
          );
          break;
      }
      return ret;
    }
    render() {
      if (
        !this.props.pipelineBlock.latestPipelineBlockMetrics ||
        !this.props.pipelineBlock.latestPipelineBlockMetrics.nodes ||
        !this.props.pipelineBlock.latestPipelineBlockMetrics.nodes.length
      ){
        return <View style={styles.noMetrics}><Text>No metrics available.</Text></View>
      }
      else return (
          <Card
            style={styles.card}
            title={this.props.title || null}
            titleStyle={styles.titleStyle}
            surfaceStyle={styles.cardSurface}
          >
            <ChipGroup style={[styles.cellChipGroup, !this.props.title && styles.cellChipGroupNoTitle]}>
              {this.props.pipelineBlock.latestPipelineBlockMetrics.nodes.map(
                (metric) => (
                  <Chip
                    key={`metric${metric.key}`}
                    label={metric.name}
                    selected={this.state.metricKey === metric.key}
                    onPress={() => this.handleChipPress(metric.key)}
                  />
                )
              )}
            </ChipGroup>
            {this.renderMetric()}
          </Card>
        );
      return null;
    }
  }
);
let styles = StyleSheet.create({
  card: {
    // flex: 1,
    // width: "100%",
  },
  cardSurface: {
    width: "100%",
    paddingBottom: 12,
    ...StyleSheet.margin(0),
    elevation: 0,
  },
  cellChipGroupNoTitle: {
    paddingTop: 12
  },
  noMetrics: {
    ...StyleSheet.padding(16)
  },
  titleStyle: {
    fontSize: 18,
    fontWeight: "500",
  },
  numericText: {
    fontSize: 18,
  },
  imageWrapper: {
    flex: 1,
    width: "100%",
    ...StyleSheet.padding(16, 0, 0, 0),
  },
});
